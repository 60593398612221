import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { IModalsService, IMODALS_SERVICE, P500UIBaseModal } from '@platform500services/p500-ui-kit';
import { filter, take } from 'rxjs/operators';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';

@Component({
  selector: 'gtd-notifications-modal',
  template: `<div class="p500-ui-modal__inner">
    <div class="p500-ui-modal__head">
      <div class="row">
        <div class="p500-ui-modal__close" (click)="close()">
          <p500-ui-icon name="controls-close"></p500-ui-icon>
        </div>
        <div class="p500-ui-modal__title col-12">{{ title }}</div>
        <div class="p500-ui-modal__subtitle col-12">ID: {{ id }}</div>
      </div>
    </div>
    <div class="p500-ui-modal__body">
      <div *ngIf="time" class="p500-ui-modal__date">{{ time | date: "dd MM. y 'at' HH:mm" }}</div>
      <div *ngIf="!template">{{ message }}</div>
      <ng-container *ngIf="template">
        <ng-container *ngTemplateOutlet="template; context: { message: message, linkId: linkId, userName: userName }"></ng-container>
      </ng-container>
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroySubscribers()
export class NotificationsModal extends P500UIBaseModal implements OnInit {
  @HostBinding('class') get className() {
    return `p500-ui-modal p500-ui-modal--small p500-ui-modal p500-ui-modal--primary`;
  }
  @Input() title: string;
  @Input() id: number;
  @Input() time: number;
  @Input() message: string;
  @Input() template: TemplateRef<any>;
  @Input() linkId: number;
  @Input() userName: string;
  subscribers: any = {};

  constructor(@Inject(IMODALS_SERVICE) public modalsService: IModalsService, private router: Router) {
    super(modalsService);
  }

  ngOnInit(): void {
    this.subscribers.router = this.router.events
      .pipe(
        filter((route) => route instanceof NavigationStart),
        take(1)
      )
      .subscribe(() => this.close());
  }
}
