<p500-ui-select-field
  [label]="settings?.label"
  [clearable]="settings?.clearable"
  [multiple]="settings?.multiple"
  [data]="selectData"
  [formControl]="control"
  [hidden]="!isHasData"
></p500-ui-select-field>
<p500-ui-tags-field
  [label]="settings?.label"
  [clearable]="settings?.clearable"
  [onlyNumbers]="true"
  [formControl]="control"
  [hidden]="isHasData || !settings?.multiple"
></p500-ui-tags-field>
<p500-ui-number-field [label]="settings?.label" [formControl]="control" [hidden]="isHasData || settings?.multiple"></p500-ui-number-field>
