import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { IParsedDataResponse } from '../services/data/data-service.interface';
import { RbacPermissionsConstant } from '../services/rbac/rbac-permissions.constant';
import { IRbacService } from '../services/rbac/rbac-service.interface';

export enum TpDependsTypes {
  tp = 'traffic_provider_id',
  offer = 'offer_id',
  affiliate = 'affiliate_id',
}

export class TPDependsFields {
  constructor(
    private subscribers: Record<string, Subscription>,
    private globalData: IParsedDataResponse,
    private rbacService: IRbacService
  ) {}
  public TpDependsTypes = TpDependsTypes;
  private tpDependData = new Map<string, IDependData>();

  public init(tpControl: AbstractControl, onlySelf = false): void {
    this.subscribers.tpControl?.unsubscribe();
    this.subscribers.tpControl = tpControl.valueChanges.pipe(startWith(tpControl.value)).subscribe((value) => {
      this.tpDependData.set(this.TpDependsTypes.offer, this.globalData.selects.offers?.[value] || null);
      if (this.rbacService.can(RbacPermissionsConstant.VISIBILITY_AFFILIATE_IDS)) {
        this.tpDependData.set(this.TpDependsTypes.affiliate, this.globalData.selects.affiliates?.[value] || null);
      } else {
        this.tpDependData.set(this.TpDependsTypes.affiliate, null);
      }
    });
    if (!tpControl.value?.length) {
      const tpKeys = Object.keys(this.globalData.selects.tracking_providers || {});
      if (tpKeys.length === 1) {
        tpControl.patchValue(tpKeys[0], { onlySelf });
      }
    }
  }

  public get affiliates(): IDependData {
    return this.tpDependData.get(this.TpDependsTypes.affiliate);
  }

  public get offers(): IDependData {
    return this.tpDependData.get(this.TpDependsTypes.offer);
  }
}

type IDependData = Record<string, string> | null;
