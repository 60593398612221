import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IAUTH_SERVICE, IAuthService } from '../services/auth/auth-service.interface';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, @Inject(IAUTH_SERVICE) private authService: IAuthService) {}

  canActivate(): boolean {
    if (this.authService.isAuthorized()) {
      return true;
    }

    this.router.navigate(['/auth/login']);

    return false;
  }
}
