export class TokenResponse {
  constructor(public token: string) {}

  /**
   * Processing from json
   */
  public static fromJson(json: any) {
    return new TokenResponse(json.data);
  }
}
