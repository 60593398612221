import { Component, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TagsFieldComponent } from '@platform500services/p500-ui-kit';
import { isObservable, Subscription } from 'rxjs';
import { DestroySubscribers } from '../../../../decorators/destroy-subscribers.decorator';

@Component({
  selector: 'gtd-select-or-tags',
  templateUrl: './select-or-tags.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectOrTagsComponent),
      multi: true,
    },
  ],
})
@DestroySubscribers()
export class SelectOrTagsComponent implements OnInit, ControlValueAccessor {
  constructor(private inj: Injector) {}
  @Input() public set data(data: unknown) {
    this.subscribers.data?.unsubscribe();
    if (isObservable(data)) {
      this.subscribers.data = data.subscribe((value) => {
        this.hasData = value;
      });
    } else {
      this.hasData = data;
    }
    this.selectData = data;
  }
  @Input() public settings: any;
  public subscribers: Record<string, Subscription> = {};
  public isHasData = false;
  public control: AbstractControl;
  public selectData: unknown;
  @ViewChild(TagsFieldComponent) private tagsFieldComponent: TagsFieldComponent;

  public ngOnInit(): void {
    this.control = this.inj.get(NgControl).control;
  }

  private set hasData(data: unknown) {
    try {
      this.isHasData = !!Object.keys(data || {}).length;
    } catch (error) {
      this.isHasData = false;
    }
    this.control?.reset();
    if (this.tagsFieldComponent) {
      this.tagsFieldComponent.changeData(this.control?.value || []);
    }
  }

  public writeValue(): void {}
  public registerOnChange(): void {}
  public registerOnTouched(): void {}
}
