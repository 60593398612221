import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'gtd-ui-current-time',
  templateUrl: './current-time.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentTimeComponent {
  public date = new Observable((obs: Subscriber<Date>) => {
    setInterval(() => {
      obs.next(new Date());
    }, 1750);
  }).pipe(startWith(new Date()));
}
