<div class="c-modal c-modal--image">
  <div class="c-modal__inner">
    <div class="c-modal__image-wrapper">
      <img [src]="url" class="c-modal__image" />
      <div class="c-modal__close" (click)="close()">
        <p500-ui-icon name="controls-close"></p500-ui-icon>
      </div>
    </div>
  </div>
</div>
