import { ActionButtonModule } from '../action-button/action-button.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActionSimpleLinkComponent } from './action-simple-link.component';

@NgModule({
  imports: [CommonModule, ActionButtonModule],
  declarations: [ActionSimpleLinkComponent],
  exports: [ActionSimpleLinkComponent],
})
export class ActionSimpleLinkModule {}
