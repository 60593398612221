<div class="c-field-value" *ngIf="dataString" [ngClass]="cssClass" [attr.data-value]="dataString">
  <span
    *ngIf="dataString.length > length"
    class="c-field-value__text cursor-pointer"
    p500UITooltip
    [tooltipData]="dataString"
    [longContent]="true"
    (click)="openViewModal($event, dataString)"
  >
    {{ dataString | slice: 0:length }}
    <button class="ml-6" type="button" p500-ui-confluent-button size="small" [transparent]="true">•••</button>
  </span>
  <span class="c-field-value__text" *ngIf="dataString.length <= length">{{ dataString }}</span>
</div>
<gtd-ui-field-empty-component *ngIf="!dataString"></gtd-ui-field-empty-component>
