import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { P500UIIconsModule } from '@platform500services/p500-ui-kit';
import { SettingsMenuComponent } from './settings-menu.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, P500UIIconsModule, RouterModule],
  declarations: [SettingsMenuComponent],
  exports: [SettingsMenuComponent],
})
export class SettingsMenuModule {}
