import { ActionButtonComponent } from './action-button.component';
import { ActionFormModalModule } from '../../../modals/action-form/action-form-modal.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProcessButtonComponent } from './process-button.component';

@NgModule({
  imports: [CommonModule, ActionFormModalModule],
  declarations: [ActionButtonComponent, ProcessButtonComponent],
  exports: [ActionButtonComponent, ProcessButtonComponent],
})
export class ActionButtonModule {}
