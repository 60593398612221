import { LOCALE_ID, NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import locale from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { IMODALS_SERVICE, ModalsService } from '@platform500services/p500-ui-kit';
import { INOTIFICATIONS_SERVICE, NotificationsService } from '@platform500services/p500-ui-kit';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GtdNgWidgetModule } from 'gtd-ng-widget';
import { GuidedTourService } from 'ngx-guided-tour';
import * as Sentry from '@sentry/angular';

import { EnvironmentService } from './core/services/environment/environment.service';
import { IENVIRONMENT_SERVICE } from './core/services/environment/environment.interface';
import { ActionsService } from './core/services/actions/actions.service';
import { IACTIONS_SERVICE } from './core/services/actions/actions-service.interface';
import { ApplicationService } from './core/services/applications/application.service';
import { IAPPLICATIONS_SERVICE } from './core/services/applications/applications.interfaces';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthService } from './core/services/auth/auth.service';
import { IAUTH_SERVICE } from './core/services/auth';
import { BaseLayoutModule } from './core/layouts/base/base.module';
import { BrowserWindowRef } from './core/services/window/window.service';
import { CanGuard } from './core/services/rbac/guards/can.guard';
import { CentrifugeService } from './core/services/sockets/centrifuge.service';
import { ISOCKETS_SERVICE } from './core/services/sockets/sockets-service.interface';
import { ClipboardService } from './core/services/clipboard/clipboard.service';
import { ICLIPBOARD_SERVICE } from './core/services/clipboard/clipboard-service.interface';
import { ConfigurationResolverService } from './core/services/configuration/configuration.resolver.service';
import { ConfigurationService } from './core/services/configuration/configuration.service';
import { ICONFIGURATION_SERVICE } from './core/services/configuration/configuration-service.interface';
import { DataResolverService } from './core/services/data/data.resolver.service';
import { DataService } from './core/services/data/data.service';
import { IDATA_SERVICE } from './core/services/data/data-service.interface';
import { EventsService } from './core/services/events/events.service';
import { IEVENT_SERVICE } from './core/services/events/events.interface';
import { GuestGuard } from './core/guards/guest.guard';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { PageService } from './core/services/page/page.service';
import { IPAGE_SERVICE } from './core/services/page/page-service.interface';
import { RbacService } from './core/services/rbac/rbac.service';
import { IRBAC_SERVICE } from './core/services/rbac/rbac-service.interface';
import { SidebarService } from './core/services/sidebar/sidebar.service';
import { ISIDEBAR_SERVICE } from './core/services/sidebar/sidebar.interface';
import { UserResolverService } from './core/services/auth/user.resolver.service';
import { UnsecuredService } from './core/services/unsecured/unsecured.service';
import { IUNSECURED_SERVICE } from './core/services/unsecured/unsecured-service.interface';
import { environment } from '../environments/environment';
import { IENVIRONMENT } from '../environments/environment.interface';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ErrorHandlerService } from './core/services/error-handler/error-handler.service';

registerLocaleData(locale, 'locale');

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    GtdNgWidgetModule.forRoot(environment.services),
    BaseLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    UserResolverService,
    DataResolverService,
    ConfigurationResolverService,
    BrowserWindowRef,
    AuthGuard,
    GuestGuard,
    CanGuard,
    { provide: LOCALE_ID, useValue: 'locale' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: ISOCKETS_SERVICE,
      useClass: CentrifugeService,
    },
    {
      provide: IAUTH_SERVICE,
      useClass: AuthService,
    },
    {
      provide: IACTIONS_SERVICE,
      useClass: ActionsService,
    },
    {
      provide: IDATA_SERVICE,
      useClass: DataService,
    },
    {
      provide: IPAGE_SERVICE,
      useClass: PageService,
    },
    {
      provide: IEVENT_SERVICE,
      useClass: EventsService,
    },
    {
      provide: IMODALS_SERVICE,
      useClass: ModalsService,
    },
    {
      provide: INOTIFICATIONS_SERVICE,
      useClass: NotificationsService,
    },
    {
      provide: IRBAC_SERVICE,
      useClass: RbacService,
    },
    {
      provide: IENVIRONMENT_SERVICE,
      useClass: EnvironmentService,
    },
    {
      provide: ICLIPBOARD_SERVICE,
      useClass: ClipboardService,
    },
    {
      provide: ICONFIGURATION_SERVICE,
      useClass: ConfigurationService,
    },
    {
      provide: IAPPLICATIONS_SERVICE,
      useClass: ApplicationService,
    },
    {
      provide: ISIDEBAR_SERVICE,
      useClass: SidebarService,
    },
    {
      provide: IUNSECURED_SERVICE,
      useClass: UnsecuredService,
    },
    {
      provide: IENVIRONMENT,
      useValue: environment,
    },
    GuidedTourService,
  ],
})
export class AppModule {}
