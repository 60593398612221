<form (ngSubmit)="submit()" [formGroup]="form">
  <div class="c-settings c-table-header__settings-inner is-open">
    <div class="c-settings__inner">
      <div class="c-settings__title">
        <div class="c-filters__title-caption">
          <p500-ui-icon name="arrows-big-left c-filters__title-arrow d-sm-none" (click)="closeModal()"></p500-ui-icon>
          List Settings
        </div>
        <div class="c-link c-link--primary -red d-sm-none" (click)="resetDisplayOptions()">Reset Settings</div>
      </div>
      <div class="c-settings__body" cdkScrollable>
        <div class="c-settings__select">
          <p500-ui-select-field label="Items per page:" [formControl]="limitControl" [data]="availableLimits">
          </p500-ui-select-field>
        </div>
        <div class="c-settings__items" *ngIf="showColumnsSettings">
          <div class="c-settings__items-label">Displayed columns:</div>
          <div cdkDropList (cdkDropListDropped)="drop($event)">
            <ng-container *ngFor="let field of fields">
              <div class="c-settings__item" cdkDrag [cdkDragDisabled]="dragDisabled">
                <p500-ui-checkbox-field [formControlName]="field.key" [label]="field.label" *ngIf="field.label">
                </p500-ui-checkbox-field>
                <!-- <div class="cdk-drag-placeholder" *cdkDragPlaceholder></div> -->
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="c-filters__footer">
        <div class="c-filters__apply">
          <button p500-ui-button size="big" type="submit">Apply Settings</button>
        </div>
        <button p500-ui-button size="big" [transparent]="true" color="warn" class="c-filters__reset d-none d-sm-block"
          type="button" (click)="resetDisplayOptions()">
          Reset Settings
        </button>
      </div>
    </div>
  </div>
</form>