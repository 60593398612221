import { CommonModule } from '@angular/common';
import { HelpMenuComponent } from './help-menu.component';
import { NgModule } from '@angular/core';
import { P500UIIconsModule } from '@platform500services/p500-ui-kit';

@NgModule({
  imports: [CommonModule, P500UIIconsModule],
  declarations: [HelpMenuComponent],
  exports: [HelpMenuComponent],
})
export class HelpMenulModule {}
