import { ActionConfigInterface, Group } from '../interfaces/action-config.interface';
import { FieldModel } from '../models/field.model';
import { Injectable, Inject } from '@angular/core';
import { IDATA_SERVICE, IDataService, IParsedDataResponse } from '../../data/data-service.interface';
import { IRBAC_SERVICE, IRbacService } from '../../rbac/rbac-service.interface';

@Injectable()
export abstract class ActionConfigAbstract implements ActionConfigInterface {
  protected abstract readonly key: string;
  protected abstract readonly repository: string;
  protected abstract readonly title: string;
  protected abstract readonly description: string;
  protected abstract readonly fields: FieldModel[];
  protected readonly modalStyle: string = '-medium';
  protected readonly gridFields: number[] = [];
  protected readonly tooltipsFields: object = {};
  protected readonly btnCaption: string = 'Submit';
  protected readonly groups: Group[];
  public globalData: IParsedDataResponse;

  constructor(@Inject(IDATA_SERVICE) public dataService: IDataService, @Inject(IRBAC_SERVICE) public rbacService: IRbacService) {
    this.globalData = this.dataService.getData();
  }

  getKey(): string {
    return this.key;
  }

  getData(): string {
    return this.repository;
  }

  getTitle(): string {
    return this.title;
  }

  getDescription(): string {
    return this.description;
  }

  getFields(): FieldModel[] {
    return this.fields;
  }

  getModalStyle(): string {
    return this.modalStyle;
  }

  getGrid(): number[] {
    return this.gridFields;
  }

  getTooltipsFields(): object {
    return this.tooltipsFields;
  }

  getBtnCaption(): string {
    return this.btnCaption;
  }

  getGroup(): Group[] | null {
    return this.groups ?? null;
  }
}
