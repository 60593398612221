export class RbacPermissionsConstant {
  /**
   * Pages
   */
  public static PAGES_SINGLE_AB_TEST = 'Pages\\Single\\ABTest';
  public static PAGES_SINGLE_BRAND = 'Pages\\Single\\Brand';
  public static PAGES_SINGLE_CAP = 'Pages\\Single\\Cap';
  public static PAGES_SINGLE_CLICK = 'Pages\\Single\\Click';
  public static PAGES_SINGLE_COMPANY = 'Pages\\Single\\Company';
  public static PAGES_SINGLE_CRM_WORKER = 'Pages\\Single\\CRMWorker';
  public static PAGES_SINGLE_FUNNEL = 'Pages\\Single\\Funnel';
  public static PAGES_SINGLE_FUNNELS_PIXEL = 'Pages\\Single\\FunnelsPixel';
  public static PAGES_SINGLE_GEO_FUNNEL = 'Pages\\Single\\GeoFunnel';
  public static PAGES_SINGLE_LEAD = 'Pages\\Single\\Lead';
  public static PAGES_SINGLE_NOTIFICATION_RULE = 'Pages\\Single\\NotificationRule';
  public static PAGES_SINGLE_PARAMETER_PROXY = 'Pages\\Single\\ParameterProxy';
  public static PAGES_SINGLE_TRACKING_PROVIDER = 'Pages\\Single\\TrackingProvider';
  public static PAGES_SINGLE_TRAFFIC_FILTER = 'Pages\\Single\\TrafficFilter';
  public static PAGES_SINGLE_EMAIL_TEMPLATE = 'Pages\\Single\\EmailTemplate';
  public static PAGES_SINGLE_INCIDENT = 'Pages\\Single\\Incident';
  public static PAGES_SINGLE_POSTBACK = 'Pages\\Single\\Postback';
  public static PAGES_SINGLE_AFFILIATE_COMMUNICATION = 'Pages\\Single\\AffiliateCommunication';

  public static PAGES_SPECIAL_ACCESS_CONTROLS = 'Pages\\Special\\AccessControls';
  public static PAGES_SPECIAL_CHARTS_BUILDER = 'Pages\\Special\\ChartsBuilder';
  public static PAGES_SPECIAL_COMPARING_ANALYTICS = 'Pages\\Special\\ComparingAnalytics';
  public static PAGES_SPECIAL_CRM_SETTINGS = 'Pages\\Special\\CRMSettings';
  public static PAGES_SPECIAL_DASHBOARD = 'Pages\\Special\\Dashboard';
  public static PAGES_SPECIAL_DEALS_UPDATER = 'Pages\\Special\\DealsUpdater';
  // public static PAGES_SPECIAL_DRILL_DOWN_REPORTS_LEGACY = 'Pages\\Special\\DrilldownReportsLegacy';
  public static PAGES_SPECIAL_DRILL_DOWN_REPORTS = 'Pages\\Special\\DrilldownReports';
  public static PAGES_SPECIAL_EMAIL_BUILDER = 'Pages\\Special\\EmailBuilder';
  public static PAGES_SPECIAL_INVOICE_GENERATOR = 'Pages\\Special\\InvoiceGenerator';
  public static PAGES_SPECIAL_QUERIES_AUDIT = 'Pages\\Special\\QueriesAudit';
  public static PAGES_SPECIAL_REPORTS_BUILDER = 'Pages\\Special\\ReportsBuilder';
  public static PAGES_SPECIAL_ROTATION = 'Pages\\Special\\Rotation';
  public static PAGES_SPECIAL_PAYOUTS = 'Pages\\Special\\PayoutTree';
  public static PAGES_SPECIAL_REVENUE = 'Pages\\Special\\RevenueTree';
  public static PAGES_SPECIAL_LEADS_REPUSH_BUILDER = 'Pages\\Special\\LeadsRepusher';
  public static PAGES_SPECIAL_LEADS_REPUSH_BUCKETS = 'Pages\\Special\\RepusherBuckets';
  public static PAGES_SPECIAL_LEADS_REPUSH_DASHBOARD = 'Pages\\Special\\RepusherDashboard';
  // TODO: set proper PAGES_SPECIAL_LEADS_REPUSH_DRILLDOWN value
  public static PAGES_SPECIAL_LEADS_REPUSH_DRILLDOWN = 'Pages\\Special\\RepusherDashboard';
  public static PAGES_SPECIAL_FOLLOW_UP_DASHBOARD = 'Pages\\Special\\FollowUpDashboard';
  public static PAGES_SPECIAL_NEW_NOTIFICATIONS_BUILDER = 'Pages\\Special\\FollowUpDashboard';
  // TODO: set proper PAGES_SPECIAL_LEADS_REPUSH_DRILLDOWN value
  public static PAGES_SPECIAL_FOLLOW_UP_DRILLDOWN = 'Pages\\Special\\FollowUpDashboard';
  public static PAGES_SPECIAL_FOLLOW_UP_CHARTS_BUILDER = 'Pages\\Special\\FollowUpChartsBuilder';
  public static PAGES_SPECIAL_INCIDENTS_DASHBOARD = 'Pages\\Special\\IncidentsDashboard';
  public static PAGES_SPECIAL_INCIDENTS_POPUP = 'Pages\\Special\\IncidentsPopup';
  public static PAGES_SPECIAL_AFFILIATE_COMMUNICATIONS_REPORT = 'Pages\\Special\\AffiliateCommunicationsReport';
  public static PAGES_SPECIAL_SETTINGS = 'Pages\\Special\\Settings';

  public static SCOPES_VIEW_ONLY = 'Scopes\\ViewOnly';

  public static PAGES_TABLE_AB_TESTS = 'Pages\\Table\\ABTests';
  public static PAGES_TABLE_API_TOKENS = 'Pages\\Table\\APITokens';
  public static PAGES_TABLE_BRANDS = 'Pages\\Table\\Brands';
  public static PAGES_TABLE_TRAFFIC_FILTERS = 'Pages\\Table\\TrafficFilters';
  public static PAGES_TABLE_CLICKS = 'Pages\\Table\\Clicks';
  public static PAGES_TABLE_COMPANIES = 'Pages\\Table\\Companies';
  public static PAGES_TABLE_CONVERSIONS = 'Pages\\Table\\Conversions';
  public static PAGES_TABLE_CRM_WORKERS = 'Pages\\Table\\CRMWorkers';
  public static PAGES_TABLE_DEALS_HISTORY = 'Pages\\Table\\DealsHistory';
  public static PAGES_TABLE_EVENTS_LOG = 'Pages\\Table\\EventsLog';
  public static PAGES_TABLE_FUNNELS = 'Pages\\Table\\Funnels';
  public static PAGES_TABLE_FUNNELS_PIXELS = 'Pages\\Table\\FunnelsPixels';
  public static PAGES_TABLE_GEO_FUNNELS = 'Pages\\Table\\GeoFunnels';
  public static PAGES_TABLE_LATE_FTDS = 'Pages\\Table\\LateFTDs';
  public static PAGES_TABLE_LEADS = 'Pages\\Table\\Leads';
  public static PAGES_TABLE_NOTIFICATION_RULES = 'Pages\\Table\\NotificationRules';
  public static PAGES_TABLE_OPTINS = 'Pages\\Table\\Optins';
  public static PAGES_TABLE_PARAMETER_PROXIES = 'Pages\\Table\\ParameterProxies';
  public static PAGES_TABLE_POSTBACKS = 'Pages\\Table\\Postbacks';
  public static PAGES_TABLE_POSTBACK_PENDERS = 'Pages\\Table\\PostbackPenders';
  public static PAGES_TABLE_TRACKING_PROVIDERS = 'Pages\\Table\\TrackingProviders';
  public static PAGES_TABLE_WHITELISTED_IPS = 'Pages\\Table\\WhitelistedIPs';
  public static PAGES_TABLE_NOTIFICATIONS_BUILDER = 'Pages\\Table\\FollowUpRules';
  public static PAGES_TABLE_INVOICES = 'Pages\\Table\\Invoices';
  public static PAGES_TABLE_EMAIL_TEMPLATES = 'Pages\\Table\\EmailTemplates';
  public static PAGES_TABLE_SMS_TEMPLATES = 'Pages\\Table\\SmsTplsLanguageGroups';
  public static PAGES_TABLE_PUSH_LANGUAGE_GROUPS = 'Pages\\Table\\PushTplsLanguageGroups';
  public static PAGES_TABLE_INCIDENTS = 'Pages\\Table\\Incidents';
  public static PAGES_TABLE_LEADS_REPUSH_BUCKETS = 'Pages\\Special\\RepusherBuckets';
  public static PAGES_TABLE_AFFILIATE_COMMUNICATIONS = 'Pages\\Table\\AffiliateCommunications';
  public static PAGES_TABLE_UNSECURED_MODE_LOGS = 'Pages\\Table\\UnsecuredModeLogs';
  public static PAGES_TABLE_BRANDS_COUNTRY_TESTS = 'Pages\\Table\\BrandsCountryTests';
  public static PAGES_TABLE_NOTIFICATIONS = 'Pages\\Table\\Notifications';

  /**
   * Global Visibility
   */
  public static VISIBILITY_LAYOUT_DOWNLOAD_APPS = 'Visibility\\Layout\\DownloadApps';
  public static VISIBILITY_LAYOUT_SEND_REPORT = 'Visibility\\Layout\\SendReport';
  public static VISIBILITY_LAYOUT_SOCKETS_DATA = 'Visibility\\Layout\\SocketsData';
  public static VISIBILITY_SCOPES_IMMEDIATE_EDGE = 'Visibility\\Data\\ImmediateEdge';
  public static VISIBILITY_SCOPES_SEPA_FX = 'Scopes\\SepaFX';
  // TODO: set proper VISIBILITY_INCIDENTS_DATA value
  public static VISIBILITY_INCIDENTS_DATA = 'Visibility\\Layout\\SocketsData';
  public static VISIBILITY_AFFILIATE_IDS = 'Visibility\\Data\\AffiliateIds';
  public static VISIBILITY_POSTBACK_PENDER_FIELDS = 'Visibility\\Fields\\PostbackPenderFields';
  public static VISIBILITY_AGREEMENTS_STATUS = 'Visibility\\Layout\\AgreementsStatus';

  /**
   * Widgets
   */
  public static WIDGETS_AB_TEST_FUNNELS = 'Widgets\\ABTest\\Funnels';
  public static WIDGETS_AB_TEST_LAST_LEADS = 'Widgets\\ABTest\\LastLeads';
  public static WIDGETS_AB_TEST_PROFILE = 'Widgets\\ABTest\\Profile';

  public static WIDGETS_BRAND_API_SETTINGS = 'Widgets\\Brand\\ApiSettings';
  public static WIDGETS_BRAND_API_STATUS = 'Widgets\\Brand\\ApiStatus';
  public static WIDGETS_BRAND_BLOCK_CONDITIONS = 'Widgets\\Brand\\BlockConditions';
  public static WIDGETS_BRAND_BRAND_STATUS = 'Widgets\\Brand\\BrandStatus';
  public static WIDGETS_BRAND_CAPS = 'Widgets\\Brand\\Caps';
  public static WIDGETS_BRAND_CURRENCY_CONDITIONS = 'Widgets\\Brand\\CurrencyConditions';
  public static WIDGETS_BRAND_LAST_LEADS = 'Widgets\\Brand\\LastLeads';
  public static WIDGETS_BRAND_LOGS = 'Widgets\\Brand\\Logs';
  public static WIDGETS_BRAND_ROTATION_LOGS = 'Widgets\\Brand\\RotationLogs';
  public static WIDGETS_BRAND_PROFILE = 'Widgets\\Brand\\Profile';
  public static WIDGETS_BRAND_SETTINGS_OVERWRITE = 'Widgets\\Brand\\SettingsOverwrite';
  public static WIDGETS_BRAND_STATISTIC = 'Widgets\\Brand\\Statistic';
  public static WIDGETS_BRAND_SALE_STATUSES = 'Widgets\\SaleStatus\\Mapping';
  public static WIDGETS_BRAND_LAST_COUNTRY_TESTS = 'Widgets\\Brand\\LastCountryTests';

  public static WIDGETS_CLICK_PROFILE = 'Widgets\\Click\\Profile';
  public static WIDGETS_CLICK_LOGS = 'Widgets\\Click\\PostbackLogs';

  public static WIDGETS_TRAFFIC_FILTERS_PROFILE = 'Widgets\\TrafficFilter\\Profile';
  public static WIDGETS_TRAFFIC_FILTERS_RULES = 'Widgets\\TrafficFilter\\Rules';

  public static WIDGETS_COMPANY_BANK_DETAILS = 'Widgets\\Company\\BankDetails';
  public static WIDGETS_COMPANY_BRANDS = 'Widgets\\Company\\Brands';
  public static WIDGETS_COMPANY_PROFILE = 'Widgets\\Company\\Profile';

  public static WIDGETS_CRM_WORKER_LOGS = 'Widgets\\CRMWorker\\Logs';
  public static WIDGETS_CRM_WORKER_PROFILE = 'Widgets\\CRMWorker\\Profile';
  public static WIDGETS_CRM_WORKER_RBAC_RULES = 'Widgets\\CRMWorker\\RbacRules';

  public static WIDGETS_DASHBOARD_CAPS = 'Widgets\\Dashboard\\Caps';
  public static WIDGETS_DASHBOARD_LAST_LEADS = 'Widgets\\Dashboard\\LastLeads';
  public static WIDGETS_DASHBOARD_REGISTRATION_CHART = 'Widgets\\Dashboard\\RegistrationChart';
  public static WIDGETS_DASHBOARD_STATISTIC = 'Widgets\\Dashboard\\Statistic';
  public static WIDGETS_DASHBOARD_TOP_ADVERTISERS = 'Widgets\\Dashboard\\TopAdvertisers';
  public static WIDGETS_DASHBOARD_COUNTRIES_STATISTICS = 'Widgets\\Dashboard\\CountriesStatistics';

  public static WIDGETS_FUNNEL_LAST_LEADS = 'Widgets\\Funnel\\LastLeads';
  public static WIDGETS_FUNNEL_PROFILE = 'Widgets\\Funnel\\Profile';
  public static WIDGETS_FUNNEL_STATISTIC = 'Widgets\\Funnel\\Statistic';
  public static WIDGETS_FUNNEL_SETTINGS = 'Widgets\\Funnel\\Settings';

  public static WIDGETS_FUNNEL_PIXEL_LOGS = 'Widgets\\FunnelPixel\\Logs';
  public static WIDGETS_FUNNEL_PIXEL_LOGS_NEW = 'Widgets\\FunnelPixel\\FunnelPixelLogs';
  public static WIDGETS_FUNNEL_PIXEL_PROFILE = 'Widgets\\FunnelPixel\\Profile';

  public static WIDGETS_GEO_FUNNEL_FUNNELS = 'Widgets\\GeoFunnel\\Funnels';
  public static WIDGETS_GEO_FUNNEL_PROFILE = 'Widgets\\GeoFunnel\\Profile';

  public static WIDGETS_LEAD_LOGS = 'Widgets\\Lead\\Logs';
  public static WIDGETS_LEAD_LOGS_NEW = 'Widgets\\Lead\\LeadLogs';
  public static WIDGETS_LEAD_PROFILE_CLICK = 'Widgets\\Lead\\ProfileClick';
  public static WIDGETS_LEAD_PROFILE_FUNNEL = 'Widgets\\Lead\\ProfileFunnel';
  public static WIDGETS_LEAD_PROFILE_LEAD = 'Widgets\\Lead\\ProfileLead';
  public static WIDGETS_LEAD_PROFILE_TRACKING_PROVIDER = 'Widgets\\Lead\\ProfileTrackingProvider';
  public static WIDGETS_LEAD_PROFILE_BRAND = 'Widgets\\Lead\\ProfileBrand';
  public static WIDGETS_LEAD_PROFILE_SALE_REGISTRY = 'Widgets\\SaleStatus\\Registry';

  public static WIDGETS_NOTIFICATION_RULE_LOGS = 'Widgets\\NotificationRule\\Logs';
  public static WIDGETS_NOTIFICATION_RULE_PROFILE = 'Widgets\\NotificationRule\\Profile';

  public static WIDGETS_PARAMETERS_PROXY_LOGS = 'Widgets\\ParametersProxy\\Logs';
  public static WIDGETS_PARAMETERS_PROXY_PROFILE = 'Widgets\\ParametersProxy\\Profile';

  public static WIDGETS_TRACKING_PROVIDER_API_SETTINGS = 'Widgets\\TrackingProvider\\ApiSettings';
  public static WIDGETS_TRACKING_PROVIDER_LAST_LEADS = 'Widgets\\TrackingProvider\\LastLeads';
  public static WIDGETS_TRACKING_PROVIDER_PROFILE = 'Widgets\\TrackingProvider\\Profile';
  public static WIDGETS_TRACKING_PROVIDER_STATISTIC = 'Widgets\\TrackingProvider\\Statistic';

  public static WIDGETS_FOLLOW_UP_STATISTICS = 'Widgets\\FollowUp\\FollowUpStatistics';
  public static WIDGETS_FOLLOW_UP_CHART = 'Widgets\\FollowUp\\FollowUpChart';
  public static WIDGETS_FOLLOW_UP_LAST_LEADS = 'Widgets\\FollowUp\\LastLeads';
  public static WIDGETS_FOLLOW_UP_LAST_CONVERSIONS = 'Widgets\\FollowUp\\LastConversions';

  public static WIDGETS_REPUSHER_LEADS_REJECTED = 'Widgets\\Repusher\\RejectedLeads';
  public static WIDGETS_REPUSHER_STATISTICS = 'Widgets\\Repusher\\Statistics';
  public static WIDGETS_REPUSHER_REJECTED_AFTER_REPUSH = 'Widgets\\Repusher\\LastRejectedRepushedLeads';
  public static WIDGETS_REPUSHER_PROFILE = 'Widgets\\Repusher\\Profile';
  public static WIDGETS_REPUSHER_LEADS_LIST = 'Widgets\\Repusher\\Tasks';

  public static WIDGETS_EMAIL_TEMPLATE_PROFILE = 'Widgets\\EmailTemplates\\Profile';
  public static WIDGETS_EMAIL_TEMPLATE_LANGUAGE_LIST = 'Widgets\\EmailTemplates\\ListTemplates';
  public static WIDGETS_EMAIL_TEMPLATE_BUILDER = 'Widgets\\EmailTemplates\\TemplatesContent';

  public static WIDGETS_INCIDENTS_STATISTIC = 'Widgets\\Incident\\Statistic';
  public static WIDGETS_INCIDENTS_CHART = 'Widgets\\Incident\\Chart';
  public static WIDGETS_INCIDENT_PROFILE = 'Widgets\\Incident\\Profile';

  public static WIDGETS_POSTBACK_PROFILE = 'Widgets\\Postback\\Profile';
  public static WIDGETS_POSTBACK_LOGS = 'Widgets\\Postback\\Logs';

  public static WIDGETS_AFFILIATE_COMMUNICATION_PROFILE = 'Widgets\\AffiliateCommunications\\Profile';
  public static WIDGETS_ADVERTISER_GOALS = 'Widgets\\Advertisers\\Goals';
  public static WIDGETS_ADVETISER_PULLING_MAPPINGS = 'Widgets\\Advertisers\\PullingMappings';

  /**
   * Commands
   */
  public static COMMANDS_AB_TEST_ATTACH_DETACH_FUNNELS = 'Commands\\ABTest\\AttachDetachFunnels';
  public static COMMANDS_AB_TEST_CREATE_EDIT = 'Commands\\ABTest\\CreateEdit';
  public static COMMANDS_ACCESS_CONTROLS_EDIT_SINGLE_PERMISSIONS = 'Commands\\CRMWorker\\EditSinglePermissions';
  public static COMMANDS_TRAFFIC_FILTERS_CREATE_EDIT = 'Commands\\TrafficFilter\\CreateEdit';
  public static COMMANDS_TRAFFIC_FILTER_CREATE_RULE = 'Commands\\TrafficFilter\\CreateEditRule';
  public static COMMANDS_TRAFFIC_FILTER_REMOVE_RULE = 'Commands\\TrafficFilter\\RemoveRule';
  public static COMMANDS_API_TOKENS_CREATE_EDIT = 'Commands\\ApiTokens\\CreateEdit';
  public static COMMANDS_BRAND_CREATE_CURRENCY_CONDITION_RULE = 'Commands\\Brand\\CreateCurrencyConditionRule';
  public static COMMANDS_BRAND_CREATE_EDIT_BLOCK_CONDITION = 'Commands\\Brand\\CreateEditBlockCondition';
  public static COMMANDS_BRAND_CREATE_EDIT_BRAND = 'Commands\\Brand\\CreateEditBrand';
  public static COMMANDS_BRAND_CREATE_EDIT_CAP = 'Commands\\Brand\\CreateEditCap';
  public static COMMANDS_BRAND_CREATE_EDIT_OVERWRITE_RULE = 'Commands\\Brand\\CreateEditOverwriteRule';
  public static COMMANDS_BRAND_EDIT_API_SETTINGS = 'Commands\\Brand\\EditApiSettings';
  public static COMMANDS_BRAND_TEST_COUNTRY = 'Commands\\Brand\\TestCountry';
  public static COMMANDS_BRAND_TEST_SETTINGS = 'Commands\\Brand\\TestSettings';
  public static COMMANDS_COMPANY_ATTACH_DETACH_BRAND = 'Commands\\Company\\AttachDetachBrand';
  public static COMMANDS_COMPANY_CREATE_EDIT = 'Commands\\Company\\CreateEdit';
  public static COMMANDS_COMPANY_EDIT_BANK_DETAILS = 'Commands\\Company\\EditBankDetails';
  public static COMMANDS_CONVERSION_RELEASE = 'Commands\\Conversion\\Release';
  public static COMMANDS_CRM_WORKER_CREATE_EDIT = 'Commands\\CRMWorker\\CreateEdit';
  public static COMMANDS_CRM_WORKER_EDIT_SINGLE_PERMISSIONS = 'Commands\\CRMWorker\\EditSinglePermissions';
  public static COMMANDS_FUNNEL_CREATE_EDIT = 'Commands\\Funnel\\CreateEdit';
  public static COMMANDS_FUNNEL_URL_REPLACER = 'Commands\\Funnel\\ReplaceFlowUrl';
  public static COMMANDS_FUNNEL_EDIT_SETTINGS = 'Commands\\Funnel\\EditSettings';
  public static COMMANDS_FUNNEL_PIXEL_CREATE_EDIT = 'Commands\\FunnelPixel\\CreateEdit';
  public static COMMANDS_GEO_FUNNEL_ATTACH_DETACH_FUNNELS = 'Commands\\GeoFunnel\\AttachDetachFunnels';
  public static COMMANDS_GEO_FUNNEL_CREATE_EDIT = 'Commands\\GeoFunnel\\CreateEdit';
  public static COMMANDS_LATE_FTDS_CREATE_EDIT = 'Commands\\LateFTDs\\CreateEdit';
  public static COMMANDS_LEAD_CHANGE_TEST_STATUS = 'Commands\\Lead\\ChangeTestStatus';
  public static COMMANDS_LEAD_CREATE_FREEBIE = 'Commands\\Lead\\CreateFreebie';
  public static COMMANDS_LEAD_EDIT_BALANCE = 'Commands\\Lead\\EditBalance';
  public static COMMANDS_REPUSHER_CREATE = 'Commands\\Repusher\\Create';
  public static COMMANDS_REPUSHER_STOP = 'Commands\\Repusher\\Stop';
  public static COMMANDS_NOTIFICATION_RULE_CREATED_EDIT = 'Commands\\NotificationRule\\CreatedEdit';
  public static COMMANDS_PARAMETER_PROXY_CREATE_EDIT = 'Commands\\ParameterProxy\\CreateEdit';
  public static COMMANDS_POSTBACK_CREATE_EDIT = 'Commands\\Postback\\CreateEdit';
  public static COMMANDS_POSTBACK_PENDERS_CREATE_EDIT = 'Commands\\PostbackPenders\\CreateEdit';
  public static COMMANDS_ROTATION_RULE_BRANDS_ATTACH_DETACH_BRAND = 'Commands\\Rotation\\AttachDetachBrand';
  public static COMMANDS_ROTATION_RULE_CREATE_EDIT = 'Commands\\Rotation\\CreateEdit';
  public static COMMANDS_ROTATION_RULE_REMOVE = 'Commands\\Rotation\\Remove';
  public static COMMANDS_TRACKING_PROVIDER_CREATE_EDIT = 'Commands\\TrackingProvider\\CreateEdit';
  public static COMMANDS_TRACKING_PROVIDER_EDIT_API_SETTINGS = 'Commands\\TrackingProvider\\EditApiSettings';
  public static COMMANDS_TRACKING_PROVIDER_TEST_SETTINGS = 'Commands\\TrackingProvider\\TestSettings';
  public static COMMANDS_WHITELISTED_IP_CREATE_EDIT = 'Commands\\WhitelistedIP\\CreateEdit';
  public static COMMANDS_CREATE_EDIT_TEMPLATE = 'Commands\\FollowUp\\CreateEditTemplate';
  public static COMMANDS_NOTIFICATION_BUILDER_CREATE_EDIT_RULE = 'Commands\\FollowUp\\CreateEditRule';
  public static COMMANDS_EDIT_SALE_STATUSES = 'Commands\\Brand\\MapStatuses';
  public static COMMANDS_INVOICE_CREATE_EDIT = 'Commands\\Invoice\\CreateEdit';
  // TODO: fix value below
  public static COMMANDS_INVOICE_CHANGE_STATUS = 'Commands\\Invoice\\EditStatus';
  public static COMMANDS_CREATE_EDIT_EMAIL_TEMPLATES = 'Commands\\EmailTemplates\\EditTemplates';
  public static COMMANDS_CREATE_EDIT_EMAIL_GROUP = 'Commands\\EmailTemplates\\CreateEdit';
  public static COMMANDS_CREATE_EDIT_EMAIL_TEMPLATE = 'Commands\\EmailTemplates\\CreateEditTemplate';
  public static COMMANDS_CREATE_EDIT_LANGUAGE_GROUP = 'Commands\\PushTemplates\\CreateEditLanguageGroup';
  public static COMMANDS_CREATE_EDIT_SMS_GROUP = 'Commands\\SmsTemplates\\CreateEditTemplate';
  public static COMMANDS_CREATE_EDIT_PUSH_GROUP = 'Commands\\PushTemplates\\CreateEditTemplate';
  public static COMMANDS_INCIDENT_RESOLVE = 'Commands\\Incident\\Update';
  public static COMMANDS_AFFILIATE_COMMUNICATION_CREATE_EDIT = 'Commands\\AffiliateCommunications\\CreateEdit';
  public static COMMANDS_BRANDS_PULLING_DATA = 'Commands\\Brand\\PullingData';

  public static TOOLS_DRILLDOWN_GROUPER_FUNNEL = 'Tools\\DrillDown\\FunnelGrouper';
  public static TOOLS_DRILLDOWN_GROUPER_OFFER = 'Tools\\DrillDown\\OfferGrouper';
  public static TOOLS_DRILLDOWN_GROUPER_BRAND = 'Tools\\DrillDown\\BrandGrouper';
  public static TOOLS_DRILLDOWN_GROUPER_DEVICE_OSG = 'Tools\\DrillDown\\DeviceOSGrouper';
  public static TOOLS_DRILLDOWN_GROUPER_LEAD_COUNTRY = 'Tools\\DrillDown\\LeadCountryGrouper';
  public static TOOLS_DRILLDOWN_GROUPER_TRACKING_PROVIDER = 'Tools\\DrillDown\\TrackingProviderGrouper';
  public static TOOLS_DRILLDOWN_GROUPER_AFFILIATE = 'Tools\\DrillDown\\AffiliateGrouper';
  public static TOOLS_DRILLDOWN_GROUPER_DEVICE_BRAND = 'Tools\\DrillDown\\DeviceBrandGrouper';
  public static TOOLS_DRILLDOWN_GROUPER_DEVICE_MODEL = 'Tools\\DrillDown\\DeviceModelGrouper';
  public static TOOLS_DRILLDOWN_GROUPER_CLICK_COUNTRY = 'Tools\\DrillDown\\ClickCountryGrouper';
  public static TOOLS_DRILLDOWN_GROUPER_AB_TEST = 'Tools\\DrillDown\\ABTestGrouper';

  public static TOOLS_DRILLDOWN_PRESETS = 'Tools\\DrillDown\\Presets';

  public static WORKFLOW_SECTION = 'Pages\\Special\\LeadsWorkflow';

  public static TOOLS_DIRLLDOWN_GROUPER_LIST: string[] = [
    RbacPermissionsConstant.TOOLS_DRILLDOWN_GROUPER_FUNNEL,
    RbacPermissionsConstant.TOOLS_DRILLDOWN_GROUPER_OFFER,
    RbacPermissionsConstant.TOOLS_DRILLDOWN_GROUPER_BRAND,
    RbacPermissionsConstant.TOOLS_DRILLDOWN_GROUPER_DEVICE_OSG,
    RbacPermissionsConstant.TOOLS_DRILLDOWN_GROUPER_LEAD_COUNTRY,
    RbacPermissionsConstant.TOOLS_DRILLDOWN_GROUPER_TRACKING_PROVIDER,
    RbacPermissionsConstant.TOOLS_DRILLDOWN_GROUPER_AFFILIATE,
    RbacPermissionsConstant.TOOLS_DRILLDOWN_GROUPER_DEVICE_BRAND,
    RbacPermissionsConstant.TOOLS_DRILLDOWN_GROUPER_DEVICE_MODEL,
    RbacPermissionsConstant.TOOLS_DRILLDOWN_GROUPER_CLICK_COUNTRY,
    RbacPermissionsConstant.TOOLS_DRILLDOWN_GROUPER_AB_TEST,
  ];
}
