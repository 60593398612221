import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { IApplicationsService, ApplicationsResponseData, ApplicationResponseData, Application } from './applications.interfaces';
import { IENVIRONMENT_SERVICE, Value, IEnvironmentService } from '../environment/environment.interface';
import { IApplication } from '@platform500services/p500-ui-kit';
import { IConfigurationService, ICONFIGURATION_SERVICE } from '../configuration/configuration-service.interface';

const APPLICATIONS_TYPES = {
  'leads-distribution': 'Leads Distribution',
  'omni-channel': 'Omni Channel',
  'tracking-software': 'Partners Platform',
};

const APPLICATIONS_SHORT_TYPES = {
  'leads-distribution': 'LD',
  'omni-channel': 'OM',
  'tracking-software': 'PP',
};

@Injectable()
export class ApplicationService implements IApplicationsService {
  private url: Value;
  private availableApplications = new Subject<Application[]>();
  private currentApplication = new Subject<Application>();
  private currentID: number;
  private mapApplication = (app: Application): IApplication => {
    return {
      ...app,
      type: APPLICATIONS_TYPES[app.type],
      shortType: APPLICATIONS_SHORT_TYPES[app.type],
      isActive: app.id === this.currentID,
      url: `${app.url}${app.path_suffix ? app.path_suffix : ''}`,
    };
  };

  constructor(
    private http: HttpClient,
    @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService,
    @Inject(ICONFIGURATION_SERVICE) public configurationService: IConfigurationService
  ) {
    this.currentID = this.configurationService.data().brand.id;
    this.url = this.envService.getVar('services.api') as string;
    this.http
      .get<ApplicationsResponseData>(this.url + '/applications')
      .pipe(
        map((response) => (response.data ? response.data : [])),
        share()
      )
      .subscribe(
        (applications) => {
          this.availableApplications.next(applications);
          this.availableApplications.complete();
        },
        () => {
          //
        }
      );

    this.http
      .get<ApplicationResponseData>(this.url + '/application')
      .pipe(
        map((response) => response.data),
        share()
      )
      .subscribe((application) => {
        this.currentApplication.next(application);
        this.currentApplication.complete();
      });
  }

  getAvailableApplications(): Observable<IApplication[]> {
    return this.availableApplications
      .asObservable()
      .pipe(map((applications) => applications.map(this.mapApplication).sort((a, b) => Number(b.isActive) - Number(a.isActive))));
  }

  getCurrentApplication(): Observable<IApplication> {
    return this.currentApplication.asObservable().pipe(map((application) => this.mapApplication(application)));
  }
}
