import { ChangeDetectionStrategy, Component, Inject, Input, ChangeDetectorRef } from '@angular/core';
import { ISOCKETS_SERVICE, ISocketsService } from '../../../core/services/sockets/sockets-service.interface';
import { TodayDataResponse } from './today-data.response';
import { DestroySubscribers } from '../../../core/decorators/destroy-subscribers.decorator';

@Component({
  selector: 'gtd-ui-today-data',
  templateUrl: './today-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroySubscribers()
export class TodayDataComponent {
  @Input() cssClass = '';
  public data: TodayDataResponse;
  private subscribers: any = {};

  constructor(@Inject(ISOCKETS_SERVICE) private socketsService: ISocketsService, private cd: ChangeDetectorRef) {
    this.subscribers.data = this.socketsService.subscribeObservable<TodayDataResponse>('gtd-crm:today').subscribe((data) => {
      if (data) {
        this.data = data;
        this.cd.markForCheck();
      }
    });
  }
}
