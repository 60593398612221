import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

interface ISettings {
  debug: boolean;
  jwt: string;
  prefix: string;
  refreshEndpoint: string;
  subscribeEndpoint: string;
  token: string;
  url: string;
  user: string;
}

interface ISocketsService {
  subscribeObservable<T>(channel: string, prefix?: boolean): Observable<T>;
}

const ISOCKETS_SERVICE = new InjectionToken<ISocketsService>('ISocketsService');

export { ISocketsService, ISOCKETS_SERVICE, ISettings };
