import { IMODALS_SERVICE, IModalsService } from '@platform500services/p500-ui-kit';
import { Component, Input, Inject, NgModuleRef } from '@angular/core';
import { ActionConfigInterface } from '../../../services/actions/interfaces/action-config.interface';
import { ActionFormModal } from '../../../modals/action-form/action-form.modal';

@Component({
  selector: 'gtd-action-button',
  templateUrl: './action-button.component.html',
})
export class ActionButtonComponent {
  @Input() config: ActionConfigInterface;
  @Input() id: number;
  @Input() repository: any = {};
  @Input() className: string;
  @Input() successCb: (data: any) => void;

  constructor(@Inject(IMODALS_SERVICE) private modalsService: IModalsService, private moduleRef: NgModuleRef<any>) {}

  load() {
    if (!this.config) {
      return;
    }
    this.modalsService.open(
      ActionFormModal,
      {
        data: {
          config: this.config,
          id: this.id,
          repository: this.repository,
        },
        successCb: this.successCb,
      },
      this.moduleRef
    );
  }
}
