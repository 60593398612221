import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { ActionConfigInterface } from './interfaces/action-config.interface';
import { DataResponse } from './responses/data.response';

interface IActionsService {
  getData(actionConfig: ActionConfigInterface, id: number | string, actionArguments?: object): Observable<DataResponse>;

  process(
    actionConfig: ActionConfigInterface,
    actionArgument: object,
    id?: number | string,
    additionalArguments?: object
  ): Observable<DataResponse>;
}

const IACTIONS_SERVICE = new InjectionToken<IActionsService>('IActionsService');

export { IActionsService, IACTIONS_SERVICE };
