import { Component, HostBinding, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IModalsService, IMODALS_SERVICE, P500UIBaseModal, TooltipDirective } from '@platform500services/p500-ui-kit';
import { IClipboardService, ICLIPBOARD_SERVICE } from '../../services/clipboard/clipboard-service.interface';
import { IEnvironmentService, IENVIRONMENT_SERVICE } from '../../services/environment/environment.interface';

@Component({
  templateUrl: './info.modal.html',
})
export class InfoModal extends P500UIBaseModal implements OnInit, OnDestroy {
  @HostBinding('class') className = 'p500-ui-modal p500-ui-modal--primary p500-ui-modal--small c-modal c-modal--info';
  @Input() title: string;
  @Input() subTitle: string;
  @Input() bodyText: string;
  @Input() type = INFO_MODAL_TYPES.INFO;
  @Input() showControlLink = false;
  @Input() onClose: () => any;

  @ViewChild(TooltipDirective) tooltipDirective: TooltipDirective;
  public types = INFO_MODAL_TYPES;
  public prettyBodyText = '';
  private timeout: any;

  constructor(
    @Inject(IMODALS_SERVICE) public modalsService: IModalsService,
    @Inject(IENVIRONMENT_SERVICE) public envService: IEnvironmentService,
    @Inject(ICLIPBOARD_SERVICE) private clipboardService: IClipboardService
  ) {
    super(modalsService);
  }

  ngOnInit(): void {
    try {
      const parsedBodyText = JSON.parse(this.bodyText);
      this.prettyBodyText = JSON.stringify(parsedBodyText, null, 2).trim();
    } catch {
      this.prettyBodyText = this.bodyText;
    }
  }

  closeModal(): void {
    if (this.onClose) {
      this.onClose();
    }
    this.close();
  }

  public copy(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.clipboardService.copyFromContent(this.prettyBodyText);
    this.tooltipDirective.updateText('Copied to clipboard');
    this.timeout = setTimeout(() => {
      this.tooltipDirective.updateText('Copy to clipboard');
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}

export const INFO_MODAL_TYPES = {
  INFO: 'INFO',
  DANGER: 'DANGER',
};
