import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

interface IDocsService {
  getPostById(postId: number): Observable<IWikiPost>;

  getPostsByParent(parentId: number): Observable<IWikiPost[]>;

  search(searchQuery?: string): Observable<IWikiPost[]>;
}

interface IWikiPost {
  date: string;
  date_gmt: string;
  modified: string;
  modified_gmt: string;
  slug: string;
  status: string;
  type: string;
  link: string;
  parent: number;
  wiki_tag: number[];
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
}

const IDOCS_SERVICE = new InjectionToken<IDocsService>('IDocsService');

export { IDocsService, IDOCS_SERVICE, IWikiPost };
