import { Directive, Input, OnChanges, Renderer2, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[gtdFieldWidth]',
})
export class FieldWidthDirective implements OnChanges {
  @Input() fields: number;
  @Input() fieldIDx: number;
  @Input() grid: number[];

  private MAX_FIELDS = 6;

  constructor(private renderer: Renderer2, private vcr: ViewContainerRef) {}

  ngOnChanges() {
    const className = this.getGridClass(this.fieldIDx, this.grid, this.fields);
    this.renderer.addClass(this.vcr.element.nativeElement, className);
  }

  getGridClass(field: number, grid: number[] = [], fields: number): string {
    return grid.length ? `col-sm-${grid[field % grid.length]}` : fields > this.MAX_FIELDS ? 'col-sm-6' : 'col-12';
  }
}
