import { P500UIIconsModule, P500UINavbarModule, P500UITooltipModule } from '@platform500services/p500-ui-kit';

import { CommonModule } from '@angular/common';
import { HelpMenulModule } from '../help-menu/help-menu.module';
import { NavPanelComponent } from './nav-panel.component';
import { NgModule } from '@angular/core';
import { ProfileMenuModule } from '../profile-menu/profile-menu.module';
import { TodayDataModule } from '../today-data/today-data.module';
import { AppSelectorHeaderModule } from '../app-selector-header/app-selector-header.module';
import { RouterModule } from '@angular/router';
import { NotificationsMenuModule } from '../notifications-menu/notifications-menu.module';
import { CanRbacModule } from './../../pipes/can-rbac/can-rbac.module';

@NgModule({
  imports: [
    CommonModule,
    P500UIIconsModule,
    P500UINavbarModule,
    P500UITooltipModule,
    TodayDataModule,
    HelpMenulModule,
    ProfileMenuModule,
    AppSelectorHeaderModule,
    RouterModule,
    NotificationsMenuModule,
    CanRbacModule,
  ],
  declarations: [NavPanelComponent],
  exports: [NavPanelComponent],
})
export class NavPanelModule {}
