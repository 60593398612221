import { ActionConfigAbstract } from '../../../core/services/actions/abstracts/action-config.abstract';
import { FieldModel } from '../../../core/services/actions/models/field.model';
import { Injectable } from '@angular/core';
import { TextareaFieldComponent } from '@platform500services/p500-ui-kit';
import { UntypedFormControl, Validators } from '@angular/forms';

@Injectable()
export class ResolveActionConfig extends ActionConfigAbstract {
  protected readonly key: string = 'Incidents\\Update';
  protected readonly repository: string = 'Eloquent\\IncidentRepository';

  protected readonly title: string = 'Incident Resolving';
  protected readonly description: string = '';

  protected readonly modalStyle: string = '-small';

  protected readonly fields: FieldModel[] = [
    new FieldModel(
      'Reason',
      'resolution_message',
      TextareaFieldComponent,
      new UntypedFormControl(null, [Validators.required, Validators.minLength(100), Validators.maxLength(255)])
    ),
  ];
}
