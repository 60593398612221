import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { FacadeService } from '../service-facade/facade.service';
import { IRBAC_SERVICE, IRbacService } from '../../../services/rbac/rbac-service.interface';
import { FilterControlsFactory } from '../../../services/actions/models/filter-controls-factory';
import { DestroySubscribers } from '../../../decorators/destroy-subscribers.decorator';
import { PageSettings, TableInterface } from '../../table-layout/table-layout.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { IColumn, IRow, ITableData } from '../service-facade/data.response';
import { IUnsecuredService, IUNSECURED_SERVICE } from '../../../services/unsecured/unsecured-service.interface';
import { combineLatest, interval, of, Subject, zip } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { IFilterModel } from '../../../services/actions/models/filter-model.interface';
import { IFilterValues, IOrderValues, IVisibilityValues } from '../../../services/tables/interfaces/table.service.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { IFacadeService, IFACADE_SERVICE } from '../service-facade/facade-service.interface';

@Component({
  selector: 'gtd-table-facade',
  templateUrl: './table-facade.component.html',
  providers: [
    {
      provide: IFACADE_SERVICE,
      useClass: FacadeService,
    },
  ],
})
@DestroySubscribers()
export class TableFacadeComponent implements OnInit {
  @Input() title: string;
  @Input() key: string;
  @Input() settings: PageSettings;

  public totalItems = 0;
  public visibilityValues: IVisibilityValues = {};
  public filtersModel: IFilterModel[] = [];
  public limit = 25;
  public page = 1;

  public visibleColumns: IColumn[];

  public data: ITableData;
  public tables: TableInterface[];

  public columns: IColumn[] = [];
  public originSortColumns: IColumn[] = [];

  public currentSettings: TableInterface;

  public checkboxesGroup?: UntypedFormGroup;
  public orderValues: IOrderValues = {};

  private routeFilters = {};
  private routeLimit: number;
  private subscribers: any = {};
  private table$ = new Subject<TableInterface>();

  constructor(
    @Inject(IRBAC_SERVICE) public rbacService: IRbacService,
    @Inject(IFACADE_SERVICE) public facadeService: IFacadeService,
    @Inject(LocalStorage) private localStorage: LocalStorage,
    @Inject(IUNSECURED_SERVICE) public unsecuredService: IUnsecuredService,
    private cd: ChangeDetectorRef,
    private filterControlsFactory: FilterControlsFactory,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.load();
    this.init();
  }

  private init() {
    this.subscribers.params = combineLatest([this.localStorage.getItem(`table_${this.key}`), this.route.queryParams])
      .pipe(take(1))
      .subscribe(([savedKey, routeParams]) => {
        const routePage = Number(routeParams.page) || 1;
        this.onPageChange(routePage);
        this.routeLimit = Number(routeParams.limit);
        for (const param in routeParams) {
          if (!['page', 'limit'].includes(param)) {
            this.routeFilters[param] = routeParams[param].split(',');
          }
        }

        if (Array.isArray(this.settings)) {
          this.tables = this.settings;
          const tableKey = routeParams?.tableKey || savedKey;
          const currentTable = this.tables.find((table) => table.key === tableKey);
          this.onTableChange(currentTable || this.tables[0]);
        } else {
          this.onTableChange(this.settings);
        }

        if (this.currentSettings?.autoRefresh) {
          this.subscribers.autoupdate = interval(this.currentSettings.autoRefresh * 1000).subscribe(() => {
            this.update();
          });
        }
      });
  }

  load(): void {
    const table$ = this.table$.asObservable();
    this.subscribers.facadeServiceInit = table$
      .pipe(
        switchMap((table) =>
          this.facadeService.init(
            table.key,
            this.currentSettings.defaultFilters,
            this.currentSettings.scopeValues,
            this.routeFilters,
            this.routeLimit
          )
        ),
        switchMap((table) => zip(of(table), this.localStorage.getItem('column_sorting_' + this.currentSettings.key)))
      )
      .subscribe(([data, sortColumn]: [ITableData, string[]]) => {
        this.data = data;
        this.limit = data.limit;
        this.page = data.page;
        this.totalItems = data.total;
        this.originSortColumns = JSON.parse(JSON.stringify(data.columns));

        this.columns =
          sortColumn?.length === data?.columns?.length
            ? data.columns.sort(
                (a, b) => sortColumn.findIndex((label) => a.label === label) - sortColumn.findIndex((label) => b.label === label)
              )
            : data.columns;

        this.visibleColumns = this.mapColumns(this.columns);

        if (this.checkboxesGroup) {
          this.setControl(data.rows);
        }

        const filtersValue = data.filterValues;

        this.filtersModel = this.data.filters.map((field) => {
          const { group } = field;
          const modelValidation = this.filtersModel.find((item) => item.model.getComponent().settings.key === field.id);
          const control = modelValidation?.model.getComponent().settings.control ?? new UntypedFormControl();
          control.setValue(filtersValue[field.id] || null);

          return {
            group,
            model: this.filterControlsFactory.getField(field.type, field.id, field.label, control),
          };
        });
        this.setRouteParams({ ...filtersValue, limit: data.limit }, true);
      });
  }

  setRouteParams(params: any, merge = false): void {
    const routeParams = {};
    for (const paramKey in params) {
      if (params[paramKey]) {
        routeParams[paramKey] = params[paramKey].toString();
      }
    }
    this.router.navigate([], {
      queryParams: routeParams,
      queryParamsHandling: merge ? 'merge' : '',
      replaceUrl: false,
    });
  }

  initVisibility(): void {
    this.subscribers.data = this.localStorage
      .getItem<IVisibilityValues>('column_visibility_' + this.currentSettings.key)
      .subscribe((data) => (this.visibilityValues = (data as IVisibilityValues) || {}));
  }

  mapColumns(columns: IColumn[]): IColumn[] {
    return columns
      .filter((column) => (this.visibilityValues[column.id] !== undefined ? this.visibilityValues[column.id] : column.visible_by_default))
      .map((column) => (column.type === 'type_protected' ? { ...column, isUnsecured: this.unsecuredService.isUnsecuredValue } : column));
  }

  resetData(): void {
    this.data = null;
    this.visibleColumns = null;
    this.orderValues = {};
    this.cd.detectChanges();
  }

  handleSort(orderValues: IOrderValues): void {
    this.resetData();
    this.orderValues = orderValues;
    this.facadeService.onChangeSort(orderValues);
  }

  update(): void {
    this.resetData();
    this.facadeService.onChangePage(1);
  }

  onPageChange(page: number): void {
    if (page === this.page) {
      return;
    }
    this.resetData();
    this.setRouteParams({ page }, true);
    this.facadeService.onChangePage(page);
  }

  onFilterChange(filters: IFilterValues): void {
    this.filtersModel = this.filtersModel.map((field) => {
      field.model.formControl.setValue(filters[field.model.key] || null);
      return field;
    });
    this.resetData();
    this.resetCheckboxGroup();
    this.onPageChange(1);
    this.setRouteParams(filters);
    this.facadeService.onChangeFilters(filters);
  }

  onLimitChange(limit: number): void {
    this.resetData();
    this.resetCheckboxGroup();
    this.facadeService.onChangeLimit(limit);
  }

  onVisibilityChange(visibilityValues: IVisibilityValues): void {
    this.subscribers.columnVisibility = this.localStorage
      .setItem('column_visibility_' + this.currentSettings.key, visibilityValues)
      .subscribe();
    this.visibilityValues = { ...visibilityValues };
    this.visibleColumns = this.mapColumns(this.columns);
    this.localStorage
      .setItem(
        'column_sorting_' + this.currentSettings.key,
        this.columns.map((col) => col.label)
      )
      .subscribe();
  }

  onTableChange(table: TableInterface): void {
    this.data = null;
    this.subscribers.table = this.localStorage.setItem(`table_${this.key}`, table.key).subscribe();
    this.currentSettings = table;
    this.checkboxesGroup = table.checkboxesConfig?.formGroup;
    if (this.checkboxesGroup) {
      table.checkboxesConfig.formGroup.reset({});
    }
    this.initVisibility();
    this.table$.next(table);
  }

  private resetCheckboxGroup(): void {
    if (this.checkboxesGroup) {
      this.checkboxesGroup.reset({});
    }
  }

  private setControl(rows: IRow[]): void {
    for (const { id } of rows) {
      if (!this.checkboxesGroup.get([id])) {
        this.checkboxesGroup.addControl(id, new UntypedFormControl());
      }
    }
  }
}
