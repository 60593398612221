import { BaseLayoutComponent } from './base.layout';
import { CommonModule } from '@angular/common';
import { CopyrightModule } from '../../components/copyright/copyright.module';
import { GuidedTourModule } from 'ngx-guided-tour';
import { KnowledgeBaseModule } from '../../components/knowledge-base/knowledge-base.module';
import { NavPanelModule } from '../../components/nav-panel/nav-panel.module';
import { NewDesignNewYearModule } from '../../modals/new-design-new-year/new-design-new-year.module';
import { NgModule } from '@angular/core';
import { P500UIButtonsModule, P500UIIconsModule, P500UINotificationsModule } from '@platform500services/p500-ui-kit';
import { RouterModule } from '@angular/router';
import { SidebarModule } from '../../components/sidebar/sidebar.module';
import { TopBarModule } from '../../components/top-bar/top-bar.module';
import { TourBoardModule } from '../../components/tour-board/tour-board.module';
import { UnsecuredBarModule } from '../../components/unsecured-bar/unsecured-bar.module';
import { MetrikaScriptModule } from '../../components/metrika-script/metrika-script.module';
import { RefreshModule } from '../../directives/refresh/refresh.module';
import { WebPushService } from '../services/web-push.service';
import { IWEB_PUSH_SERVICE } from '../services/web-push-service.interface';
import { NotificationsSidebarModule } from '../../components/notifications-sidebar/notifications-sidebar.module';

@NgModule({
  imports: [
    CommonModule,
    P500UINotificationsModule,
    P500UIIconsModule,
    P500UIButtonsModule,
    RouterModule,
    GuidedTourModule,
    TourBoardModule,
    KnowledgeBaseModule,
    CopyrightModule,
    TopBarModule,
    NavPanelModule,
    SidebarModule,
    NewDesignNewYearModule,
    UnsecuredBarModule,
    RefreshModule,
    MetrikaScriptModule,
    NotificationsSidebarModule,
  ],
  declarations: [BaseLayoutComponent],
  exports: [BaseLayoutComponent],
  providers: [
    {
      provide: IWEB_PUSH_SERVICE,
      useClass: WebPushService,
    },
  ],
})
export class BaseLayoutModule {}
