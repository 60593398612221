import { Component, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { IEVENT_SERVICE, IEventsService } from '../../../core/services/events/events.interface';
import { IConfigurationService, ICONFIGURATION_SERVICE } from '../../../core/services/configuration/configuration-service.interface';
import { ISidebarService, ISIDEBAR_SERVICE } from '../../../core/services/sidebar/sidebar.interface';
import { BrowserWindowRef } from '../../../core/services/window/window.service';
import { IApplicationsService, IAPPLICATIONS_SERVICE } from '../../../core/services/applications/applications.interfaces';
import { IENVIRONMENT_SERVICE, IEnvironmentService } from '../../services/environment/environment.interface';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';
import { IIncidentsDataService, IINCIDENTS_SERVICE } from '../notifications-menu/services/incidents/incidents-data.interface';
import { IAlert, IAlertService, IALERT_SERVICE } from '../notifications-menu/services/alerts/alert.interfaces';
import { getLatestDate } from '../notifications-menu/services/alerts/alert.helpers';
import { RbacPermissionsConstant } from '../../../core/services/rbac/rbac-permissions.constant';

@Component({
  selector: 'gtd-nav-panel',
  templateUrl: './nav-panel.component.html',
})
@DestroySubscribers()
export class NavPanelComponent {
  public isAlertRead = true;
  public isCriticalAlert = false;
  public isDangerAlert = false;
  public applicationsAmount = 0;
  private subscribers: any = {};
  public controlLink = `https://control.${this.envService.getHost()}.com/app/company`;
  public latestAlertDate: number;
  public RbacPermissionsConstant = RbacPermissionsConstant;

  constructor(
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    @Inject(ICONFIGURATION_SERVICE) public configurationService: IConfigurationService,
    @Inject(IINCIDENTS_SERVICE) public incidentsDataService: IIncidentsDataService,
    @Inject(IALERT_SERVICE) private alertService: IAlertService,
    @Inject(DOCUMENT) private dom: Document,
    @Inject(BrowserWindowRef) private window: BrowserWindowRef,
    @Inject(ISIDEBAR_SERVICE) public sidebarService: ISidebarService,
    @Inject(IAPPLICATIONS_SERVICE) private applicationsService: IApplicationsService,
    @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService
  ) {
    this.subscribers.notificationStatus = this.alertService.getReadStatus().subscribe((status: boolean) => {
      this.isAlertRead = status;
    });

    this.subscribers.alertStatus = this.alertService.getAlerts().subscribe((alerts: IAlert[]) => {
      this.isCriticalAlert = alerts.some((alert) => alert.type === ' Error');
      this.isDangerAlert = alerts.some((alert) => alert.type === 'Danger' || alert.type === 'Critical');
      this.latestAlertDate = getLatestDate(alerts);
    });

    this.subscribers.applicationsAmount = this.applicationsService.getAvailableApplications().subscribe((data) => {
      this.applicationsAmount = data.length;
    });
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touch', ['$event'])
  handleOutsideClick(event) {
    if (this.sidebarService.getMenuSection() === 'menu' || this.window.nativeWindow.innerWidth < 481) {
      return;
    }
    const popups = Array.from(this.dom.querySelectorAll('.c-menu-list'));
    if (!popups.some((popup) => popup.contains(event.target))) {
      this.sidebarService.setMenuSection('menu');
    }
  }

  openCrmSwitchModal(): void {
    this.eventsService.broadcast('isOpenSelector:toggle');
  }

  openMenuSection(buttonName: string): void {
    setTimeout(() => {
      this.sidebarService.setMenuSection(buttonName);
    }, 0);
  }

  closeSidebar(): void {
    this.eventsService.broadcast('sidebarComponent:close');
  }

  openNotificationsMenu(date: number): void {
    this.alertService.updateReadStatus(date);
    this.eventsService.broadcast('isOpenNotificationsSidebar:toggle');
  }
}
