import { InjectionToken } from '@angular/core';

interface IEnvironment {
  name: string;
  production: boolean;
  services: {
    api: string;
  };
  sentryDsn?: string;
}

const SENTRY_DSN = 'https://c70e7ef5167d4ec0a800c18f07ac9408@o445185.ingest.sentry.io/5519291';

const PROD_API = '/backend/api/v1/crm';

const IENVIRONMENT = new InjectionToken<IEnvironment>('environment');

export { IEnvironment, IENVIRONMENT, PROD_API, SENTRY_DSN };
