import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { SignDetailsRequest } from './requests';
import { UserResponse } from './responses/user.response';

interface IAuthService {
  auth(signDetailsRequest: SignDetailsRequest, successCb: (res?: any) => void, errorCb: (err?: any) => void): void;
  authViaToken(token: string, successCb: (res?: any) => void, errorCb: (err?: any) => void): void;
  isAuthorized(): boolean;
  setUser(user: UserResponse): void;
  getUser(): Observable<UserResponse>;
  logout(): void;
  getUserData(): UserResponse;
}

const IAUTH_SERVICE = new InjectionToken<IAuthService>('IAuthService');

export { IAuthService, IAUTH_SERVICE };
