<div *ngIf="gdprAccepted; else gdprTemplate" class="p500-ui-modal__inner">
  <form class="c-form c-form--d" (ngSubmit)="submit()" [formGroup]="form">
    <div class="p500-ui-modal__head">
      <div class="row">
        <div class="p500-ui-modal__close" (click)="close()">
          <p500-ui-icon name="controls-close"></p500-ui-icon>
        </div>
        <div class="p500-ui-modal__title col-12">Enter 2FA Code</div>
        <div class="p500-ui-modal__subtitle col-12">Enter 2FA code For Unsecured Mode</div>
      </div>
    </div>

    <div class="p500-ui-modal__body">
      <p500-ui-cube-preloader [hidden]="!isLoading"></p500-ui-cube-preloader>
      <div [hidden]="isLoading">
        <div class="c-modal__description">
          You will see the secured data in the Key Info column. Data will be hidden after 30 minutes for security reasons.
        </div>
        <div class="row" *ngIf="form">
          <ng-container *ngFor="let field of components | keyvalue">
            <div class="c-form__section col-12">
              <p500-ui-control-dynamic-component
                [component]="field.value"
                [settings]="componentsSettings.get(field.key)"
              ></p500-ui-control-dynamic-component>
            </div>
          </ng-container>
        </div>
        <button
          p500-ui-button-icon
          iconName="controls-refresh"
          color="primary"
          size="xsmall"
          class="c-modal__send-button mb-12"
          type="button"
          (click)="resendCode()"
        >
          <span class="text">Send the code again</span>
        </button>
      </div>
    </div>

    <div class="p500-ui-modal__footer">
      <div class="p500-ui-modal__action" (click)="close()">
        <button p500-ui-button [stroked]="true" type="button">Cancel</button>
      </div>

      <div class="p500-ui-modal__action">
        <button p500-ui-button classIcon="h-icon-check" positionIcon="right" type="submit" [disabled]="!form.valid || isLoading">
          Submit
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #gdprTemplate>
  <div class="p500-ui-modal__inner">
    <div class="p500-ui-modal__close" (click)="close()">
      <p500-ui-icon name="controls-close"></p500-ui-icon>
    </div>

    <div class="c-modal__gdpr" *ngIf="globalData.unsecured_mode_disabled === 'yes'">
      <span class="c-label c-label--d c-label--color--orange">Unsecured Mode Disabled by Administrator</span>
    </div>

    <div class="c-modal__gdpr" *ngIf="!globalData.unsecured_mode_disabled || globalData.unsecured_mode_disabled === 'no'">
      <img src="assets/img/EU-Logo.svg" width="58" height="58" alt="EU-logo" />
      <h2>IREV supports GDPR</h2>
      <p>
        To be the most trusted place we protect your personal data and comply with
        <a href="https://gdpr.eu/" target="_blank">the EU's General Data Protection Regulation (GDPR)</a>
      </p>
      <button p500-ui-button (click)="acceptTerms()" type="button">Accept the terms</button>
    </div>
  </div>
</ng-template>
