<!-- Section: App -->
<div
  class="l-base"
  [ngClass]="{ 'is-overlay': isOverlay, 'main--demo': configurationService.data().isDemo, 'is-tour-opened': isTourOpened, 'is-collapsed-sidebar': isCollapsedSidebar }"
>
  <!-- Section: App Container -->
  <ng-container #appContainer></ng-container>
  <!-- End of Section: App Container -->

  <!-- Section: Float Notifications -->
  <p500-ui-float-notifications></p500-ui-float-notifications>
  <!-- End of Section: Float Notifications -->

  <gtd-notifications-sidebar *ngIf="isNotificationsOpened"></gtd-notifications-sidebar>

  <ngx-guided-tour tourStepWidth="200" nextText="Next" doneText="Next" progressIndicatorLocation="hidden"></ngx-guided-tour>
  <gtd-tour-board *ngIf="isTourOpened"></gtd-tour-board>
  <gtd-knowledge-base></gtd-knowledge-base>

  <!-- Section: Main -->
  <div class="l-base__inner">
    <div class="l-base__top-bar">
      <!-- Section: Top Bar -->
      <ng-container *ngIf="isLoaded">
        <gtd-top-bar class="c-top-bar"></gtd-top-bar>
      </ng-container>
      <!-- End of Section: Top Bar -->
    </div>

    <div class="l-base__nav-panel">
      <gtd-nav-panel></gtd-nav-panel>
    </div>

    <div class="l-base__sidebar">
      <!-- Section: Sidebar -->
      <ng-container *ngIf="isLoaded">
        <gtd-sidebar></gtd-sidebar>
      </ng-container>
      <!-- End of Section: Sidebar -->
    </div>

    <div class="l-base__body">
      <!-- Section: Main Content -->
      <div class="main-content">
        <div class="main-content__content">
          <ng-container *ngIf="isLoaded">
            <gtd-unsecured-bar></gtd-unsecured-bar>
            <router-outlet></router-outlet>
          </ng-container>
        </div>
        <span class="main-content__copyright">
          <gtd-copyright></gtd-copyright>
        </span>
      </div>
      <!-- End of Section: Main Content -->
    </div>

    <div class="l-base__onboarding"></div>
  </div>
  <!-- End of Section: Main -->
</div>
<!-- End of Section: App -->
<gtd-metrika-script></gtd-metrika-script>
