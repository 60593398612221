import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { CHANGELOG_VERSION } from './changelog-version';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    release: `gtd-angular@${CHANGELOG_VERSION.version}`,
    dsn: environment.sentryDsn,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Integrations.BrowserTracing({
        // tracingOrigins: ['localhost', 'https://yourserver.io/api'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // Do not sent 5xx and 4xx errors to Sentry
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (
        typeof error === 'string'
          ? error.includes('Http failure response') || error.includes('ChunkLoadError')
          : error.name === 'HttpErrorResponse' ||
            error.name === 'ChunkLoadError' ||
            error.message?.includes('ChunkLoadError') ||
            error.message?.includes(`expected expression, got '<'`) ||
            error.message?.includes(`Unexpected token '<'`) ||
            error.message?.includes('Server returned code 500 with body')
      ) {
        return null;
      }
      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Sentry automatically creates environments
    // when it receives an event with the environment tag.
    environment: environment.name,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
