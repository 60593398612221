import { Inject, Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IAUTH_SERVICE, IAuthService } from '../auth';

import { IDATA_SERVICE, IDataService } from './data-service.interface';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class DataResolverService implements Resolve<any> {
  constructor(
    @Inject(IDATA_SERVICE) private dataService: IDataService,
    @Inject(IAUTH_SERVICE) private authService: IAuthService,
    private router: Router
  ) {}

  resolve(): Observable<any> {
    return this.dataService.getDataFromServer().pipe(
      switchMap((data) => (data ? of(data) : throwError('no data'))),
      catchError(() => {
        this.authService.logout();
        this.router.navigate(['/auth/login'], { queryParams: { error: true } });
        return of(null);
      })
    );
  }
}
