import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ITableView } from '../../components/facade/service-facade/data.response';

interface IDataService {
  setData(data: any): void;

  getDataFromServer(): Observable<any>;

  getData(): IParsedDataResponse;
}

interface IPermission {
  name: string;
  items: {
    key: string;
    name: string;
  };
}

interface ICountries {
  [country: string]: { code: string; name: string; img: string; prefix: string; currency_code: string };
}

interface ITimezones {
  [timezone: string]: { value: number | string; name: string };
}

interface ILanguages {
  [language: string]: {
    code: string;
    name: string;
    img: string;
  };
}

interface ICurrencies {
  [currencies: string]: { code: string; name: string; img: string };
}

interface IPlaceholder {
  id: number;
  key: string;
  label: string;
}

interface IProvider {
  id: number;
  type: string;
  name: string;
  placeholders: IPlaceholder[];
}

interface IProvidersTypes {
  'email-collector-provider': IKeyValueObject;
  'brand-provider': IKeyValueObject;
  'traffic-provider': IKeyValueObject;
  'traffic-collector': IKeyValueObject;
}

interface IKeyValueObject {
  [key: string]: any;
}

interface IDataResponse {
  data: {
    version: string;
    unsecured_mode_disabled: string;
    unpaid_client: string;
    unpaid_client_timeout: string;
    providers: {
      brands: any;
    };
    public_ip: string;
    default_leads_workflow_state_for_injections: string;
    selects: {
      ab: IKeyValueObject;
      api_tokens_phone_format: IKeyValueObject;
      leads_workflow_events: IKeyValueObject;
      leads_workflow_states: IKeyValueObject;
      leads_workflow_states_for_pixels: IKeyValueObject;
      leads_workflow_states_for_injections: IKeyValueObject;
      leads_workflow_states_colors: IKeyValueObject;
      go_tracks: IKeyValueObject;
      funnel_domains: IKeyValueObject;
      events_log_namespaces: string[];
      affiliates: IKeyValueObject;
      brands: IKeyValueObject;
      injection_lists: IKeyValueObject;
      companies: IKeyValueObject;
      email_templates_language_groups: IKeyValueObject;
      follow_up_events: IKeyValueObject;
      funnels: IKeyValueObject;
      master_funnels: {};
      incidents: {
        severities: IKeyValueObject;
        statuses: IKeyValueObject;
      };
      invoice_statuses: IKeyValueObject;
      mapped_sale_statuses: IKeyValueObject;
      offers: IKeyValueObject;
      permissions: IPermission[];
      push_templates_language_groups: IKeyValueObject;
      rejection_reasons: IKeyValueObject;
      roles: IKeyValueObject;
      sms_templates_language_groups: IKeyValueObject;
      tracking_providers: IKeyValueObject;
      lead_workflows: IKeyValueObject;
      leads_workflow_types: IKeyValueObject;
      terms_format: IKeyValueObject;
      leads_workflow_forms: IKeyValueObject;
      leads_workflow_profile_configurations: IKeyValueObject;
      leads_profile_configurations: IKeyValueObject;
      leads_workflow_pulling_mapping_types: IKeyValueObject;
      leads_workflow_goal_types: IKeyValueObject;
      sale_statuses_string: IKeyValueObject;
      payout_tiers: IKeyValueObject;
      revenue_tiers: IKeyValueObject;
      terms: IKeyValueObject;
      traffic_collector_states: IKeyValueObject;
      traffic_collectors: IKeyValueObject;
      crm_notification_events: IKeyValueObject;
      executed_risk_statuses: IKeyValueObject;
      executed_postback_statuses: IKeyValueObject;
    };
    integrations: IProvider[];
    oauth?: {
      slack?: {
        client_id?: string;
        redirect_uri?: string;
      };
    };
    agreement_status: IKeyValueObject;
    leads_table_refresh: number;
  };
}

interface IParsedDataResponse {
  version: string;
  unsecured_mode_disabled: string;
  unpaid_client: string;
  unpaid_client_timeout: string;
  languages: ILanguages;
  countries: ICountries;
  n_countries: ICountries;
  currencies: ICurrencies;
  types: IKeyValueObject;
  timezones: ITimezones;
  public_ip: string;
  selects: {
    ab: IKeyValueObject;
    api_tokens_phone_format: IKeyValueObject;
    leads_workflow_events: IKeyValueObject;
    leads_workflow_states: IKeyValueObject;
    leads_workflow_states_for_pixels: IKeyValueObject;
    leads_workflow_states_for_injections: IKeyValueObject;
    leads_workflow_states_colors: IKeyValueObject;
    go_tracks: IKeyValueObject;
    funnel_domains: IKeyValueObject;
    tracking_providers: IKeyValueObject;
    funnels: IKeyValueObject;
    brands: IKeyValueObject;
    affiliates: IKeyValueObject;
    events_log_namespaces: string[];
    injection_lists: IKeyValueObject;
    getAffiliates: (trackingProviderId: string | number) => IKeyValueObject;
    all_affiliates: IKeyValueObject;
    all_n_affiliates: IKeyValueObject;
    all_n_affiliates_no_id: IKeyValueObject;
    affiliates_no_id: IKeyValueObject;
    getAffiliatesNoId: (trackingProviderId: string | number) => IKeyValueObject;
    all_affiliates_no_id: IKeyValueObject;
    companies: IKeyValueObject;
    offers: IKeyValueObject;
    getOffers: (trackingProviderId: string | number) => IKeyValueObject;
    all_offers: IKeyValueObject;
    all_n_offers: IKeyValueObject;
    roles: IKeyValueObject;
    permissions: IPermission[];
    mapped_sale_statuses: IKeyValueObject;
    rejection_reasons: IKeyValueObject;
    sms_templates_language_groups: IKeyValueObject;
    push_templates_language_groups: IKeyValueObject;
    email_templates_language_groups: IKeyValueObject;
    follow_up_events: IKeyValueObject;
    incidents: {
      severities: IKeyValueObject;
      statuses: IKeyValueObject;
    };
    invoice_statuses: IKeyValueObject;
    master_funnels: {};
    leads_workflow_types: IKeyValueObject;
    terms_format: IKeyValueObject;
    lead_workflows: IKeyValueObject;
    leads_workflow_forms: IKeyValueObject;
    leads_workflow_profile_configurations: IKeyValueObject;
    leads_profile_configurations: IKeyValueObject;
    leads_workflow_pulling_mapping_types: IKeyValueObject;
    leads_workflow_goal_types: IKeyValueObject;
    sale_statuses_string: IKeyValueObject;
    payout_tiers: IKeyValueObject;
    revenue_tiers: IKeyValueObject;
    terms: IKeyValueObject;
    traffic_collector_states: IKeyValueObject;
    traffic_collectors: IKeyValueObject;
    advertiser_business_models: { [key: string]: string };
    crm_notification_events: IKeyValueObject;
    autologin_types: IKeyValueObject;
    executed_postback_statuses: IKeyValueObject;
    executed_risk_statuses: IKeyValueObject;
  };
  integrations: IProvider[];
  integration_providers: IProvidersTypes;
  providers: {
    brands: IKeyValueObject;
  };
  table_views: ITableView[];
  advertiser_default_business_model: string;
  default_leads_workflow_state_for_injections: string;
  oauth: {
    slack: {
      client_id: string;
      redirect_uri: string;
    };
  };
  agreement_status: IKeyValueObject;
  leads_table_refresh: number;
}

const IDATA_SERVICE = new InjectionToken<IDataService>('IDataService');

export {
  IParsedDataResponse,
  IDataResponse,
  IDataService,
  IDATA_SERVICE,
  ICountries,
  ICurrencies,
  ILanguages,
  ITimezones,
  IKeyValueObject,
};
