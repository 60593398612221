<form [formGroup]="form">
  <div class="c-filters">
    <div class="c-filters__inner">
      <div class="c-filters__title">
        <div class="c-filters__title-caption">
          <p500-ui-icon name="arrows-big-left c-filters__title-arrow d-sm-none" (click)="closeModal()"></p500-ui-icon>
          Filters
        </div>
        <p500-ui-text-field class="c-filters__title-search" [formControl]="searchControl" placeholder="Start your search">
        </p500-ui-text-field>
      </div>
      <div class="c-filters__body">
        <div class="c-filters__select" *ngIf="activeItemsToggle">
          <p500-ui-slide-toggle-field [formControl]="activeItemsToggle" [isLabelRight]="true">
            <ng-template p500UIFieldLabel>
              <small class="c-filters__label">Show only active items</small>
            </ng-template>
          </p500-ui-slide-toggle-field>
        </div>
        <ng-container *ngFor="let group of components; last as isLast; index as i">
          <div [hidden]="searchControl.value && !searchMap.has(i)">
            <div class="c-filters__group-title" (click)="groupToggle(i)">
              {{ group.title }}
              <p500-ui-icon size="10px" className="ml-5" [name]="!groupToggleMap.get(i) ? 'arrows-bold-up' : 'arrows-bold-down'">
              </p500-ui-icon>
            </div>
            <div class="mt-2" [class.c-filters__group]="!isLast" (click)="$event.stopPropagation()" [hidden]="groupToggleMap.get(i)">
              <ng-container *ngFor="let field of group.items; trackBy: trackByFn; index as j">
                <div class="c-filters__item" [hidden]="searchControl.value && !searchMap.get(i)?.has(j)">
                  <div class="c-filters__item__header dropdown__header" (click)="itemToggle(i, j)">
                    <div class="c-filters__content-left">
                      <div class="c-filters__key">{{field.label}}</div>
                      <button
                        type="button"
                        p500-ui-button-icon
                        size="xsmall"
                        color="warn"
                        [transparent]="true"
                        *ngIf="getCountSelected(field.settings.control)"
                        class="c-filters__delete align-items-center"
                        (click)="clear(field.settings.control);$event.stopPropagation()"
                      >
                        <p500-ui-icon name="buttons-bin"></p500-ui-icon>
                      </button>
                    </div>
                    <div class="c-filters__content-right">
                      <div class="c-filters__counter" *ngIf="getCountSelected(field.settings.control)">
                        {{ getCountSelected(field.settings.control) }}
                      </div>
                      <button p500-ui-button-icon size="2xsmall" type="button" class="c-filters__toggle-button align-items-center">
                        <p500-ui-icon [name]="itemToggleMap.get(i)?.get(j) ? 'controls-minus' : 'controls-plus'"> </p500-ui-icon>
                      </button>
                    </div>
                  </div>
                  <div class="mt-2" (click)="$event.stopPropagation()" [hidden]="!itemToggleMap.get(i)?.get(j)">
                    <p500-ui-control-dynamic-component
                      [component]="field.component"
                      [settings]="field.settings"
                      [hidden]="isHiddenTpFields(field.type)"
                    >
                    </p500-ui-control-dynamic-component>
                    <!-- Depend TP Fields -->
                    <ng-container [ngSwitch]="field.type">
                      <ng-container *ngSwitchCase="TPDependsFields.TpDependsTypes.affiliate">
                        <gtd-select-or-tags
                          [data]="TPDependsFields.affiliates"
                          [formControl]="field.settings.control"
                          [settings]="{multiple: true}"
                        >
                        </gtd-select-or-tags>
                      </ng-container>
                      <ng-container *ngSwitchCase="TPDependsFields.TpDependsTypes.offer">
                        <gtd-select-or-tags
                          [data]="TPDependsFields.offers"
                          [formControl]="field.settings.control"
                          [settings]="{multiple: true}"
                        >
                        </gtd-select-or-tags>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="c-filters__footer">
        <div class="c-filters__apply">
          <button type="button" p500-ui-button size="big" (click)="submit()">Apply Filters</button>
        </div>
        <button type="reset" p500-ui-button size="big" color="warn" [transparent]="true" class="c-filters__reset" (click)="resetFilters()">
          Reset Filters
        </button>
      </div>
    </div>
  </div>
</form>
