import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { tours } from './tours/tours.constants';

@Injectable()
export class TourProgressService {
  private localStorageData = {};
  private finishedToursNumber = 0;
  private progressData = {};

  constructor(private localStorage: LocalStorage) {
    this.loadProgress();
  }

  loadProgress(): void {
    this.localStorage.getItem('tours:progress').subscribe((data) => {
      this.localStorageData = data ? data : {};
      this.updateData();
    });
  }

  updateData(): void {
    this.progressData = {};
    this.finishedToursNumber = 0;
    tours.forEach((tour) => {
      const finishedSectionsNumber = this.localStorageData[tour.tourKey] || 0;
      const totalSectionsNumber = tour.tourSections.length;
      const progressPercent = Math.round((finishedSectionsNumber / totalSectionsNumber) * 100);
      if (progressPercent === 100) {
        this.finishedToursNumber++;
      }
      this.progressData[tour.tourKey] = {
        finishedSectionsNumber,
        totalSectionsNumber,
        progressPercent,
      };
    });
  }

  saveProgress(tourKey, sectionsNumber): void {
    if (this.progressData[tourKey].finishedSectionsNumber >= sectionsNumber) {
      return;
    }
    this.localStorageData[tourKey] = sectionsNumber;
    this.localStorage.setItem('tours:progress', this.localStorageData).subscribe(() => {});
    this.updateData();
  }

  getFinishedToursNumber(): number {
    return this.finishedToursNumber;
  }

  getFinishedSectionsNumber(tourKey: string): number {
    return this.progressData[tourKey] ? this.progressData[tourKey].finishedSectionsNumber : 0;
  }

  getTotalSectionsNumber(tourKey: string): number {
    return this.progressData[tourKey] ? this.progressData[tourKey].totalSectionsNumber : 0;
  }

  getProgressPercent(tourKey: string): number {
    return this.progressData[tourKey] ? this.progressData[tourKey].progressPercent : 0;
  }
}
