import { Component, HostBinding, Inject, ViewChild } from '@angular/core';
import { UserResponse } from '../../../core/services/auth';
import { IConfigurationService, ICONFIGURATION_SERVICE } from '../../../core/services/configuration/configuration-service.interface';
import { IEVENT_SERVICE, IEventsService } from '../../../core/services/events/events.interface';
import { ISidebarService, ISIDEBAR_SERVICE } from '../../../core/services/sidebar/sidebar.interface';
import { IMenuItem } from '@platform500services/p500-ui-kit';
import { Router } from '@angular/router';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';
import { filterMenuConfig, addTableViews } from './sidebar-helpers';
import { MENU_CONFIG } from './sidebar.menu-config';
import { IRBAC_SERVICE, IRbacService } from '../../services/rbac/rbac-service.interface';
import { IDATA_SERVICE, IDataService } from '../../services/data/data-service.interface';
import { SETTINGS_CONTENT } from './../../../modules/settings/settings-page/settings-page.content';
import { CHANGELOG_VERSION } from '../../../../changelog-version';

@Component({
  selector: 'gtd-sidebar',
  templateUrl: './sidebar.component.html',
})
@DestroySubscribers()
export class SidebarComponent {
  public sidebarOverlay = false;
  public isShownTickers = false;
  public isIncidentsOpen = false;
  private subscribers: any = {};
  public user: UserResponse;
  @HostBinding('class') className = 'c-sidebar';
  @HostBinding('class.-open-selector') public isOpenSelector = false;
  @ViewChild('searchInner') insideElement;
  public sidebarMenu: IMenuItem[];
  public searchData: IMenuItem[];
  public isCollapsedNavigation = false;
  public currentSection?: IMenuItem;
  public changelogVersion: string;

  constructor(
    private router: Router,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    @Inject(ICONFIGURATION_SERVICE) public configurationService: IConfigurationService,
    @Inject(ISIDEBAR_SERVICE) public sidebarService: ISidebarService,
    @Inject(IRBAC_SERVICE) private rbacService: IRbacService,
    @Inject(IDATA_SERVICE) private dataService: IDataService
  ) {
    let menu = filterMenuConfig(MENU_CONFIG, this.rbacService);
    const tableViewsSorted = this.dataService.getData().table_views.sort((a, b) => b.sorting - a.sorting);
    menu = addTableViews(menu, tableViewsSorted);
    this.sidebarMenu = menu;

    const items = Object.values(SETTINGS_CONTENT)
      .reduce((acc, item) => acc.concat(item), [])
      .reduce((acc, item) => {
        if (!rbacService.can(item.permission)) {
          return acc;
        }

        const { name: title, permission, link } = item;

        return [...acc, { title, permission, link }];
      }, []);

    this.searchData = this.sidebarMenu.concat({ title: 'Settings', children: items });

    this.subscribers.isShownTickers = this.eventsService.on('tickersOpen:toggle').subscribe(() => {
      if (!this.isShownTickers) {
        if (this.isIncidentsOpen) {
          this.eventsService.broadcast('incidentsOpen:toggle');
        }
      }
      this.isShownTickers = !this.isShownTickers;
      this.sidebarOverlay = !this.sidebarOverlay;
    });

    this.subscribers.isShownSidebar = this.eventsService.on('sidebarComponent:toggle').subscribe(() => {
      this.sidebarOverlay = !this.sidebarOverlay;
    });

    this.subscribers.isShownIncidents = this.eventsService.on('incidentsOpen:toggle').subscribe(() => {
      if (!this.isIncidentsOpen) {
        if (this.isShownTickers) {
          this.eventsService.broadcast('tickersOpen:toggle');
        }
      }
      this.sidebarOverlay = !this.sidebarOverlay;
      this.isIncidentsOpen = !this.isIncidentsOpen;
    });

    this.changelogVersion = CHANGELOG_VERSION.version || '1.0.0';
  }

  toggleSidebar(): void {
    if (this.isIncidentsOpen) {
      this.eventsService.broadcast('incidentsOpen:toggle');
    }
    if (this.isShownTickers) {
      this.eventsService.broadcast('tickersOpen:toggle');
    }
    if (this.sidebarOverlay) {
      this.eventsService.broadcast('sidebarComponent:toggle');
    }
  }

  selectSearchItem(item) {
    this.router.navigate([item.link]);
  }

  toggleNavigation() {
    this.isCollapsedNavigation = !this.isCollapsedNavigation;
    this.eventsService.broadcast('sidebarComponent:collapsed-toggle', this.isCollapsedNavigation);
  }

  isActiveSection(section: IMenuItem) {
    return (
      (section.link && this.router.isActive(section.link, false)) ||
      section.children?.some((item) => item.some((deepItem) => this.router.isActive(deepItem.link, false)))
    );
  }

  openSection(section: IMenuItem) {
    this.currentSection = section;

    if (section.link) {
      this.router.navigateByUrl(section.link);
    }
  }

  closeSection() {
    this.currentSection = null;
  }
}
