import { FieldModel } from '../../../services/actions/models/field.model';
import {
  BooleanSelectFieldComponent,
  DatepickerFieldComponent,
  DisplayFilterInterface,
  SelectComponent,
} from '@platform500services/p500-ui-kit';
import moment from 'moment';
import { SelectOrTagsComponent } from '../../form-fields/default/select-or-tags/select-or-tags.component';

export function getDisplayFilters(field: FieldModel, dataValue?: any): DisplayFilterInterface {
  const component = field.component;
  const data = dataValue || field.settings.data;
  const value = field.formControl.value;
  let displayValue: string;

  switch (component) {
    case SelectComponent:
    case SelectOrTagsComponent:
      if (data) {
        displayValue = Array.isArray(value)
          ? value
              .map((item) => {
                let name: string;
                if (data[item] && (data[item] as any).name) {
                  name = (data[item] as any).name;
                } else {
                  name = data[item] ? data[item] : item;
                }

                return `"${name}"`;
              })
              .join(', ')
          : `"${data[value] && data[value].name ? data[value].name : data[value]}"`;
      }
      break;
    case DatepickerFieldComponent:
      displayValue = Array.isArray(value) ? `"${moment(value[0]).utc().format('YYYY-MM-DD HH:mm')}"` : `"${value}"`;
      break;
    case BooleanSelectFieldComponent:
      displayValue =
        Array.isArray(value) && value.length
          ? value[0] === 1 || value[0] === '1' || value[0] === true
            ? '"Yes"'
            : '"No"'
          : value === 1 || value === '1' || value === true
          ? '"Yes"'
          : '"No"';
      break;
  }
  if (!displayValue) {
    displayValue = Array.isArray(value) ? value.map((item) => `"${item}"`).join(', ') : `"${value}"`;
  }
  return {
    key: field.key,
    name: field.label,
    value: displayValue,
  };
}
