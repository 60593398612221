import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  P500UIAccordionModule,
  P500UIButtonsModule,
  P500UIFormFieldsModule,
  P500UIIconsModule,
  P500UIProgressModule,
} from '@platform500services/p500-ui-kit';

import { ActionFormModal } from './action-form.modal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupFormComponent } from './group-form/group-form.component';
import { FieldWidthDirective } from './field-width.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    P500UIIconsModule,
    P500UIButtonsModule,
    P500UIProgressModule,
    P500UIFormFieldsModule,
    P500UIAccordionModule,
  ],
  declarations: [ActionFormModal, GroupFormComponent, FieldWidthDirective],
  exports: [ActionFormModal, P500UIIconsModule, P500UIButtonsModule, P500UIProgressModule, P500UIFormFieldsModule, P500UIAccordionModule],
})
export class ActionFormModalModule {}
