<div class="c-modal--new-design">
  <div class="c-modal">
    <div class="c-modal__inner">
      <div class="c-modal__close">
        <p500-ui-icon name="controls-close" class="c-modal__close-btn" (click)="close()"></p500-ui-icon>
      </div>
      <div class="c-modal__border">
        <div class="c-modal__svg">
          <img src="assets/new-design-modal/new-design-modal.svg" alt="New Design" />
        </div>
        <div class="c-modal__body align-items-start">
          <div class="c-modal__date">01 March 2022</div>
          <div class="c-modal__title">New CRM Design v2.0.0</div>
          <div class="c-modal__message">
            We are pleased to announce that we have made a new version of our product. We tried to make it easier and more understandable.
            Also we added a lot of new chips!
          </div>
          <div class="c-modal__buttons contact text-center">
            <!-- TODO: dont remove, temporary changes -->
            <!-- <button
            class="m-auto d-xl-flex d-none mr-20"
            p500-ui-button
            classIcon="d-flex h-icon-check"
            positionIcon="right"
            (click)="openTourBoard()"
          >
            Go to Learning Center
          </button> -->
            <button p500-ui-button classIcon="d-flex h-icon-check" positionIcon="right" (click)="close()">Continue Work</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
