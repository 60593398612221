import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { P500UIIconsModule } from '@platform500services/p500-ui-kit';
import { NotificationsModal } from './notifications.modal';

@NgModule({
  imports: [CommonModule, P500UIIconsModule],
  declarations: [NotificationsModal],
  exports: [NotificationsModal],
})
export class NotificationsModalModule {}
