import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  P500ApplicationsSelectorModule,
  P500UIButtonsModule,
  P500UIIconsModule,
  P500UISidenavModule,
  P500UITooltipModule,
} from '@platform500services/p500-ui-kit';
import { ChangelogBtnModule } from '../changelog-btn/changelog-btn.module';
import { TodayDataModule } from '../today-data/today-data.module';
import { AppSelectorCarouselHeaderComponent } from './app-selector-carousel-header/app-selector-carousel-header.component';
import { AppSelectorDropdownHeaderComponent } from './app-selector-dropdown-header/app-selector-dropdown-header.component';

@NgModule({
  imports: [
    CommonModule,
    P500UISidenavModule,
    P500ApplicationsSelectorModule,
    P500ApplicationsSelectorModule,
    P500UIButtonsModule,
    P500UIIconsModule,
    P500UITooltipModule,
    TodayDataModule,
    ChangelogBtnModule,
  ],
  declarations: [AppSelectorCarouselHeaderComponent, AppSelectorDropdownHeaderComponent],
  exports: [AppSelectorCarouselHeaderComponent, AppSelectorDropdownHeaderComponent],
})
export class AppSelectorHeaderModule {}
