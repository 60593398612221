import { Component, Inject, OnDestroy, HostBinding } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { LocalStorage } from '@ngx-pwa/local-storage';
import {
  IMODALS_SERVICE,
  IModalsService,
  INOTIFICATIONS_SERVICE,
  INotificationsService,
  P500UIBaseModal,
  TextFieldComponent,
} from '@platform500services/p500-ui-kit';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';
import { ComponentModel, FieldModel } from '../../services/actions/models/field.model';
import { IUnsecuredService, IUNSECURED_SERVICE } from '../../services/unsecured/unsecured-service.interface';
import { IDATA_SERVICE, IDataService, IParsedDataResponse } from '../../services/data/data-service.interface';

@Component({
  templateUrl: './2fa.modal.html',
})
@DestroySubscribers()
export class Auth2FAModal extends P500UIBaseModal implements OnDestroy {
  @HostBinding('class') className = 'p500-ui-modal p500-ui-modal--primary p500-ui-modal--xsmall c-modal c-modal--2fa';
  public isLoading = true;
  public gdprAccepted = false;
  public form: UntypedFormGroup;
  public components: Map<string, ComponentModel['component']> = new Map();
  public componentsSettings: Map<string, ComponentModel['settings']> = new Map();
  public source: string;

  public globalData: IParsedDataResponse;

  private fieldModel: FieldModel[] = [
    new FieldModel('2FA', 'token_2fa', TextFieldComponent, new UntypedFormControl(null, Validators.required), {
      placeholder: 'Enter code...',
    }),
  ];

  constructor(
    @Inject(IMODALS_SERVICE) public modalsService: IModalsService,
    @Inject(IUNSECURED_SERVICE) public unsecuredService: IUnsecuredService,
    @Inject(INOTIFICATIONS_SERVICE) private notificationsService: INotificationsService,
    @Inject(LocalStorage) private localStorage: LocalStorage,
    @Inject(IDATA_SERVICE) public dataService: IDataService
  ) {
    super(modalsService);
    this.globalData = this.dataService.getData();
  }

  init(): void {
    const formGroup = {};
    this.fieldModel.forEach((fieldModel) => {
      formGroup[fieldModel.key] = fieldModel.formControl;
      const component = fieldModel.getComponent();
      this.components.set(fieldModel.key, component.component);
      this.componentsSettings.set(fieldModel.key, { ...component.settings });
    });
    this.form = new UntypedFormGroup(formGroup);

    this.subscribers.unsecuredInit = this.unsecuredService.enable(undefined, this.source).subscribe(
      () => {},
      (response) => {
        if (response.error && response.error.error && response.error.error.otp) {
          this.isLoading = false;
          this.notificationsService.add({ type: 'success', title: '2FA Code has been send' }, true);
        } else {
          this.notificationsService.add({ type: 'error', title: response.error.message || 'Unknown Error' });
        }
      }
    );
  }

  resendCode(): void {
    this.subscribers.unsecuredResendCode = this.unsecuredService.enable(undefined, this.source).subscribe(
      () => {},
      () => {
        this.notificationsService.add({ type: 'success', title: '2FA Code has been send' }, true);
      }
    );
  }

  acceptTerms(): void {
    this.gdprAccepted = true;
    this.subscribers.acceptTerms = this.localStorage.setItem('isAcceptUnsecured', true).subscribe(() => {});
    this.init();
  }

  submit(): void {
    this.isLoading = true;
    this.subscribers.submit = this.unsecuredService.enable(this.form.getRawValue().token_2fa, this.source).subscribe(
      (response) => {
        if (response.data && response.data.otp === false) {
          this.close();
          this.unsecuredService.setUnsecuredStatus(true);
        }
      },
      () => {
        this.isLoading = false;
        this.notificationsService.add({ type: 'error', title: '2FA Code is invalid. Try again' }, true);
      }
    );
  }
}
