import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'gtd-empty',
  templateUrl: './empty.component.html',
})
export class EmptyComponent {
  @Input() actions: TemplateRef<any>[];
  @Input() desc: string;
  @Input() icon: string;
}
