import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IWikiPost, IDocsService } from './docs.interface';

@Injectable()
export class DocsService implements IDocsService {
  private apiUrl = 'https://docs.platform500.com/wp-json/wp/v2';
  private wikiCategory = '29'; // Leads Distribution category

  constructor(private http: HttpClient) {}

  getPostById(postId: number): Observable<IWikiPost> {
    return this.http.get<IWikiPost>(`${this.apiUrl}/wiki/${postId}`);
  }

  getPostsByParent(parentId: number): Observable<IWikiPost[]> {
    return this.http.get<IWikiPost[]>(`${this.apiUrl}/wiki`, {
      params: {
        parent: parentId.toString(),
        wiki_category: this.wikiCategory,
        per_page: '100',
      },
    });
  }

  search(searchQuery?: string): Observable<IWikiPost[]> {
    const params: any = {
      search: searchQuery || '',
      per_page: '3',
      wiki_category: this.wikiCategory,
    };
    if (searchQuery) {
      params.orderby = 'relevance';
    }
    return this.http.get<IWikiPost[]>(`${this.apiUrl}/wiki`, { params });
  }
}
