<div class="c-menu-list c-menu-list--notifications" [ngClass]="cssClass">
  <div class="c-menu-list__title">Notifications Panel ({{ alerts.length }})</div>
  <ul *ngIf="alerts.length" class="c-menu-list__content">
    <ng-container *ngFor="let alert of alerts">
      <li *ngIf="!alert?.isIncident; else incident" class="c-menu-list__item">
        <div class="c-menu-list__item-title-wrapper">
          <span class="c-menu-list__item-title" [ngClass]="'-' + alert.type">
            <gtd-ui-field-special-notification-type [data]="alert.type"></gtd-ui-field-special-notification-type>
          </span>
        </div>
        <span class="c-menu-list__item-time">{{ alert.created_at_timestamp | timeAgo }}</span>
        <p class="c-menu-list__item-message">{{ alert.message }}</p>
      </li>

      <ng-template #incident>
        <li class="c-menu-list__item c-menu-list__item--incident">
          <gtd-action-button [id]="alert.id" [repository]="{ status: 'Resolved' }" [config]="resolveAction">
            <div class="c-menu-list__item-title-wrapper">
              <span class="c-menu-list__item-title" [ngClass]="'-' + alert.type | lowercase">
                <gtd-ui-field-special-notification-type [data]="alert.type"></gtd-ui-field-special-notification-type>
              </span>
              <div class="c-menu-list__item-resolve">
                <p500-ui-icon name="buttons-check" size="12px"></p500-ui-icon>
                Resolve
              </div>
            </div>
            <span class="c-menu-list__item-time">{{ alert?.created_at_timestamp | timeAgo }}</span>
            <p class="c-menu-list__item-message">{{ alert.message }}</p>
          </gtd-action-button>
        </li>
      </ng-template>
    </ng-container>
  </ul>
  <footer class="c-menu-list__footer">
    <a class="c-link c-link--primary" [routerLink]="'/app/notifications/notifications'" (click)="closeNotifications()">
      <p500-ui-icon name="controls-time" class="mr-6"></p500-ui-icon>All Notifications
    </a>
  </footer>
</div>
