import {
  P500UIButtonsModule,
  P500UIDropdownModule,
  P500UIIconsModule,
  P500UITooltipModule,
  P500UIPipesModule,
} from '@platform500services/p500-ui-kit';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ActionSimpleLinkModule } from '../actions/action-simple-link/action-simple-link.module';
import { ActionButtonModule } from '../actions/action-button/action-button.module';
import { CanRbacModule } from './../../pipes/can-rbac/can-rbac.module';
import { CanActionRbacModule } from '../../pipes/can-action-rbac/can-action-rbac.module';
import { InfoModalModule } from '../../modals/info/info.module';
import { QuickModeModalModule } from '../../modals/quick-mode/quick-mode-modal.module';
import { DynamicComponent } from './dynamic/dynamic.component';

import { BooleanComponent } from './default/boolean/boolean.component';
import { CountriesComponent } from './default/countries/countries.component';
import { CountryComponent } from './default/country/country.component';
import { CurrencyComponent } from './default/currency/currency.component';
import { DatetimeComponent } from './default/datetime/datetime.component';
import { DatetimeOrEmptyComponent } from './default/datetime-or-empty/datetime-or-empty.component';
import { DefaultEmptyComponent } from './default/empty/default-empty.component';
import { ImageComponent } from './default/image/image.component';
import { LanguageComponent } from './default/language/language.component';
import { LinkComponent } from './default/link/link.component';
import { ManyItemsComponent } from './default/many-items/many-items.component';
import { MoneyComponent } from './default/money/money.component';
import { ProgressComponent } from './default/progress/progress.component';
import { StringComponent } from './default/string/string.component';
import { StringCopyToClipboardComponent } from './default/string-copy-to-clipboard/string-copy-to-clipboard.component';
import { StringOrEmptyComponent } from './default/string-or-empty/string-or-empty.component';
import { StringOrEmptyPreComponent } from './default/string-or-empty-pre/string-or-empty-pre.component';
import { StringWhitespaceOrEmptyComponent } from './default/string-whitespace-or-empty/string-whitespace-or-empty.component';
import { StringCapitalizeWordsOrEmptyComponent } from './default/string-capitalize-words-or-empty/string-capitalize-words-or-empty';
import { ProtectedFieldComponent } from './default/protected/protected.component';
import { ViewModeComponent } from './default/view-mode/view-mode.component';
import { BadgeComponent } from './default/bagde/badge.component';
import { ChangesComponent } from './default/changes/changes.component';
import { ChangesModal } from './default/changes/modal/changes.modal';
import { NodeComponent } from './default/node/node.component';

import { AffiliateComponent } from './special/affiliate/affiliate.component';
import { AffiliatesComponent } from './special/affiliates/affiliates.component';
import { ApiTokenComponent } from './special/api-token/api-token.component';
import { AuthorComponent } from './special/author/author.component';
import { EditLeadsIsTestComponent } from './special/edit-leads-is-test/edit-leads-is-test.component';
import { BrandComponent } from './special/brand/brand.component';
import { BrandsComponent } from './special/brands/brands.component';
import { CompanyComponent } from './special/company/company.component';
import { EventsLogTypeComponent } from './special/events-log-type/events-log-type.component';
import { FormulaFieldComponent } from './special/formula-field/formula-field.component';
import { FunnelComponent } from './special/funnel/funnel.component';
import { FunnelForcedFieldComponent } from './special/funnel-forced-field/funnel-forced-field.component';
import { IncidentStatusComponent } from './special/incident-status/incident-status.component';
import { IntegrationComponent } from './special/integration/integration.component';
import { JsonParseComponent } from './special/json-parse/json-parse.component';
import { OfferComponent } from './special/offer/offer.component';
import { OffersComponent } from './special/offers/offers.component';
import { PostbackStatusComponent } from './special/postback-status/postback-status.component';
import { PqlFieldComponent } from './special/pql-field/pql-field.component';
import { StateLeadComponent } from './special/state-lead/state-lead.component';
import { ShapeOfColorComponent } from './special/shape-of-color/shape-of-color.component';
import { SaleStatusColorComponent } from './special/sale-status-color/sale-status-of-color.component';
import { InjectionsStatusFieldComponent } from './special/injections-status-field/injections-status-field.component';
import { InjectionsTaskStatusFieldComponent } from './special/injections-task-status-field/injections-task-status-field.component';
import { PqlFieldTypedComponent } from './special/pql-field-typed/pql-field-typed.component';
import { PqlTypeAffiliateComponent } from './special/pql-field-typed/types/affiliate/affiliate.component';
import { PqlTypeOfferComponent } from './special/pql-field-typed/types/offer/offer.component';
import { PqlTypeFunnelComponent } from './special/pql-field-typed/types/funnel/funnel.component';
import { PqlTypeDayComponent } from './special/pql-field-typed/types/day/day.component';
import { PqlTypeAdvertiserComponent } from './special/pql-field-typed/types/advertiser/advertiser.component';
import { PqlTypeHourComponent } from './special/pql-field-typed/types/hour/hour.component';
import { PqlTypeFreeComponent } from './special/pql-field-typed/types/free/free.component';
import { PqlTypeAdvertiserCountryComponent } from './special/pql-field-typed/types/advertiser-country/advertiser-country.component';
import { PqlTypeAffiliateOfferComponent } from './special/pql-field-typed/types/affiliate-offer/affiliate-offer.component';
import { PqlTypeCountryAffiliateComponent } from './special/pql-field-typed/types/country-affiliate/country-affiliate.component';
import { PqlTypeCountryFunnelComponent } from './special/pql-field-typed/types/country-funnel/country-funnel.component';
import { PqlTypeFunnelAffiliateComponent } from './special/pql-field-typed/types/funnel-affiliate/funnel-affiliate.component';
import { PqlTypeCountryAffiliateOfferComponent } from './special/pql-field-typed/types/country-affiliate-offer/country-affiliate-offer.component';
import { PqlTypeFsmStateComponent } from './special/pql-field-typed/types/fsm-state/fsm-state.component';
import { PqlTypeCountryOfferComponent } from './special/pql-field-typed/types/country-offer/country-offer.component';
import { PqlTypeDayHourComponent } from './special/pql-field-typed/types/day-hour/day-hour.component';
import { PqlTypeAffiliateAffSubComponent } from './special/pql-field-typed/types/affiliate-affsub/affiliate-affsub.component';
import { PqlPathComponent } from './special/pql-path/pql-path.component';
import { PqlTypeAdvertiserHourComponent } from './special/pql-field-typed/types/advertiser-hour/advertiser-hour.component';
import { PqlTypePassAllComponent } from './special/pql-field-typed/types/pass-all/pass-all.component';
import { GoalTypeComponent } from './special/goal-type/goal-type.component';
import { NotificationTypeComponent } from './special/notification-type/notification-type.component';
import { RejectionReasonComponent } from './special/rejection-reason/rejection-reason.component';
import { LeadFlowFieldComponent } from './special/lead-flow-field/lead-flow-field.component';
import { RiskStatusComponent } from './special/risk-status/risk-status.component';
import { ExecutedRuleStatusComponent } from './special/executed-rule-status/executed-rule-status.component';
import { EventsModelComponent } from './special/events-model/events-model.component';
import { AutoInjectionsStatusFieldComponent } from './special/auto-injections-status-field/auto-injections-status-field.component';
import { ManualStatusComponent } from './special/manual-status/manual-status.component';
import { LateStatusComponent } from './special/late-status/late-status.component';
import { AutoLoginTypeComponent } from './special/auto-login-type/auto-login-type.component';
import { TreeLogTypeComponent } from './special/tree-log-type/tree-log-type.component';
import { AcceptedTypeComponent } from './special/accepted-type/accepted-type.component';
import { ScheduledReportTypeComponent } from './special/scheduled-report-type/scheduled-report-type.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    P500UIIconsModule,
    P500UITooltipModule,
    P500UIPipesModule,
    ActionSimpleLinkModule,
    ActionButtonModule,
    P500UIButtonsModule,
    P500UIDropdownModule,
    QuickModeModalModule,
    CanRbacModule,
    CanActionRbacModule,
    InfoModalModule,
  ],
  declarations: [
    DynamicComponent,
    BooleanComponent,
    StringComponent,
    DatetimeComponent,
    StringOrEmptyComponent,
    DatetimeOrEmptyComponent,
    MoneyComponent,
    CountryComponent,
    StringWhitespaceOrEmptyComponent,
    ManyItemsComponent,
    ProgressComponent,
    LanguageComponent,
    StringCopyToClipboardComponent,
    StringOrEmptyPreComponent,
    CountriesComponent,
    LinkComponent,
    DefaultEmptyComponent,
    StateLeadComponent,
    CurrencyComponent,
    ImageComponent,
    OfferComponent,
    AffiliateComponent,
    FunnelForcedFieldComponent,
    AffiliatesComponent,
    ApiTokenComponent,
    OffersComponent,
    EventsLogTypeComponent,
    CompanyComponent,
    FunnelComponent,
    BrandComponent,
    BrandsComponent,
    AuthorComponent,
    IncidentStatusComponent,
    JsonParseComponent,
    IntegrationComponent,
    PqlFieldComponent,
    FormulaFieldComponent,
    PostbackStatusComponent,
    EditLeadsIsTestComponent,
    StringCapitalizeWordsOrEmptyComponent,
    ShapeOfColorComponent,
    SaleStatusColorComponent,
    ProtectedFieldComponent,
    InjectionsStatusFieldComponent,
    AutoInjectionsStatusFieldComponent,
    InjectionsTaskStatusFieldComponent,
    PqlFieldTypedComponent,
    PqlPathComponent,
    PqlTypeAffiliateComponent,
    PqlTypeOfferComponent,
    PqlTypeFunnelComponent,
    PqlTypeDayComponent,
    PqlTypeAdvertiserComponent,
    PqlTypeHourComponent,
    PqlTypeFreeComponent,
    PqlTypeAdvertiserCountryComponent,
    PqlTypeAffiliateOfferComponent,
    PqlTypeCountryAffiliateComponent,
    PqlTypeCountryFunnelComponent,
    PqlTypeFunnelAffiliateComponent,
    PqlTypeCountryAffiliateOfferComponent,
    PqlTypeCountryOfferComponent,
    PqlTypeDayHourComponent,
    PqlTypeFsmStateComponent,
    PqlTypeAffiliateAffSubComponent,
    PqlTypeAdvertiserHourComponent,
    PqlTypePassAllComponent,
    ViewModeComponent,
    GoalTypeComponent,
    NotificationTypeComponent,
    RejectionReasonComponent,
    LeadFlowFieldComponent,
    RiskStatusComponent,
    ExecutedRuleStatusComponent,
    EventsModelComponent,
    BadgeComponent,
    ManualStatusComponent,
    LateStatusComponent,
    AutoLoginTypeComponent,
    ChangesComponent,
    ChangesModal,
    TreeLogTypeComponent,
    NodeComponent,
    AcceptedTypeComponent,
    ScheduledReportTypeComponent,
  ],
  exports: [
    DynamicComponent,
    BooleanComponent,
    StringComponent,
    DatetimeComponent,
    StringOrEmptyComponent,
    DatetimeOrEmptyComponent,
    MoneyComponent,
    CountryComponent,
    StringWhitespaceOrEmptyComponent,
    ManyItemsComponent,
    ProgressComponent,
    LanguageComponent,
    StringCopyToClipboardComponent,
    StringOrEmptyPreComponent,
    CountriesComponent,
    LinkComponent,
    DefaultEmptyComponent,
    StateLeadComponent,
    CurrencyComponent,
    ImageComponent,
    OfferComponent,
    AffiliateComponent,
    FunnelForcedFieldComponent,
    AffiliatesComponent,
    OffersComponent,
    EventsLogTypeComponent,
    CompanyComponent,
    FunnelComponent,
    BrandComponent,
    BrandsComponent,
    AuthorComponent,
    IncidentStatusComponent,
    JsonParseComponent,
    IntegrationComponent,
    PqlFieldComponent,
    FormulaFieldComponent,
    PostbackStatusComponent,
    ShapeOfColorComponent,
    SaleStatusColorComponent,
    ProtectedFieldComponent,
    InjectionsStatusFieldComponent,
    AutoInjectionsStatusFieldComponent,
    InjectionsTaskStatusFieldComponent,
    PqlFieldTypedComponent,
    PqlPathComponent,
    ViewModeComponent,
    GoalTypeComponent,
    NotificationTypeComponent,
    RejectionReasonComponent,
    LeadFlowFieldComponent,
    RiskStatusComponent,
    ExecutedRuleStatusComponent,
    EventsModelComponent,
    BadgeComponent,
    ManualStatusComponent,
    LateStatusComponent,
    AutoLoginTypeComponent,
    ChangesComponent,
    TreeLogTypeComponent,
    NodeComponent,
    AcceptedTypeComponent,
    ScheduledReportTypeComponent,
  ],
})
export class FieldsModule {}
