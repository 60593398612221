import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { IModalsService, IMODALS_SERVICE } from '@platform500services/p500-ui-kit';
import { InfoModal } from '../../../../modals/info/info.modal';

@Component({
  selector: 'gtd-ui-field-default-string-whitespace-or-empty',
  templateUrl: './string-whitespace-or-empty.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StringWhitespaceOrEmptyComponent implements OnChanges {
  @Input() data: any;
  @Input() length = 30;
  @Input() cssClass = '';
  public dataString = '';

  constructor(@Inject(IMODALS_SERVICE) private modalsService: IModalsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const value = changes.data.currentValue;
    this.dataString = value === null ? '' : String(value);
  }

  openViewModal(event: MouseEvent, data: string): void {
    event.preventDefault();
    event.stopPropagation();

    this.modalsService.open(InfoModal, {
      title: 'Event Info',
      bodyText: data,
    });
  }
}
