import { P500UIBaseModal, IMODALS_SERVICE, IModalsService } from '@platform500services/p500-ui-kit';
import { Component, Inject, OnInit, HostListener, ElementRef, Type, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';
import { IDATA_SERVICE, IDataService, IParsedDataResponse } from '../../services/data/data-service.interface';
import { IEVENT_SERVICE, IEventsService } from '../../services/events/events.interface';
import { IFilterModel } from './../../services/actions/models/filter-model.interface';
import { TPDependsFields } from '../../helpers/tp-depends-fields';
import { IRBAC_SERVICE, IRbacService } from '../../services/rbac/rbac-service.interface';

type DatatableComponent<T = any> = {
  settings: Partial<T> & { key: string };
  component: Type<T>;
  label: string;
};

interface IGroupDatatebleFilters {
  title: string;
  items: DatatableComponent[];
}

@Component({
  selector: 'gtd-datatable-filters-modal',
  templateUrl: './datatable-filters.modal.html',
})
@DestroySubscribers()
export class DatatableFiltersModal extends P500UIBaseModal implements OnInit, AfterContentChecked {
  public form: UntypedFormGroup;
  public components: IGroupDatatebleFilters[] = [];

  public changeFilters: (filters: any) => void;
  public filtersModel: IFilterModel[];
  public buttonFilters: ElementRef;
  public activeItemsToggle: UntypedFormControl;
  public searchControl = new UntypedFormControl();
  public searchMap = new Map<number, Set<number>>();
  private globalData: IParsedDataResponse;
  public groupToggleMap = new Map<number, boolean>();
  public itemToggleMap = new Map<number, Map<number, boolean>>();
  public TPDependsFields: TPDependsFields;

  constructor(
    @Inject(IMODALS_SERVICE) public modalsService: IModalsService,
    @Inject(IDATA_SERVICE) private dataService: IDataService,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    private eRef: ElementRef,
    @Inject(DOCUMENT) private dom,
    private cd: ChangeDetectorRef,
    @Inject(IRBAC_SERVICE) private rbacService: IRbacService
  ) {
    super(modalsService);
    this.globalData = this.dataService.getData();
    this.subscribers.filterModalObserver = this.eventsService.on('closeFilters').subscribe(() => {
      this.close();
    });
    this.subscribers.search = this.searchControl.valueChanges.subscribe((value) => {
      this.searchMap.clear();
      const reg = new RegExp(value, 'i');
      this.components.forEach((value, i) => {
        value.items.forEach((item, j) => {
          if (item.label.search(reg) !== -1) {
            this.searchMap.set(i, this.searchMap.get(i)?.add(j) || new Set([j]));
          }
        });
      });
    });
    this.TPDependsFields = new TPDependsFields(this.subscribers, this.globalData, this.rbacService);
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touch', ['$event'])
  handleOutsideClick(event) {
    if (
      (this.dom.querySelector('.c-tour-board') && this.dom.querySelector('.c-tour-board').contains(event.target)) ||
      (this.dom.querySelector('ngx-guided-tour') && this.dom.querySelector('ngx-guided-tour').contains(event.target)) ||
      event.target.classList.contains('next-button')
    ) {
      return;
    }
    if (!this.buttonFilters.nativeElement.contains(event.target) && !this.eRef.nativeElement.contains(event.target)) {
      this.closeModal();
    }
  }

  ngOnInit(): void {
    this.activeItemsToggle = this.filtersModel.find(({ model: { key } }) => key === 'active')?.model.formControl;
    const formGroup = {};
    let tpControl: AbstractControl;
    this.filtersModel.forEach((filter, i) => {
      const { model } = filter;
      if (model.key !== 'active') {
        const group = filter.group ? filter.group : 'Other';
        const filterComponent = {
          component: model.component,
          settings: { ...model.settings, key: model.key, control: model.formControl },
          label: model.label,
          type: model.type,
        };
        const component = this.components.find((feild) => feild.title === group);
        if (component) {
          component.items.push(filterComponent);
        } else {
          const field = {
            title: group,
            items: [filterComponent],
          };
          this.components.push(field);
        }
      }
      formGroup[model.key] = model.formControl;
      this.groupToggleMap.set(i, false);
      if (model.type === this.TPDependsFields.TpDependsTypes.tp) {
        tpControl = model.formControl;
      }
    });
    this.form = new UntypedFormGroup(formGroup);
    /**
     * Depend TP Fields
     */
    if (tpControl) {
      this.TPDependsFields.init(tpControl);
    }
  }

  groupToggle(i: number): void {
    this.groupToggleMap.set(i, !this.groupToggleMap.get(i));
  }

  itemToggle(i: number, j: number): void {
    const open = !this.itemToggleMap.get(i)?.get(j);
    this.itemToggleMap.set(i, this.itemToggleMap.get(i)?.set(j, open) || new Map([[j, open]]));
  }

  isHiddenTpFields(type: string): boolean {
    switch (type) {
      case this.TPDependsFields.TpDependsTypes.affiliate:
      case this.TPDependsFields.TpDependsTypes.offer:
        return true;
      default:
        return false;
    }
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  submit(): void {
    const formRawValues = this.form.getRawValue();
    const filtersMapped = {};
    Object.entries(formRawValues).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        if (key !== 'active') {
          filtersMapped[key] = value;
        } else {
          if (value) {
            filtersMapped[key] = '1';
          }
        }
      }
    });
    this.changeFilters(filtersMapped);

    this.closeModal();
  }

  resetFilters(): void {
    this.form.reset();
    this.submit();
  }

  clear(formControl: UntypedFormControl): void {
    formControl.setValue(null);
  }

  getCountSelected(formControl: UntypedFormControl): number {
    if (formControl.value !== null) {
      return Array.isArray(formControl.value) ? formControl.value.length : 1;
    } else {
      return 0;
    }
  }

  closeModal(): void {
    this.eventsService.broadcast('closeFilters');
  }
}
