import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

interface IAlert {
  isIncident: boolean;
  id: number;
  status?: string;
  message: string;
  created_at_timestamp: number;
  type: string;
}

interface IAlertService {
  getAlerts(): Observable<IAlert[]>;
  getReadStatus(): Observable<boolean>;
  updateReadStatus(date: number): void;
}

const IALERT_SERVICE = new InjectionToken<IAlertService>('IAlertService');

export { IAlert, IAlertService, IALERT_SERVICE };
