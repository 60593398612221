import { CommonModule } from '@angular/common';
import { CurrentTimeModule } from '../current-time/current-time.module';
import { NgModule } from '@angular/core';
import { TodayDataComponent } from './today-data.component';
import { P500UIIconsModule } from '@platform500services/p500-ui-kit';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule, CurrentTimeModule, P500UIIconsModule],
  declarations: [TodayDataComponent],
  exports: [TodayDataComponent],
})
export class TodayDataModule {}
