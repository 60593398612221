import { Component } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router } from '@angular/router';

import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';
import { CHANGELOG_VERSION } from '../../../../changelog-version';

@Component({
  selector: 'gtd-changelog-btn',
  templateUrl: './changelog-btn.component.html',
})
@DestroySubscribers()
export class ChangelogBtnComponent {
  public isShowBtnCheckUpdate = false;
  public readonly localStorageKey = 'last-changelog-date';

  private changelogDate: number;
  private subscribers: any = {};

  constructor(private router: Router, private localStorage: LocalStorage) {
    this.subscribers.lastChangelogDate = this.localStorage.getItem(this.localStorageKey).subscribe((lastChangelogDate): void => {
      this.changelogDate = new Date(CHANGELOG_VERSION.date).getTime();
      if (lastChangelogDate && this.changelogDate <= lastChangelogDate) {
        this.isShowBtnCheckUpdate = false;
      } else {
        this.isShowBtnCheckUpdate = true;
      }
    });
  }

  checkNewUpdate(): void {
    this.subscribers.setItem = this.localStorage.setItem(this.localStorageKey, this.changelogDate).subscribe(() => {});
    this.isShowBtnCheckUpdate = false;
    this.router.navigate(['/app/changelog']);
  }
}
