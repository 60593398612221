import { RbacPermissionsConstant } from '../../../core/services/rbac/rbac-permissions.constant';
import { IMenuItem } from '@platform500services/p500-ui-kit';

export const MENU_CONFIG: IMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'p500-ui-icon-menu-home',
    permission: RbacPermissionsConstant.PAGES_SPECIAL_DASHBOARD,
    link: '/app/dashboard',
  },
  {
    title: 'Traffic Distribution',
    icon: ' p500-ui-icon-menu-landings',
    children: [
      {
        title: 'Advertisers',
        permission: RbacPermissionsConstant.PAGES_TABLE_BRANDS,
        link: '/app/traffic-distribution/advertisers',
      },
      {
        title: 'Rotation Control',
        permission: RbacPermissionsConstant.PAGES_SPECIAL_ROTATION, // TODO correct RBAC
        link: '/app/traffic-distribution/rotation-control',
      },
    ],
  },
  {
    title: 'Traffic Data',
    icon: ' p500-ui-icon-menu-folders',
    children: [
      {
        key: 'LeadModelsTable',
        title: 'Events',
        permission: RbacPermissionsConstant.PAGES_TABLE_LEADS,
        link: '/app/new-traffic-data/leads',
      },
    ],
  },
  {
    title: 'Leads Conversions',
    icon: 'p500-ui-icon-menu-traffic-data',
    children: [
      {
        title: 'Payouts',
        link: '/app/leads-conversions/payouts',
        permission: RbacPermissionsConstant.PAGES_SPECIAL_PAYOUTS,
      },
      {
        title: 'Revenues',
        link: '/app/leads-conversions/revenues',
        permission: RbacPermissionsConstant.PAGES_SPECIAL_REVENUE,
      },
      {
        title: 'Conversions',
        link: '/app/leads-conversions/conversions',
        permission: RbacPermissionsConstant.PAGES_TABLE_CONVERSIONS,
      },
    ],
  },
  {
    title: 'Landings',
    icon: 'p500-ui-icon-menu-template',
    children: [
      {
        title: 'Marketplace',
        permission: RbacPermissionsConstant.PAGES_TABLE_FUNNELS,
        link: '/app/landings/marketplace',
      },
      {
        title: 'AB Tests',
        permission: RbacPermissionsConstant.PAGES_TABLE_AB_TESTS,
        link: '/app/landings/ab-tests',
      },
      {
        title: 'Geo Groups',
        permission: RbacPermissionsConstant.PAGES_TABLE_GEO_FUNNELS,
        link: '/app/landings/geo-funnels',
      },
    ],
  },
  {
    title: 'Pixels & postbacks',
    icon: 'p500-ui-icon-menu-bag',
    children: [
      {
        title: 'Postbacks',
        permission: RbacPermissionsConstant.PAGES_TABLE_POSTBACKS,
        link: '/app/pixels-postbacks/backend-postbacks',
      },
      {
        title: 'Funnels Pixels',
        permission: RbacPermissionsConstant.PAGES_TABLE_FUNNELS_PIXELS,
        link: '/app/pixels-postbacks/funnels-pixels',
      },
    ],
  },
  {
    title: 'Reporting',
    icon: 'p500-ui-icon-menu-reporting',
    children: [
      // {
      //   title: 'Charts Builder',
      //   permission: RbacPermissionsConstant.PAGES_SPECIAL_CHARTS_BUILDER,
      //   link: '/app/reporting/charts-builder',
      // },
      // {
      //   title: 'Comparing Analytics',
      //   permission: RbacPermissionsConstant.PAGES_SPECIAL_COMPARING_ANALYTICS,
      //   link: '/app/reporting/comparative-analytics',
      // },
      {
        title: 'Data Exporter',
        permission: RbacPermissionsConstant.PAGES_SPECIAL_REPORTS_BUILDER,
        link: '/app/reporting/data-exporter',
      },
      {
        title: 'Drilldown Reports',
        permission: RbacPermissionsConstant.PAGES_SPECIAL_DRILL_DOWN_REPORTS,
        link: '/app/reporting/new-drilldown-reports',
      },
    ],
  },
  {
    title: 'Notifications',
    icon: 'p500-ui-icon-other-warning',
    sectionButtonClass: '-incidents',
    permission: RbacPermissionsConstant.PAGES_TABLE_INCIDENTS,
    link: '/app/notifications/notifications',
  },
  {
    title: 'Finance and Tools',
    icon: 'p500-ui-icon-menu-finance',
    children: [
      {
        title: 'Deals History',
        permission: RbacPermissionsConstant.PAGES_TABLE_DEALS_HISTORY,
        link: '/app/finance-tools/deals-history',
      },
      {
        title: 'Companies',
        permission: RbacPermissionsConstant.PAGES_TABLE_COMPANIES,
        link: '/app/finance-tools/companies',
      },
      {
        title: 'Invoices',
        permission: RbacPermissionsConstant.PAGES_TABLE_INVOICES,
        link: '/app/finance-tools/invoices',
      },
      {
        title: 'Deals Updater',
        permission: RbacPermissionsConstant.PAGES_SPECIAL_DEALS_UPDATER,
        link: '/app/finance-tools/deals-updater',
        canAction: true,
      },
      {
        title: 'Invoice Generator',
        permission: RbacPermissionsConstant.PAGES_SPECIAL_INVOICE_GENERATOR,
        link: '/app/finance-tools/invoice-generator',
        canAction: true,
      },
    ],
  },
  {
    title: 'Injections',
    icon: 'p500-ui-icon-menu-speaker',
    children: [
      {
        title: 'Lists',
        permission: RbacPermissionsConstant.PAGES_TABLE_LEADS_REPUSH_BUCKETS,
        link: '/app/injections/leads-lists',
      },
      {
        title: 'Injections',
        permission: RbacPermissionsConstant.PAGES_SPECIAL_LEADS_REPUSH_DRILLDOWN,
        link: '/app/injections/injections',
      },
      {
        title: 'Auto Injector',
        permission: RbacPermissionsConstant.PAGES_SPECIAL_LEADS_REPUSH_DRILLDOWN,
        link: '/app/injections/auto-injections',
      },
    ],
  },
];

// export const SETTINGS_MENU_CONFIG: IMenuItem[] = [
//   {
//     title: 'Settings Navigation',
//     icon: 'p500-ui-icon-menu-home',
//     link: '/app/settings/navigation',
//   },
//   {
//     title: 'Leads Management',
//     icon: 'p500-ui-icon-workflow',
//     children: [
//       {
//         title: 'Profile',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/lead-profile',
//       },
//       {
//         title: 'Flow',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/lead-flow',
//       },
//       {
//         title: 'Forms',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/leads-form',
//       },
//       {
//         title: 'Sale Statuses',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/sale-status',
//       },
//     ],
//   },
//   {
//     title: 'Traffic Management',
//     icon: 'p500-ui-icon-menu-trafficControl',
//     children: [
//       {
//         title: 'Filters',
//         permission: RbacPermissionsConstant.PAGES_TABLE_TRAFFIC_FILTERS,
//         link: '/app/settings/traffic-filters',
//       },
//       {
//         title: 'Collectors ',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/traffic-collectors-trees',
//       },
//       {
//         title: 'Collectors Types',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/traffic-collectors',
//       },
//       {
//         title: 'Providers',
//         permission: RbacPermissionsConstant.PAGES_TABLE_TRACKING_PROVIDERS,
//         link: '/app/settings/tracking-providers',
//       },
//     ],
//   },
//   {
//     title: 'Customization CRM',
//     icon: 'p500-ui-icon-menu-tools',
//     children: [
//       {
//         title: 'Terms',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/crm-terms',
//       },
//       {
//         title: 'Presets',
//         permission: RbacPermissionsConstant.PAGES_SPECIAL_DRILL_DOWN_REPORTS,
//         link: '/app/settings/presets-list',
//       },
//       {
//         title: 'CRM Settings',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/crm-settings',
//       },
//     ],
//   },
//   {
//     title: 'Goals Management',
//     icon: 'p500-ui-icon-menu-external',
//     children: [
//       {
//         title: 'Goals',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/goals',
//       },
//       {
//         title: 'Goals Types',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/goal-types',
//       },
//     ],
//   },
//   {
//     title: 'Pulling Mappings',
//     icon: 'p500-ui-icon-other-tree',
//     children: [
//       {
//         title: 'Pulling Mappings',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/pulling-mappings',
//       },
//       {
//         title: 'Pulling Mappings Types',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/pulling-mappings-types',
//       },
//     ],
//   },
//   {
//     title: 'API Management',
//     icon: 'p500-ui-icon-domains',
//     link: '/app/settings/api-management',
//     permission: RbacPermissionsConstant.PAGES_TABLE_API_TOKENS,
//   },
//   {
//     title: 'Risk Management',
//     icon: 'p500-ui-icon-menu-affilate',
//     link: '/app/settings/risk-management',
//     permission: RbacPermissionsConstant.PAGES_TABLE_POSTBACK_PENDERS,
//   },
//   {
//     title: 'System Logs',
//     icon: 'p500-ui-icon-menu-template',
//     children: [
//       {
//         title: 'Executed Postbacks',
//         permission: RbacPermissionsConstant.PAGES_TABLE_POSTBACKS,
//         link: '/app/settings/executed-postbacks',
//       },
//       {
//         title: 'Executed Risk',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/executed-risk-page',
//       },
//       {
//         title: 'Events Logs',
//         permission: RbacPermissionsConstant.PAGES_TABLE_EVENTS_LOG,
//         link: '/app/settings/events-logs',
//       },
//       {
//         title: 'Queries Audit',
//         permission: RbacPermissionsConstant.PAGES_SPECIAL_QUERIES_AUDIT,
//         link: '/app/settings/queries-audit',
//       },
//       {
//         title: 'Advertisers Tests',
//         permission: RbacPermissionsConstant.PAGES_TABLE_BRANDS_COUNTRY_TESTS,
//         link: '/app/settings/advertisers-tests',
//       },
//       {
//         title: 'Unsecured Mode Logs',
//         permission: RbacPermissionsConstant.PAGES_TABLE_UNSECURED_MODE_LOGS,
//         link: '/app/settings/unsecured-mode-logs',
//       },
//       {
//         title: 'Billing',
//         permission: RbacPermissionsConstant.WORKFLOW_SECTION,
//         link: '/app/settings/billing',
//       },
//     ],
//   },
// ];
