import { Component, Input, Inject } from '@angular/core';
import { IEVENT_SERVICE, IEventsService } from '../../../core/services/events/events.interface';

@Component({
  selector: 'gtd-help-menu',
  templateUrl: './help-menu.component.html',
})
export class HelpMenuComponent {
  @Input() cssClass = '';

  constructor(@Inject(IEVENT_SERVICE) private eventsService: IEventsService) {}

  openDocsBoard() {
    this.eventsService.broadcast('docs:open');
  }

  openTourBoard() {
    this.eventsService.broadcast('tourBoard:open');
  }
}
