import { Component, HostBinding } from '@angular/core';
import { AppSelectorBaseComponent } from '../base/app-selector-base';

@Component({
  selector: 'gtd-selector-dropdown-header',
  templateUrl: './app-selector-dropdown-header.component.html',
})
export class AppSelectorDropdownHeaderComponent extends AppSelectorBaseComponent {
  @HostBinding('class') className = 'c-sidebar__header';

  public isShowMenu = false;

  toggleMenu(): void {
    this.isShowMenu = !this.isShowMenu;
  }
}
