import { AfterViewInit, Component, Inject, ViewChild, ViewContainerRef, NgModuleRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDATA_SERVICE, IDataService, IParsedDataResponse } from '../../../core/services/data/data-service.interface';
import { IEVENT_SERVICE, IEventsService } from '../../../core/services/events/events.interface';
import { IMODALS_SERVICE, IModalsService } from '@platform500services/p500-ui-kit';
import {
  IConfigurationData,
  IConfigurationService,
  ICONFIGURATION_SERVICE,
} from '../../../core/services/configuration/configuration-service.interface';
import { DOCUMENT } from '@angular/common';
import { IAUTH_SERVICE, IAuthService, UserResponse } from '../../../core/services/auth';
import { DestroySubscribers } from '../../../core/decorators/destroy-subscribers.decorator';
import { NewDesignNewYearModal } from '../../modals/new-design-new-year/new-design-new-year.modal';
import { IWebPushService, IWEB_PUSH_SERVICE } from '../services/web-push-service.interface';
import { InfoModal, INFO_MODAL_TYPES } from '../../modals/info/info.modal';
import { timer } from 'rxjs';
import { RbacPermissionsConstant } from '../../services/rbac/rbac-permissions.constant';
import { IRbacService, IRBAC_SERVICE } from '../../services/rbac/rbac-service.interface';

@Component({
  templateUrl: './base.layout.html',
})
@DestroySubscribers()
export class BaseLayoutComponent implements AfterViewInit {
  @ViewChild('appContainer', { read: ViewContainerRef }) appContainer: ViewContainerRef;

  public isOverlay = false;
  public isLoaded = false;
  public isTourOpened = false;
  public isCollapsedSidebar: boolean;
  public isNotificationsOpened = false;

  private isShownSidebar = false;
  private globalData: IParsedDataResponse;

  private subscribers: any = {};

  constructor(
    @Inject(IMODALS_SERVICE) private modalsService: IModalsService,
    @Inject(IDATA_SERVICE) private dataService: IDataService,
    @Inject(IRBAC_SERVICE) private rbacService: IRbacService,
    @Inject(ICONFIGURATION_SERVICE) public configurationService: IConfigurationService,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    @Inject(IAUTH_SERVICE) private authService: IAuthService,
    @Inject(IWEB_PUSH_SERVICE) private webPushService: IWebPushService,
    @Inject(DOCUMENT) private document: HTMLDocument,
    private activatedRoute: ActivatedRoute,
    private moduleRef: NgModuleRef<any>,
    private render: Renderer2
  ) {
    this.subscribers.isShownSidebar = this.eventsService.on('sidebarComponent:toggle').subscribe(() => {
      this.isShownSidebar = !this.isShownSidebar;
      this.isOverlay = !this.isOverlay;
    });
    this.subscribers.closeSidebar = this.eventsService.on('sidebarComponent:close').subscribe(() => {
      this.isShownSidebar = false;
      this.isOverlay = false;
    });
    this.subscribers.isCollapsedNavigation = this.eventsService.on('sidebarComponent:collapsed-toggle').subscribe((value: boolean) => {
      this.isCollapsedSidebar = value;
    });

    this.subscribers.route = this.activatedRoute.data.subscribe(
      (data: { userData: UserResponse; dataData: any; configurationData: IConfigurationData }) => {
        this.authService.setUser(data.userData);
        this.dataService.setData(data.dataData);
        this.configurationService.setData(data.configurationData);
        this.document.getElementById('app-favicon').setAttribute('href', data.configurationData ? data.configurationData.brand.icon : '');
        this.globalData = this.dataService.getData();
        this.isLoaded = true;
      }
    );

    this.subscribers.openedTour = this.eventsService.on('tourBoard:open').subscribe(() => (this.isTourOpened = true));
    this.subscribers.docsOpen = this.eventsService.on('docs:open').subscribe(() => (this.isTourOpened = false));
    this.subscribers.accountMode = this.eventsService.on<boolean>('account-mode').subscribe((v) => {
      if (v) {
        this.render.addClass(document.body, 'account-mode');
      } else {
        this.render.removeClass(document.body, 'account-mode');
      }
    });
    this.subscribers.isShownSidebar = this.eventsService.on('isOpenNotificationsSidebar:toggle').subscribe(() => {
      this.isNotificationsOpened = !this.isNotificationsOpened;
    });

    this.webPushService.wepPush();
  }

  ngAfterViewInit(): void {
    this.modalsService.target = this.appContainer;

    const key = 'gtd-new-design-v6';
    const isModalShown = localStorage.getItem(key);
    if (isModalShown !== 'true') {
      this.modalsService.open(NewDesignNewYearModal, {}, this.moduleRef, false);
      localStorage.setItem(key, 'true');
    }
    const defaultDelay = 20;
    const unpaidClientTimeout = Number(this.globalData.unpaid_client_timeout ?? defaultDelay);
    const delay = (isNaN(unpaidClientTimeout) ? defaultDelay : unpaidClientTimeout) * 1000;
    this.showUnpaidClientModal(delay);
    this.showAgreementsModal(10000);
  }

  showUnpaidClientModal(delay: number): void {
    timer(delay).subscribe(() => {
      if (this.globalData.unpaid_client === 'yes') {
        this.modalsService.open(
          InfoModal,
          {
            title: 'Warning',
            bodyText: 'Your system is not paid. Please contact your account manager as soon as possible to sort it out.',
            type: INFO_MODAL_TYPES.DANGER,
            onClose: () => {
              this.showUnpaidClientModal(delay);
            },
          },
          this.moduleRef
        );
      }
    });
  }

  showAgreementsModal(delay: number): void {
    timer(delay).subscribe(() => {
      if (this.globalData.agreement_status.all_signed === false) {
        this.modalsService.open(
          InfoModal,
          {
            title: 'Attention!',
            bodyText: this.rbacService.can(RbacPermissionsConstant.VISIBILITY_AGREEMENTS_STATUS)
              ? 'We have updated our terms of service. You are required to read and sign up before continue. Go to Profile.'
              : 'We have updated our terms of service. Contact your product owner to read and sign up before continue.',
            type: INFO_MODAL_TYPES.DANGER,
            showControlLink: this.rbacService.can(RbacPermissionsConstant.VISIBILITY_AGREEMENTS_STATUS),
            onClose: () => {
              this.showAgreementsModal(20000);
            },
          },
          this.moduleRef
        );
      }
    });
  }
}
