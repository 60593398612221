<div class="c-empty">
  <div class="c-empty__inner">
    <div class="c-empty__icon">
      <img [src]="'assets/new-img/icons/black/' + icon + '.svg'" alt="empty" />
    </div>
    <div class="c-empty__desc" [class.mb-14]="actions?.length">{{ desc }}</div>
    <ng-container *ngIf="actions?.length">
      <div *ngFor="let action of actions" class="c-empty__action">
        <ng-container *ngTemplateOutlet="action"></ng-container>
      </div>
    </ng-container>
  </div>
</div>
