import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  P500TabsUIModule,
  P500UIButtonsModule,
  P500UIIconsModule,
  P500UIPaginationModule,
  P500UIPipesModule,
  P500UIProgressModule,
} from '@platform500services/p500-ui-kit';
import { FieldsModule } from '../fields/fields.module';
import { TableService } from '../../services/tables/table.service';
import { ActionButtonModule } from '../actions/action-button/action-button.module';
import { NotificationsSidebarComponent } from './notifications-sidebar.component';
import { NotificationsApplicationService } from '../notifications-menu/services/notifications/notifications.service';
import { IncidentsDataService } from '../notifications-menu/services/incidents/incidents-data.service';
import { AlertService } from '../notifications-menu/services/alerts/alert.service';
import { SearchModule } from '../form-fields/search/search.module';
import { FormsModule } from '@angular/forms';
import { EmptyModule } from '../empty/empty.module';
import { NotificationsModalModule } from '../../modals/notifications-modal/notifications-modal.module';

@NgModule({
  imports: [
    CommonModule,
    P500UIIconsModule,
    P500UIPipesModule,
    RouterModule,
    P500UIButtonsModule,
    ActionButtonModule,
    FieldsModule,
    P500UIPaginationModule,
    SearchModule,
    P500TabsUIModule,
    FormsModule,
    P500UIProgressModule,
    EmptyModule,
    NotificationsModalModule,
  ],
  declarations: [NotificationsSidebarComponent],
  exports: [NotificationsSidebarComponent],
  providers: [
    {
      provide: 'ITableService',
      useClass: TableService,
    },
    {
      provide: 'INotificationsApplicationService',
      useClass: NotificationsApplicationService,
    },
    {
      useClass: IncidentsDataService,
      provide: 'IncidentsDataServiceInterface',
    },
    {
      provide: 'IAlertService',
      useClass: AlertService,
    },
  ],
})
export class NotificationsSidebarModule {}
