interface FormData {
  [field: string]: any;
}

const mapToBackendFormat = (form: FormData): FormData => {
  return Object.entries(form).reduce(
    (acc, [name, value]) => ({
      ...acc,
      [name]: typeof value === 'boolean' ? String(Number(value)) : value,
    }),
    {}
  );
};

export { mapToBackendFormat, FormData };
