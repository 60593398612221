import { Injectable, Inject } from '@angular/core';
import { GuidedTour, GuidedTourService } from 'ngx-guided-tour';
import { DOCUMENT } from '@angular/common';
import { tours, ITourSection, ITour } from './tours/tours.constants';
import { Subject } from 'rxjs';
import { TourProgressService } from './tour-progress.service';
import { IEVENT_SERVICE, IEventsService } from '../../../core/services/events/events.interface';

@Injectable()
export class TourService {
  private currentTourKey = '';
  private currentTourSections: ITourSection[];
  private currentTourChainId: number;
  private currentSectionId: number;
  private currentViewport: string;
  private elementObserver: IntersectionObserver;
  private lastViewportScrollTime = 0;
  private checkSelectorsInterval;
  private readonly checkTourChangesInterval = 500;
  private subs: any = {};

  public tourCurrentSectionStream = new Subject<ITourSection>();
  public tourCurrentStepStream = new Subject<any>();

  constructor(
    private guidedTour: GuidedTourService,
    private tourProgressService: TourProgressService,
    @Inject(DOCUMENT) private dom,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService
  ) {}

  startGuidedTour(tourKey: string): void {
    this.currentTourKey = tourKey;
    const foundTour = tours.find((tour: ITour) => tour.tourKey === tourKey);

    this.currentTourSections = foundTour ? foundTour.tourSections : tours[0].tourSections;
    this.currentSectionId = 0;
    this.currentTourChainId = null;
    this.updateSectionStream();

    this.subs.stepStream = this.guidedTour.guidedTourCurrentStepStream.subscribe((step) => {
      if (!step) {
        return;
      }
      this.tourCurrentStepStream.next({ selector: step.selector, last: this.isTourFinished() });
      setTimeout(() => {
        this.fixNextButtonPosition(step.selector);
      });
    });

    this.prepareNextTour();
  }

  prepareNextTour(): void {
    let tourSection = this.currentTourSections[this.currentSectionId];
    this.currentTourChainId = this.currentTourChainId === null ? 0 : this.currentTourChainId + 1;
    if (this.currentTourChainId >= tourSection.tourChains.length) {
      this.currentTourChainId = 0;
      this.currentSectionId++;
      this.tourProgressService.saveProgress(this.currentTourKey, this.currentSectionId);
      tourSection = this.currentTourSections[this.currentSectionId];
      if (!tourSection) {
        return;
      }
      this.updateSectionStream();
    }
    const tourData = tourSection.tourChains[this.currentTourChainId];
    const tour: GuidedTour = {
      tourId: tourData.tourId,
      steps: tourData.steps.map((step) => {
        return {
          selector: step.selector,
          title: step.stepText,
          content: '',
          useHighlightPadding: true,
          highlightPadding: 5,
          orientation: step.orientation,
          closeAction: () => {
            this.eventsService.broadcast('tour:afterStep');
          },
        };
      }),
      completeCallback: () => {
        this.prepareNextTour();
      },
      preventBackdropFromAdvancing: true,
    };
    this.currentViewport = tourData.viewport;
    this.checkElementsExist(tour);
  }

  updateSectionStream(): void {
    const tourSection = this.currentTourSections[this.currentSectionId];
    this.tourCurrentSectionStream.next({
      ...tourSection,
      totalSectionsNumber: this.currentTourSections.length,
      currentSectionNumber: this.currentTourSections.indexOf(tourSection) + 1,
    });
  }

  checkElementsExist(tour: GuidedTour): void {
    this.checkSelectorsInterval = setInterval(() => {
      if (this.currentViewport !== '.c-modal' && this.dom.querySelector('.c-modal')) {
        return;
      }
      const selectorsFound = tour.steps.reduce((totalFound, step) => totalFound + (this.dom.querySelector(step.selector) ? 1 : 0), 0);
      if (tour.steps.length === selectorsFound) {
        clearInterval(this.checkSelectorsInterval);
        this.checkElementsVisible(tour);
      }
    }, this.checkTourChangesInterval);
  }

  checkElementsVisible(tour: GuidedTour): void {
    this.startScrollViewportObserver();
    const elementsVisible = [];
    this.elementObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.9) {
            elementsVisible.push(entry.target);
          } else {
            const elementIndex = elementsVisible.indexOf(entry.target);
            if (elementIndex !== -1) {
              elementsVisible.splice(elementsVisible.indexOf(entry.target), 1);
            }
          }
        });
      },
      {
        root: this.dom.querySelector(this.currentViewport),
        threshold: 0.9,
      }
    );

    tour.steps
      .map((step) => step.selector)
      .forEach((selector) => {
        const target = document.querySelector(selector);
        this.elementObserver.observe(target);
      });

    const waitForStart = setInterval(() => {
      const lastScrollTimeLeft = Date.now() - this.lastViewportScrollTime;
      if (tour.steps.length === elementsVisible.length && lastScrollTimeLeft > this.checkTourChangesInterval) {
        this.elementObserver.disconnect();
        this.guidedTour.startTour(tour);
        clearInterval(waitForStart);
      }
    }, this.checkTourChangesInterval);
  }

  startScrollViewportObserver(): void {
    const viewport = this.dom.querySelector(this.currentViewport);
    const handler = (event) => {
      this.lastViewportScrollTime = Date.now();
      if (!event.target.classList.contains(this.currentViewport)) {
        viewport.removeEventListener(scroll, handler);
      }
    };
    viewport.addEventListener('scroll', handler);
  }

  nextStep(): void {
    if (!this.isTourFinished()) {
      this.guidedTour.nextStep();
    }
  }

  isLastTourChain(): boolean {
    const currentSection = this.currentTourSections[this.currentSectionId];
    return !currentSection.tourChains[this.currentTourChainId + 1];
  }

  isLastSection(): boolean {
    return !this.currentTourSections[this.currentSectionId + 1];
  }

  isTourFinished(): boolean {
    const finished: boolean = this.guidedTour.onLastStep && this.isLastTourChain() && this.isLastSection();
    if (finished) {
      this.tourProgressService.saveProgress(this.currentTourKey, this.currentTourSections.length);
    }
    return finished;
  }

  stopTour(): void {
    this.elementObserver.disconnect();
    clearInterval(this.checkSelectorsInterval);
    this.subs.stepStream.unsubscribe();
    this.guidedTour.resetTour();
  }

  fixNextButtonPosition(stepSelector) {
    const element = this.dom.querySelector(stepSelector);
    const elementHeight = element.offsetHeight;
    const tourStepBlock = this.dom.querySelector('ngx-guided-tour .tour-step');
    tourStepBlock.style.top = parseInt(tourStepBlock.style.top, 10) - elementHeight / 2 + 'px';
    tourStepBlock.style.opacity = 1;
  }
}
