import { guideTour } from './guide-tour.constants';
import { Orientation } from 'ngx-guided-tour';
import { setupTrafficProviderTour } from './setup-traffic-provider-tour';
import { workWithFunnelsTour } from './work-with-funnels-tour';
import { managingOfPostbacksTour } from './managing-of-postbacks-tour';
import { brandSetupTour } from './brand-setup-tour';

export interface ITour {
  tourKey: string;
  tourName: string;
  tourIcon: string;
  tourDescription: string;
  tourSections: ITourSection[];
}

export interface ITourSection {
  name: string;
  commonTopic: string;
  description: string;
  image: string;
  tourChains: ITourChain[];
  totalSectionsNumber?: number;
  currentSectionNumber?: number;
}

export interface ITourChain {
  tourId: string;
  viewport: string;
  steps: IStep[];
}

export interface IStep {
  selector: string;
  stepText: string;
  orientation: Orientation;
}

export const tours: ITour[] = [guideTour, setupTrafficProviderTour, workWithFunnelsTour, managingOfPostbacksTour, brandSetupTour];
