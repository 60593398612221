import { RbacPermissionsConstant } from '../../../core/services/rbac/rbac-permissions.constant';

export const SETTINGS_CONTENT = {
  PARAMETERS: [
    {
      name: 'Lead Profile',
      description: 'There is the info about Lead which you get from the funnel page.',
      link: '/app/settings/lead-profile',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Lead Flow',
      description:
        'Lead Flow is the workflow of the entire system. It defines the route which lead goes through and what should be done on each step, called State.',
      link: '/app/settings/lead-flow',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'CRM Terms',
      description: 'CRM Terms are basically the formulas consisting of the variables and mathematical signs.',
      link: '/app/settings/crm-terms',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'CRM Settings',
      description:
        'CRM Settings section allows you to set all necessary settings for Dashboard, Tables and Other. There are widgets for Dashboard with useful info, display of tables, rules of validation for different countries and etc. ',
      link: '/app/settings/crm-settings',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Goals',
      description:
        'Goals are the definition of what is conversion according to your needs. You can create a goal for each state based on your Flow, select a Payout Tier and Revenue Tier. Each Lead that passes this state will be counted in the Conversions list in Traffic Data section and given a payout and revenue based on settings.',
      link: '/app/settings/goals',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Funnels',
      description: 'Here you can add your own landings.',
      link: '/app/settings/funnels',
      permission: RbacPermissionsConstant.PAGES_TABLE_FUNNELS,
    },
    {
      name: 'Goal Types',
      description: 'Goal Types are goals categories which you want to achieve (Pushed Lead, FTD for example)',
      link: '/app/settings/goal-types',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Pulling Mappings',
      description:
        'Here you can setup the way you want to receive data from an advertiser about conversions. It’s either a pull or a postback. Pulling Mapping should be set up for every advertiser if the conversion for your system is a state after the first Advertiser Accepted.',
      link: '/app/settings/pulling-mappings',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Pulling Mappings Types',
      description: 'Pulling Mappings type is a kind of conversion for your system (FTD, Pushed Lead for example).',
      link: '/app/settings/pulling-mappings-types',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Traffic Filters',
      description:
        'There are rules which define what traffic will be declined. You select the mix, either logical AND or OR. It’s crucial when you have multiple rules, AND will decline only the traffic that matches all rules, OR will decline the traffic that matches at least one rule.',
      link: '/app/settings/traffic-filters',
      permission: RbacPermissionsConstant.PAGES_TABLE_TRAFFIC_FILTERS,
    },
    {
      name: 'Traffic Collectors Settings',
      description:
        'Integrate a third-party system to collect lead`s profile data more extended. You can set up rules to collect information from several different offers and/or affiliates according to your needs.',
      link: '/app/settings/traffic-collectors',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Traffic Collectors',
      description:
        'Integrate a third-party system to collect lead`s profile data more extended. You can set up rules to collect information from several different offers and/or affiliates according to your needs.',
      link: '/app/settings/traffic-collectors-trees',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Forms',
      description:
        'Forms section is used to create forms that will appear on the funnel based on the lead profile Keys. They are also used in the Flow in the Form Handler, and you can select particular forms for an advertiser in the Edit section of it.',
      link: '/app/settings/leads-form',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Risk Management',
      description:
        'This section is used to create pender rules that will hold firing of postbacks back to the TPs when the PQL rules are passed. You can see the same structure in the Rotation section.',
      link: '/app/settings/risk-management',
      permission: RbacPermissionsConstant.PAGES_TABLE_POSTBACK_PENDERS,
    },
    {
      name: 'Sale Statuses',
      description:
        'Sale Statuses section allows you to see the status of the Lead which a call manager set for him. It has two types: Advertiser Sale Status and Sale Status.',
      link: '/app/settings/sale-status',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Traffic Providers',
      description:
        'Traffic Providers section allows you to add and maintain connection with tracking systems for Tracking Software or any third party one. Tracking systems are software used to manage offers and affiliates, and are crucial for LD functioning. General information about the click, affiliates, offers, etc. are obtained by pulling such information from an integrated tracking system via API connection.',
      link: '/app/settings/tracking-providers',
      permission: RbacPermissionsConstant.PAGES_TABLE_TRACKING_PROVIDERS,
    },
    // {
    //   name: 'Whitelisted IPs',
    //   description:
    //     'This is the CRM section where authorised IP address of the personal computer is provided.
    //      Here you can add IPs of the users approved and affiliates you are working with for better safety.',
    //   link: '/app/settings/whitelisted-ips',
    //   permission: RbacPermissionsConstant.PAGES_TABLE_WHITELISTED_IPS,
    // },
    {
      name: 'Presets',
      description:
        'Presets are preconfigured conditions and fields for Data Exporter, and conditions for DrillDown reports. They allow user to create his own set of what he wants to see in each of them and use it, instead of setting it up everytime he wants to see a report',
      link: '/app/settings/presets-list',
      permission: RbacPermissionsConstant.PAGES_SPECIAL_DRILL_DOWN_REPORTS,
    },
    {
      name: 'Api Tokens',
      description: 'Here you can create an API Token - the personal unique key according to your needs.',
      link: '/app/settings/api-tokens',
      permission: RbacPermissionsConstant.PAGES_SPECIAL_DRILL_DOWN_REPORTS,
    },
    {
      name: 'Billing',
      description: 'Raw conversion data used for invoices.',
      link: '/app/settings/billing',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Notification Settings',
      description: 'Here you can enable or disable different types of notifications',
      link: '/app/settings/notification-settings',
      permission: '',
    },
    {
      name: 'Scheduled Report',
      description: '',
      link: '/app/settings/scheduled-report',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    /* tslint:disable */
    // {
    //   name: 'Pixels Tree',
    //   description:
    //     'Pixel is a small part of HTML code built into the funnel. It can be triggered by different actions like opening the funnel, opt-in or lead registration and so on. Pixel transfers necessary information to the client’s system and has tree-like structure like a Rotation. It allows you to create anything that you want at any level. We highly recommend using a State PQL to create a pixel, so it will fire on a desired step. But anyway, a Pixel entity follows every PQL rule. They’re located in the External section.',
    //   link: 'funnels-pixels',
    // },
    // {
    //   name: 'Postbacks Tree',
    //   description:
    //     'Postback is a link redirecting to a webpage that transfers information back to the original host. Unlike Pixels, Postbacks are fired from the LD side rather than the funnel’s, and they can be fired by FTD or Deposit state. We highly recommend using a  State PQL to create a postback, so it will fire on a desired step. But anyway, a Postback entity follows every PQL rule.They’re located in the Postbacks section.',
    //   link: 'backend-postbacks',
    // },
    /* tslint:enable */
  ],
  CUSTOMIZE: [
    {
      name: 'Trees Logs',
      description: 'Logs for Rotation, Payouts, Postbacks, Pixels and other trees',
      link: '/app/settings/trees-logs',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Executed Postbacks',
      description:
        'This section allows us to see how postbacks work. The table of Executed Postbacks display us Postback and Lead ID, the status of postback (success or not) and error message in case of unsuccessful operation.',
      link: '/app/settings/executed-postbacks',
      permission: RbacPermissionsConstant.PAGES_TABLE_POSTBACKS,
    },
    {
      name: 'Executed Risk',
      description: 'In this section you will find info about which risk management rule was executed, when, and on which conversion\n',
      link: '/app/settings/executed-risk-page',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Events Logs',
      description:
        'Events Logs display most of the actions processed by the system as bugs, errors, notifications, pushed and rejected leads and so on. There are Namespace, Message and Type of logs in the table.',
      link: '/app/settings/events-logs',
      permission: RbacPermissionsConstant.PAGES_TABLE_EVENTS_LOG,
    },
    {
      name: 'Queries Audit',
      description:
        'Here you can see records of actions made by users in the form of the request endpoint, when they made it, what browser or platform they used, their IP and login.',
      link: '/app/settings/queries-audit',
      permission: RbacPermissionsConstant.PAGES_SPECIAL_QUERIES_AUDIT,
    },
    {
      name: 'Affiliate Request Logs',
      description: 'Explore API request logs.',
      link: '/app/settings/affiliate-request-logs',
      permission: RbacPermissionsConstant.WORKFLOW_SECTION,
    },
    {
      name: 'Advertisers Country Tests',
      description: 'Here you can see all leads pushed through the Test Country option',
      link: '/app/settings/country-tests',
      permission: RbacPermissionsConstant.PAGES_TABLE_BRANDS_COUNTRY_TESTS,
    },
    {
      name: 'Unsecured Mode Logs',
      description:
        'Here you can see the information about employees who used an Unsecured Mode feature. There are user IDs, date and time when Unsecured Mode was activated. Only successful activations of Unsecured Mode with two-step authorization in the system are here.',
      link: '/app/settings/unsecured-mode-logs',
      permission: RbacPermissionsConstant.PAGES_TABLE_UNSECURED_MODE_LOGS,
    },
    {
      name: 'Charts Builder',
      description: '',
      link: '/app/settings/charts-builder',
      permission: RbacPermissionsConstant.PAGES_SPECIAL_CHARTS_BUILDER,
    },
    {
      name: 'Comparing Analytics',
      description: '',
      link: '/app/settings/comparative-analytics',
      permission: RbacPermissionsConstant.PAGES_SPECIAL_COMPARING_ANALYTICS,
    },
  ],
};
