import { CommonModule } from '@angular/common';
import { NewDesignNewYearModal } from './new-design-new-year.modal';
import { NgModule } from '@angular/core';
import { P500UIButtonsModule, P500UIIconsModule } from '@platform500services/p500-ui-kit';

@NgModule({
  imports: [CommonModule, P500UIButtonsModule, P500UIIconsModule],
  declarations: [NewDesignNewYearModal],
  exports: [NewDesignNewYearModal],
})
export class NewDesignNewYearModule {}
