import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';
import { DataResponse } from '../services/actions/responses/data.response';

export function getMarkedIDs(): OperatorFunction<{ [id: string]: boolean }, string[]> {
  return (source) =>
    source.pipe(
      map((values) =>
        Object.entries(values)
          .filter(([, value]) => value)
          .map(([id]) => id)
      )
    );
}

export function pluckLength<T>(): OperatorFunction<T[], number> {
  return (source) => source.pipe(pluck('length'));
}

export type SuccessAnnotation<T> = { type: 'success'; payload: T };
export type ErrorAnnotation<T> = { type: 'error'; payload: T };
export type Annotation<T, O> = SuccessAnnotation<T> | ErrorAnnotation<O>;

export function catchErrorWithAnnotation<T, O = any>(): OperatorFunction<T, Annotation<T, O>> {
  return (source: Observable<T>): Observable<Annotation<T, O>> =>
    source.pipe(
      map((payload: T) => ({ type: 'success' as 'success', payload })),
      catchError((payload: O) => of({ type: 'error' as 'error', payload }))
    );
}

export type FormWithEntityID = { entityID: string; [prop: string]: any };

export function getFormData(entityID): OperatorFunction<DataResponse, FormWithEntityID> {
  return (source) =>
    source.pipe(
      map((data) =>
        (data.data.fields as []).reduce(
          /* "typeof value === 'boolean'" need because backend can't understand true/false */
          (acc, { name, value }) => ({ ...acc, [name]: typeof value === 'boolean' ? String(Number(value)) : value }),
          { entityID }
        )
      )
    );
}
