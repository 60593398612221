import { P500UIButtonsModule, P500UIIconsModule, P500UIProgressModule } from '@platform500services/p500-ui-kit';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ImageViewModule } from '../../modals/image-view/image-view.module';
import { KnowledgeBaseComponent } from './knowledge-base.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, P500UIButtonsModule, P500UIIconsModule, P500UIProgressModule, FormsModule, ImageViewModule],
  declarations: [KnowledgeBaseComponent],
  exports: [KnowledgeBaseComponent],
})
export class KnowledgeBaseModule {}
