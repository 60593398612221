import { HostBinding, Input } from '@angular/core';
import { IMODALS_SERVICE, IModalsService, P500UIBaseModal } from '@platform500services/p500-ui-kit';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActionConfigInterface, Group } from '../../services/actions/interfaces/action-config.interface';
import { IActionsService, IACTIONS_SERVICE } from '../../services/actions/actions-service.interface';
import { INOTIFICATIONS_SERVICE, INotificationsService } from '@platform500services/p500-ui-kit';
import { IPAGE_SERVICE, IPageService } from '../../services/page/page-service.interface';
import { ComponentModel, FieldModel } from '../../services/actions/models/field.model';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';
import { isObservable, of } from 'rxjs';
import { mapToBackendFormat } from '../../helpers/map-to-backend-format';

@Component({
  selector: 'gtd-action-form-modal',
  templateUrl: './action-form.modal.html',
})
@DestroySubscribers()
export class ActionFormModal extends P500UIBaseModal implements OnInit {
  @Input() data: ActionFormData;
  @Input() successCb: (data: any) => void;
  private fieldsModel: FieldModel[] = [];
  public isLoading = true;
  public form: UntypedFormGroup;
  public components: Map<string, ComponentModel['component']> = new Map();
  public componentsSettings: Map<string, ComponentModel['settings']> = new Map();
  public activeItemsToggle: UntypedFormControl;
  public groups?: Group[];

  constructor(
    @Inject(IMODALS_SERVICE) public modalsService: IModalsService,
    @Inject(IACTIONS_SERVICE) private actionsService: IActionsService,
    @Inject(INOTIFICATIONS_SERVICE) private notificationsService: INotificationsService,
    @Inject(IPAGE_SERVICE) private pageService: IPageService
  ) {
    super(modalsService);
  }

  public originalOrder = () => 0;
  @HostBinding('class') get className() {
    const styleConfig = this.data.config
      .getModalStyle()
      .split(' ')
      .map((style) => `p500-ui-modal-${style}`)
      .join(' ');
    return `p500-ui-modal p500-ui-modal--primary ${styleConfig}`;
  }

  ngOnInit(): void {
    this.fieldsModel = this.data.config.getFields();
    this.initForm();
    this.initSubs();
    this.initGroups();
  }

  initForm(): void {
    const formGroup = {};
    this.activeItemsToggle = this.fieldsModel.find((fieldModel) => fieldModel.key === 'active')?.formControl;
    this.fieldsModel.forEach((fieldModel) => {
      fieldModel.formControl.reset();
      fieldModel.formControl.updateValueAndValidity();
      formGroup[fieldModel.key] = fieldModel.formControl;
      const component = fieldModel.getComponent();
      const hint = this.getFieldTooltip(fieldModel.key);
      this.components.set(fieldModel.key, component.component);
      this.componentsSettings.set(fieldModel.key, { ...component.settings, hint });
    });
    this.form = new UntypedFormGroup(formGroup);
  }

  initSubs(): void {
    const { config, id, repository } = this.data;

    const fields$ = !!id
      ? this.actionsService.getData(config, id, repository)
      : of({
          data: {
            fields: this.fieldsModel.map((field) => ({ value: field.getInitialValue(), name: field.key })),
          },
        });

    this.subscribers.procress = fields$.subscribe((data: any) => {
      if (!data || !data.data) {
        return;
      }
      const dataResponse = data.data;
      this.fieldsModel.forEach((fieldModel: FieldModel) => {
        const valueObj = dataResponse.fields.find((elem: any) => elem.name === fieldModel.key);
        const fieldData = fieldModel.settings.data;

        let value = valueObj?.value;
        if (Array.isArray(value) && !isObservable(fieldData) && fieldData) {
          value = value.filter((item) => fieldData[item]);
        }

        if (fieldModel.key === 'active') {
          value = value === '1' || value === 1 || value === true;
        }

        fieldModel.formControl.setValue(value);
      });

      this.isLoading = false;
    });
  }

  private initGroups() {
    this.groups = this.data.config.getGroup();
  }

  getFieldTooltip(field): string {
    return this.data.config.getTooltipsFields()[field];
  }

  submit(): void {
    this.isLoading = true;
    const { config, id, repository } = this.data;

    const formValues = mapToBackendFormat(this.form.getRawValue());

    this.subscribers.process = this.actionsService.process(config, formValues, id, repository).subscribe(
      (data: any) => {
        if (!data) {
          return;
        }

        if (this.successCb) {
          this.successCb(data.data);
        }

        this.close();
        this.pageService.refresh();
        this.isLoading = false;
        const more = data.data.messages;

        this.notificationsService.add(
          {
            type: 'success',
            title: 'Success In',
            more,
          },
          true
        );
      },
      (error) => {
        /*
         * error.errors =  {
         *   [key: string]: message;
         * }
         * */

        for (const i in error.errors) {
          if (error.errors.hasOwnProperty(i)) {
            if (this.componentsSettings.has(i)) {
              this.componentsSettings.set(i, {
                ...this.componentsSettings.get(i),
                cssClass: 'is-invalid',
                errorMessage: error.errors[i][0],
              });
            }
          }
        }
        this.isLoading = false;

        if (error.message) {
          this.notificationsService.add({
            type: 'error',
            title: 'Error in',
            more: [{ title: 'Error', message: error.message }],
          });
        } else {
          this.notificationsService.add({
            type: 'error',
            title: 'Error in',
          });
        }
      }
    );
  }

  trackByFn(index) {
    return index;
  }
}

interface ActionFormData {
  config: ActionConfigInterface;
  id: number;
  repository: any;
}
