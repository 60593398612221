<div class="c-menu-list" [ngClass]="cssClass">
  <div class="c-menu-list__title">Profile panel</div>
  <ul class="c-menu-list__content">
    <li class="c-menu-list__item">
      <p500-ui-slide-toggle-field [formControl]="tableModeControl" [isLabelRight]="true" size="medium">
        <ng-template p500UIFieldLabel>
          <small class="c-menu-list__label">Accounting Mode</small>
        </ng-template>
      </p500-ui-slide-toggle-field>
    </li>
    <li class="c-menu-list__item">
      <p500-ui-slide-toggle-field [formControl]="unsecuredModeControl" [isLabelRight]="true" size="medium">
        <ng-template p500UIFieldLabel>
          <small class="c-menu-list__label">Unsecured Mode</small>
        </ng-template>
      </p500-ui-slide-toggle-field>
    </li>
    <li class="c-menu-list__item">
      <a href="{{ controlUrl }}" target="_blank"> <p500-ui-icon name="other-pen h-icon"></p500-ui-icon>Edit Profile</a>
    </li>
    <div class="c-menu-list__item -red" (click)="logout()"><p500-ui-icon name="controls-logout h-icon"></p500-ui-icon>Go to ID</div>
  </ul>
</div>
