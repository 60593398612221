import { IRbacService } from '../../services/rbac/rbac-service.interface';
import { IMenuItem } from '@platform500services/p500-ui-kit';
import { ITableView } from '../facade/service-facade/data.response';

export function filterMenuConfig(menu, rbacService: IRbacService): IMenuItem[] {
  const canMenuItem = (menuItem: IMenuItem) => {
    if (menuItem.showOnlyDemo && !this.configurationService.data().isDemo) {
      return false;
    }
    if (!menuItem.permission) {
      return true;
    }
    if (menuItem.atLeast) {
      const permission: string[] = Array.isArray(menuItem.permission) ? menuItem.permission : [menuItem.permission];
      return rbacService.canAtLeast(permission);
    } else if (menuItem.canAction) {
      return rbacService.canAction(menuItem.permission);
    } else {
      return rbacService.can(menuItem.permission);
    }
  };

  return menu.reduce((list: IMenuItem[], item: IMenuItem) => {
    if (canMenuItem(item)) {
      const children = item.children?.length ? item.children.filter(canMenuItem) : null;
      if (children || item.link) {
        if (children) {
          return children?.length ? [...list, { ...item, children }] : list;
        } else {
          return [...list, item];
        }
      }
    }
    return list;
  }, []);
}

export function addTableViews(menuItems: IMenuItem[], settings: ITableView[]): IMenuItem[] {
  /*TODO: Need refactoring: to filter */
  return menuItems.reduce((list: IMenuItem[], item: IMenuItem) => {
    const newList = [...list];
    const separateItem = item.title === 'Traffic Data' ? item.children.filter((value) => value.title === 'Events') : [];
    const childness = item.children
      ? { ...item, children: [addTableViews(item.children, settings).filter((value) => value.title !== 'Events'), separateItem] }
      : { ...item };
    newList.push(childness);
    if (item.key) {
      const pageSettings = settings.filter((page) => page.table === item.key);
      const newItems = pageSettings.map((page) => ({
        ...item,
        title: page.title,
        link: `${item.link}-views/${page.alias}`,
      }));
      newList.push(...newItems);
    }
    return newList;
  }, []);
}
