<div class="c-today-data">
  <div class="c-today-data__head">
    <gtd-ui-current-time></gtd-ui-current-time>
  </div>
  <div class="c-today-data__body">
    <div class="c-today-data__items" *ngIf="data && data.result; else emptyTemplate">
      <div class="c-today-data__item" *ngFor="let item of data.result | keyvalue">
        <div class="c-today-data__value">{{ item.value | number }}</div>
        <div class="c-today-data__key">{{ item.key }}</div>
      </div>
    </div>
    <ng-template #emptyTemplate>
      <div class="c-today-data__empty mb-8">
        <p500-ui-icon name="other-warning" class="icon"></p500-ui-icon>
        Data wasn't found
      </div>
      <a class="c-today-data__link c-link c-link--primary -blue" routerLink="/app/settings/crm-terms">Set up CRM Terms</a>
    </ng-template>
  </div>
</div>
