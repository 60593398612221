import { ICountries, ICurrencies, ILanguages, ITimezones } from './data-service.interface';

export class DataServiceConstants {
  public static types = {
    text: 'Text',
    email: 'Email',
    phone: 'Phone',
    date: 'Date',
    password: 'Password',
    accepted: 'Accepted',
    selector: 'Selector',
    'select-countries': 'Select Countries',
  };

  public static intervals = {
    minute: 'Minute',
    hour: 'Hour',
    day: 'Day',
    week: 'Week',
    month: 'Month',
  };

  public static autologinTypes = {
    regular: 'Regular',
    'iframe-user-password': 'IFrame User Password',
  };

  public static timezones: ITimezones = {
    etc_GMT_P_12: { value: -12, name: '[UTC-12] Baker Island Time' },
    etc_GMT_P_11: { value: -11, name: '[UTC-11] Niue Time' },
    etc_GMT_P_10: { value: -10, name: '[UTC-10] Cook Island Time' },
    pacific_Marquesas: { value: -9.5, name: '[UTC-9:30] Marquesas Islands Time' },
    etc_GMT_P_9: { value: -9, name: '[UTC-9] Alaska Standard Time' },
    etc_GMT_P_8: { value: -8, name: '[UTC-8] Pacific Standard Time' },
    etc_GMT_P_7: { value: -7, name: '[UTC-7] Mountain Standard Time' },
    etc_GMT_P_6: { value: -6, name: '[UTC-6] Central Standard Time' },
    etc_GMT_P_5: { value: -5, name: '[UTC-5] Eastern Standard Time' },
    etc_GMT_P_4: { value: -4, name: '[UTC-4] Atlantic Standard Time' },
    canada_Newfoundland: { value: '-3:30', name: '[UTC-3:30] Newfoundland Standard Time' },
    etc_GMT_P_3: { value: -3, name: '[UTC-3] Amazon Standard Time' },
    etc_GMT_P_2: { value: -2, name: '[UTC-2] Central Greenland Time' },
    etc_GMT_P_1: { value: -1, name: '[UTC-1] Cape Verde Time' },
    etc_GMT: { value: 0, name: '[UTC] Greenwich Mean Time' },
    etc_GMT_M_1: { value: 1, name: '[UTC+1] Central European Time' },
    etc_GMT_M_2: { value: 2, name: '[UTC+2] Eastern European Time' },
    etc_GMT_M_3: { value: 3, name: '[UTC+3] Moscow Standard Time' },
    asia_Tehran: { value: 3.5, name: '[UTC+3:30] Iran Standard Time' },
    etc_GMT_M_4: { value: 4, name: '[UTC+4] Gulf Standard Time' },
    asia_Kabul: { value: 4.5, name: '[UTC+4:30] Afghanistan Time' },
    etc_GMT_M_5: { value: 5, name: '[UTC+5] Pakistan Standard Time' },
    asia_Calcutta: { value: 5.5, name: '[UTC+5:30] Indian Standard Time' },
    asia_Katmandu: { value: 5.75, name: '[UTC+5:45] Nepal Time' },
    etc_GMT_M_6: { value: 6, name: '[UTC+6] Bangladesh Time' },
    asia_Rangoon: { value: 6.5, name: '[UTC+6:30] Cocos Islands Time' },
    etc_GMT_M_7: { value: 7, name: '[UTC+7] Indochina Time' },
    etc_GMT_M_8: { value: 8, name: '[UTC+8] Chinese Standard Time' },
    etc_GMT_M_9: { value: 9, name: '[UTC+9] Japan Standard Time' },
    australia_Adelaide: { value: 9.5, name: '[UTC+9:30] Australian Central Standard Time' },
    etc_GMT_M_10: { value: 10, name: '[UTC+10] Australian Eastern Standard Time' },
    australia_Lord_Howe: { value: 10.5, name: '[UTC+10:30] Lord Howe Standard Time' },
    etc_GMT_M_11: { value: 11, name: '[UTC+11] Solomon Island Time' },
    etc_GMT_M_12: { value: 12, name: '[UTC+12] New Zealand Time' },
    etc_GMT_M_13: { value: 13, name: '[UTC+13] Tonga Time' },
    etc_GMT_M_14: { value: 14, name: '[UTC+14] Line Island Time' },
  };

  public static languages: ILanguages = {
    ab: { code: 'ab', name: 'Abkhaz', img: '/assets/new-img/flags/GE.png' },
    aa: { code: 'aa', name: 'Afar', img: '/assets/new-img/flags/ET.png' },
    af: { code: 'af', name: 'Afrikaans', img: '/assets/new-img/flags/AF.png' },
    ak: { code: 'ak', name: 'Akan', img: '/assets/new-img/flags/GH.png' },
    sq: { code: 'sq', name: 'Albanian', img: '/assets/new-img/flags/AL.png' },
    am: { code: 'am', name: 'Amharic', img: '/assets/new-img/flags/AM.png' },
    ar: { code: 'ar', name: 'Arabic', img: '/assets/new-img/flags/SA.png' },
    an: { code: 'an', name: 'Aragonese', img: '/assets/new-img/flags/AN.png' },
    hy: { code: 'hy', name: 'Armenian', img: '/assets/new-img/flags/AM.png' },
    as: { code: 'as', name: 'Assamese', img: '/assets/new-img/flags/AS.png' },
    av: { code: 'av', name: 'Avaric', img: '/assets/new-img/flags/RU.png' },
    ae: { code: 'ae', name: 'Avestan', img: '/assets/new-img/flags/AE.png' },
    ay: { code: 'ay', name: 'Aymara', img: '/assets/new-img/flags/BO.png' },
    az: { code: 'az', name: 'South Azerbaijani', img: '/assets/new-img/flags/AZ.png' },
    bm: { code: 'bm', name: 'Bambara', img: '/assets/new-img/flags/BM.png' },
    ba: { code: 'ba', name: 'Bashkir', img: '/assets/new-img/flags/RU.png' },
    eu: { code: 'eu', name: 'Basque', img: '/assets/new-img/flags/ES.png' },
    be: { code: 'be', name: 'Belarusian', img: '/assets/new-img/flags/BY.png' },
    bn: { code: 'bn', name: 'Bengali', img: '/assets/new-img/flags/IN.png' },
    bh: { code: 'bh', name: 'Bihari', img: '/assets/new-img/flags/BH.png' },
    bi: { code: 'bi', name: 'Bislama', img: '/assets/new-img/flags/VU.png' },
    bs: { code: 'bs', name: 'Bosnian', img: '/assets/new-img/flags/BA.png' },
    br: { code: 'br', name: 'Breton', img: '/assets/new-img/flags/FR.png' },
    bg: { code: 'bg', name: 'Bulgarian', img: '/assets/new-img/flags/BG.png' },
    my: { code: 'my', name: 'Burmese', img: '/assets/new-img/flags/TH.png' },
    ca: { code: 'ca', name: 'Catalan', img: '/assets/new-img/flags/ES.png' },
    ch: { code: 'ch', name: 'Chamorro', img: '/assets/new-img/flags/US.png' },
    ce: { code: 'ce', name: 'Chechen', img: '/assets/new-img/flags/RU.png' },
    ny: { code: 'ny', name: 'Chichewa', img: '/assets/new-img/flags/ZM.png' },
    zh: { code: 'zh', name: 'Chinese', img: '/assets/new-img/flags/CN.png' },
    cv: { code: 'cv', name: 'Chuvash', img: '/assets/new-img/flags/RU.png' },
    kw: { code: 'kw', name: 'Cornish', img: '/assets/new-img/flags/GB.png' },
    co: { code: 'co', name: 'Corsican', img: '/assets/new-img/flags/FR.png' },
    cr: { code: 'cr', name: 'Cree', img: '/assets/new-img/flags/US.png' },
    hr: { code: 'hr', name: 'Croatian', img: '/assets/new-img/flags/HR.png' },
    cs: { code: 'cs', name: 'Czech', img: '/assets/new-img/flags/CZ.png' },
    da: { code: 'da', name: 'Danish', img: '/assets/new-img/flags/DK.png' },
    dv: { code: 'dv', name: 'Maldivian', img: '/assets/new-img/flags/MV.png' },
    nl: { code: 'nl', name: 'Dutch', img: '/assets/new-img/flags/NL.png' },
    dz: { code: 'dz', name: 'Dzongkha', img: '/assets/new-img/flags/BT.png' },
    en: { code: 'en', name: 'English', img: '/assets/new-img/flags/GB.png' },
    eo: { code: 'eo', name: 'Esperanto', img: '/assets/new-img/flags/GB.png' },
    et: { code: 'et', name: 'Estonian', img: '/assets/new-img/flags/EE.png' },
    ee: { code: 'ee', name: 'Ewe', img: '/assets/new-img/flags/BO.png' },
    fo: { code: 'fo', name: 'Faroese', img: '/assets/new-img/flags/DK.png' },
    fj: { code: 'fj', name: 'Fijian', img: '/assets/new-img/flags/FJ.png' },
    fi: { code: 'fi', name: 'Finnish', img: '/assets/new-img/flags/FI.png' },
    fr: { code: 'fr', name: 'French', img: '/assets/new-img/flags/FR.png' },
    ff: { code: 'ff', name: 'Fula', img: '/assets/new-img/flags/MR.png' },
    gl: { code: 'gl', name: 'Galician', img: '/assets/new-img/flags/ES.png' },
    ka: { code: 'ka', name: 'Georgian', img: '/assets/new-img/flags/GE.png' },
    de: { code: 'de', name: 'German', img: '/assets/new-img/flags/DE.png' },
    el: { code: 'el', name: 'Greek', img: '/assets/new-img/flags/GR.png' },
    gn: { code: 'gn', name: 'Guarana', img: '/assets/new-img/flags/BR.png' },
    gu: { code: 'gu', name: 'Gujarati', img: '/assets/new-img/flags/IN.png' },
    ht: { code: 'ht', name: 'Haitian', img: '/assets/new-img/flags/FR.png' },
    ha: { code: 'ha', name: 'Hausa', img: '/assets/new-img/flags/NE.png' },
    he: { code: 'he', name: 'Hebrew', img: '/assets/new-img/flags/IL.png' },
    hz: { code: 'hz', name: 'Herero', img: '/assets/new-img/flags/NA.png' },
    hi: { code: 'hi', name: 'Hindi', img: '/assets/new-img/flags/IN.png' },
    ho: { code: 'ho', name: 'Hiri Motu', img: '/assets/new-img/flags/PG.png' },
    hu: { code: 'hu', name: 'Hungarian', img: '/assets/new-img/flags/HU.png' },
    ia: { code: 'ia', name: 'Interlingua', img: '/assets/new-img/flags/GB.png' },
    id: { code: 'id', name: 'Indonesian', img: '/assets/new-img/flags/ID.png' },
    ie: { code: 'ie', name: 'Interlingue', img: '/assets/new-img/flags/GB.png' },
    ga: { code: 'ga', name: 'Irish', img: '/assets/new-img/flags/IE.png' },
    ig: { code: 'ig', name: 'Igbo', img: '/assets/new-img/flags/NG.png' },
    ik: { code: 'ik', name: 'Inupiaq', img: '/assets/new-img/flags/US.png' },
    io: { code: 'io', name: 'Ido', img: '/assets/new-img/flags/GB.png' },
    is: { code: 'is', name: 'Icelandic', img: '/assets/new-img/flags/IS.png' },
    it: { code: 'it', name: 'Italian', img: '/assets/new-img/flags/IT.png' },
    iu: { code: 'iu', name: 'Inuktitut', img: '/assets/new-img/flags/US.png' },
    ja: { code: 'ja', name: 'Japanese', img: '/assets/new-img/flags/JP.png' },
    jv: { code: 'jv', name: 'Javanese', img: '/assets/new-img/flags/MC.png' },
    kl: { code: 'kl', name: 'Greenlandic', img: '/assets/new-img/flags/GL.png' },
    kn: { code: 'kn', name: 'Kannada', img: '/assets/new-img/flags/CA.png' },
    kr: { code: 'kr', name: 'Kanuri', img: '/assets/new-img/flags/NG.png' },
    ks: { code: 'ks', name: 'Kashmiri', img: '/assets/new-img/flags/IN.png' },
    kk: { code: 'kk', name: 'Kazakh', img: '/assets/new-img/flags/KZ.png' },
    km: { code: 'km', name: 'Khmer', img: '/assets/new-img/flags/KH.png' },
    ki: { code: 'ki', name: 'Kikuyu', img: '/assets/new-img/flags/KE.png' },
    rw: { code: 'rw', name: 'Kinyarwanda', img: '/assets/new-img/flags/RW.png' },
    ky: { code: 'ky', name: 'Kyrgyz', img: '/assets/new-img/flags/KG.png' },
    kv: { code: 'kv', name: 'Komi', img: '/assets/new-img/flags/RU.png' },
    kg: { code: 'kg', name: 'Kongo', img: '/assets/new-img/flags/CD.png' },
    ko: { code: 'ko', name: 'Korean', img: '/assets/new-img/flags/KR.png' },
    ku: { code: 'ku', name: 'Kurdish', img: '/assets/new-img/flags/TR.png' },
    kj: { code: 'kj', name: 'Kwanyama', img: '/assets/new-img/flags/NA.png' },
    la: { code: 'la', name: 'Latin', img: '/assets/new-img/flags/GB.png' },
    lb: { code: 'lb', name: 'Luxembourgish', img: '/assets/new-img/flags/LU.png' },
    lg: { code: 'lg', name: 'Ganda', img: '/assets/new-img/flags/UG.png' },
    li: { code: 'li', name: 'Limburgish', img: '/assets/new-img/flags/LU.png' },
    ln: { code: 'ln', name: 'Lingala', img: '/assets/new-img/flags/CD.png' },
    lo: { code: 'lo', name: 'Lao', img: '/assets/new-img/flags/TH.png' },
    lt: { code: 'lt', name: 'Lithuanian', img: '/assets/new-img/flags/LT.png' },
    lu: { code: 'lu', name: 'Luba-Katanga', img: '/assets/new-img/flags/CD.png' },
    lv: { code: 'lv', name: 'Latvian', img: '/assets/new-img/flags/LV.png' },
    gv: { code: 'gv', name: 'Manx', img: '/assets/new-img/flags/IM.png' },
    mk: { code: 'mk', name: 'Macedonian', img: '/assets/new-img/flags/MK.png' },
    mg: { code: 'mg', name: 'Malagasy', img: '/assets/new-img/flags/MG.png' },
    ms: { code: 'ms', name: 'Malay', img: '/assets/new-img/flags/MY.png' },
    ml: { code: 'ml', name: 'Malayalam', img: '/assets/new-img/flags/IN.png' },
    mt: { code: 'mt', name: 'Maltese', img: '/assets/new-img/flags/MT.png' },
    mi: { code: 'mi', name: 'Maori', img: '/assets/new-img/flags/NZ.png' },
    mr: { code: 'mr', name: 'Marathi', img: '/assets/new-img/flags/IN.png' },
    mh: { code: 'mh', name: 'Marshallese', img: '/assets/new-img/flags/MH.png' },
    mn: { code: 'mn', name: 'Mongolian', img: '/assets/new-img/flags/MN.png' },
    na: { code: 'na', name: 'Nauru', img: '/assets/new-img/flags/NR.png' },
    nv: { code: 'nv', name: 'Navaho', img: '/assets/new-img/flags/US.png' },
    nb: { code: 'nb', name: 'Norwegian (NB)', img: '/assets/new-img/flags/NO.png' },
    nd: { code: 'nd', name: 'North Ndebele', img: '/assets/new-img/flags/ZW.png' },
    ne: { code: 'ne', name: 'Nepali', img: '/assets/new-img/flags/NP.png' },
    ng: { code: 'ng', name: 'Ndonga', img: '/assets/new-img/flags/NA.png' },
    nn: { code: 'nn', name: 'Norwegian Nynorsk', img: '/assets/new-img/flags/NO.png' },
    no: { code: 'no', name: 'Norwegian (NO)', img: '/assets/new-img/flags/NO.png' },
    ii: { code: 'ii', name: 'Nuosu', img: '/assets/new-img/flags/CN.png' },
    nr: { code: 'nr', name: 'South Ndebele', img: '/assets/new-img/flags/ZW.png' },
    oc: { code: 'oc', name: 'Occitan', img: '/assets/new-img/flags/ES.png' },
    oj: { code: 'oj', name: 'Ojibwe', img: '/assets/new-img/flags/US.png' },
    cu: { code: 'cu', name: 'Church Slavonic', img: '/assets/new-img/flags/RU.png' },
    om: { code: 'om', name: 'Oromo', img: '/assets/new-img/flags/ET.png' },
    or: { code: 'or', name: 'Oriya', img: '/assets/new-img/flags/IN.png' },
    os: { code: 'os', name: 'Ossetian', img: '/assets/new-img/flags/RU.png' },
    pa: { code: 'pa', name: 'Panjabi', img: '/assets/new-img/flags/IN.png' },
    pi: { code: 'pi', name: 'Pali', img: '/assets/new-img/flags/IN.png' },
    fa: { code: 'fa', name: 'Persian', img: '/assets/new-img/flags/IR.png' },
    pl: { code: 'pl', name: 'Polish', img: '/assets/new-img/flags/PL.png' },
    ps: { code: 'ps', name: 'Pashto', img: '/assets/new-img/flags/AF.png' },
    pt: { code: 'pt', name: 'Portuguese', img: '/assets/new-img/flags/PT.png' },
    qu: { code: 'qu', name: 'Quechua', img: '/assets/new-img/flags/AR.png' },
    rm: { code: 'rm', name: 'Romansh', img: '/assets/new-img/flags/SZ.png' },
    rn: { code: 'rn', name: 'Kirundi', img: '/assets/new-img/flags/CD.png' },
    ro: { code: 'ro', name: 'Romanian', img: '/assets/new-img/flags/RO.png' },
    ru: { code: 'ru', name: 'Russian', img: '/assets/new-img/flags/RU.png' },
    sa: { code: 'sa', name: 'Sanskrit', img: '/assets/new-img/flags/GB.png' },
    sc: { code: 'sc', name: 'Sardinian', img: '/assets/new-img/flags/IT.png' },
    sd: { code: 'sd', name: 'Sindhi', img: '/assets/new-img/flags/IN.png' },
    se: { code: 'se', name: 'Northern Sami', img: '/assets/new-img/flags/NO.png' },
    sm: { code: 'sm', name: 'Samoan', img: '/assets/new-img/flags/WS.png' },
    sg: { code: 'sg', name: 'Sango', img: '/assets/new-img/flags/CF.png' },
    sr: { code: 'sr', name: 'Serbian', img: '/assets/new-img/flags/RS.png' },
    gd: { code: 'gd', name: 'Gaelic', img: '/assets/new-img/flags/IE.png' },
    sn: { code: 'sn', name: 'Shona', img: '/assets/new-img/flags/ZW.png' },
    si: { code: 'si', name: 'Sinhala', img: '/assets/new-img/flags/LK.png' },
    sk: { code: 'sk', name: 'Slovak', img: '/assets/new-img/flags/SK.png' },
    sl: { code: 'sl', name: 'Slovene', img: '/assets/new-img/flags/SI.png' },
    so: { code: 'so', name: 'Somali', img: '/assets/new-img/flags/SO.png' },
    st: { code: 'st', name: 'Southern Sotho', img: '/assets/new-img/flags/ZA.png' },
    es: { code: 'es', name: 'Spanish', img: '/assets/new-img/flags/ES.png' },
    su: { code: 'su', name: 'Sundanese', img: '/assets/new-img/flags/ID.png' },
    sw: { code: 'sw', name: 'Swahili', img: '/assets/new-img/flags/UG.png' },
    ss: { code: 'ss', name: 'Swati', img: '/assets/new-img/flags/ZA.png' },
    sv: { code: 'sv', name: 'Swedish', img: '/assets/new-img/flags/SE.png' },
    ta: { code: 'ta', name: 'Tamil', img: '/assets/new-img/flags/IN.png' },
    te: { code: 'te', name: 'Telugu', img: '/assets/new-img/flags/IN.png' },
    tg: { code: 'tg', name: 'Tajik', img: '/assets/new-img/flags/TJ.png' },
    th: { code: 'th', name: 'Thai', img: '/assets/new-img/flags/TH.png' },
    ti: { code: 'ti', name: 'Tigrinya', img: '/assets/new-img/flags/ET.png' },
    bo: { code: 'bo', name: 'Tibetan', img: '/assets/new-img/flags/IN.png' },
    tk: { code: 'tk', name: 'Turkmen', img: '/assets/new-img/flags/TM.png' },
    tl: { code: 'tl', name: 'Tagalog', img: '/assets/new-img/flags/PH.png' },
    tn: { code: 'tn', name: 'Tswana', img: '/assets/new-img/flags/ZA.png' },
    to: { code: 'to', name: 'Tonga', img: '/assets/new-img/flags/TO.png' },
    tr: { code: 'tr', name: 'Turkish', img: '/assets/new-img/flags/TR.png' },
    ts: { code: 'ts', name: 'Tsonga', img: '/assets/new-img/flags/ZA.png' },
    tt: { code: 'tt', name: 'Tatar', img: '/assets/new-img/flags/RU.png' },
    tw: { code: 'tw', name: 'Twi', img: '/assets/new-img/flags/CD.png' },
    ty: { code: 'ty', name: 'Tahitian', img: '/assets/new-img/flags/PF.png' },
    ug: { code: 'ug', name: 'Uyghur', img: '/assets/new-img/flags/CN.png' },
    uk: { code: 'uk', name: 'Ukrainian', img: '/assets/new-img/flags/UA.png' },
    ur: { code: 'ur', name: 'Urdu', img: '/assets/new-img/flags/IN.png' },
    uz: { code: 'uz', name: 'Uzbek', img: '/assets/new-img/flags/UZ.png' },
    ve: { code: 've', name: 'Venda', img: '/assets/new-img/flags/ZA.png' },
    vi: { code: 'vi', name: 'Vietnamese', img: '/assets/new-img/flags/VN.png' },
    vo: { code: 'vo', name: 'Volapak', img: '/assets/new-img/flags/GB.png' },
    wa: { code: 'wa', name: 'Walloon', img: '/assets/new-img/flags/FR.png' },
    cy: { code: 'cy', name: 'Welsh', img: '/assets/new-img/flags/GB.png' },
    wo: { code: 'wo', name: 'Wolof', img: '/assets/new-img/flags/SN.png' },
    fy: { code: 'fy', name: 'Western Frisian', img: '/assets/new-img/flags/AN.png' },
    xh: { code: 'xh', name: 'Xhosa', img: '/assets/new-img/flags/ZA.png' },
    yi: { code: 'yi', name: 'Yiddish', img: '/assets/new-img/flags/IL.png' },
    yo: { code: 'yo', name: 'Yoruba', img: '/assets/new-img/flags/NG.png' },
    za: { code: 'za', name: 'Zhuang', img: '/assets/new-img/flags/CN.png' },
    zu: { code: 'zu', name: 'Zulu', img: '/assets/new-img/flags/ZA.png' },
  };

  public static currencies: ICurrencies = {
    USD: { code: 'USD', name: 'US Dollar', img: '/assets/new-img/currencies/USD.svg' },
    ANG: { code: 'ANG', name: 'Netherlands Antillean guilder', img: '/assets/new-img/currencies/ANG.svg' },
    CAD: { code: 'CAD', name: 'Canadian Dollar', img: '/assets/new-img/currencies/CAD.svg' },
    EUR: { code: 'EUR', name: 'Euro', img: '/assets/new-img/currencies/EUR.svg' },
    AED: { code: 'AED', name: 'United Arab Emirates Dirham', img: '/assets/new-img/currencies/AED.svg' },
    AFN: { code: 'AFN', name: 'Afghan Afghani', img: '/assets/new-img/currencies/AFN.svg' },
    ALL: { code: 'ALL', name: 'Albanian Lek', img: '/assets/new-img/currencies/ALL.svg' },
    AMD: { code: 'AMD', name: 'Armenian Dram', img: '/assets/new-img/currencies/AMD.svg' },
    ARS: { code: 'ARS', name: 'Argentine Peso', img: '/assets/new-img/currencies/ARS.svg' },
    AUD: { code: 'AUD', name: 'Australian Dollar', img: '/assets/new-img/currencies/AUD.svg' },
    AZN: { code: 'AZN', name: 'Azerbaijani Manat', img: '/assets/new-img/currencies/AZN.svg' },
    BAM: { code: 'BAM', name: 'Bosnia-Herzegovina Convertible Mark', img: '/assets/new-img/currencies/BAM.svg' },
    BDT: { code: 'BDT', name: 'Bangladeshi Taka', img: '/assets/new-img/currencies/BDT.svg' },
    BGN: { code: 'BGN', name: 'Bulgarian Lev', img: '/assets/new-img/currencies/BGN.svg' },
    BHD: { code: 'BHD', name: 'Bahraini Dinar', img: '/assets/new-img/currencies/BHD.svg' },
    BIF: { code: 'BIF', name: 'Burundian Franc', img: '/assets/new-img/currencies/BIF.svg' },
    BND: { code: 'BND', name: 'Brunei Dollar', img: '/assets/new-img/currencies/BND.svg' },
    BOB: { code: 'BOB', name: 'Bolivian Boliviano', img: '/assets/new-img/currencies/BOB.svg' },
    BRL: { code: 'BRL', name: 'Brazilian Real', img: '/assets/new-img/currencies/BRL.svg' },
    BWP: { code: 'BWP', name: 'Botswanan Pula', img: '/assets/new-img/currencies/BWP.svg' },
    BYR: { code: 'BYR', name: 'Belarusian Ruble', img: '/assets/new-img/currencies/BYR.svg' },
    BZD: { code: 'BZD', name: 'Belize Dollar', img: '/assets/new-img/currencies/BZD.svg' },
    CDF: { code: 'CDF', name: 'Congolese Franc', img: '/assets/new-img/currencies/CDF.svg' },
    CHF: { code: 'CHF', name: 'Swiss Franc', img: '/assets/new-img/currencies/CHF.svg' },
    CLP: { code: 'CLP', name: 'Chilean Peso', img: '/assets/new-img/currencies/CLP.svg' },
    CNY: { code: 'CNY', name: 'Chinese Yuan', img: '/assets/new-img/currencies/CNY.svg' },
    COP: { code: 'COP', name: 'Colombian Peso', img: '/assets/new-img/currencies/COP.svg' },
    CRC: { code: 'CRC', name: 'Costa Rican Colón', img: '/assets/new-img/currencies/CRC.svg' },
    CVE: { code: 'CVE', name: 'Cape Verdean Escudo', img: '/assets/new-img/currencies/CVE.svg' },
    CZK: { code: 'CZK', name: 'Czech Republic Koruna', img: '/assets/new-img/currencies/CZK.svg' },
    DJF: { code: 'DJF', name: 'Djiboutian Franc', img: '/assets/new-img/currencies/DJF.svg' },
    DKK: { code: 'DKK', name: 'Danish Krone', img: '/assets/new-img/currencies/DKK.svg' },
    DOP: { code: 'DOP', name: 'Dominican Peso', img: '/assets/new-img/currencies/DOP.svg' },
    DZD: { code: 'DZD', name: 'Algerian Dinar', img: '/assets/new-img/currencies/DZD.svg' },
    EEK: { code: 'EEK', name: 'Estonian Kroon', img: '/assets/new-img/currencies/EEK.svg' },
    EGP: { code: 'EGP', name: 'Egyptian Pound', img: '/assets/new-img/currencies/EGP.svg' },
    ERN: { code: 'ERN', name: 'Eritrean Nakfa', img: '/assets/new-img/currencies/ERN.svg' },
    ETB: { code: 'ETB', name: 'Ethiopian Birr', img: '/assets/new-img/currencies/ETB.svg' },
    GBP: { code: 'GBP', name: 'British Pound Sterling', img: '/assets/new-img/currencies/GBP.svg' },
    GEL: { code: 'GEL', name: 'Georgian Lari', img: '/assets/new-img/currencies/GEL.svg' },
    GHS: { code: 'GHS', name: 'Ghanaian Cedi', img: '/assets/new-img/currencies/GHS.svg' },
    GNF: { code: 'GNF', name: 'Guinean Franc', img: '/assets/new-img/currencies/GNF.svg' },
    GTQ: { code: 'GTQ', name: 'Guatemalan Quetzal', img: '/assets/new-img/currencies/GTQ.svg' },
    HKD: { code: 'HKD', name: 'Hong Kong Dollar', img: '/assets/new-img/currencies/HKD.svg' },
    HNL: { code: 'HNL', name: 'Honduran Lempira', img: '/assets/new-img/currencies/HNL.svg' },
    HRK: { code: 'HRK', name: 'Croatian Kuna', img: '/assets/new-img/currencies/HRK.svg' },
    HUF: { code: 'HUF', name: 'Hungarian Forint', img: '/assets/new-img/currencies/HUF.svg' },
    IDR: { code: 'IDR', name: 'Indonesian Rupiah', img: '/assets/new-img/currencies/IDR.svg' },
    ILS: { code: 'ILS', name: 'Israeli New Sheqel', img: '/assets/new-img/currencies/ILS.svg' },
    INR: { code: 'INR', name: 'Indian Rupee', img: '/assets/new-img/currencies/INR.svg' },
    IQD: { code: 'IQD', name: 'Iraqi Dinar', img: '/assets/new-img/currencies/IQD.svg' },
    IRR: { code: 'IRR', name: 'Iranian Rial', img: '/assets/new-img/currencies/IRR.svg' },
    ISK: { code: 'ISK', name: 'Icelandic Króna', img: '/assets/new-img/currencies/ISK.svg' },
    JMD: { code: 'JMD', name: 'Jamaican Dollar', img: '/assets/new-img/currencies/JMD.svg' },
    JOD: { code: 'JOD', name: 'Jordanian Dinar', img: '/assets/new-img/currencies/JOD.svg' },
    JPY: { code: 'JPY', name: 'Japanese Yen', img: '/assets/new-img/currencies/JPY.svg' },
    KES: { code: 'KES', name: 'Kenyan Shilling', img: '/assets/new-img/currencies/KES.svg' },
    KHR: { code: 'KHR', name: 'Cambodian Riel', img: '/assets/new-img/currencies/KHR.svg' },
    KMF: { code: 'KMF', name: 'Comorian Franc', img: '/assets/new-img/currencies/KMF.svg' },
    KRW: { code: 'KRW', name: 'South Korean Won', img: '/assets/new-img/currencies/KRW.svg' },
    KWD: { code: 'KWD', name: 'Kuwaiti Dinar', img: '/assets/new-img/currencies/KWD.svg' },
    KZT: { code: 'KZT', name: 'Kazakhstani Tenge', img: '/assets/new-img/currencies/KZT.svg' },
    LBP: { code: 'LBP', name: 'Lebanese Pound', img: '/assets/new-img/currencies/LBP.svg' },
    LKR: { code: 'LKR', name: 'Sri Lankan Rupee', img: '/assets/new-img/currencies/LKR.svg' },
    LTL: { code: 'LTL', name: 'Lithuanian Litas', img: '/assets/new-img/currencies/LTL.svg' },
    LVL: { code: 'LVL', name: 'Latvian Lats', img: '/assets/new-img/currencies/LVL.svg' },
    LYD: { code: 'LYD', name: 'Libyan Dinar', img: '/assets/new-img/currencies/LYD.svg' },
    MAD: { code: 'MAD', name: 'Moroccan Dirham', img: '/assets/new-img/currencies/MAD.svg' },
    MDL: { code: 'MDL', name: 'Moldovan Leu', img: '/assets/new-img/currencies/MDL.svg' },
    MGA: { code: 'MGA', name: 'Malagasy Ariary', img: '/assets/new-img/currencies/MGA.svg' },
    MKD: { code: 'MKD', name: 'Macedonian Denar', img: '/assets/new-img/currencies/MKD.svg' },
    MMK: { code: 'MMK', name: 'Myanma Kyat', img: '/assets/new-img/currencies/MMK.svg' },
    MOP: { code: 'MOP', name: 'Macanese Pataca', img: '/assets/new-img/currencies/MOP.svg' },
    MUR: { code: 'MUR', name: 'Mauritian Rupee', img: '/assets/new-img/currencies/MUR.svg' },
    MXN: { code: 'MXN', name: 'Mexican Peso', img: '/assets/new-img/currencies/MXN.svg' },
    MYR: { code: 'MYR', name: 'Malaysian Ringgit', img: '/assets/new-img/currencies/MYR.svg' },
    MZN: { code: 'MZN', name: 'Mozambican Metical', img: '/assets/new-img/currencies/MZN.svg' },
    NAD: { code: 'NAD', name: 'Namibian Dollar', img: '/assets/new-img/currencies/NAD.svg' },
    NGN: { code: 'NGN', name: 'Nigerian Naira', img: '/assets/new-img/currencies/NGN.svg' },
    NIO: { code: 'NIO', name: 'Nicaraguan Córdoba', img: '/assets/new-img/currencies/NIO.svg' },
    NOK: { code: 'NOK', name: 'Norwegian Krone', img: '/assets/new-img/currencies/NOK.svg' },
    NPR: { code: 'NPR', name: 'Nepalese Rupee', img: '/assets/new-img/currencies/NPR.svg' },
    NZD: { code: 'NZD', name: 'New Zealand Dollar', img: '/assets/new-img/currencies/NZD.svg' },
    OMR: { code: 'OMR', name: 'Omani Rial', img: '/assets/new-img/currencies/OMR.svg' },
    PAB: { code: 'PAB', name: 'Panamanian Balboa', img: '/assets/new-img/currencies/PAB.svg' },
    PEN: { code: 'PEN', name: 'Peruvian Nuevo Sol', img: '/assets/new-img/currencies/PEN.svg' },
    PHP: { code: 'PHP', name: 'Philippine Peso', img: '/assets/new-img/currencies/PHP.svg' },
    PKR: { code: 'PKR', name: 'Pakistani Rupee', img: '/assets/new-img/currencies/PKR.svg' },
    PLN: { code: 'PLN', name: 'Polish Zloty', img: '/assets/new-img/currencies/PLN.svg' },
    PYG: { code: 'PYG', name: 'Paraguayan Guarani', img: '/assets/new-img/currencies/PYG.svg' },
    QAR: { code: 'QAR', name: 'Qatari Rial', img: '/assets/new-img/currencies/QAR.svg' },
    RON: { code: 'RON', name: 'Romanian Leu', img: '/assets/new-img/currencies/RON.svg' },
    RSD: { code: 'RSD', name: 'Serbian Dinar', img: '/assets/new-img/currencies/RSD.svg' },
    RUB: { code: 'RUB', name: 'Russian Ruble', img: '/assets/new-img/currencies/RUB.svg' },
    RWF: { code: 'RWF', name: 'Rwandan Franc', img: '/assets/new-img/currencies/RWF.svg' },
    SAR: { code: 'SAR', name: 'Saudi Riyal', img: '/assets/new-img/currencies/SAR.svg' },
    SDG: { code: 'SDG', name: 'Sudanese Pound', img: '/assets/new-img/currencies/SDG.svg' },
    SEK: { code: 'SEK', name: 'Swedish Krona', img: '/assets/new-img/currencies/SEK.svg' },
    SGD: { code: 'SGD', name: 'Singapore Dollar', img: '/assets/new-img/currencies/SGD.svg' },
    SOS: { code: 'SOS', name: 'Somali Shilling', img: '/assets/new-img/currencies/SOS.svg' },
    SYP: { code: 'SYP', name: 'Syrian Pound', img: '/assets/new-img/currencies/SYP.svg' },
    THB: { code: 'THB', name: 'Thai Baht', img: '/assets/new-img/currencies/THB.svg' },
    TND: { code: 'TND', name: 'Tunisian Dinar', img: '/assets/new-img/currencies/TND.svg' },
    TOP: { code: 'TOP', name: 'Tongan Paʻanga', img: '/assets/new-img/currencies/TOP.svg' },
    TRY: { code: 'TRY', name: 'Turkish Lira', img: '/assets/new-img/currencies/TRY.svg' },
    TTD: { code: 'TTD', name: 'Trinidad and Tobago Dollar', img: '/assets/new-img/currencies/TTD.svg' },
    TWD: { code: 'TWD', name: 'New Taiwan Dollar', img: '/assets/new-img/currencies/TWD.svg' },
    TZS: { code: 'TZS', name: 'Tanzanian Shilling', img: '/assets/new-img/currencies/TZS.svg' },
    UAH: { code: 'UAH', name: 'Ukrainian Hryvnia', img: '/assets/new-img/currencies/UAH.svg' },
    UGX: { code: 'UGX', name: 'Ugandan Shilling', img: '/assets/new-img/currencies/UGX.svg' },
    UYU: { code: 'UYU', name: 'Uruguayan Peso', img: '/assets/new-img/currencies/UYU.svg' },
    UZS: { code: 'UZS', name: 'Uzbekistan Som', img: '/assets/new-img/currencies/UZS.svg' },
    VEF: { code: 'VEF', name: 'Venezuelan Bolívar', img: '/assets/new-img/currencies/VEF.svg' },
    VND: { code: 'VND', name: 'Vietnamese Dong', img: '/assets/new-img/currencies/VND.svg' },
    XAF: { code: 'XAF', name: 'CFA Franc BEAC', img: '/assets/new-img/currencies/XAF.svg' },
    XOF: { code: 'XOF', name: 'CFA Franc BCEAO', img: '/assets/new-img/currencies/XOF.svg' },
    YER: { code: 'YER', name: 'Yemeni Rial', img: '/assets/new-img/currencies/YER.svg' },
    ZAR: { code: 'ZAR', name: 'South African Rand', img: '/assets/new-img/currencies/ZAR.svg' },
    ZMK: { code: 'ZMK', name: 'Zambian Kwacha', img: '/assets/new-img/currencies/ZMK.svg' },
  };

  public static countries: ICountries = {
    '**': { code: '**', name: 'All Countries', prefix: '', currency_code: 'USD', img: '' },
    AF: { code: 'AF', name: 'Afghanistan', prefix: '++93', currency_code: 'AFN', img: '/assets/new-img/flags/AF.png' },
    AX: { code: 'AX', name: 'Aland Islands', prefix: '++93', currency_code: 'EUR', img: '/assets/new-img/flags/AX.png' },
    AL: { code: 'AL', name: 'Albania', prefix: '++355', currency_code: 'ALL', img: '/assets/new-img/flags/AL.png' },
    DZ: { code: 'DZ', name: 'Algeria', prefix: '++213', currency_code: 'DZD', img: '/assets/new-img/flags/DZ.png' },
    AS: { code: 'AS', name: 'American Samoa', prefix: '++1', currency_code: 'EUR', img: '/assets/new-img/flags/AS.png' },
    AD: { code: 'AD', name: 'Andorra', prefix: '++376', currency_code: 'EUR', img: '/assets/new-img/flags/AD.png' },
    AO: { code: 'AO', name: 'Angola', prefix: '++244', currency_code: 'AOA', img: '/assets/new-img/flags/AO.png' },
    AI: { code: 'AI', name: 'Anguilla', prefix: '++1', currency_code: 'XCD', img: '/assets/new-img/flags/AI.png' },
    AQ: { code: 'AQ', name: 'Antarctica', prefix: '++93', currency_code: 'AQD', img: '/assets/new-img/flags/AQ.png' },
    AG: { code: 'AG', name: 'Antigua and Barbuda', prefix: '++1', currency_code: 'XCD', img: '/assets/new-img/flags/AG.png' },
    AR: { code: 'AR', name: 'Argentina', prefix: '++54', currency_code: 'ARS', img: '/assets/new-img/flags/AR.png' },
    AM: { code: 'AM', name: 'Armenia', prefix: '++374', currency_code: 'AMD', img: '/assets/new-img/flags/AM.png' },
    AW: { code: 'AW', name: 'Aruba', prefix: '++297', currency_code: 'ANG', img: '/assets/new-img/flags/AW.png' },
    AU: { code: 'AU', name: 'Australia', prefix: '++61', currency_code: 'AUD', img: '/assets/new-img/flags/AU.png' },
    AT: { code: 'AT', name: 'Austria', prefix: '++43', currency_code: 'EUR', img: '/assets/new-img/flags/AT.png' },
    AZ: { code: 'AZ', name: 'Azerbaijan', prefix: '++994', currency_code: 'AZN', img: '/assets/new-img/flags/AZ.png' },
    BS: { code: 'BS', name: 'Bahamas', prefix: '++1', currency_code: 'BSD', img: '/assets/new-img/flags/BS.png' },
    BH: { code: 'BH', name: 'Bahrain', prefix: '++973', currency_code: 'BHD', img: '/assets/new-img/flags/BH.png' },
    BD: { code: 'BD', name: 'Bangladesh', prefix: '++880', currency_code: 'BDT', img: '/assets/new-img/flags/BD.png' },
    BB: { code: 'BB', name: 'Barbados', prefix: '++1', currency_code: 'BBD', img: '/assets/new-img/flags/BB.png' },
    BY: { code: 'BY', name: 'Belarus', prefix: '++375', currency_code: 'BYR', img: '/assets/new-img/flags/BY.png' },
    BE: { code: 'BE', name: 'Belgium', prefix: '++32', currency_code: 'EUR', img: '/assets/new-img/flags/BE.png' },
    BZ: { code: 'BZ', name: 'Belize', prefix: '++501', currency_code: 'BZD', img: '/assets/new-img/flags/BZ.png' },
    BJ: { code: 'BJ', name: 'Benin', prefix: '++229', currency_code: 'XOF', img: '/assets/new-img/flags/BJ.png' },
    BM: { code: 'BM', name: 'Bermuda', prefix: '++1', currency_code: 'BMD', img: '/assets/new-img/flags/BM.png' },
    BT: { code: 'BT', name: 'Bhutan', prefix: '++975', currency_code: 'INR', img: '/assets/new-img/flags/BT.png' },
    BO: { code: 'BO', name: 'Bolivia', prefix: '++591', currency_code: 'BOB', img: '/assets/new-img/flags/BO.png' },
    BA: { code: 'BA', name: 'Bosnia and Herzegovina', prefix: '++387', currency_code: 'BAM', img: '/assets/new-img/flags/BA.png' },
    BW: { code: 'BW', name: 'Botswana', prefix: '++267', currency_code: 'BWP', img: '/assets/new-img/flags/BW.png' },
    BV: { code: 'BV', name: 'Bouvet Island', prefix: '++93', currency_code: 'NOK', img: '/assets/new-img/flags/BV.png' },
    BR: { code: 'BR', name: 'Brazil', prefix: '++55', currency_code: 'BRL', img: '/assets/new-img/flags/BR.png' },
    IO: { code: 'IO', name: 'British Indian Ocean Territory', prefix: '++1', currency_code: 'USD', img: '/assets/new-img/flags/IO.png' },
    BN: { code: 'BN', name: 'Brunei Darussalam', prefix: '++673', currency_code: 'BND', img: '/assets/new-img/flags/BN.png' },
    BG: { code: 'BG', name: 'Bulgaria', prefix: '++359', currency_code: 'BGN', img: '/assets/new-img/flags/BG.png' },
    BF: { code: 'BF', name: 'Burkina Faso', prefix: '++226', currency_code: 'XOF', img: '/assets/new-img/flags/BF.png' },
    BI: { code: 'BI', name: 'Burundi', prefix: '++257', currency_code: 'BIF', img: '/assets/new-img/flags/BI.png' },
    KH: { code: 'KH', name: 'Cambodia', prefix: '++855', currency_code: 'KHR', img: '/assets/new-img/flags/KH.png' },
    CM: { code: 'CM', name: 'Cameroon', prefix: '++237', currency_code: 'XAF', img: '/assets/new-img/flags/CM.png' },
    CA: { code: 'CA', name: 'Canada', prefix: '++1', currency_code: 'CAD', img: '/assets/new-img/flags/CA.png' },
    CV: { code: 'CV', name: 'Cape Verde', prefix: '++238', currency_code: 'CVE', img: '/assets/new-img/flags/CV.png' },
    KY: { code: 'KY', name: 'Cayman Islands', prefix: '++1', currency_code: 'KYD', img: '/assets/new-img/flags/KY.png' },
    CF: { code: 'CF', name: 'Central African Republic', prefix: '++236', currency_code: 'XAF', img: '/assets/new-img/flags/CF.png' },
    TD: { code: 'TD', name: 'Chad', prefix: '++235', currency_code: 'XAF', img: '/assets/new-img/flags/TD.png' },
    CL: { code: 'CL', name: 'Chile', prefix: '++56', currency_code: 'CLP', img: '/assets/new-img/flags/CL.png' },
    CN: { code: 'CN', name: 'China', prefix: '++86', currency_code: 'CNY', img: '/assets/new-img/flags/CN.png' },
    CX: { code: 'CX', name: 'Christmas Island', prefix: '++93', currency_code: 'AUD', img: '/assets/new-img/flags/CX.png' },
    CC: { code: 'CC', name: 'Cocos (Keeling) Islands', prefix: '++93', currency_code: 'AUD', img: '/assets/new-img/flags/CC.png' },
    CO: { code: 'CO', name: 'Colombia', prefix: '++57', currency_code: 'COP', img: '/assets/new-img/flags/CO.png' },
    KM: { code: 'KM', name: 'Comoros', prefix: '++269', currency_code: 'KMF', img: '/assets/new-img/flags/KM.png' },
    CG: { code: 'CG', name: 'Congo', prefix: '++242', currency_code: 'XAF', img: '/assets/new-img/flags/CG.png' },
    CD: {
      code: 'CD',
      name: 'Congo, the Democratic Republic of the',
      prefix: '++243',
      currency_code: 'CDF',
      img: '/assets/new-img/flags/CD.png',
    },
    CK: { code: 'CK', name: 'Cook Islands', prefix: '++682', currency_code: 'NZD', img: '/assets/new-img/flags/CK.png' },
    CR: { code: 'CR', name: 'Costa Rica', prefix: '++506', currency_code: 'CRC', img: '/assets/new-img/flags/CR.png' },
    CI: { code: 'CI', name: 'Cote D Ivoire', prefix: '++93', currency_code: 'XOF', img: '/assets/new-img/flags/CI.png' },
    HR: { code: 'HR', name: 'Croatia', prefix: '++385', currency_code: 'HRK', img: '/assets/new-img/flags/HR.png' },
    CU: { code: 'CU', name: 'Cuba', prefix: '++53', currency_code: 'CUP', img: '/assets/new-img/flags/CU.png' },
    CW: { code: 'CW', name: 'Curacao', prefix: '++599', currency_code: 'ANG', img: '/assets/new-img/flags/CW.png' },
    CY: { code: 'CY', name: 'Cyprus', prefix: '++357', currency_code: 'CYP', img: '/assets/new-img/flags/CY.png' },
    CZ: { code: 'CZ', name: 'Czech Republic', prefix: '++420', currency_code: 'CZK', img: '/assets/new-img/flags/CZ.png' },
    DK: { code: 'DK', name: 'Denmark', prefix: '++45', currency_code: 'DKK', img: '/assets/new-img/flags/DK.png' },
    DJ: { code: 'DJ', name: 'Djibouti', prefix: '++253', currency_code: 'DJF', img: '/assets/new-img/flags/DJ.png' },
    DM: { code: 'DM', name: 'Dominica', prefix: '++1', currency_code: 'XCD', img: '/assets/new-img/flags/DM.png' },
    DO: { code: 'DO', name: 'Dominican Republic', prefix: '++1', currency_code: 'DOP', img: '/assets/new-img/flags/DO.png' },
    EC: { code: 'EC', name: 'Ecuador', prefix: '++593', currency_code: 'ECS', img: '/assets/new-img/flags/EC.png' },
    EG: { code: 'EG', name: 'Egypt', prefix: '++20', currency_code: 'EGP', img: '/assets/new-img/flags/EG.png' },
    SV: { code: 'SV', name: 'El Salvador', prefix: '++503', currency_code: 'SVC', img: '/assets/new-img/flags/SV.png' },
    GQ: { code: 'GQ', name: 'Equatorial Guinea', prefix: '++240', currency_code: 'XAF', img: '/assets/new-img/flags/GQ.png' },
    ER: { code: 'ER', name: 'Eritrea', prefix: '++291', currency_code: 'ETB', img: '/assets/new-img/flags/ER.png' },
    EE: { code: 'EE', name: 'Estonia', prefix: '++372', currency_code: 'EEK', img: '/assets/new-img/flags/EE.png' },
    ET: { code: 'ET', name: 'Ethiopia', prefix: '++251', currency_code: 'ETB', img: '/assets/new-img/flags/ET.png' },
    FK: { code: 'FK', name: 'Falkland Islands (Malvinas)', prefix: '++500', currency_code: 'FKP', img: '/assets/new-img/flags/FK.png' },
    FO: { code: 'FO', name: 'Faroe Islands', prefix: '++298', currency_code: 'DKK', img: '/assets/new-img/flags/FO.png' },
    FJ: { code: 'FJ', name: 'Fiji', prefix: '++679', currency_code: 'FJD', img: '/assets/new-img/flags/FJ.png' },
    FI: { code: 'FI', name: 'Finland', prefix: '++358', currency_code: 'EUR', img: '/assets/new-img/flags/FI.png' },
    FR: { code: 'FR', name: 'France', prefix: '++33', currency_code: 'EUR', img: '/assets/new-img/flags/FR.png' },
    GF: { code: 'GF', name: 'French Guiana', prefix: '++594', currency_code: 'EUR', img: '/assets/new-img/flags/GF.png' },
    PF: { code: 'PF', name: 'French Polynesia', prefix: '++689', currency_code: 'XPF', img: '/assets/new-img/flags/PF.png' },
    TF: { code: 'TF', name: 'French Southern Territories', prefix: '++93', currency_code: 'EUR', img: '/assets/new-img/flags/TF.png' },
    GA: { code: 'GA', name: 'Gabon', prefix: '++241', currency_code: 'XAF', img: '/assets/new-img/flags/GA.png' },
    GM: { code: 'GM', name: 'Gambia', prefix: '++220', currency_code: 'GMD', img: '/assets/new-img/flags/GM.png' },
    GE: { code: 'GE', name: 'Georgia', prefix: '++995', currency_code: 'GEL', img: '/assets/new-img/flags/GE.png' },
    DE: { code: 'DE', name: 'Germany', prefix: '++49', currency_code: 'EUR', img: '/assets/new-img/flags/DE.png' },
    GH: { code: 'GH', name: 'Ghana', prefix: '++233', currency_code: 'GHS', img: '/assets/new-img/flags/GH.png' },
    GI: { code: 'GI', name: 'Gibraltar', prefix: '++350', currency_code: 'GIP', img: '/assets/new-img/flags/GI.png' },
    GR: { code: 'GR', name: 'Greece', prefix: '++30', currency_code: 'EUR', img: '/assets/new-img/flags/GR.png' },
    GL: { code: 'GL', name: 'Greenland', prefix: '++299', currency_code: 'DKK', img: '/assets/new-img/flags/GL.png' },
    GD: { code: 'GD', name: 'Grenada', prefix: '++1', currency_code: 'XCD', img: '/assets/new-img/flags/GD.png' },
    GP: { code: 'GP', name: 'Guadeloupe', prefix: '++590', currency_code: 'EUR', img: '/assets/new-img/flags/GP.png' },
    GU: { code: 'GU', name: 'Guam', prefix: '++1', currency_code: 'USD', img: '/assets/new-img/flags/GU.png' },
    GT: { code: 'GT', name: 'Guatemala', prefix: '++502', currency_code: 'GTQ', img: '/assets/new-img/flags/GT.png' },
    GG: { code: 'GG', name: 'Guernsey', prefix: '++224', currency_code: 'GGP', img: '/assets/new-img/flags/GG.png' },
    GN: { code: 'GN', name: 'Guinea', prefix: '++224', currency_code: 'GNF', img: '/assets/new-img/flags/GN.png' },
    GW: { code: 'GW', name: 'Guinea-Bissau', prefix: '++245', currency_code: 'XOF', img: '/assets/new-img/flags/GW.png' },
    GY: { code: 'GY', name: 'Guyana', prefix: '++592', currency_code: 'GYD', img: '/assets/new-img/flags/GY.png' },
    HT: { code: 'HT', name: 'Haiti', prefix: '++509', currency_code: 'HTG', img: '/assets/new-img/flags/HT.png' },
    HM: {
      code: 'HM',
      name: 'Heard Island and Mcdonald Islands',
      prefix: '++93',
      currency_code: 'AUD',
      img: '/assets/new-img/flags/HM.png',
    },
    VA: { code: 'VA', name: 'Holy See (Vatican City State)', prefix: '++93', currency_code: 'EUR', img: '/assets/new-img/flags/VA.png' },
    HN: { code: 'HN', name: 'Honduras', prefix: '++504', currency_code: 'HNL', img: '/assets/new-img/flags/HN.png' },
    HK: { code: 'HK', name: 'Hong Kong', prefix: '++852', currency_code: 'HKD', img: '/assets/new-img/flags/HK.png' },
    HU: { code: 'HU', name: 'Hungary', prefix: '++36', currency_code: 'HUF', img: '/assets/new-img/flags/HU.png' },
    IS: { code: 'IS', name: 'Iceland', prefix: '++354', currency_code: 'ISK', img: '/assets/new-img/flags/IS.png' },
    IN: { code: 'IN', name: 'India', prefix: '++91', currency_code: 'INR', img: '/assets/new-img/flags/IN.png' },
    ID: { code: 'ID', name: 'Indonesia', prefix: '++62', currency_code: 'IDR', img: '/assets/new-img/flags/ID.png' },
    IR: { code: 'IR', name: 'Iran, Islamic Republic of', prefix: '++98', currency_code: 'IRR', img: '/assets/new-img/flags/IR.png' },
    IQ: { code: 'IQ', name: 'Iraq', prefix: '++964', currency_code: 'IQD', img: '/assets/new-img/flags/IQ.png' },
    IE: { code: 'IE', name: 'Ireland', prefix: '++353', currency_code: 'EUR', img: '/assets/new-img/flags/IE.png' },
    IM: { code: 'IM', name: 'Isle Of Man', prefix: '++93', currency_code: 'GBP', img: '/assets/new-img/flags/IM.png' },
    IL: { code: 'IL', name: 'Israel', prefix: '++972', currency_code: 'ILS', img: '/assets/new-img/flags/IL.png' },
    IT: { code: 'IT', name: 'Italy', prefix: '++39', currency_code: 'EUR', img: '/assets/new-img/flags/IT.png' },
    JM: { code: 'JM', name: 'Jamaica', prefix: '++1', currency_code: 'JMD', img: '/assets/new-img/flags/JM.png' },
    JP: { code: 'JP', name: 'Japan', prefix: '++81', currency_code: 'JPY', img: '/assets/new-img/flags/JP.png' },
    JE: { code: 'JE', name: 'Jersey', prefix: '++93', currency_code: 'GBP', img: '/assets/new-img/flags/JE.png' },
    JO: { code: 'JO', name: 'Jordan', prefix: '++962', currency_code: 'JOD', img: '/assets/new-img/flags/JO.png' },
    KZ: { code: 'KZ', name: 'Kazakhstan', prefix: '++7', currency_code: 'KZT', img: '/assets/new-img/flags/KZ.png' },
    KE: { code: 'KE', name: 'Kenya', prefix: '++254', currency_code: 'KES', img: '/assets/new-img/flags/KE.png' },
    KI: { code: 'KI', name: 'Kiribati', prefix: '++686', currency_code: 'AUD', img: '/assets/new-img/flags/KI.png' },
    KR: { code: 'KR', name: 'Korea, Republic of', prefix: '++93', currency_code: 'KRW', img: '/assets/new-img/flags/KR.png' },
    XK: { code: 'XK', name: 'Kosovo', prefix: '++383', currency_code: 'EUR', img: '/assets/new-img/flags/XK.png' },
    KW: { code: 'KW', name: 'Kuwait', prefix: '++965', currency_code: 'KWD', img: '/assets/new-img/flags/KW.png' },
    KG: { code: 'KG', name: 'Kyrgyzstan', prefix: '++996', currency_code: 'KGS', img: '/assets/new-img/flags/KG.png' },
    LA: { code: 'LA', name: 'Lao Peoples Democratic Republic', prefix: '++856', currency_code: 'LAK', img: '/assets/new-img/flags/LA.png' },
    LV: { code: 'LV', name: 'Latvia', prefix: '++371', currency_code: 'LVL', img: '/assets/new-img/flags/LV.png' },
    LB: { code: 'LB', name: 'Lebanon', prefix: '++961', currency_code: 'LBP', img: '/assets/new-img/flags/LB.png' },
    LS: { code: 'LS', name: 'Lesotho', prefix: '++266', currency_code: 'LSL', img: '/assets/new-img/flags/LS.png' },
    LR: { code: 'LR', name: 'Liberia', prefix: '++231', currency_code: 'LRD', img: '/assets/new-img/flags/LR.png' },
    LY: { code: 'LY', name: 'Libyan Arab Jamahiriya', prefix: '++218', currency_code: 'LYD', img: '/assets/new-img/flags/LY.png' },
    LI: { code: 'LI', name: 'Liechtenstein', prefix: '++423', currency_code: 'CHF', img: '/assets/new-img/flags/LI.png' },
    LT: { code: 'LT', name: 'Lithuania', prefix: '++370', currency_code: 'LTL', img: '/assets/new-img/flags/LT.png' },
    LU: { code: 'LU', name: 'Luxembourg', prefix: '++352', currency_code: 'EUR', img: '/assets/new-img/flags/LU.png' },
    MO: { code: 'MO', name: 'Macao', prefix: '++853', currency_code: 'MOP', img: '/assets/new-img/flags/MO.png' },
    MK: {
      code: 'MK',
      name: 'Macedonia, the Former Yugoslav Republic of',
      prefix: '++389',
      currency_code: 'MKD',
      img: '/assets/new-img/flags/MK.png',
    },
    MG: { code: 'MG', name: 'Madagascar', prefix: '++261', currency_code: 'MGA', img: '/assets/new-img/flags/MG.png' },
    MW: { code: 'MW', name: 'Malawi', prefix: '++265', currency_code: 'MWK', img: '/assets/new-img/flags/MW.png' },
    MY: { code: 'MY', name: 'Malaysia', prefix: '++60', currency_code: 'MYR', img: '/assets/new-img/flags/MY.png' },
    MV: { code: 'MV', name: 'Maldives', prefix: '++960', currency_code: 'MVR', img: '/assets/new-img/flags/MV.png' },
    ML: { code: 'ML', name: 'Mali', prefix: '++223', currency_code: 'XOF', img: '/assets/new-img/flags/ML.png' },
    MT: { code: 'MT', name: 'Malta', prefix: '++356', currency_code: 'MTL', img: '/assets/new-img/flags/MT.png' },
    MH: { code: 'MH', name: 'Marshall Islands', prefix: '++692', currency_code: 'USD', img: '/assets/new-img/flags/MH.png' },
    MQ: { code: 'MQ', name: 'Martinique', prefix: '++596', currency_code: 'EUR', img: '/assets/new-img/flags/MQ.png' },
    MR: { code: 'MR', name: 'Mauritania', prefix: '++222', currency_code: 'MRO', img: '/assets/new-img/flags/MR.png' },
    MU: { code: 'MU', name: 'Mauritius', prefix: '++230', currency_code: 'MUR', img: '/assets/new-img/flags/MU.png' },
    YT: { code: 'YT', name: 'Mayotte', prefix: '++262', currency_code: 'EUR', img: '/assets/new-img/flags/YT.png' },
    MX: { code: 'MX', name: 'Mexico', prefix: '++52', currency_code: 'MXN', img: '/assets/new-img/flags/MX.png' },
    FM: { code: 'FM', name: 'Micronesia, Federated States of', prefix: '++691', currency_code: 'USD', img: '/assets/new-img/flags/FM.png' },
    MD: { code: 'MD', name: 'Moldova, Republic of', prefix: '++373', currency_code: 'MDL', img: '/assets/new-img/flags/MD.png' },
    MC: { code: 'MC', name: 'Monaco', prefix: '++377', currency_code: 'EUR', img: '/assets/new-img/flags/MC.png' },
    MN: { code: 'MN', name: 'Mongolia', prefix: '++976', currency_code: 'MNT', img: '/assets/new-img/flags/MN.png' },
    ME: { code: 'ME', name: 'Montenegro', prefix: '++382', currency_code: 'EUR', img: '/assets/new-img/flags/ME.png' },
    MS: { code: 'MS', name: 'Montserrat', prefix: '++1', currency_code: 'XCD', img: '/assets/new-img/flags/MS.png' },
    MA: { code: 'MA', name: 'Morocco', prefix: '++212', currency_code: 'MAD', img: '/assets/new-img/flags/MA.png' },
    MZ: { code: 'MZ', name: 'Mozambique', prefix: '++258', currency_code: 'MZN', img: '/assets/new-img/flags/MZ.png' },
    MM: { code: 'MM', name: 'Myanmar', prefix: '++95', currency_code: 'MMK', img: '/assets/new-img/flags/MM.png' },
    NA: { code: 'NA', name: 'Namibia', prefix: '++264', currency_code: 'NAD', img: '/assets/new-img/flags/NA.png' },
    NR: { code: 'NR', name: 'Nauru', prefix: '++674', currency_code: 'AUD', img: '/assets/new-img/flags/NR.png' },
    NP: { code: 'NP', name: 'Nepal', prefix: '++977', currency_code: 'NPR', img: '/assets/new-img/flags/NP.png' },
    NL: { code: 'NL', name: 'Netherlands', prefix: '++31', currency_code: 'EUR', img: '/assets/new-img/flags/NL.png' },
    AN: { code: 'AN', name: 'Netherlands Antilles', prefix: '++599', currency_code: 'ANG', img: '/assets/new-img/flags/AN.png' },
    NC: { code: 'NC', name: 'New Caledonia', prefix: '++687', currency_code: 'XPF', img: '/assets/new-img/flags/NC.png' },
    NZ: { code: 'NZ', name: 'New Zealand', prefix: '++64', currency_code: 'NZD', img: '/assets/new-img/flags/NZ.png' },
    NI: { code: 'NI', name: 'Nicaragua', prefix: '++505', currency_code: 'NIO', img: '/assets/new-img/flags/NI.png' },
    NE: { code: 'NE', name: 'Niger', prefix: '++227', currency_code: 'XOF', img: '/assets/new-img/flags/NE.png' },
    NG: { code: 'NG', name: 'Nigeria', prefix: '++234', currency_code: 'NGN', img: '/assets/new-img/flags/NG.png' },
    NU: { code: 'NU', name: 'Niue', prefix: '++683', currency_code: 'NZD', img: '/assets/new-img/flags/NU.png' },
    NF: { code: 'NF', name: 'Norfolk Island', prefix: '++93', currency_code: 'AUD', img: '/assets/new-img/flags/NF.png' },
    MP: { code: 'MP', name: 'Northern Mariana Islands', prefix: '++1', currency_code: 'USD', img: '/assets/new-img/flags/MP.png' },
    NO: { code: 'NO', name: 'Norway', prefix: '++47', currency_code: 'NOK', img: '/assets/new-img/flags/NO.png' },
    OM: { code: 'OM', name: 'Oman', prefix: '++968', currency_code: 'OMR', img: '/assets/new-img/flags/OM.png' },
    PK: { code: 'PK', name: 'Pakistan', prefix: '++92', currency_code: 'PKR', img: '/assets/new-img/flags/PK.png' },
    PW: { code: 'PW', name: 'Palau', prefix: '++680', currency_code: 'USD', img: '/assets/new-img/flags/PW.png' },
    PS: { code: 'PS', name: 'Palestinian Territory', prefix: '++93', currency_code: 'JOD', img: '/assets/new-img/flags/PS.png' },
    PA: { code: 'PA', name: 'Panama', prefix: '++507', currency_code: 'PAB', img: '/assets/new-img/flags/PA.png' },
    PG: { code: 'PG', name: 'Papua New Guinea', prefix: '++675', currency_code: 'PGK', img: '/assets/new-img/flags/PG.png' },
    PY: { code: 'PY', name: 'Paraguay', prefix: '++595', currency_code: 'PYG', img: '/assets/new-img/flags/PY.png' },
    PE: { code: 'PE', name: 'Peru', prefix: '++51', currency_code: 'PEN', img: '/assets/new-img/flags/PE.png' },
    PH: { code: 'PH', name: 'Philippines', prefix: '++63', currency_code: 'PHP', img: '/assets/new-img/flags/PH.png' },
    PN: { code: 'PN', name: 'Pitcairn', prefix: '++93', currency_code: 'NZD', img: '/assets/new-img/flags/PN.png' },
    PL: { code: 'PL', name: 'Poland', prefix: '++48', currency_code: 'PLN', img: '/assets/new-img/flags/PL.png' },
    PT: { code: 'PT', name: 'Portugal', prefix: '++351', currency_code: 'EUR', img: '/assets/new-img/flags/PT.png' },
    PR: { code: 'PR', name: 'Puerto Rico', prefix: '++1', currency_code: 'USD', img: '/assets/new-img/flags/PR.png' },
    QA: { code: 'QA', name: 'Qatar', prefix: '++974', currency_code: 'QAR', img: '/assets/new-img/flags/QA.png' },
    RE: { code: 'RE', name: 'Reunion', prefix: '++262', currency_code: 'EUR', img: '/assets/new-img/flags/RE.png' },
    RO: { code: 'RO', name: 'Romania', prefix: '++40', currency_code: 'RON', img: '/assets/new-img/flags/RO.png' },
    RU: { code: 'RU', name: 'Russia', prefix: '++7', currency_code: 'RUB', img: '/assets/new-img/flags/RU.png' },
    RW: { code: 'RW', name: 'Rwanda', prefix: '++250', currency_code: 'RWF', img: '/assets/new-img/flags/RW.png' },
    BL: { code: 'BL', name: 'Saint Barthelemy', prefix: '++93', currency_code: 'EUR', img: '/assets/new-img/flags/BL.png' },
    SH: { code: 'SH', name: 'Saint Helena', prefix: '++290', currency_code: 'GBP', img: '/assets/new-img/flags/SH.png' },
    KN: { code: 'KN', name: 'Saint Kitts and Nevis', prefix: '++1', currency_code: 'XCD', img: '/assets/new-img/flags/KN.png' },
    LC: { code: 'LC', name: 'Saint Lucia', prefix: '++1', currency_code: 'XCD', img: '/assets/new-img/flags/LC.png' },
    MF: { code: 'MF', name: 'Saint Martin', prefix: '++93', currency_code: 'ANG', img: '/assets/new-img/flags/MF.png' },
    SX: { code: 'SX', name: 'Sint Maarten', prefix: '++1', currency_code: 'ANG', img: '/assets/new-img/flags/NL.png' },
    PM: { code: 'PM', name: 'Saint Pierre and Miquelon', prefix: '++508', currency_code: 'EUR', img: '/assets/new-img/flags/PM.png' },
    VC: { code: 'VC', name: 'Saint Vincent and the Grenadines', prefix: '++1', currency_code: 'XCD', img: '/assets/new-img/flags/VC.png' },
    WS: { code: 'WS', name: 'Samoa', prefix: '++685', currency_code: 'EUR', img: '/assets/new-img/flags/WS.png' },
    SM: { code: 'SM', name: 'San Marino', prefix: '++378', currency_code: 'EUR', img: '/assets/new-img/flags/SM.png' },
    ST: { code: 'ST', name: 'Sao Tome and Principe', prefix: '++239', currency_code: 'STD', img: '/assets/new-img/flags/ST.png' },
    SA: { code: 'SA', name: 'Saudi Arabia', prefix: '++966', currency_code: 'SAR', img: '/assets/new-img/flags/SA.png' },
    SN: { code: 'SN', name: 'Senegal', prefix: '++221', currency_code: 'XOF', img: '/assets/new-img/flags/SN.png' },
    RS: { code: 'RS', name: 'Serbia', prefix: '++381', currency_code: 'RSD', img: '/assets/new-img/flags/RS.png' },
    SC: { code: 'SC', name: 'Seychelles', prefix: '++248', currency_code: 'SCR', img: '/assets/new-img/flags/SC.png' },
    SL: { code: 'SL', name: 'Sierra Leone', prefix: '++232', currency_code: 'SLL', img: '/assets/new-img/flags/SL.png' },
    SG: { code: 'SG', name: 'Singapore', prefix: '++65', currency_code: 'SGD', img: '/assets/new-img/flags/SG.png' },
    SK: { code: 'SK', name: 'Slovakia', prefix: '++421', currency_code: 'SKK', img: '/assets/new-img/flags/SK.png' },
    SI: { code: 'SI', name: 'Slovenia', prefix: '++386', currency_code: 'EUR', img: '/assets/new-img/flags/SI.png' },
    SB: { code: 'SB', name: 'Solomon Islands', prefix: '++677', currency_code: 'SBD', img: '/assets/new-img/flags/SB.png' },
    SO: { code: 'SO', name: 'Somalia', prefix: '++252', currency_code: 'SOS', img: '/assets/new-img/flags/SO.png' },
    ZA: { code: 'ZA', name: 'South Africa', prefix: '++27', currency_code: 'ZAR', img: '/assets/new-img/flags/ZA.png' },
    GS: {
      code: 'GS',
      name: 'South Georgia and the South Sandwich Islands',
      prefix: '++93',
      currency_code: 'GBP',
      img: '/assets/new-img/flags/GS.png',
    },
    ES: { code: 'ES', name: 'Spain', prefix: '++34', currency_code: 'EUR', img: '/assets/new-img/flags/ES.png' },
    LK: { code: 'LK', name: 'Sri Lanka', prefix: '++94', currency_code: 'LKR', img: '/assets/new-img/flags/LK.png' },
    SD: { code: 'SD', name: 'Sudan', prefix: '++249', currency_code: 'SDG', img: '/assets/new-img/flags/SD.png' },
    SR: { code: 'SR', name: 'Suriname', prefix: '++597', currency_code: 'SRD', img: '/assets/new-img/flags/SR.png' },
    SJ: { code: 'SJ', name: 'Svalbard and Jan Mayen', prefix: '++93', currency_code: 'NOK', img: '/assets/new-img/flags/SJ.png' },
    SZ: { code: 'SZ', name: 'Swaziland', prefix: '++268', currency_code: 'SZL', img: '/assets/new-img/flags/SZ.png' },
    SE: { code: 'SE', name: 'Sweden', prefix: '++46', currency_code: 'SEK', img: '/assets/new-img/flags/SE.png' },
    CH: { code: 'CH', name: 'Switzerland', prefix: '++41', currency_code: 'CHF', img: '/assets/new-img/flags/CH.png' },
    SY: { code: 'SY', name: 'Syrian Arab Republic', prefix: '++963', currency_code: 'SYP', img: '/assets/new-img/flags/SY.png' },
    TW: { code: 'TW', name: 'Taiwan, Province of China', prefix: '++886', currency_code: 'TWD', img: '/assets/new-img/flags/TW.png' },
    TJ: { code: 'TJ', name: 'Tajikistan', prefix: '++992', currency_code: 'TJS', img: '/assets/new-img/flags/TJ.png' },
    TZ: { code: 'TZ', name: 'Tanzania, United Republic of', prefix: '++255', currency_code: 'TZS', img: '/assets/new-img/flags/TZ.png' },
    TH: { code: 'TH', name: 'Thailand', prefix: '++66', currency_code: 'THB', img: '/assets/new-img/flags/TH.png' },
    TL: { code: 'TL', name: 'Timor-Leste', prefix: '++93', currency_code: 'USD', img: '/assets/new-img/flags/TL.png' },
    TG: { code: 'TG', name: 'Togo', prefix: '++228', currency_code: 'XOF', img: '/assets/new-img/flags/TG.png' },
    TK: { code: 'TK', name: 'Tokelau', prefix: '++690', currency_code: 'NZD', img: '/assets/new-img/flags/TK.png' },
    TO: { code: 'TO', name: 'Tonga', prefix: '++676', currency_code: 'TOP', img: '/assets/new-img/flags/TO.png' },
    TT: { code: 'TT', name: 'Trinidad and Tobago', prefix: '++1', currency_code: 'TTD', img: '/assets/new-img/flags/TT.png' },
    TN: { code: 'TN', name: 'Tunisia', prefix: '++216', currency_code: 'TND', img: '/assets/new-img/flags/TN.png' },
    TR: { code: 'TR', name: 'Turkey', prefix: '++90', currency_code: 'TRY', img: '/assets/new-img/flags/TR.png' },
    TM: { code: 'TM', name: 'Turkmenistan', prefix: '++993', currency_code: 'TMT', img: '/assets/new-img/flags/TM.png' },
    TC: { code: 'TC', name: 'Turks and Caicos Islands', prefix: '++1', currency_code: 'USD', img: '/assets/new-img/flags/TC.png' },
    TV: { code: 'TV', name: 'Tuvalu', prefix: '++688', currency_code: 'AUD', img: '/assets/new-img/flags/TV.png' },
    UG: { code: 'UG', name: 'Uganda', prefix: '++256', currency_code: 'UGX', img: '/assets/new-img/flags/UG.png' },
    UA: { code: 'UA', name: 'Ukraine', prefix: '++380', currency_code: 'UAH', img: '/assets/new-img/flags/UA.png' },
    AE: { code: 'AE', name: 'United Arab Emirates', prefix: '++971', currency_code: 'AED', img: '/assets/new-img/flags/AE.png' },
    GB: { code: 'GB', name: 'United Kingdom', prefix: '++44', currency_code: 'GBP', img: '/assets/new-img/flags/GB.png' },
    US: { code: 'US', name: 'United States', prefix: '++1', currency_code: 'USD', img: '/assets/new-img/flags/US.png' },
    UM: {
      code: 'UM',
      name: 'United States Minor Outlying Islands',
      prefix: '++1',
      currency_code: 'USD',
      img: '/assets/new-img/flags/UM.png',
    },
    UY: { code: 'UY', name: 'Uruguay', prefix: '++598', currency_code: 'UYU', img: '/assets/new-img/flags/UY.png' },
    UZ: { code: 'UZ', name: 'Uzbekistan', prefix: '++998', currency_code: 'UZS', img: '/assets/new-img/flags/UZ.png' },
    VU: { code: 'VU', name: 'Vanuatu', prefix: '++678', currency_code: 'VUV', img: '/assets/new-img/flags/VU.png' },
    VE: { code: 'VE', name: 'Venezuela', prefix: '++58', currency_code: 'VEF', img: '/assets/new-img/flags/VE.png' },
    VN: { code: 'VN', name: 'Viet Nam', prefix: '++84', currency_code: 'VND', img: '/assets/new-img/flags/VN.png' },
    VG: { code: 'VG', name: 'Virgin Islands, British', prefix: '++93', currency_code: 'USD', img: '/assets/new-img/flags/VG.png' },
    VI: { code: 'VI', name: 'Virgin Islands, U.S.', prefix: '++93', currency_code: 'USD', img: '/assets/new-img/flags/VI.png' },
    WF: { code: 'WF', name: 'Wallis and Futuna', prefix: '++681', currency_code: 'XPF', img: '/assets/new-img/flags/WF.png' },
    EH: { code: 'EH', name: 'Western Sahara', prefix: '++93', currency_code: 'MAD', img: '/assets/new-img/flags/EH.png' },
    YE: { code: 'YE', name: 'Yemen', prefix: '++967', currency_code: 'YER', img: '/assets/new-img/flags/YE.png' },
    ZM: { code: 'ZM', name: 'Zambia', prefix: '++260', currency_code: 'ZMK', img: '/assets/new-img/flags/ZM.png' },
    ZW: { code: 'ZW', name: 'Zimbabwe', prefix: '++263', currency_code: 'ZWD', img: '/assets/new-img/flags/ZW.png' },
  };
}
