import { INotification } from '../components/notifications-menu/services/notifications/notifications-service.interface';

const getLinkMsg = (msg: string): { msg: string; link: string } | null => {
  const link = String(msg)
    .match(/http\S*/)
    ?.toString();
  return link ? { msg: msg.replace(link, ''), link } : null;
};

const isAffiliateType = (notification: INotification): boolean => {
  return notification?.status === 'NEW_AFFILIATE_SIGNUP' || notification?.status === 'user_registered';
};

export { isAffiliateType, getLinkMsg };
