<div class="c-knowledge-base" [ngClass]="{ 'is-opened': isOpened }">
  <div class="c-knowledge-base__inner">
    <div class="c-knowledge-base__body">
      <p500-ui-icon name="controls-close c-knowledge-base__close-btn" (click)="close()"></p500-ui-icon>

      <ng-container *ngIf="screen === screenClass.WikiHome">
        <!-- TODO: dont remove, temporary changes -->
        <div class="text-center">
          <!-- <button
            p500-ui-button
            color="white"
            [stroked]="true"
            positionIcon="left"
            classIcon="p500-ui-icon-buttons-book"
            (click)="openTourBoard()"
            class="c-knowledge-base__tour-button -desktop-show"
          >
            Learning Center
          </button> -->
          <img src="assets/new-img/art/wiki.svg" class="mb-28" />
        </div>

        <ng-container *ngIf="!isLoading">
          <ng-container *ngIf="relevantPosts?.length">
            <div class="c-knowledge-base__relevant-posts">
              <div class="c-knowledge-base__title">Relevant articles</div>
              <div *ngFor="let post of relevantPosts" class="c-card c-card--wiki" (click)="openPost(post.id, post.title.rendered)">
                <div class="c-card__title">{{ post.title.rendered }}</div>
                <div class="c-card__text" *ngIf="post.content.rendered.length">{{ getPostPreview(post.content.rendered) }}</div>
              </div>
            </div>
          </ng-container>
          <div class="c-knowledge-base__subtitle">Menu of Docs</div>
          <div *ngFor="let post of menuPosts" class="c-card c-card--wiki" (click)="openPost(post.id, post.title.rendered)">
            <div class="c-card__title">{{ post.title.rendered }}</div>
          </div>
        </ng-container>

        <p500-ui-cube-preloader *ngIf="isLoading" color="light"></p500-ui-cube-preloader>
      </ng-container>

      <ng-container *ngIf="screen === screenClass.WikiSubMenu">
        <ng-container *ngIf="!isLoading">
          <div class="c-knowledge-base__title">
            <p500-ui-icon name="arrows-big-left c-knowledge-base__back-btn" (click)="closePost()"></p500-ui-icon>
            {{ subMenuTitle }}
          </div>
          <div *ngFor="let post of menuPosts; let i = index" class="c-card c-card--wiki" (click)="openPost(post.id, post.title.rendered)">
            <div class="c-card__title">{{ post.title.rendered }}</div>
            <div class="c-card__text" *ngIf="post.content.rendered.length && i < 2">{{ getPostPreview(post.content.rendered) }}</div>
          </div>
        </ng-container>
        <p500-ui-cube-preloader *ngIf="isLoading" color="light"></p500-ui-cube-preloader>
      </ng-container>

      <ng-container *ngIf="screen === screenClass.WikiPost">
        <ng-container *ngIf="!isLoading">
          <div class="c-knowledge-base__title">
            <p500-ui-icon name="arrows-big-left c-knowledge-base__back-btn" (click)="closePost()"></p500-ui-icon>
            {{ currentPost?.title.rendered }}
          </div>
          <div class="c-knowledge-base__post" [innerHTML]="currentPost?.content.rendered"></div>
          <a [href]="currentPost?.link" target="_blank" class="c-knowledge-base__post-link">Visit this page on Docs</a>
        </ng-container>
        <p500-ui-cube-preloader *ngIf="isLoading" color="light"></p500-ui-cube-preloader>
      </ng-container>

      <div class="c-knowledge-base__gradient"></div>
    </div>

    <div class="c-knowledge-base__search">
      <p500-ui-icon name="controls-search"></p500-ui-icon>
      <div class="c-form-field c-form-field--search-wiki -transparent">
        <div class="c-form-field__inner">
          <input
            class="c-form-field__input"
            type="text"
            placeholder="Search in the docs..."
            [(ngModel)]="searchValue"
            (input)="onSearchInput($event)"
          />
        </div>
      </div>
    </div>
  </div>
</div>
