import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  TextFieldComponent,
  SelectComponent,
  BooleanSelectFieldComponent,
  DatepickerFieldComponent,
  TagsFieldComponent,
  SelectWithIconComponent,
  NumberFieldComponent,
} from '@platform500services/p500-ui-kit';
import { IDATA_SERVICE, IDataService } from '../../data/data-service.interface';
import { IRBAC_SERVICE, IRbacService } from '../../rbac/rbac-service.interface';
import { FieldModel } from './field.model';
import { TREE_LOG_TYPE } from '../../../components/fields/special/tree-log-type/tree-log-type.constant';
import { SelectOrTagsComponent } from '../../../components/form-fields/default/select-or-tags/select-or-tags.component';

@Injectable({
  providedIn: 'root',
})
export class FilterControlsFactory {
  private readonly globalData = this.dataService.getData();

  constructor(@Inject(IDATA_SERVICE) private dataService: IDataService, @Inject(IRBAC_SERVICE) public rbacService: IRbacService) {}

  public getField(type: any, key: string, label?: string, formControl?: UntypedFormControl): FieldModel {
    const control = formControl ? formControl : new UntypedFormControl();

    switch (type) {
      case 'string':
        return new FieldModel(label, key, TextFieldComponent, control);

      case 'boolean':
        return new FieldModel(label, key, BooleanSelectFieldComponent, control);

      case 'datetime':
        return new FieldModel(label, key, DatepickerFieldComponent, control);

      case 'currency':
        return new FieldModel(label, key, SelectWithIconComponent, control, { multiple: true, data: this.globalData.currencies });

      case 'country':
        return new FieldModel(label, key, SelectWithIconComponent, control, { multiple: true, data: this.globalData.countries });

      case 'language':
        return new FieldModel(label, key, SelectWithIconComponent, control, { multiple: true, data: this.globalData.languages });

      case 'advertiser_id':
        return new FieldModel(label, key, SelectComponent, control, { multiple: true, data: this.globalData.selects.brands });

      case 'ab_id':
        return new FieldModel(label, key, SelectComponent, control, { multiple: true, data: this.globalData.selects.ab });

      case 'company_id':
        return new FieldModel(label, key, SelectComponent, control, { multiple: true, data: this.globalData.selects.companies });

      case 'employee_id':
        return new FieldModel(label, key, NumberFieldComponent, control);

      case 'brand_provider_integration_id':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: this.globalData.integration_providers['brand-provider'],
        });

      case 'traffic_provider_integration_id':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: this.globalData.integration_providers['traffic-provider'],
        });

      case 'traffic_collector_integration_id':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: this.globalData.integration_providers['traffic-collector'],
        });

      case 'traffic_provider_id':
        return new FieldModel(
          label,
          key,
          SelectComponent,
          control,
          { multiple: false, data: this.globalData.selects.tracking_providers },
          type
        );

      case 'funnel_id':
        return new FieldModel(label, key, SelectComponent, control, { multiple: true, data: this.globalData.selects.funnels });

      case 'affiliate_id':
      case 'offer_id':
        return new FieldModel(label, key, SelectOrTagsComponent, control, {}, type);

      case 'incident_severity':
        return new FieldModel(label, key, SelectComponent, control, { multiple: true, data: this.globalData.selects.incidents.severities });

      case 'incident_status':
        return new FieldModel(label, key, SelectComponent, control, { multiple: true, data: this.globalData.selects.incidents.statuses });

      case 'leads_workflow_state':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: this.globalData.selects.leads_workflow_states,
        });

      case 'leads_workflow_state_single':
        return new FieldModel(label, key, SelectComponent, control, { data: this.globalData.selects.leads_workflow_states });

      case 'mapped_sale_status':
        const selectData = {};
        Object.entries(this.globalData.selects.mapped_sale_statuses).forEach(([itemKey, value]) => (selectData[itemKey] = value.name));
        return new FieldModel(label, key, SelectComponent, control, { multiple: true, data: selectData });

      case 'advertiser_leads_status':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            0: 'Inactive',
            1: 'Low',
            2: 'Active',
          },
        });

      case 'advertiser_ftds_status':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            0: 'Inactive',
            1: 'Low',
            2: 'Active',
          },
        });

      case 'type_events_log_filter_type':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            1: 'Danger',
            2: 'Success',
            3: 'System',
            0: 'Unknown',
          },
        });

      case 'type_events_log_filter_namespace':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: this.globalData.selects.events_log_namespaces.reduce((acc, item) => ({ ...acc, [item]: item }), {}),
        });

      case 'traffic_filter_level':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            'click-registration': 'Clicks prior to registration',
            'click-redirect': 'Clicks prior to redirect',
            'lead-registration': 'Lead prior to registration',
          },
        });

      case 'placeholder':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            tp_hash: 'Transaction ID',
            tp_aff_sub: 'Aff Sub 1',
            tp_aff_sub2: 'Aff Sub 2',
            tp_aff_sub3: 'Aff Sub 3',
            tp_aff_sub4: 'Aff Sub 4',
            tp_aff_sub5: 'Aff Sub 5',
            tp_affiliate_id: 'Affiliate ID',
            tp_offer_id: 'Offer ID',
            tp_id: 'Traffic Provider ID',
          },
        });

      case 'funnel_pixel_type':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            1: 'Inline',
            2: 'Script',
          },
        });

      case 'leads_workflow_event':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: this.globalData.selects.leads_workflow_events,
        });

      case 'funnel_pixel_affiliate_mode':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            0: 'Include',
            1: 'Exclude',
          },
        });

      case 'funnel_pixel_offer_mode':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            0: 'Include',
            1: 'Exclude',
          },
        });

      case 'backend_postback_type':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            default: 'Default',
            affiliate: 'Affiliate',
          },
        });

      case 'backend_postback_method':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            default: 'default',
            'traffic-provider-api': 'traffic-provider-api',
          },
        });

      case 'notification_rule_event':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            'App\\Modules\\Events\\Src\\Events\\LeadPushedToBrand': 'Leads Pushed To Advertiser',
            'App\\Modules\\Events\\Src\\Events\\ConversionCreated': 'Conversion Created',
            'App\\Modules\\Events\\Src\\Events\\BrandPushingError': 'Advertiser Pushing Error',
            'App\\Modules\\Events\\Src\\Events\\DiscrepanciesFoundInData': 'Discrepancies Found In Data',
          },
        });

      case 'notification_rule_type':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            sms: 'SMS',
            slack: 'Slack',
          },
        });

      case 'tag_numbers':
        return new FieldModel(label, key, TagsFieldComponent, control, { onlyNumbers: true });

      case 'tag_strings':
        return new FieldModel(label, key, TagsFieldComponent, control);

      case 'tree_active_elements':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: false,
          data: {
            all: 'All',
            active: 'Active',
            not_active: 'Not active',
          },
        });

      case 'days':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            Monday: 'Monday',
            Tuesday: 'Tuesday',
            Wednesday: 'Wednesday',
            Thursday: 'Thursday',
            Friday: 'Friday',
            Saturday: 'Saturday',
            Sunday: 'Sunday',
          },
        });

      case 'hoursOfDayFrom':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            'hourOfDay >= 0': '00:00',
            'hourOfDay >= 1': '01:00',
            'hourOfDay >= 2': '02:00',
            'hourOfDay >= 3': '03:00',
            'hourOfDay >= 4': '04:00',
            'hourOfDay >= 5': '05:00',
            'hourOfDay >= 6': '06:00',
            'hourOfDay >= 7': '07:00',
            'hourOfDay >= 8': '08:00',
            'hourOfDay >= 9': '09:00',
            'hourOfDay >= 10': '10:00',
            'hourOfDay >= 11': '11:00',
            'hourOfDay >= 12': '12:00',
            'hourOfDay >= 13': '13:00',
            'hourOfDay >= 14': '14:00',
            'hourOfDay >= 15': '15:00',
            'hourOfDay >= 16': '16:00',
            'hourOfDay >= 17': '17:00',
            'hourOfDay >= 18': '18:00',
            'hourOfDay >= 19': '19:00',
            'hourOfDay >= 20': '20:00',
            'hourOfDay >= 21': '21:00',
            'hourOfDay >= 22': '22:00',
            'hourOfDay >= 23': '23:00',
          },
        });

      case 'hoursOfDayTo':
        return new FieldModel(label, key, SelectComponent, control, {
          multiple: true,
          data: {
            'hourOfDay < 1': '00:59',
            'hourOfDay < 2': '01:59',
            'hourOfDay < 3': '02:59',
            'hourOfDay < 4': '03:59',
            'hourOfDay < 5': '04:59',
            'hourOfDay < 6': '05:59',
            'hourOfDay < 7': '06:59',
            'hourOfDay < 8': '07:59',
            'hourOfDay < 9': '08:59',
            'hourOfDay < 10': '09:59',
            'hourOfDay < 11': '10:59',
            'hourOfDay < 12': '11:59',
            'hourOfDay < 13': '12:59',
            'hourOfDay < 14': '13:59',
            'hourOfDay < 15': '14:59',
            'hourOfDay < 16': '15:59',
            'hourOfDay < 17': '15:59',
            'hourOfDay < 18': '17:59',
            'hourOfDay < 19': '18:59',
            'hourOfDay < 20': '19:59',
            'hourOfDay < 21': '20:59',
            'hourOfDay < 22': '21:59',
            'hourOfDay < 23': '22:59',
            'hourOfDay < 24': '23:59',
          },
        });

      case 'type_tree_log_type':
        return new FieldModel(label, key, SelectComponent, control, {
          data: TREE_LOG_TYPE,
        });

      case 'executed_risk_status_name':
        return new FieldModel(label, key, SelectComponent, control, { data: this.globalData.selects.executed_risk_statuses });

      case 'executed_postback_status_name':
        return new FieldModel(label, key, SelectComponent, control, { data: this.globalData.selects.executed_postback_statuses });

      default:
        return new FieldModel(label, key, TextFieldComponent, control);
    }
  }
}
