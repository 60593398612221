import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgModuleRef,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DatatableFiltersModal } from '../../../modals/datatable-filters/datatable-filters.modal';
import { IEVENT_SERVICE, IEventsService } from '../../../services/events/events.interface';
import { DatatableSettingsModal } from '../../../modals/datatable-settings/datatable-settings.modal';
import { IMODALS_SERVICE, DisplayFilterInterface, IModalsService } from '@platform500services/p500-ui-kit';
import { IColumn, ITableDataResponse } from '../service-facade/data.response';
import { ITableAction, MultiAction } from '../../table-layout/table-layout.model';
import { getDisplayFilters } from './header-facade.helpers';
import { IFilterValues, IVisibilityValues } from '../../../services/tables/interfaces/table.service.interface';
import { IFilterModel } from '../../../services/actions/models/filter-model.interface';
import { DestroySubscribers } from '../../../../core/decorators/destroy-subscribers.decorator';
import { TpDependsTypes } from '../../../helpers/tp-depends-fields';
import { IDataService, IDATA_SERVICE, IParsedDataResponse } from '../../../services/data/data-service.interface';

@Component({
  selector: 'gtd-table-header',
  templateUrl: './header-facade.component.html',
})
@DestroySubscribers()
export class HeaderFacadeComponent implements OnInit {
  @Input() page: number;
  @Input() totalItems: number;
  @Input() visibilityValues: {};
  @Input() columns: IColumn[];
  @Input() originSortColumns: IColumn[];
  @Input() limit: number;
  @Input() layoutType: string;
  @Input() headerActions?: ITableAction[] = [];
  @Input() multiActions?: MultiAction;
  @Input() titleTemplate: TemplateRef<{}>;
  @Input() set fieldModels(fieldsModels: IFilterModel[]) {
    this.filtersModel = fieldsModels;
    let tp = fieldsModels.find((item) => item.model.type === TpDependsTypes.tp && item.model.formControl.value?.length)?.model?.formControl
      ?.value;
    tp = Array.isArray(tp) ? tp[0] : tp;
    let tpData = {
      [TpDependsTypes.offer]: this.globalData.selects.offers?.[tp],
      [TpDependsTypes.affiliate]: this.globalData.selects.affiliates?.[tp],
    };
    this.displayFilters = fieldsModels
      .filter(({ model }) => model.formControl.value !== null)
      .map(({ model }) => getDisplayFilters(model, tpData[model.type]));
    const filterValues = {};
    fieldsModels
      .filter(({ model }) => model.formControl.value !== null)
      .map(({ model }) => (filterValues[model.key] = model.formControl.value));
    this.filterValues = filterValues;
  }
  @Output() reload: EventEmitter<any> = new EventEmitter();
  @Output() onChangeFilters: EventEmitter<IFilterValues> = new EventEmitter();
  @Output() onChangeLimit: EventEmitter<number> = new EventEmitter();
  @Output() onChangePage: EventEmitter<number> = new EventEmitter();

  @Output() onChangeVisibility: EventEmitter<IVisibilityValues> = new EventEmitter();
  @ViewChild('buttonFilters') buttonFilters: ElementRef;

  @ViewChild('buttonSettings') buttonSettings: ElementRef;
  public displayFilters: DisplayFilterInterface[];
  public filtersModel: IFilterModel[];
  public availableLimits = [10, 25, 50, 100];
  public isFiltersOpen: boolean;
  public isSettingsOpen: boolean;
  public data: ITableDataResponse;

  private filterValues: IFilterValues = {};
  private subscribers: any = {};
  private globalData: IParsedDataResponse;

  constructor(
    @Inject(IMODALS_SERVICE) private modalsService: IModalsService,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    protected cd: ChangeDetectorRef,
    protected moduleRef: NgModuleRef<any>,
    @Inject(IDATA_SERVICE) private dataService: IDataService
  ) {
    this.globalData = this.dataService.getData();
  }

  ngOnInit() {
    this.subscribers.closeSettings = this.eventsService.on('closeSettings').subscribe(() => {
      this.isSettingsOpen = false;
    });

    this.subscribers.closeFilters = this.eventsService.on('closeFilters').subscribe(() => {
      this.isFiltersOpen = false;
    });
  }

  onClickUpdate(): void {
    this.reload.emit();
  }

  onClickToggleFilters(): void {
    this.isFiltersOpen = !this.isFiltersOpen;
    if (this.isFiltersOpen) {
      this.modalsService.open(
        DatatableFiltersModal,
        {
          filtersModel: this.filtersModel,
          changeFilters: (value) => this.changeFilters(value),
          buttonFilters: this.buttonFilters,
        },
        this.moduleRef,
        false
      );
    } else {
      this.eventsService.broadcast('closeFilters');
    }
  }

  onClickToggleSettings(): void {
    this.isSettingsOpen = !this.isSettingsOpen;
    if (this.isSettingsOpen) {
      this.modalsService.open(
        DatatableSettingsModal,
        {
          limit: this.limit,
          availableLimits: this.availableLimits,
          columns: this.columns,
          visibilityValues: this.visibilityValues,
          showColumnsSettings: this.layoutType !== 'card',
          buttonSettings: this.buttonSettings,
          originSortColumns: this.originSortColumns,
          changeLimit: (value) => this.changeLimit(value),
          changeVisibility: (value) => this.changeVisibility(value),
        },
        this.moduleRef,
        false
      );
    } else {
      this.eventsService.broadcast('closeSettings');
    }
  }

  onPageChange($event): void {
    this.onChangePage.emit($event);
  }

  changeLimit(limit: number): void {
    if (this.limit === limit) {
      return;
    }
    this.onChangeLimit.emit(limit);
  }

  changeFilters(filters: IFilterValues = {}): void {
    const mappedFilters = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null) {
        mappedFilters[key] = Array.isArray(value) ? value : [value];
      }
    });
    this.onChangeFilters.emit(mappedFilters);
  }

  changeVisibility(visibilityValues: IVisibilityValues): void {
    this.onChangeVisibility.emit(visibilityValues);
  }

  removeFilter(key: string): void {
    const filters: IFilterValues = {};
    for (const { model } of this.filtersModel) {
      if (model.key !== key && model.formControl.value !== null) {
        filters[model.key] = model.formControl.value;
      }
    }
    this.changeFilters(filters);
  }

  setFilterById(id: string[]): void {
    const filters = this.filtersModel.reduce((acc, { model }) => ({ ...acc, [model.key]: model.formControl.value }), {});
    this.changeFilters({ ...filters, id });
  }

  removeAllFilters() {
    this.changeFilters({});
  }

  getActionProps(action: ITableAction): Partial<any> {
    return action.passTableData
      ? { filterValues: this.filterValues, visibilityValues: this.visibilityValues, ...action.props }
      : action.props;
  }

  normalizeId(value: string): string[] {
    return (value ?? '')
      .split(/[\s,]/)
      .map((numberValue) => numberValue.trim())
      .filter((numberValue) => Number.isInteger(Number(numberValue)) && Number(numberValue) > 0);
  }
}
