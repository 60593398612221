import { Observable } from 'rxjs';
import { IApplication } from '@platform500services/p500-ui-kit';
import { InjectionToken } from '@angular/core';

interface IApplicationsService {
  getAvailableApplications(): Observable<IApplication[]>;
  getCurrentApplication(): Observable<IApplication>;
}

interface ApplicationsResponseData {
  data: Application[];
  measure: number;
  measure_with_boot: number;
}

interface ApplicationResponseData {
  data: Application;
  measure: number;
  measure_with_boot: number;
}

interface Application {
  id: number;
  name: string;
  type: string;
  url: string;
  logo: string;
  path_suffix?: string;
}

const IAPPLICATIONS_SERVICE = new InjectionToken<IApplicationsService>('IApplicationsService');

export { IApplicationsService, IAPPLICATIONS_SERVICE, ApplicationsResponseData, ApplicationResponseData, Application };
