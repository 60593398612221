import { Component } from '@angular/core';
import { CHANGELOG_VERSION } from '../../../../changelog-version';

@Component({
  selector: 'gtd-copyright',
  templateUrl: './copyright.component.html',
})
export class CopyrightComponent {
  public appVersion = CHANGELOG_VERSION;
  public year = new Date().getFullYear();
}
