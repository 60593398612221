import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

interface INotificationSocket {
  id: number;
  channel: string;
  level: string;
  master_id: number;
  message: string;
  type: string;
  created_at_timestamp: number;
  user_id?: number;
  user_name?: string;
  status?: string;
}

interface INotificationTable {
  id: number;
  level: string;
  message: string;
  type: string;
  created_at_timestamp: number;
  user_id?: number;
  user_name?: string;
  status?: string;
}

type INotification = INotificationTable | INotificationSocket;

interface INotificationsApplicationService {
  getNotifications(): Observable<INotification[]>;
  getTotalPages(): number;
  onChangePage(page: number): void;
  onFilterChange(filter: { [key: string]: any[] }): void;
}

const INOTIFICATIONS_APPLICATION_SERVICE = new InjectionToken<INotificationsApplicationService>('INotificationsApplicationService');

export { INotificationSocket, INotificationTable, INotification, INotificationsApplicationService, INOTIFICATIONS_APPLICATION_SERVICE };
