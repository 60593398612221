import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { INOTIFICATIONS_SERVICE, INotificationsService } from '@platform500services/p500-ui-kit';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { pluck, scan, publishReplay, refCount, share, filter, map } from 'rxjs/operators';
import { IEnvironmentService, IENVIRONMENT_SERVICE } from '../../../../services/environment/environment.interface';
import { IAUTH_SERVICE, IAuthService } from '../../../../services/auth';
import { ISOCKETS_SERVICE, ISocketsService } from '../../../../services/sockets/sockets-service.interface';
import { ITableDataResponse, ITableResponse } from '../../../facade/service-facade/data.response';
import { INotification, INotificationsApplicationService, INotificationSocket } from './notifications-service.interface';

@Injectable()
export class NotificationsApplicationService implements INotificationsApplicationService {
  private apiUrl: string;
  private notifications$: Observable<INotification[]>;
  public page$: BehaviorSubject<number> = new BehaviorSubject(1);
  private filters$: BehaviorSubject<{ [key: string]: any[] }> = new BehaviorSubject({});
  public totalPages = 0;

  constructor(
    private http: HttpClient,
    @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService,
    @Inject(ISOCKETS_SERVICE) private socketsService: ISocketsService,
    @Inject(INOTIFICATIONS_SERVICE) private notificationsService: INotificationsService,
    @Inject(IAUTH_SERVICE) private authService: IAuthService
  ) {
    this.apiUrl = this.envService.getVar('services.api') as string;
    const userId = this.authService.getUserData()?.id;

    const tableNotifications$ = this.getNotificationTable();
    const crmNotification$ = this.socketsService.subscribeObservable<INotificationSocket>(`gtd-crm:notifications`);
    const userNotifications$ = this.socketsService.subscribeObservable<INotificationSocket>(`gtd-crm:user${userId}`);

    this.notifications$ = merge(tableNotifications$, crmNotification$, userNotifications$).pipe(
      scan((seed: INotification[], notifications) => this.addNotification(seed, notifications), []),
      publishReplay(1),
      refCount()
    );
  }

  public getNotifications(): Observable<INotification[]> {
    return this.notifications$;
  }

  onChangePage(page: number): void {
    this.page$.next(page);
  }

  onFilterChange(filterValue: { [key: string]: any[] }): void {
    this.filters$.next(filterValue);
  }

  getTotalPages(): number {
    return this.totalPages;
  }

  private getNotificationTable(): Observable<INotification[]> {
    const body = {
      table: 'NotificationTable',
      page: 1,
      limit: 5,
      filters: {},
      order_values: {},
    };

    return this.http.post<ITableResponse>(this.apiUrl + '/table/data', body).pipe(
      share(),
      filter((response) => !!response.data),
      map((response) => ({ ...response.data, columns: response.data.columns || [] })),
      pluck<ITableDataResponse, INotification[]>('rows')
    );
  }

  private addNotification(seed: INotification[], notification: INotificationSocket | INotification[]): INotification[] {
    if (Array.isArray(notification)) {
      return notification.concat(seed).slice(-5);
    } else {
      const isHasNotification = seed.some((item) => item.id === notification.id);
      const result = isHasNotification ? seed : [notification as INotification].concat(seed).slice(0, 5);

      if (!isHasNotification) {
        this.notificationsService.add({
          type: notification.type === 'system' ? 'success' : 'error',
          title: 'New Notification',
          more: [{ title: this.fixNotificationTitle(notification.type), message: notification.message }],
        });
      }

      return result;
    }
  }

  private fixNotificationTitle(value: string): string {
    if (value) {
      const cutFirstChar = value.slice(1);
      return `${value[0].toLocaleUpperCase()}${cutFirstChar}`;
    } else {
      return '';
    }
  }
}
