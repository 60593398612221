import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { P500UIButtonsModule, P500UIFormFieldsModule, P500UIIconsModule, P500UIProgressModule } from '@platform500services/p500-ui-kit';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Auth2FAModal } from './2fa.modal';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    P500UIIconsModule,
    P500UIButtonsModule,
    P500UIProgressModule,
    P500UIFormFieldsModule,
  ],
  declarations: [Auth2FAModal],
  exports: [Auth2FAModal],
})
export class Auth2FAModalModule {}
