import { ITour } from './tours.constants';
import { Orientation } from 'ngx-guided-tour';

export const managingOfPostbacksTour: ITour = {
  tourKey: 'managingOfPostbacksTour',
  tourName: 'Managing of Postbacks',
  tourIcon: '/assets/new-img/tours/managing-of-postbacks-tour/icon.png',
  tourDescription: 'Transfering information about leads and conversions back to the traffic provider',
  tourSections: [
    {
      name: 'Managing of Postbacks',
      commonTopic: 'Postbacks Section',
      description: `After you added a new Offer in your Traffic Provider you should created Postbacks for it in Leads Distribution CRM
                    in case affiliates will see their leads pushed and made conversions.`,
      image: '/assets/new-img/tours/managing-of-postbacks-tour/tour1.png',
      tourChains: [
        {
          tourId: 'postbacks_menu-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section--menu-item .p500-ui-nav-section__title[data-title="Postbacks"]',
              stepText: '1.1. Click on Postbacks menu section',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'postbacks_item-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section__child[data-title="Postbacks"]',
              stepText: '1.2. Go to Postbacks page',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Managing of Postbacks Tour',
      commonTopic: 'Postbacks Section',
      description: '',
      image: '/assets/new-img/tours/managing-of-postbacks-tour/tour2.png',
      tourChains: [
        {
          tourId: 'postbacks_plus-button',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '.p500-ui-table[data-tag="table_BackendPostbacksTable"] gtd-action-button button[p500-ui-circled-button]',
              stepText:
                '2.1. You need to create 2 Postbacks for every Offer - for Lead goal and for Conversion Goal. To add new one click on Plus Button',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'postbacks_fill-form',
          viewport: '.c-modal',
          steps: [
            {
              selector: 'gtd-action-form-modal .c-modal__title',
              stepText: `2.2. Here you choose the event: leads pushed, then the traffic provider, affiliates mode always Include, select an offer
                        needed. In the Settings field, you paste the goal ID from the Traffic Provider. Content for the postback may be found in
                        Help Documents of your Traffic Provider. If not - please contact Support Service of find some examples in our Docs. Type
                        and method are set up to default`,
              orientation: Orientation.Bottom,
            },
            {
              selector: 'gtd-action-form-modal .c-modal__footer .c-modal__action:nth-child(2)',
              stepText: `2.3. Click Submit and now the postback is done. Don't forget to create one more for the Conversion goal with the
                        Event - Conversion created`,
              orientation: Orientation.Top,
            },
          ],
        },
      ],
    },
  ],
};
