<div class="c-top-bar__inner">
  <div class="c-top-bar__header">
    <!-- Logo -->
    <div class="c-top-bar__logo">
      <a [routerLink]="'/app/dashboard'">
        <img class="d-sm-none" [src]="configurationService.data().brand.icon" width="20" height="20" alt="" />
        <img class="d-none d-sm-block" [src]="configurationService.data().brand.icon" height="20" alt="" />
      </a>
      <div class="c-top-bar__separator"></div>
      <div class="c-top-bar__title">
        {{ mobileTitle?.name }}
        <span *ngIf="mobileTitle?.id" class="c-top-bar__id">/ {{ mobileTitle?.id }}</span>
      </div>
    </div>
    <!-- End of Logo -->

    <!-- Toggle Sidebar -->
    <div class="c-top-bar__toggle" (click)="toggleSidebar()">
      <button p500-ui-circled-button color="primary" size="large">
        <div class="c-top-bar__toggle--rects">
          <div class="c-top-bar__toggle--rect top"></div>
          <div class="c-top-bar__toggle--rect bottom"></div>
        </div>
      </button>
    </div>
    <!-- End of Toggle Sidebar -->
  </div>
  <!-- Actions -->
  <!--
    TODO: Add Add check that this is a demo
   *ngIf="configurationService.data().isDemo"
   -->

  <div class="c-top-bar__actions">
    <div class="c-top-bar__action c-top-bar__action--incidents">
      <gtd-nav-panel></gtd-nav-panel>
    </div>
  </div>
  <!-- End of Actions -->
</div>
