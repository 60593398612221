<div class="p500-ui-modal__inner">
  <form class="c-form c-form--d" (ngSubmit)="submit()" [formGroup]="form">
    <div class="p500-ui-modal__head">
      <div class="p500-ui-modal__close" (click)="close()">
        <p500-ui-icon name="controls-close"></p500-ui-icon>
      </div>
      <div class="p500-ui-modal__title">{{ data.config.getTitle() }}<span *ngIf="data.id">&nbsp;#{{ data.id }}</span></div>
      <div class="p500-ui-modal__subtitle">{{ data.config.getDescription() }}</div>
    </div>

    <div class="p500-ui-modal__body">
      <p500-ui-cube-preloader [hidden]="!isLoading"></p500-ui-cube-preloader>

      <div [hidden]="isLoading">
        <ng-container *ngIf="groups; else defaultTpl">
          <gtd-group-form
            [formGroup]="form"
            [groups]="groups"
            [components]="components"
            [componentsSettings]="componentsSettings"
          ></gtd-group-form>
        </ng-container>
      </div>
    </div>

    <div class="p500-ui-modal__footer" [ngClass]="activeItemsToggle ? 'justify-content-between' : 'justify-content-end'">
      <div *ngIf="activeItemsToggle">
        <p500-ui-slide-toggle-field [formControl]="activeItemsToggle" [isLabelRight]="false" label="Active" size="medium">
        </p500-ui-slide-toggle-field>
      </div>
      <div class="d-flex">
        <div class="p500-ui-modal__action" (click)="close();$event.preventDefault()">
          <button p500-ui-button [stroked]="true" type="button">Cancel</button>
        </div>

        <div class="p500-ui-modal__action">
          <button p500-ui-button classIcon="h-icon-check" positionIcon="right" type="submit" [disabled]="!form.valid">
            {{ data.config.getBtnCaption() }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #defaultTpl>
  <div [formGroup]="form" class="row" *ngIf="form">
    <ng-container *ngIf="components | keyvalue: originalOrder as components">
      <ng-container *ngFor="let field of components; let i = index; trackBy: trackByFn">
        <div
          *ngIf="field.key !== 'active'"
          class="c-form__section"
          gtdFieldWidth
          [grid]="data.config.getGrid()"
          [fieldIDx]="i"
          [fields]="components.length"
        >
          <p500-ui-control-dynamic-component
            [component]="field.value"
            [settings]="componentsSettings.get(field.key)"
          ></p500-ui-control-dynamic-component>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
