import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

interface IConfigurationService {
  setData(data: IConfigurationData): void;
  getDataFromServer(): Observable<IConfigurationData>;
  data(): IConfigurationData;
}

interface IConfigurationResponse {
  data: IConfigurationData;
}

interface IConfigurationData {
  isDemo: boolean;
  brand: {
    id: number;
    name: string;
    icon: string;
    favicon: string;
    supportEmail: string;
    logo: {
      big: string;
      small: string;
      medium: string;
    };
    ouath_client_id: string;
    yandexMetrikaId: string;
    active: boolean;
    deactivation_reason?: string;
    vapid_public: string;
  };
}

const ICONFIGURATION_SERVICE = new InjectionToken<IConfigurationService>('IConfigurationService');

export { IConfigurationService, ICONFIGURATION_SERVICE, IConfigurationResponse, IConfigurationData };
