import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IENVIRONMENT_SERVICE, IEnvironmentService } from '../../../../services/environment/environment.interface';
import { IDataIncidentsResponseInterface, IIncident, IncidentsDataInterface, IIncidentsDataService } from './incidents-data.interface';
import { concat, Observable } from 'rxjs';
import { INOTIFICATIONS_SERVICE, INotificationsService } from '@platform500services/p500-ui-kit';
import { ISOCKETS_SERVICE, ISocketsService } from '../../../../services/sockets/sockets-service.interface';
import { pluck, publishReplay, refCount, scan } from 'rxjs/operators';

@Injectable()
export class IncidentsDataService implements IIncidentsDataService {
  private url: string;
  private readonly incidents$: Observable<IIncident[]>;

  constructor(
    @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService,
    @Inject(ISOCKETS_SERVICE) private socketsService: ISocketsService,
    @Inject(INOTIFICATIONS_SERVICE) private notificationsService: INotificationsService,
    private http: HttpClient
  ) {
    this.url = this.envService.getVar('services.api') as string;

    const incidents$ = this.http.get<IDataIncidentsResponseInterface>(this.url + '/incidents/unresolved', {}).pipe(pluck('incidents'));
    const incidentsSocket$ = this.socketsService.subscribeObservable<IncidentsDataInterface>('gtd-crm:incidents');
    this.incidents$ = concat(incidents$, incidentsSocket$).pipe(
      scan((seed: IIncident[], incidents: IncidentsDataInterface | IIncident[]) => {
        return this.addIncidents(seed, incidents);
      }, []),
      publishReplay(1),
      refCount()
    );
  }

  getIncidents(): Observable<IIncident[]> {
    return this.incidents$;
  }

  private addIncidents(incidents: IIncident[], additionalIncident: IncidentsDataInterface | IIncident[]): IIncident[] {
    if (Array.isArray(additionalIncident)) {
      return additionalIncident.concat(incidents);
    } else {
      if (additionalIncident.event === 'created' && !incidents.find((incident) => incident.id === additionalIncident.incident.id)) {
        this.notificationsService.add({
          type: 'error',
          title: 'New Incident',
          more: [{ title: additionalIncident.incident.severity, message: additionalIncident.incident.message }],
        });
        return [additionalIncident.incident, ...incidents];
      } else if (additionalIncident.event === 'updated') {
        return incidents.filter((incident) => incident.id !== additionalIncident.incident.id);
      }
      return [];
    }
  }
}
