<div class="c-unsecured mb-12" *ngIf="unsecuredStatus?.isUnsecured">
  <div class="c-unsecured__left">
    <div class="c-unsecured__title">
      <p500-ui-icon name="secure-2" class="icon"></p500-ui-icon>
      Unsecured Mode
    </div>
  </div>
  <div class="c-unsecured__right">
    <div class="c-unsecured__time">{{ timeLeft | date: 'mm:ss' }}</div>
    <div class="c-unsecured__separator"></div>
    <button
      p500-ui-confluent-button
      [transparent]="true"
      size="medium"
      color="warn"
      class="c-unsecured__button"
      [disabled]="isLoading"
      (click)="leaveMode()"
    >
      <span class="text">Leave Mode</span>
    </button>
  </div>
</div>
