import { Inject, Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IAUTH_SERVICE, IAuthService } from '../auth';
import { IConfigurationService, IConfigurationData, ICONFIGURATION_SERVICE } from './configuration-service.interface';

@Injectable()
export class ConfigurationResolverService implements Resolve<IConfigurationData> {
  constructor(
    @Inject(ICONFIGURATION_SERVICE) private configurationService: IConfigurationService,
    @Inject(IAUTH_SERVICE) private authService: IAuthService,
    private router: Router
  ) {}

  resolve(): Observable<IConfigurationData> {
    return this.configurationService.getDataFromServer().pipe(
      tap((data) => {
        if (!data.brand.active) {
          this.authService.logout();
          this.router.navigate(['/auth/login'], {
            queryParams: { applicationId: data.brand.id, deactivation_reason: data.brand.deactivation_reason },
          });
        }
      }),
      catchError(() => {
        return of(null);
      })
    );
  }
}
