import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { ITableAction, StatusColorSchema } from '../../table-layout/table-layout.model';
import { IColumn, IRow } from '../service-facade/data.response';
import { IOrderValues } from '../../../services/tables/interfaces/table.service.interface';
import { UntypedFormGroup } from '@angular/forms';
import { getMarkedIDs } from '../../../helpers/multi-actions.helpers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { getReplayControlValueChange } from '../../../helpers/replay-control';

@Component({
  selector: 'gtd-table',
  templateUrl: './table.component.html',
})
export class TableComponent implements OnChanges {
  @Input() checkboxesGroup?: UntypedFormGroup;
  @Input() key: string;
  @Input() rows: IRow[];
  @Input() columns: IColumn[];
  @Input() statusHint: string;
  @Input() rowActions?: ITableAction[];
  @Input() statusColorScheme: StatusColorSchema;
  @Input() emptyTableAction: TemplateRef<{}>;
  @Input() orderValues: IOrderValues;

  @Output() sort = new EventEmitter<IOrderValues>();
  public isIndeterminate$: Observable<boolean>;
  public isAllChecked$: Observable<boolean>;

  ngOnChanges(changes: SimpleChanges): void {
    this.rowActions = this.rowActions?.filter((action) => action);
    if (('rows' in changes && this.checkboxesGroup) || ('checkboxesGroup' in changes && this.rows)) {
      const currentRowsIDs: string[] = this.rows?.map(({ id }) => String(id)) ?? [];
      const markedIDs$ = getReplayControlValueChange(this.checkboxesGroup).pipe(getMarkedIDs());
      this.isAllChecked$ = markedIDs$.pipe(map((rowIDs) => currentRowsIDs.every((id) => rowIDs.includes(id))));
      this.isIndeterminate$ = this.isAllChecked$.pipe(
        switchMap((isAllChecked) => markedIDs$.pipe(map((rowIDs) => currentRowsIDs.some((id) => rowIDs.includes(id)) && !isAllChecked)))
      );
    }
  }

  handleSort(column: string) {
    if (this.orderValues[column] === 'asc') {
      this.sort.emit({ [column]: 'desc' });
    } else {
      this.sort.emit({ [column]: 'asc' });
    }
  }

  getCellStatusHexColor(row, statusColorScheme: StatusColorSchema): string {
    return statusColorScheme
      ? Object.entries(statusColorScheme)
          .filter(([, func]) => func(row))
          .map(([hexColor]) => hexColor)
          .join(' ')
      : '';
  }

  changeValueGroup(checked: boolean) {
    for (const { id } of this.rows) {
      this.checkboxesGroup.get([id]).setValue(checked);
    }
  }
}
