import { Component, Inject, HostListener, ElementRef } from '@angular/core';
import { DocsService } from './docs.service';
import { IDocsService, IDOCS_SERVICE, IWikiPost } from './docs.interface';
import { IEVENT_SERVICE, IEventsService } from '../../../core/services/events/events.interface';
import { DOCUMENT } from '@angular/common';
import { IMODALS_SERVICE, IModalsService } from '@platform500services/p500-ui-kit';
import { DestroySubscribers } from '../../../core/decorators/destroy-subscribers.decorator';
import { ImageViewModal } from '../../../core/modals/image-view/image-view.modal';
enum Screen {
  WikiHome,
  WikiSubMenu,
  WikiPost,
}

@Component({
  selector: 'gtd-knowledge-base',
  templateUrl: './knowledge-base.component.html',
  providers: [
    {
      provide: IDOCS_SERVICE,
      useClass: DocsService,
    },
  ],
})
@DestroySubscribers()
export class KnowledgeBaseComponent {
  public relevantPosts: IWikiPost[];
  public menuPosts: IWikiPost[];
  public currentPost: IWikiPost;
  public subMenuTitle = '';
  public searchValue = '';
  public pageTitle = '';

  public isOpened = false;
  public isLoading = false;
  public isTourOpened = false;
  public subscribers: any = {};

  public screen: Screen = Screen.WikiHome;
  public screenClass = Screen;

  constructor(
    private eRef: ElementRef,
    @Inject(IDOCS_SERVICE) private docs: IDocsService,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    @Inject(IMODALS_SERVICE) private modalsService: IModalsService,
    @Inject(DOCUMENT) private dom: Document
  ) {
    this.searchDocs();
    this.loadMenuPosts();

    this.subscribers.pageTitleChange = this.eventsService.on('mobileTopbarTitle:change').subscribe((data: any) => {
      if (data) {
        this.pageTitle = data.name;
        this.searchDocs(this.pageTitle);
      }
    });

    this.subscribers.docs = this.eventsService.on('docs:open').subscribe(() => {
      this.isTourOpened = false;
      setTimeout(() => (this.isOpened = true), 0);
    });
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touch', ['$event'])
  onClick($event) {
    const imageElements = Array.from(this.dom.querySelectorAll('.wp-block-image'));
    if (imageElements.some((image) => image.contains($event.target))) {
      const url = $event.target.getAttribute('src');
      $event.preventDefault();
      $event.stopPropagation();
      this.openImageModal(url);
    }
    const overlayElement = this.dom.querySelector('.p500-ui-overlay-background');
    if (overlayElement && overlayElement.contains($event.target)) {
      return;
    }
    if (!this.eRef.nativeElement.contains($event.target)) {
      this.close();
    }
  }

  openImageModal(url: string): void {
    this.modalsService.open(ImageViewModal, {
      url,
    });
  }

  onSearchInput($event: any): void {
    const value = $event.target.value;
    this.screen = Screen.WikiHome;
    this.searchDocs(value);
    this.loadMenuPosts();
  }

  searchDocs(searchValue?: string): void {
    this.isLoading = true;
    this.subscribers.searchDocs = this.docs.search(searchValue).subscribe((response: IWikiPost[]) => {
      this.relevantPosts = response;
      this.isLoading = false;
    });
  }

  loadMenuPosts(parentId?: number): void {
    this.isLoading = true;
    this.subscribers.loadMenuPosts = this.docs.getPostsByParent(parentId || 0).subscribe((response: IWikiPost[]) => {
      this.menuPosts = response;
      this.isLoading = false;
    });
  }

  openPost(postId: number, postTitle: string): void {
    setTimeout(() => {
      this.screen = Screen.WikiPost;
      this.isLoading = true;
      this.subscribers.listResponse = this.docs.getPostsByParent(postId).subscribe((listResponse: IWikiPost[]) => {
        if (listResponse.length) {
          this.menuPosts = listResponse;
          this.isLoading = false;
          this.screen = Screen.WikiSubMenu;
          this.subMenuTitle = postTitle;
        } else {
          this.subscribers.postResponse = this.docs.getPostById(postId).subscribe((postResponse: IWikiPost) => {
            this.currentPost = postResponse;
            this.isLoading = false;
            this.screen = Screen.WikiPost;
          });
        }
      });
    }, 0);
  }

  closePost(): void {
    setTimeout(() => {
      this.screen = Screen.WikiHome;
      this.currentPost = null;
      this.searchValue = '';
      this.searchDocs(this.pageTitle);
      this.loadMenuPosts();
    }, 0);
  }

  getPostPreview(postContent: string): string {
    const textContent = postContent.replace(/<[^>]*>?/gm, '');
    return textContent.length ? textContent.slice(0, 80) + '...' : '';
  }

  open() {
    this.isOpened = true;
  }

  close() {
    this.isOpened = false;
  }

  openTourBoard() {
    this.isTourOpened = true;
    this.eventsService.broadcast('tourBoard:open');
    this.close();
  }
}
