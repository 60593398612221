import { ITour } from './tours.constants';
import { Orientation } from 'ngx-guided-tour';

export const brandSetupTour: ITour = {
  tourKey: 'advertiserSetupTour',
  tourName: 'Advertisers Setup',
  tourIcon: '/assets/new-img/tours/brand-setup-tour/icon.png',
  tourDescription: 'Connecting your CRM with Advertiser’s systems and getting data from them',
  tourSections: [
    // tours
    {
      name: 'Advertiser Setup',
      commonTopic: 'Advertisers Section',
      description: `A Advertiser is company who will get leads and make calls to make them do deposits. You should contact the company
                    you are going to work with and ask them to give you API credentials. So you can connect your CRM with their
                    system and get data from them.`,
      image: '/assets/new-img/tours/brand-setup-tour/tour1.png',
      tourChains: [
        {
          tourId: 'advertisers_menu-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section--menu-item .p500-ui-nav-section__title[data-title="Traffic Distribution"]',
              stepText: '1.1. Click on Traffic Distribution menu section',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'advertisers_item-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section__child[data-title="Advertisers"]',
              stepText: '1.2. Go to Advertisers page',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Advertiser Setup',
      commonTopic: 'Advertisers Section',
      description: '',
      image: '/assets/new-img/tours/brand-setup-tour/tour2.png',
      tourChains: [
        {
          tourId: 'advertisers_plus-button',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '.p500-ui-table[data-tag="table_BrandsTable"] gtd-action-button button.c-widget__add-icon',
              stepText: '2.1. To create new Advertiser click on Plus Button',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'advertisers_fill-form',
          viewport: '.c-modal',
          steps: [
            {
              selector: 'gtd-action-form-modal .c-modal__title',
              stepText: `2.2. Fill in the Name and Frontend Name (it will be shown for the lead), Description.<br>
                        <strong>Conversions Pulling</strong> – Yes set up is recommended. If turned to No, conversions must be pulled manually on the day of their
                        creation, otherwise, they’re lost. Choose Default Currency the Advertiser works with and Advertiser Provider - the platform which the
                        Advertiser uses for trading. If you don't see the necessary provider in the list please contact Support Service.<br>
                        <strong>Logo URL</strong> – Logo that will pop-up after successful registration, can be found on a advertiser’s page<br>
                        <strong>iFrame Supported</strong> – Shows whether the advertiser’s page can be opened in funnel’s iFrame and lead can be successfully
                        registered there. Check with the advertiser if they support this. Turning on is recommended.<br>
                        <strong>Sale Status Supported</strong> – Shows lead’s sale status. <br>
                        <strong>FTD Amount Supported</strong> – Show the value the lead has deposited.`,
              orientation: Orientation.Bottom,
            },
            {
              selector: 'gtd-action-form-modal .c-modal__footer .c-modal__action:nth-child(2)',
              stepText: '2.3. Submit form',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Advertiser Setup',
      commonTopic: 'Advertisers Section',
      description: `New Advertiser was created and you should connect your CRM with it's system.`,
      image: '/assets/new-img/tours/brand-setup-tour/tour3.png',
      tourChains: [
        {
          tourId: 'advertisers_go-to-single',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '.p500-ui-table[data-tag="table_BrandsTable"] .p500-ui-table__body tbody tr:nth-child(1) td:nth-child(1) .c-link',
              stepText: `3.1. To open Advertiser's personal page click Advertiser's ID`,
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'advertisers_api-connection',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '.p500-ui-tabs__tab:nth-child(2)',
              stepText: '3.2. Switch to Api Connection Tab',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Advertiser Setup',
      commonTopic: 'Advertisers Section',
      description: `New Advertiser was created and you should connect your CRM with it's system.`,
      image: '/assets/new-img/tours/brand-setup-tour/tour4.png',
      tourChains: [
        {
          tourId: 'advertisers_edit-api-settings',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '*[data-tag="advertiser_edit-api-settings"]',
              stepText: '4.1. Click here to open the API settings form',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'advertisers_api-settings-form',
          viewport: '.c-modal',
          steps: [
            {
              selector: 'gtd-api-settings-modal .c-modal__title',
              stepText: '4.2. Fill in the form with the credentials the Advertiser gave you',
              orientation: Orientation.Bottom,
            },
            {
              selector: 'gtd-api-settings-modal .c-modal__footer .c-modal__action:nth-child(2)',
              stepText: '4.3. Submit form',
              orientation: Orientation.Top,
            },
          ],
        },
      ],
    },
    {
      name: 'Advertiser Setup',
      commonTopic: 'Advertisers Section',
      description: '',
      image: '/assets/new-img/tours/brand-setup-tour/tour5.png',
      tourChains: [
        {
          tourId: 'advertisers_test-settings-button',
          viewport: '.l-base__body',
          steps: [
            {
              selector: 'gtd-widget-advertisers-api-settings .c-widget__links .col-auto:nth-child(2) gtd-action-button button',
              stepText: '5.1. This button will help you to understand if the API settings were placed properly. Click it now',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'advertisers_test-settings-form',
          viewport: '.c-modal',
          steps: [
            {
              selector: 'gtd-action-form-modal .c-modal__footer .c-modal__action:nth-child(2)',
              stepText: '5.2. Click Submit',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'advertisers_test-settings-result',
          viewport: '.p500-ui-notification',
          steps: [
            {
              selector: 'p500-ui-notification .p500-ui-notification__header .p500-ui-notification__btn div:nth-child(1)',
              stepText: `5.3. Click on More here to see the info - if you get "Advertiser settings have been tested with 1 result"
                        connection should work correctly`,
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Advertiser Setup',
      commonTopic: 'Advertisers Section',
      description: '',
      image: '/assets/new-img/tours/brand-setup-tour/tour6.png',
      tourChains: [
        {
          tourId: 'advertisers_test-country-button',
          viewport: '.l-base__body',
          steps: [
            {
              selector: 'gtd-widget-advertisers-api-settings .c-widget__links .col-auto:nth-child(1) gtd-action-button button',
              stepText: `6.1. To be sure that everything works fine you should push test lead to the Advertiser. Leads Distribution CRM
                        allows you to do it automatically by clicking just one button! Let's see`,
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'advertisers_test-country-form',
          viewport: '.c-modal',
          steps: [
            {
              selector: 'gtd-action-form-modal .c-modal__title',
              stepText: `6.2. Choose the country which the Advertiser can accept leads from. Other fields are not mandatory. Fiil them in only
                        if the Advertiser requires unique data for these parameters`,
              orientation: Orientation.Bottom,
            },
            {
              selector: 'gtd-action-form-modal .c-modal__footer .c-modal__action:nth-child(2)',
              stepText: '6.3. Click Submit',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'advertisers_test-country-button',
          viewport: '.notifications',
          steps: [
            {
              selector: 'p500-ui-float-notifications .p500-ui-notification__header',
              stepText: `6.4. Click More here to see if lead was pushed to the Advertiser correctly. If you get autologin URL and Advertiser CRM ID
                        for your test lead  was a success`,
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
  ],
};
