<div class="c-nav-panel">
  <div class="c-nav-panel__top">
    <div class="c-nav-panel__item" (mouseenter)="openCrmSwitchModal()">
      <div class="c-nav-panel__logo">
        <img [src]="configurationService.data().brand.icon || 'assets/new-img/logo/ld-logo.svg'" />
      </div>
      <div class="c-nav-panel__subtitle">ld</div>
    </div>
    <div class="c-nav-panel__header">
      <gtd-selector-carousel-header></gtd-selector-carousel-header>
    </div>
    <span class="c-nav-panel__separator"></span>
    <div class="c-nav-panel__item c-nav-panel__item--setings" p500UITooltip [tooltipData]="'Control Product'" tooltipPlacement="right">
      <a [href]="controlLink" target="_blank">
        <div class="c-nav-panel__logo">
          <img src="assets/new-img/logo/control.svg" />
        </div>
      </a>
      <div class="c-nav-panel__subtitle">Cp</div>
    </div>
  </div>
  <div class="c-nav-panel__bottom">
    <div class="c-nav-panel__button d-sm-none">
      <p500-ui-navbar-button
        class="-menu"
        icon="h-icon-other-menu"
        [class.is-active]="sidebarService.isCurrent('menu')"
        (click)="openMenuSection('menu')"
      ></p500-ui-navbar-button>
    </div>
    <div
      p500UITooltip
      [tooltipData]="'Notifications'"
      tooltipPlacement="right"
      class="c-nav-panel__button c-nav-panel__button--notifications"
      [class.is-notification]="!isAlertRead"
      [class.is-critical-incident]="isCriticalAlert"
      [class.is-danger-incident]="isDangerAlert"
      [class.is-active]="sidebarService.isCurrent('notifications')"
      (click)="openNotificationsMenu(latestAlertDate)"
    >
      <p500-ui-icon name="menu-notification"></p500-ui-icon>
      <!-- <gtd-notifications-menu cssClass="-box -floating"></gtd-notifications-menu> -->
    </div>
    <div class="c-nav-panel__button">
      <p500-ui-navbar-button
        icon="h-icon-other-question"
        [class.is-active]="sidebarService.isCurrent('help')"
        (click)="openMenuSection('help')"
      >
        <gtd-help-menu *p500UINavbarButtonPopup cssClass="-box -floating"></gtd-help-menu>
      </p500-ui-navbar-button>
    </div>
    <a
      *ngIf="RbacPermissionsConstant.PAGES_SPECIAL_SETTINGS | canRbac"
      class="c-nav-panel__button"
      [routerLink]="'/app/settings/page'"
      routerLinkActive="-black"
      (click)="closeSidebar()"
    >
      <p500-ui-navbar-button icon="h-icon-managment"></p500-ui-navbar-button>
    </a>
    <div class="c-nav-panel__button">
      <p500-ui-navbar-button
        class="-circle -blue"
        icon="h-icon-other-user-1"
        [class.is-active]="sidebarService.isCurrent('profile')"
        (click)="openMenuSection('profile')"
      >
        <gtd-profile-menu *p500UINavbarButtonPopup cssClass="-box -floating -profile-menu"></gtd-profile-menu>
      </p500-ui-navbar-button>
    </div>
  </div>
</div>
