import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gtd-table-search',
  templateUrl: './search.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchComponent),
    },
  ],
})
export class SearchComponent implements ControlValueAccessor {
  @Input() placeholder = 'Search By ID ...';
  @Input() normalizeFn;

  public value: string;
  private onChange: (value: string[]) => void;
  private onTouch: () => void;

  registerOnChange(fn: (value) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  onKeydownSearch($event: KeyboardEvent): void {
    if ($event.key === 'Enter') {
      this.search(($event.currentTarget as HTMLInputElement).value);
    }
  }

  search(value: string) {
    if (this.normalizeFn) {
      const searchValue = this.normalizeFn(value);
      this.onChange(searchValue);
      this.onTouch();
      this.value = searchValue.join(', ');
    } else {
      this.onChange([value]);
      this.onTouch();
      this.value = value;
    }
  }
}
