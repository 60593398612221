import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { P500UIFormFieldsModule, P500UIIconsModule } from '@platform500services/p500-ui-kit';

import { ProfileMenuComponent } from './profile-menu.component';

@NgModule({
  imports: [CommonModule, P500UIIconsModule, P500UIFormFieldsModule, FormsModule, ReactiveFormsModule],
  declarations: [ProfileMenuComponent],
  exports: [ProfileMenuComponent],
})
export class ProfileMenuModule {}
