import { Injectable } from '@angular/core';
import { ISidebarService } from './sidebar.interface';

@Injectable()
export class SidebarService implements ISidebarService {
  private menuSection = 'menu';

  getMenuSection(): string {
    return this.menuSection;
  }

  setMenuSection(value: string) {
    this.menuSection = value;
  }

  isCurrent(value: string) {
    return this.menuSection === value;
  }
}
