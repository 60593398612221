import { ITour } from './tours.constants';
import { Orientation } from 'ngx-guided-tour';

export const workWithFunnelsTour: ITour = {
  tourKey: 'workWithFunnelsTour',
  tourName: 'Work with Funnels',
  tourIcon: '/assets/new-img/tours/work-with-funnels-tour/icon.png',
  tourDescription: 'Adding funnel in your CRM and generating tracking link for next work',
  tourSections: [
    {
      name: 'Work with Funnels',
      commonTopic: 'Funnels Section',
      description: `Now it's time to create your first offer - a proposal to affiliate which spots conditions of his cooperation
                    with the affiliate network (in other words with You). Offer is based on the funnel. Every funnel should be
                    prepared by your tech team at first. When you have a ready link you need to create a funnel note.`,
      image: '/assets/new-img/tours/work-with-funnels-tour/tour1.png',
      tourChains: [
        {
          tourId: 'funnels_menu-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section--menu-item .p500-ui-nav-section__title[data-title="Landings"]',
              stepText: '1.1. Click on Landings menu section',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'funnels_item-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section__child[data-title="Funnels"]',
              stepText: '1.2. Go to Funnels page',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Work with Funnels',
      commonTopic: 'Funnels Section',
      description: `Now it's time to create your first offer - a proposal to affiliate which spots conditions of his cooperation
                    with the affiliate network (in other words with You). Offer is based on the funnel. Every funnel should be
                    prepared by your tech team at first. When you have a ready link you need to create a funnel note.`,
      image: '/assets/new-img/tours/work-with-funnels-tour/tour2.png',
      tourChains: [
        {
          tourId: 'funnels_plus-button',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '.p500-ui-table[data-tag="table_FunnelsTable"] gtd-action-button button[p500-ui-circled-button]',
              stepText: '2.1. Click Plus button',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'funnels_fill-form',
          viewport: '.c-modal',
          steps: [
            {
              selector: 'gtd-action-form-modal .c-modal__title',
              stepText: `2.2. Fill in the Name and Funnel URL. Choose funnel language. Set up next parameters as you want them to work:<br>
                        <strong>SSL</strong> – funnel encryption settings.<br>
                        <strong>Auto Password Generate</strong> – Password field is removed in the funnel page, the password is generated automatically
                        and sent to the lead’s email address.<br>
                        <strong>Auto Push Notification</strong> – is used for browser push, SMS, e-mail notifications settings. To get such notifications
                        user must accept or be subscribed to browser notifications in his browser. This setting is connected to the Notifications Builder.<br>
                        <strong>Validation</strong> – enables or disables CAPTCHA on the funnel page.`,
              orientation: Orientation.Bottom,
            },
            {
              selector: 'gtd-action-form-modal .c-modal__footer .c-modal__action:nth-child(2)',
              stepText: '2.3. Submit form',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Work with Funnels',
      commonTopic: 'Funnels Section',
      description: `Now it's time to create your first offer - a proposal to affiliate which spots conditions of his cooperation
                    with the affiliate network (in other words with You). Offer is based on the funnel. Every funnel should be
                    prepared by your tech team at first. When you have a ready link you need to create a funnel note.`,
      image: '/assets/new-img/tours/work-with-funnels-tour/tour3.png',
      tourChains: [
        {
          tourId: 'funnels_go-to-single',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '.p500-ui-table[data-tag="table_FunnelsTable"] .p500-ui-table__body tbody tr:nth-child(1) td:nth-child(1) .c-link',
              stepText: `3.1. Click on the Funnel's ID to go to it's personal page`,
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'funnels_single-page',
          viewport: '.l-base__body',
          steps: [
            {
              selector: 'gtd-widget-funnels-settings .c-widget__title',
              stepText: `3.2. Image Url, Icon URL, Funnel Name and Color settings are not mandatory, but you need to remember that their
                        values can be used for SMS / Email / Push templates for Notification builder.`,
              orientation: Orientation.Bottom,
            },
            {
              selector: 'gtd-widget-funnels-profile ul:nth-child(1) li.title',
              stepText: `3.3. Here you will take the Tracking URL which you will use while creating an Offer in your Traffic Provider as
                        for example Tracking Software by IREV`,
              orientation: Orientation.Bottom,
            },
            {
              selector: 'gtd-widget-funnels-profile ul:nth-child(1) li:nth-child(2) p500-ui-icon[name="buttons-copy-big"]',
              stepText: '3.4. Click here to copy the URL',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
  ],
};
