import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IENVIRONMENT_SERVICE, Value, IEnvironmentService } from '../environment/environment.interface';
import { IAuthService } from './auth-service.interface';
import { SignDetailsRequest } from './requests';
import { AuthResponse, UserResponse } from './responses';

@Injectable()
export class AuthService implements IAuthService {
  private endpointApi: Value;
  private isQa: Value;
  private user: UserResponse;
  private tokenKey = 'token';

  constructor(private http: HttpClient, @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService) {
    this.endpointApi = this.envService.getVar('services.api');
    this.isQa = this.envService.getVar('isQa');
  }

  isAuthorized(): boolean {
    return this.isQa ? true : !!localStorage.getItem(this.tokenKey);
  }

  setUser(user: UserResponse): void {
    this.user = user;
  }

  getUser(): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${this.endpointApi}/sign/user`).pipe(map((data) => UserResponse.fromJson(data)));
  }

  getUserData(): UserResponse {
    return this.user;
  }

  auth(signDetailsRequest: SignDetailsRequest, succesCb: (user: UserResponse) => void, errorCb: (error: any) => void): void {
    const form = {
      login: signDetailsRequest.login,
      password: signDetailsRequest.password,
      token_2fa: null,
    };

    const { token_2fa } = signDetailsRequest;

    if (token_2fa) {
      form.token_2fa = token_2fa;
    }

    this.http
      .post<AuthResponse>(`${this.endpointApi}/sign/in`, form)
      .pipe(map((data) => AuthResponse.fromJson(data)))
      .subscribe(
        (response) => {
          // we use it because of async complexity
          localStorage.setItem(this.tokenKey, response.token.token);
          this.setUser(response.user);
          succesCb(this.user);
        },
        (error) => {
          errorCb(error);
        }
      );
  }

  authViaToken(token: string, successCb: (res?: any) => void, errorCb: (err?: any) => void): void {
    localStorage.setItem(this.tokenKey, token);
    this.http
      .post<AuthResponse>(`${this.endpointApi}/sign/callback`, null)
      .pipe(map((data) => AuthResponse.fromJson(data)))
      .subscribe(
        (response) => {
          // we use it because of async complexity
          localStorage.setItem(this.tokenKey, response.token.token);
          this.setUser(response.user);
          successCb(this.user);
        },
        (error) => {
          errorCb(error);
        }
      );
  }

  public logout(): void {
    localStorage.removeItem(this.tokenKey);
  }
}
