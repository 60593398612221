import { IEventsService } from './events.interface';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BroadcastEvent } from './broadcast-event.interface';

@Injectable()
export class EventsService implements IEventsService {
  private eventBus: Subject<BroadcastEvent>;

  constructor() {
    this.eventBus = new Subject<BroadcastEvent>();
  }

  on<T>(key: string): Observable<T> {
    return this.eventBus.asObservable().pipe(
      filter((event) => event.key === key),
      map((event) => event.data as T)
    );
  }

  broadcast(key: string, data?: any): void {
    this.eventBus.next({ key, data });
  }
}
