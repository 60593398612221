import { Directive, Inject, Input, OnDestroy } from '@angular/core';
import { GtdNgWidgetComponentAbstract } from 'gtd-ng-widget';
import { IPAGE_SERVICE, IPageService } from '../../../core/services/page/page-service.interface';
import { TableFacadeComponent } from '../../components/facade/table-facade/table-facade.component';

@Directive({
  exportAs: 'gtdAppRefresh',
  selector: '[gtdAppRefresh]',
})
export class RefreshDirective implements OnDestroy {
  @Input() gtdAppRefresh: GtdNgWidgetComponentAbstract | TableFacadeComponent;

  private readonly key: string;

  constructor(@Inject(IPAGE_SERVICE) private pageService: IPageService) {
    this.key = Math.random().toString(36).substr(2, 9);
    this.pageService.push(this.key, this);
  }

  ngOnDestroy(): void {
    this.pageService.remove(this.key);
  }

  component(): GtdNgWidgetComponentAbstract | TableFacadeComponent {
    return this.gtdAppRefresh;
  }
}
