import { P500UIIconsModule } from '@platform500services/p500-ui-kit';

import { CommonModule } from '@angular/common';
import { ImageViewModal } from './image-view.modal';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, P500UIIconsModule],
  declarations: [ImageViewModal],
  exports: [ImageViewModal],
})
export class ImageViewModule {}
