import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAUTH_SERVICE, IAuthService } from '../auth';
import { IENVIRONMENT_SERVICE, Value, IEnvironmentService } from '../environment/environment.interface';
import { IPAGE_SERVICE, IPageService } from '../page/page-service.interface';
import { IUnsecuredService, IUnsecuredStatus } from './unsecured-service.interface';

@Injectable()
export class UnsecuredService implements IUnsecuredService {
  private endpointApi: Value;
  private unsecuredStatus$: BehaviorSubject<IUnsecuredStatus> = new BehaviorSubject({ isUnsecured: false, unsecuredBefore: null });

  constructor(
    private http: HttpClient,
    @Inject(IPAGE_SERVICE) private pageService: IPageService,
    @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService,
    @Inject(IAUTH_SERVICE) private authService: IAuthService
  ) {
    this.endpointApi = this.envService.getVar('services.api');
    const userData = this.authService.getUserData();
    this.setUnsecuredStatus(userData.unsecured_enabled, userData.unsecured_before_at, false);
  }

  get isUnsecuredValue(): boolean {
    return this.unsecuredStatus$.getValue().isUnsecured;
  }

  getUnsecuredStatus(): Observable<IUnsecuredStatus> {
    return this.unsecuredStatus$.asObservable();
  }

  setUnsecuredStatus(isUnsecured: boolean, unsecuredBefore = null, refresh = true): void {
    this.unsecuredStatus$.next({ isUnsecured, unsecuredBefore });
    if (refresh) {
      this.pageService.refresh();
    }
    if (isUnsecured && !unsecuredBefore) {
      this.authService.getUser().subscribe((data) => {
        this.unsecuredStatus$.next({ isUnsecured: data.unsecured_enabled, unsecuredBefore: data.unsecured_before_at });
      });
    }
  }

  enable(token_2fa: string, section: string): Observable<any> {
    return this.http.post(`${this.endpointApi}/unsecured/enable`, { token_2fa, section });
  }

  leave(): Observable<any> {
    return this.http.post(`${this.endpointApi}/unsecured/leave`, {});
  }
}
