import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { tours } from './tours/tours.constants';
import { TourProgressService } from './tour-progress.service';
import { IEVENT_SERVICE, IEventsService } from '../../../core/services/events/events.interface';
import { TourService } from './tour.service';
import { DestroySubscribers } from '../../../core/decorators/destroy-subscribers.decorator';

@Component({
  selector: 'gtd-tour-board',
  templateUrl: './tour-board.component.html',
  providers: [TourService, TourProgressService],
})
@DestroySubscribers()
export class TourBoardComponent implements OnInit, OnDestroy {
  public sectionData: any;
  public stepsDone = [];
  public isLastStep = false;
  public isStepShown = false;
  public isTourGoing = false;
  public toursData = tours;
  private subscribers: any = {};

  constructor(
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    private tourService: TourService,
    public progressService: TourProgressService
  ) {}

  ngOnInit(): void {
    this.subscribers.currentSection = this.tourService.tourCurrentSectionStream.subscribe((section) => {
      this.sectionData = section;
      this.stepsDone = [];
    });
    this.subscribers.currentStep = this.tourService.tourCurrentStepStream.subscribe((step: any) => {
      this.isStepShown = true;
      if (!this.stepsDone.includes(step.selector)) {
        this.stepsDone = [...this.stepsDone, step.selector];
      }
      this.isLastStep = step.last;
    });
    this.subscribers.afterStep = this.eventsService.on('tour:afterStep').subscribe(() => (this.isStepShown = false));
  }

  openTour(tourKey: string): void {
    this.sectionData = null;
    this.stepsDone = [];
    this.isLastStep = false;
    this.isStepShown = false;

    this.isTourGoing = true;
    this.tourService.startGuidedTour(tourKey);
  }

  next(): void {
    this.tourService.nextStep();
  }

  closeTour(): void {
    this.tourService.stopTour();
    this.isTourGoing = false;
  }

  closeTourBoard(): void {
    this.eventsService.broadcast('docs:open');
  }

  ngOnDestroy(): void {
    this.closeTourBoard();
  }
}
