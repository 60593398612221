import { Inject, Pipe, PipeTransform } from '@angular/core';
import { IRbacService, IRBAC_SERVICE } from '../../services/rbac/rbac-service.interface';

@Pipe({
  name: 'canRbac',
  pure: true,
})
export class CanRbacPipe implements PipeTransform {
  constructor(@Inject(IRBAC_SERVICE) private rbacService: IRbacService) {}

  transform(value: string | string[]): boolean {
    return this.rbacService.can(value);
  }
}
