import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { IENVIRONMENT_SERVICE, Value, IEnvironmentService } from '../environment/environment.interface';
import { IConfigurationService, IConfigurationResponse, IConfigurationData } from './configuration-service.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService implements IConfigurationService {
  private api: Value;
  public configurationData: IConfigurationData = {
    isDemo: false,
    brand: {
      id: 0,
      icon: '',
      name: '',
      favicon: '',
      supportEmail: '',
      logo: {
        big: '',
        small: '',
        medium: '',
      },
      ouath_client_id: '',
      yandexMetrikaId: '',
      active: false,
      deactivation_reason: '',
      vapid_public: '',
    },
  };

  constructor(private http: HttpClient, @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService) {
    this.api = this.envService.getVar('services.api');
  }

  data(): IConfigurationData {
    return this.configurationData;
  }

  setData(configurationData: IConfigurationData): void {
    if (configurationData) {
      this.configurationData = configurationData;
    }
  }

  getDataFromServer(): Observable<IConfigurationData> {
    return this.http.get<IConfigurationResponse>(`${this.api}/configuration`).pipe(
      map((response) => {
        return response.data;
      }),
      share()
    );
  }
}
