import { ITour } from './tours.constants';
import { Orientation } from 'ngx-guided-tour';

export const setupTrafficProviderTour: ITour = {
  tourKey: 'setupTrafficProviderTour',
  tourName: 'Setup Traffic Provider',
  tourIcon: '/assets/new-img/tours/setup-traffic-provider-tour/icon.png',
  tourDescription: 'The system where the affiliates will be registered and get tracking links',
  tourSections: [
    {
      name: 'Setup Traffic Provider',
      commonTopic: 'Traffic Provider Section',
      description: `Let’s start - you need to connect your CRM with Traffic Provider - the system where the affiliates
                    will be registered and get tracking links for the offers. For example you can use Tracking Software by IREV.`,
      image: '/assets/new-img/tours/setup-traffic-provider-tour/tour1.png',
      tourChains: [
        {
          tourId: 'traffic-provider_menu-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section--menu-item .p500-ui-nav-section__title[data-title="Incoming Traffic"]',
              stepText: '1.1. Click on Incoming Traffic menu section',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'traffic-provider_item-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section__child[data-title="Traffic Providers"]',
              stepText: '1.2. Go to Traffic Provider page',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Setup Traffic Provider',
      commonTopic: 'Traffic Provider Section',
      description: `Let’s start - you need to connect your CRM with Traffic Provider - the system where the affiliates
                    will be registered and get tracking links for the offers. For example you can use Tracking Software by IREV.`,
      image: '/assets/new-img/tours/setup-traffic-provider-tour/tour2.png',
      tourChains: [
        {
          tourId: 'traffic-provider_plus-button',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '.p500-ui-table[data-tag="table_TrackingProvidersTable"] gtd-action-button button[p500-ui-circled-button]',
              stepText: '2.1. Click plus button',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'traffic-provider_fill-form',
          viewport: '.c-modal',
          steps: [
            {
              selector: 'gtd-action-form-modal .c-modal__title',
              stepText: '2.2. Fill in the Name and Description for the Traffic Provider and choose the Type',
              orientation: Orientation.Bottom,
            },
            {
              selector: 'gtd-action-form-modal .c-modal__footer .c-modal__action:nth-child(2)',
              stepText: '2.3. Submit this form',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Setup Traffic Provider',
      commonTopic: 'Traffic Provider Section',
      description: `Let’s start - you need to connect your CRM with Traffic Provider - the system where the affiliates
                    will be registered and get tracking links for the offers. For example you can use Tracking Software by IREV.`,
      image: '/assets/new-img/tours/setup-traffic-provider-tour/tour3.png',
      tourChains: [
        {
          tourId: 'traffic-provider_go-to-single',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '.p500-ui-table[data-tag="table_TrackingProvidersTable"] tbody tr:nth-child(1) td:nth-child(1) .c-link',
              stepText: '3.1. Now you need to set up new Traffic Provider to connect it to your CRM. Click on its ID',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'traffic-provider_api-connection',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '.p500-ui-tabs__tab:nth-child(2)',
              stepText: '3.2. Switch to API Connection tab',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Setup Traffic Provider',
      commonTopic: 'Traffic Provider Section',
      description: `Let’s start - you need to connect your CRM with Traffic Provider - the system where the affiliates
                    will be registered and get tracking links for the offers. For example you can use Tracking Software by IREV.`,
      image: '/assets/new-img/tours/setup-traffic-provider-tour/tour3.png',
      tourChains: [
        {
          tourId: 'traffic-provider_edit-api-settings',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '*[data-tag="traffic-provider_edit-api-settings"]',
              stepText: '4.1. Click on Edit Api Settings button',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'traffic-provider__api-settings-form',
          viewport: '.c-modal',
          steps: [
            {
              selector: 'gtd-api-settings-modal .c-modal__title',
              stepText: `4.2. Fill in the necessary API settings. You can get them on the site of the Traffic Provider you use.
                        If everything is correct - you can see the offers and the affiliates from your Traffic Provider in the filters
                        and CRM sections while working`,
              orientation: Orientation.Bottom,
            },
            {
              selector: 'gtd-api-settings-modal .c-modal__footer .c-modal__action:nth-child(2)',
              stepText: '4.3. Submit form',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
  ],
};
