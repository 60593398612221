<div class="c-menu-list" [ngClass]="cssClass">
  <div class="c-menu-list__title">Settings</div>
  <ul class="c-menu-list__content">
    <li class="c-menu-list__item">
      <a [routerLink]="'/app/settings/page'" class="c-menu-list__link">
        <p500-ui-icon size="18px" name="workflow" className="text-color--primary mr-16"></p500-ui-icon>
        Definitions
      </a>
    </li>
  </ul>
</div>
