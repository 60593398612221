import { IEnvironment, PROD_API } from './environment.interface';

export const environment: IEnvironment = {
  name: 'production-test',
  production: true,
  services: {
    api: PROD_API,
  },
  sentryDsn: null,
  // sentryDsn: SENTRY_DSN,
};
