import { Tabs } from './tabs.interface';

const tabs: Tabs = {
  groups: {
    Incidents: {
      subTabs: [
        {
          Default: null,
        },
      ],
    },
    Notifications: {
      subTabs: [
        {
          Master: null,
        },
      ],
    },
  },
  activeTabTitle: null,
  activeSubTabTitle: null,
};

export { tabs };
