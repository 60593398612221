import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { P500UIButtonsModule, P500UIIconsModule, P500UIPipesModule } from '@platform500services/p500-ui-kit';
import { ActionButtonModule } from '../actions/action-button/action-button.module';
import { FieldsModule } from '../fields/fields.module';
import { NotificationsMenuComponent } from './notifications-menu.component';
import { IncidentsDataService } from './services/incidents/incidents-data.service';
import { IINCIDENTS_SERVICE } from './services/incidents/incidents-data.interface';
import { NotificationsApplicationService } from './services/notifications/notifications.service';
import { AlertService } from './services/alerts/alert.service';
import { IALERT_SERVICE } from './services/alerts/alert.interfaces';
import { INOTIFICATIONS_APPLICATION_SERVICE } from './services/notifications/notifications-service.interface';

@NgModule({
  imports: [CommonModule, P500UIIconsModule, P500UIPipesModule, RouterModule, P500UIButtonsModule, ActionButtonModule, FieldsModule],
  declarations: [NotificationsMenuComponent],
  exports: [NotificationsMenuComponent],
  providers: [
    {
      provide: INOTIFICATIONS_APPLICATION_SERVICE,
      useClass: NotificationsApplicationService,
    },
    {
      provide: IINCIDENTS_SERVICE,
      useClass: IncidentsDataService,
    },
    {
      provide: IALERT_SERVICE,
      useClass: AlertService,
    },
  ],
})
export class NotificationsMenuModule {}
