<span class="c-copyright">
  Copyright&nbsp;&nbsp;
  <img src="assets/new-img/logo/favicon.svg" alt="logo" width="14" />
  &nbsp;&nbsp;IREV, {{ year }}.&nbsp;
  <span class="d-none d-sm-inline-block">All Rights Reserved</span>
  .&nbsp;
  <span>
    Version: <a routerLink="/app/changelog">{{ appVersion.version || (appVersion.date | date: 'dd.MM.yyyy') }}</a>
  </span>
</span>
