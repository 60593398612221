import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, concat, Observable, Subject } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { IFilterValues, IOrderValues, ITableService, ITABLE_SERVICE } from '../../../services/tables/interfaces/table.service.interface';
import { IColumn, IFilter, ITableData, ITableDataResponse } from './data.response';
import { ActivatedRoute } from '@angular/router';
import { IDATA_SERVICE, IDataService } from '../../../services/data/data-service.interface';
import { IFacadeService } from './facade-service.interface';

@Injectable()
export class FacadeService implements IFacadeService {
  private page$: BehaviorSubject<number> = new BehaviorSubject(1);
  private orderValues$: BehaviorSubject<IOrderValues> = new BehaviorSubject({});
  private filterValues$: Subject<IFilterValues> = new Subject();
  private limit$: Subject<number> = new Subject();

  private tableKey: string;
  private tableViewAlias: string;

  constructor(
    @Inject(LocalStorage) private localStorage: LocalStorage,
    @Inject(ITABLE_SERVICE) private tableService: ITableService,
    @Inject(IDATA_SERVICE) private dataService: IDataService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      if (params.name) {
        const tableView = this.dataService.getData().table_views.find((view) => view.alias === params.name);
        if (tableView) {
          this.tableViewAlias = tableView.alias;
        }
      }
    });
  }

  init(
    key: string,
    defaultFilters: IFilterValues = {},
    scopeValues: { [key: string]: any },
    routeFilters: { [key: string]: string[] },
    routeLimit: number
  ): Observable<ITableData> {
    this.tableKey = key;
    const page$ = this.page$.asObservable();
    const limit$ = concat(
      this.localStorage.getItem('display_limit_' + this.tableKey).pipe(map((value: number) => routeLimit || Number(value) || 25)),
      this.limit$.asObservable()
    );
    const orderValues$ = this.orderValues$.asObservable();
    const filterValues$ = concat(
      this.localStorage.getItem('filters_' + this.tableKey + this.getStorageKeyAlias()).pipe(
        map((localFilterData: {}) => {
          return Object.keys(routeFilters).length ? { ...routeFilters, ...defaultFilters } : { ...localFilterData, ...defaultFilters };
        })
      ),
      this.filterValues$.asObservable().pipe(map((filters) => (filters && Object.keys(filters).length !== 0 ? filters : {})))
    );
    return combineLatest([page$, filterValues$, limit$, orderValues$]).pipe(
      switchMap(([page, filterValues, limit, orderValues]) =>
        this.tableService.getData(key, page, limit, filterValues, orderValues, scopeValues).pipe(
          catchError(() => {
            return this.localStorage.removeItem('filters_' + this.tableKey + this.getStorageKeyAlias()).pipe(map(() => null));
          }),
          filter((data) => !!data),
          map((data: ITableDataResponse) => {
            const total = !isNaN(data.total) ? Number(data.total) : Infinity;
            const sortedFilters = this.sortingFilters(data.filters, data.columns);

            return { ...data, filters: sortedFilters, filterValues, total: Math.ceil(total) };
          })
        )
      )
    );
  }

  onChangeFilters(filterValues: IFilterValues) {
    this.filterValues$.next(filterValues);
    this.localStorage.setItem('filters_' + this.tableKey + this.getStorageKeyAlias(), filterValues).subscribe(() => {});
  }

  onChangeLimit(limit: number) {
    this.localStorage.setItem('display_limit_' + this.tableKey, limit).subscribe(() => {});
    this.limit$.next(limit);
  }

  onChangePage(page: number) {
    this.page$.next(page);
  }

  onChangeSort(sort: IOrderValues) {
    this.orderValues$.next(sort);
  }

  getStorageKeyAlias(): string {
    return this.tableViewAlias ? '_' + this.tableViewAlias : '';
  }

  private sortingFilters(filters: IFilter[], columns: IColumn[]): IFilter[] {
    return [...filters].sort(({ id: aID }, { id: bID }) => {
      const aIndex = columns.findIndex((column) => column.id === aID);
      const bIndex = columns.findIndex((column) => column.id === bID);
      if (aIndex === -1) {
        return 1;
      } else if (bIndex === -1) {
        return -1;
      } else if (aIndex > bIndex) {
        return 1;
      } else if (aIndex < bIndex) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}
