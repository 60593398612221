import { Component, HostBinding } from '@angular/core';
import { AppSelectorBaseComponent } from '../base/app-selector-base';

@Component({
  selector: 'gtd-selector-carousel-header',
  templateUrl: './app-selector-carousel-header.component.html',
})
export class AppSelectorCarouselHeaderComponent extends AppSelectorBaseComponent {
  @HostBinding('class') className = 'c-sidebar__header';
}
