import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IENVIRONMENT_SERVICE, Value, IEnvironmentService } from '../environment/environment.interface';
import { IDataService, IDataResponse, IParsedDataResponse } from './data-service.interface';
import { DataServiceConstants } from './data.constants';

@Injectable()
export class DataService implements IDataService {
  private dataApi: Value;
  private data: any;
  private dataServiceConstants = DataServiceConstants;

  constructor(private http: HttpClient, @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService) {
    this.dataApi = this.envService.getVar('services.api');
  }

  setData(data: any): void {
    this.data = data;
  }

  parseData(origin: IDataResponse): IParsedDataResponse {
    const data: IParsedDataResponse = {
      version: 'local',
      unsecured_mode_disabled: 'no',
      unpaid_client: 'no',
      unpaid_client_timeout: '20',
      languages: {},
      countries: {},
      types: {},
      n_countries: {},
      currencies: {},
      timezones: {},
      public_ip: '',
      default_leads_workflow_state_for_injections: '',
      selects: {
        ab: {},
        api_tokens_phone_format: {},
        leads_workflow_events: {},
        leads_workflow_states: {},
        leads_workflow_states_for_pixels: {},
        leads_workflow_states_for_injections: {},
        leads_workflow_states_colors: {},
        go_tracks: {},
        funnel_domains: {},
        tracking_providers: {},
        funnels: {},
        brands: {},
        events_log_namespaces: [],
        affiliates: {},
        injection_lists: {},
        getAffiliates: () => ({}),
        all_affiliates: {},
        all_n_affiliates: {},
        all_n_affiliates_no_id: {},
        affiliates_no_id: {},
        getAffiliatesNoId: () => ({}),
        all_affiliates_no_id: {},
        companies: {},
        offers: {},
        getOffers: () => ({}),
        all_offers: {},
        all_n_offers: {},
        roles: {},
        permissions: [],
        mapped_sale_statuses: {},
        rejection_reasons: {},
        sms_templates_language_groups: {},
        push_templates_language_groups: {},
        email_templates_language_groups: {},
        follow_up_events: {},
        incidents: {
          severities: {},
          statuses: {},
        },
        invoice_statuses: {},
        master_funnels: {},
        leads_workflow_types: {},
        terms_format: {},
        lead_workflows: {},
        leads_workflow_forms: {},
        leads_workflow_profile_configurations: {},
        leads_profile_configurations: {},
        leads_workflow_pulling_mapping_types: {},
        leads_workflow_goal_types: {},
        payout_tiers: {},
        revenue_tiers: {},
        sale_statuses_string: {},
        terms: {},
        traffic_collector_states: {},
        traffic_collectors: {},
        advertiser_business_models: {},
        crm_notification_events: {},
        autologin_types: {},
        executed_postback_statuses: {},
        executed_risk_statuses: {},
      },
      integrations: [],
      integration_providers: {
        'email-collector-provider': {},
        'brand-provider': {},
        'traffic-provider': {},
        'traffic-collector': {},
      },
      providers: {
        brands: {},
      },
      table_views: [],
      advertiser_default_business_model: '',
      oauth: {
        slack: {
          client_id: '',
          redirect_uri: '',
        },
      },
      agreement_status: {},
      leads_table_refresh: 0,
    };

    data.selects.autologin_types = this.dataServiceConstants.autologinTypes;

    data.countries = this.dataServiceConstants.countries;
    data.n_countries = JSON.parse(JSON.stringify(this.dataServiceConstants.countries));
    delete data.n_countries['**'];

    data.types = this.dataServiceConstants.types;
    data.languages = this.dataServiceConstants.languages;
    data.currencies = this.dataServiceConstants.currencies;

    for (const i in this.dataServiceConstants.timezones) {
      if (this.dataServiceConstants.timezones.hasOwnProperty(i)) {
        data.timezones[i] = this.dataServiceConstants.timezones[i];
      }
    }

    if (origin.data.providers && origin.data.providers.brands) {
      for (const i in origin.data.providers.brands) {
        if (origin.data.providers.brands) {
          data.providers.brands[i] = origin.data.providers.brands[i];
        }
      }
    }

    const allNoAffiliatesValue = 'All/No Affiliates';
    const allNoOffersValue = 'All/No Offers';

    data.unsecured_mode_disabled = origin.data.unsecured_mode_disabled;
    data.unpaid_client = origin.data.unpaid_client;
    data.unpaid_client_timeout = origin.data.unpaid_client_timeout;

    data.selects.affiliates = origin.data.selects.affiliates;
    data.selects.all_affiliates[''] = allNoAffiliatesValue;
    data.selects.all_affiliates_no_id[''] = allNoAffiliatesValue;

    for (const [trackingProviderId, affiliates] of Object.entries(data.selects.affiliates)) {
      for (const [affiliateId, affiliateName] of Object.entries(affiliates)) {
        data.selects.affiliates[trackingProviderId][affiliateId] = affiliateName + ' / ' + affiliateId;

        if (data.selects.affiliates_no_id[trackingProviderId] === undefined) {
          data.selects.affiliates_no_id[trackingProviderId] = {};
        }
        data.selects.affiliates_no_id[trackingProviderId][affiliateId] = affiliateName;

        const nAff = data.selects.all_n_affiliates[affiliateId];
        data.selects.all_n_affiliates[affiliateId] = affiliateName + ' / ' + (nAff ? nAff : affiliateId);

        const nAffNoId = data.selects.all_n_affiliates_no_id[affiliateId];
        data.selects.all_n_affiliates_no_id[affiliateId] = affiliateName + ' / ' + (nAffNoId ? nAffNoId : '');

        const aff = data.selects.all_affiliates[affiliateId];
        data.selects.all_affiliates[affiliateId] = affiliateName + ' / ' + (aff ? aff : affiliateId);

        const affNoId = data.selects.all_affiliates_no_id[affiliateId];
        data.selects.all_affiliates_no_id[affiliateId] = affiliateName + ' / ' + (affNoId ? affNoId : '');
      }

      data.selects.affiliates[trackingProviderId][''] = allNoAffiliatesValue;
      data.selects.affiliates_no_id[trackingProviderId][''] = allNoAffiliatesValue;
    }

    data.selects.getAffiliates = (trackingProviderId) =>
      data.selects.affiliates[trackingProviderId] ? data.selects.affiliates[trackingProviderId] : { '': allNoAffiliatesValue };

    data.selects.getAffiliatesNoId = (trackingProviderId) =>
      data.selects.affiliates_no_id[trackingProviderId] ? data.selects.affiliates[trackingProviderId] : { '': allNoAffiliatesValue };

    data.selects.getOffers = (trackingProviderId) =>
      data.selects.offers[trackingProviderId] ? data.selects.offers[trackingProviderId] : { '': allNoOffersValue };

    data.selects.offers = origin.data.selects.offers;
    data.selects.injection_lists = Object.entries(origin.data.selects.injection_lists).reduce(
      (acc, [id, name]) => Object.assign(acc, { [id]: `${name} / ${id}` }),
      {}
    );
    data.selects.all_offers[''] = allNoOffersValue;

    for (const [trackingProviderId, offers] of Object.entries(data.selects.offers)) {
      for (const [offerId, offerName] of Object.entries(offers)) {
        data.selects.offers[trackingProviderId][offerId] = offerName + ' / ' + offerId;

        const offer = data.selects.all_offers[offerId];
        data.selects.all_offers[offerId] = offerName + ' / ' + (offer ? offer : offerId);

        const n_offer = data.selects.all_n_offers[offerId];
        data.selects.all_n_offers[offerId] = offerName + ' / ' + (n_offer ? n_offer : offerId);
      }

      data.selects.offers[trackingProviderId][''] = allNoOffersValue;
    }

    const PROVIDER_TYPES = ['brand-provider', 'traffic-provider', 'traffic-collector', 'email-collector-provider'];
    if (origin.data.integrations && origin.data.integrations.length) {
      PROVIDER_TYPES.forEach((type) => {
        origin.data.integrations
          .filter((provider) => {
            return provider.type === type;
          })
          .forEach((provider) => {
            data.integration_providers[type][provider.id] = provider.name;
          });
      });
    }

    data.oauth.slack.client_id = origin.data.oauth?.slack?.client_id ?? '';
    data.oauth.slack.redirect_uri = origin.data.oauth?.slack?.redirect_uri ?? '';
    data.selects.ab = origin.data.selects.ab;
    data.selects.api_tokens_phone_format = origin.data.selects.api_tokens_phone_format;
    data.selects.leads_workflow_events = origin.data.selects.leads_workflow_events;
    data.selects.leads_workflow_states = origin.data.selects.leads_workflow_states;
    data.selects.leads_workflow_states_for_pixels = origin.data.selects.leads_workflow_states_for_pixels;
    data.selects.leads_workflow_states_for_injections = origin.data.selects.leads_workflow_states_for_injections;
    data.selects.leads_workflow_states_colors = origin.data.selects.leads_workflow_states_colors;
    data.selects.go_tracks = origin.data.selects.go_tracks;
    data.selects.roles = origin.data.selects.roles;
    data.selects.funnels = origin.data.selects.funnels;
    data.selects.funnel_domains = origin.data.selects.funnel_domains;
    data.selects.brands = origin.data.selects.brands;
    data.selects.companies = origin.data.selects.companies;
    data.selects.tracking_providers = origin.data.selects.tracking_providers;
    data.selects.permissions = origin.data.selects.permissions;
    data.selects.mapped_sale_statuses = origin.data.selects.mapped_sale_statuses;
    data.selects.rejection_reasons = origin.data.selects.rejection_reasons;
    data.selects.sms_templates_language_groups = origin.data.selects.sms_templates_language_groups;
    data.selects.push_templates_language_groups = origin.data.selects.push_templates_language_groups;
    data.selects.email_templates_language_groups = origin.data.selects.email_templates_language_groups;
    data.selects.events_log_namespaces = origin.data.selects.events_log_namespaces;
    data.selects.follow_up_events = origin.data.selects.follow_up_events;
    data.selects.incidents = origin.data.selects.incidents;
    data.selects.invoice_statuses = origin.data.selects.invoice_statuses;
    data.selects.leads_workflow_types = origin.data.selects.leads_workflow_types;
    data.selects.terms_format = origin.data.selects.terms_format;
    data.selects.leads_workflow_forms = origin.data.selects.leads_workflow_forms;
    data.selects.leads_workflow_profile_configurations = origin.data.selects.leads_workflow_profile_configurations;
    data.selects.leads_profile_configurations = origin.data.selects.leads_profile_configurations;
    data.selects.leads_workflow_pulling_mapping_types = origin.data.selects.leads_workflow_pulling_mapping_types;
    data.selects.leads_workflow_goal_types = origin.data.selects.leads_workflow_goal_types;
    data.selects.payout_tiers = origin.data.selects.payout_tiers;
    data.selects.revenue_tiers = origin.data.selects.revenue_tiers;
    data.selects.terms = origin.data.selects.terms;
    data.selects.lead_workflows = origin.data.selects.lead_workflows;
    data.selects.traffic_collector_states = origin.data.selects.traffic_collector_states;
    data.selects.sale_statuses_string = origin.data.selects.sale_statuses_string;
    data.selects.traffic_collectors = origin.data.selects.traffic_collectors;
    Object.keys(origin.data.selects.terms).forEach((value) => {
      data.selects.terms[value].id = Number(value);
    });
    data.selects.advertiser_business_models = origin.data.selects['advertiser-business-models'];
    data.selects.crm_notification_events = origin.data.selects.crm_notification_events;
    data.version = origin.data.version;
    data.integrations = origin.data.integrations;
    data.table_views = origin.data['table-views'];
    data.advertiser_default_business_model = origin.data['advertiser-default-business-model'];
    data.public_ip = origin.data.public_ip;
    data.default_leads_workflow_state_for_injections = origin.data.default_leads_workflow_state_for_injections;
    data.agreement_status = origin.data.agreement_status ? origin.data.agreement_status : {};
    data.selects.executed_risk_statuses = origin.data.selects.executed_risk_statuses;
    data.selects.executed_postback_statuses = origin.data.selects.executed_postback_statuses;
    data.leads_table_refresh = Math.abs(origin.data.leads_table_refresh) || 0;

    return data;
  }

  getDataFromServer(): Observable<any> {
    return this.http.get(`${this.dataApi}/data`).pipe(
      map((response: any) => {
        return this.parseData(response);
      })
    );
  }

  getData(): IParsedDataResponse {
    return this.data;
  }
}
