import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickModeModal } from './quick-mode-modal.component';
import { P500TabsUIModule, P500UIButtonsModule, P500UIIconsModule, P500UIDirectivesModule } from '@platform500services/p500-ui-kit';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [QuickModeModal],
  imports: [CommonModule, P500UIButtonsModule, P500UIIconsModule, RouterModule, P500TabsUIModule, P500UIDirectivesModule],
  exports: [QuickModeModal],
})
export class QuickModeModalModule {}
