import { InjectionToken } from '@angular/core';

type Value = string | number | boolean | object;

interface IEnvironmentService {
  getVars(): any;
  getVar(key: string): Value;
  getHost(): string;
}

const IENVIRONMENT_SERVICE = new InjectionToken<IEnvironmentService>('IEnvironmentService');

export { Value, IEnvironmentService, IENVIRONMENT_SERVICE };
