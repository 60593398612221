<div class="c-sidebar__logo">
  <div class="c-sidebar__logo-inner">
    <div class="c-sidebar__logo-text">
      <div class="c-sidebar__logo-title" p500UITooltip [tooltipData]="header?.title">{{ header?.title }}</div>
      <div class="c-selector__el-subtitle">{{ header?.subtitle }}</div>
    </div>

    <button class="c-button c-sidebar__logo-toggle" (click)="toggleMenu()" type="button">
      <p500-ui-icon [name]="isShowMenu ? 'arrows-bold-up' : 'arrows-bold-down'"></p500-ui-icon>
    </button>
  </div>

  <div class="c-sidebar__logo-menu" [class.-isShow]="isShowMenu">
    <gtd-ui-today-data></gtd-ui-today-data>
  </div>
  <div class="c-sidebar__logo-changelog">
    <gtd-changelog-btn></gtd-changelog-btn>
  </div>
</div>
<div class="c-sidebar__selector c-sidebar__mobile">
  <p500-ui-applications-selector-mobile
    class="c-sidebar__application-mobile"
    [applications]="applications"
    [header]="header"
  ></p500-ui-applications-selector-mobile>

  <div class="c-sidebar__logo-menu -isShow">
    <gtd-ui-today-data></gtd-ui-today-data>
    <div class="c-sidebar__logo-changelog">
      <gtd-changelog-btn></gtd-changelog-btn>
    </div>
  </div>
</div>
