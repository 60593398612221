import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IFilterValues, IOrderValues } from '../../../services/tables/interfaces/table.service.interface';
import { ITableData } from './data.response';

interface IFacadeService {
  init(
    key: string,
    defaultFilters: IFilterValues,
    scopeValues: {
      [key: string]: any;
    },
    routeParams: {
      [key: string]: string[];
    },
    routeLimit: number
  ): Observable<ITableData>;

  onChangeFilters(filterValues: IFilterValues): void;

  onChangeLimit(limit: number): void;

  onChangePage(page: number): void;

  onChangeSort(sort: IOrderValues): void;
}

const IFACADE_SERVICE = new InjectionToken<IFacadeService>('IFacadeService');

export { IFacadeService, IFACADE_SERVICE };
