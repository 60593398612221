import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, NgModuleRef, OnInit, TemplateRef } from '@angular/core';
import { IRbacService, IRBAC_SERVICE } from '../../services/rbac/rbac-service.interface';
import { IEventsService, IEVENT_SERVICE } from '../../services/events/events.interface';
import { INotification } from '../notifications-menu/services/notifications/notifications-service.interface';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';
import { IFilterValues } from '../../services/tables/interfaces/table.service.interface';
import { Observable, Subject } from 'rxjs';
import { IMODALS_SERVICE, IModalsService } from '@platform500services/p500-ui-kit';
import { NotificationsModal } from '../../modals/notifications-modal/notifications.modal';
import { Tabs } from './tabs.interface';
import { tabs } from './notification.helper';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NotificationsService } from './notifications-service/notifications.service';
import { getLinkMsg, isAffiliateType } from '../../helpers/notification-msg.helper';
import { RbacPermissionsConstant } from '../../services/rbac/rbac-permissions.constant';

@Component({
  selector: 'gtd-notifications-sidebar',
  templateUrl: './notifications-sidebar.component.html',
  providers: [NotificationsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroySubscribers()
export class NotificationsSidebarComponent implements OnInit {
  @Input() cssClass = '';
  public notifications$: Observable<INotification[]>;
  public page = 1;
  public isSettingLoaded = false;
  public tabs = tabs;
  public limit: number;
  /**
   * TODO: refac local storage
   * Need change storage key if you rename old tabs or add new tabs
   */
  private localStorageTabsKey = 'notifications:tabs';
  public RbacPermissionsConstant = RbacPermissionsConstant;
  public getLinkMsg = getLinkMsg;
  public isAffiliateType = isAffiliateType;
  private click$ = new Subject();

  constructor(
    @Inject(IRBAC_SERVICE) public rbacService: IRbacService,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    @Inject(IMODALS_SERVICE) private modalsService: IModalsService,
    @Inject(LocalStorage) private localStorage: LocalStorage,
    private notificationsService: NotificationsService,
    private cd: ChangeDetectorRef,
    private moduleRef: NgModuleRef<any>
  ) {
    this.limit = this.notificationsService.limit;
  }

  handleClick(event: MouseEvent): void {
    event.stopPropagation();
    this.click$.next();
  }

  close() {
    this.eventsService.broadcast('isOpenNotificationsSidebar:toggle');
  }

  onPageChange(page: number): void {
    this.page = page;
    this.isMasterTab ? this.notificationsService.notificationsApplicationService.onChangePage(page) : this.getNotificationsData(page);
  }

  setFilterById(id: number[]): void {
    let filters: IFilterValues = { id };

    if (id.length === 0) {
      filters = {};
    }

    if (this.isMasterTab) {
      this.notificationsService.notificationsApplicationService.onChangePage(this.page);
      this.notificationsService.notificationsApplicationService.onFilterChange(filters);
    } else {
      this.getNotificationsData(1, filters);
    }
  }

  getNotificationsData(page = 1, filters: IFilterValues = {}): void {
    this.notifications$ = this.notificationsService.getNotificationsData(this.activeTab, page, filters);
    this.localStorage.setItem(this.localStorageTabsKey, this.tabs).subscribe();
  }

  get mainTabs(): string[] {
    return Object.entries(this.tabs.groups).reduce((acc, [key, value]) => (value.subTabs?.length ? [...acc, key] : acc), []);
  }

  get subTabs(): string[] {
    return this.tabs.groups[this.tabs.activeTabTitle]?.subTabs.map((key) => Object.keys(key)[0]);
  }

  get isLoading(): boolean {
    return this.notificationsService.isLoading;
  }

  get totalItems(): number {
    return this.notificationsService.totalItems;
  }

  private get activeTab(): { [key: string]: string } {
    return { [this.tabs.activeTabTitle]: this.tabs.activeSubTabTitle[this.tabs.activeTabTitle] };
  }

  private get isMasterTab(): boolean {
    return this.activeTab?.Notifications === 'Master';
  }

  openNotificationsModal(
    title: string,
    id: number,
    time: number,
    message: string,
    template: TemplateRef<any>,
    linkId: number,
    userName: string
  ): void {
    this.modalsService.open(
      NotificationsModal,
      {
        title,
        id,
        time,
        message,
        template,
        linkId,
        userName,
      },
      this.moduleRef
    );
  }

  ngOnInit(): void {
    this.localStorage.getItem(this.localStorageTabsKey).subscribe((tableTabs: Tabs) => {
      if (tableTabs?.activeTabTitle && tableTabs?.activeSubTabTitle) {
        this.tabs.activeTabTitle = tableTabs.activeTabTitle;
        this.tabs.activeSubTabTitle = tableTabs.activeSubTabTitle;
      } else {
        this.tabs.activeTabTitle = Object.keys(this.tabs.groups)[0];
        this.tabs.activeSubTabTitle = Object.entries(this.tabs.groups).reduce((acc, [key, { subTabs }]) => {
          acc[key] = subTabs?.length ? Object.keys(subTabs[0])[0] : {};
          return acc;
        }, {});
      }

      /**
       * check permissions in tabs
       */
      for (const key in this.tabs.groups) {
        if (key in this.tabs.groups) {
          const subTabs = this.tabs.groups[key].subTabs.filter((child) => this.rbacService.can(Object.values(child)[0]));
          subTabs.length ? (this.tabs.groups[key].subTabs = subTabs) : delete this.tabs.groups[key];
        }
      }

      this.getNotificationsData();
      this.isSettingLoaded = true;
      this.cd.detectChanges();
    });
  }
}
