import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, pluck } from 'rxjs/operators';
import { ITableService, IFilterValues } from '../../../services/tables/interfaces/table.service.interface';
import { ITableDataResponse } from '../../facade/service-facade/data.response';
import {
  INotificationsApplicationService,
  INotification,
} from '../../notifications-menu/services/notifications/notifications-service.interface';
import { INotifications } from './notifications.service.interface';

@Injectable()
export class NotificationsService implements INotifications {
  constructor(
    @Inject('INotificationsApplicationService') public notificationsApplicationService: INotificationsApplicationService,
    @Inject('ITableService') public tableService: ITableService
  ) {}

  public isLoading = true;
  public totalItems = 0;
  public limit = 5;

  getNotificationsData(activeTab: { [key: string]: string }, page = 1, filters: IFilterValues = {}): Observable<INotification[]> {
    this.isLoading = true;
    this.totalItems = 0;
    let notifications$: Observable<INotification[]>;

    if (activeTab.Incidents) {
      switch (activeTab.Incidents) {
        case 'Default':
          notifications$ = this.tableService.getData('IncidentsTable', page, this.limit, filters, {}).pipe(
            tap(({ total }) => (this.totalItems = total)),
            pluck<ITableDataResponse, INotification[]>('rows')
          );
          break;
      }
    }

    if (activeTab.Notifications) {
      switch (activeTab.Notifications) {
        case 'Master':
          notifications$ = this.notificationsApplicationService
            .getNotifications()
            .pipe(tap(() => (this.totalItems = this.notificationsApplicationService.getTotalPages())));
          break;
      }
    }
    return notifications$.pipe(tap(() => (this.isLoading = false)));
  }
}
