import { CommonModule } from '@angular/common';
import { CurrentTimeComponent } from './current-time.component';
import { NgModule } from '@angular/core';
import { P500UITooltipModule } from '@platform500services/p500-ui-kit';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule, P500UITooltipModule],
  declarations: [CurrentTimeComponent],
  exports: [CurrentTimeComponent],
})
export class CurrentTimeModule {}
