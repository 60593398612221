import { Component, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IConfigurationService, ICONFIGURATION_SERVICE } from '../../../core/services/configuration/configuration-service.interface';
import { IAUTH_SERVICE, IAuthService } from '../../../core/services/auth';
import { UserResponse } from '../../../core/services/auth/responses';
import { IEVENT_SERVICE, IEventsService } from '../../../core/services/events/events.interface';
import { IPAGE_SERVICE, IPageService } from '../../../core/services/page/page-service.interface';
import { RbacPermissionsConstant } from '../../../core/services/rbac/rbac-permissions.constant';
import { IRBAC_SERVICE, IRbacService } from '../../../core/services/rbac/rbac-service.interface';
import { Title } from '@angular/platform-browser';
import { DestroySubscribers } from '../../../core/decorators/destroy-subscribers.decorator';

@Component({
  selector: 'gtd-top-bar',
  templateUrl: './top-bar.component.html',
})
@DestroySubscribers()
export class TopBarComponent implements OnDestroy {
  public user$: Observable<UserResponse>;
  public user: UserResponse;
  public mobileTitle = { name: '', id: 0 };
  private subscribers: any = {};

  public RbacPermissionsConstant = RbacPermissionsConstant;

  constructor(
    @Inject(IAUTH_SERVICE) private authService: IAuthService,
    @Inject(IPAGE_SERVICE) private pageService: IPageService,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    @Inject(IRBAC_SERVICE) public rbacService: IRbacService,
    @Inject(ICONFIGURATION_SERVICE) public configurationService: IConfigurationService,
    @Inject(Title) public titleService: Title,
    public cd: ChangeDetectorRef,
    private router: Router
  ) {
    this.user = this.authService.getUserData();
    this.subscribers.changeMobileTitle = this.eventsService.on('mobileTopbarTitle:change').subscribe((data: any) => {
      if (data) {
        this.mobileTitle = {
          id: data.id ? data.id : 0,
          name: data.name,
        };
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.cd.detach();
  }

  refresh() {
    this.pageService.refresh();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth/login'], { queryParams: { logout: true } });
  }

  toggleSidebar() {
    this.eventsService.broadcast('sidebarComponent:toggle');
  }
}
