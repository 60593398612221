import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ITableDataResponse } from '../../../components/facade/service-facade/data.response';

interface IFilterValues {
  [key: string]: any[];
}

interface IOrderValues {
  [key: string]: 'asc' | 'desc';
}

interface IVisibilityValues {
  [key: string]: boolean;
}

interface ITableService {
  getData(
    tableKey: string,
    page: number,
    limit: number,
    filters: IFilterValues,
    orderValues: IOrderValues,
    scope_values?: {
      [key: string]: any;
    }
  ): Observable<ITableDataResponse>;
}

interface TableBody {
  table: string;
  page: number;
  limit: number;
  filters: IFilterValues;
  order_values: IOrderValues;
  scope_values?: {
    [key: string]: any;
  };
}

const ITABLE_SERVICE = new InjectionToken<ITableService>('ITableService');

export { IFilterValues, IOrderValues, IVisibilityValues, ITableService, ITABLE_SERVICE, TableBody };
