export class UserResponse {
  constructor(
    public id: number,
    public fname: string,
    public lname: string,
    public login: string,
    public permissions: any,
    public unsecured_before_at: number,
    public unsecured_enabled: boolean,
    public slack_workspace: string,
    public logout: boolean
  ) {}

  /**
   * Processing from json
   */
  public static fromJson(json: any) {
    return new UserResponse(
      json.data.id,
      json.data.fname,
      json.data.lname,
      json.data.login,
      json.data.permissions,
      json.data.unsecured_before_at,
      json.data.unsecured_enabled,
      json.data.slack_workspace,
      json.data.logout
    );
  }
}
