import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'gtd-ui-field-default-string-or-empty',
  templateUrl: './string-or-empty.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StringOrEmptyComponent {
  @Input() data: any;
  @Input() customClass = '';
}
