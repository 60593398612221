import { InjectionToken } from '@angular/core';

interface IRbacService {
  can(permission: string | string[]): boolean;

  canAction(permission: string | string[]): boolean;

  canAtLeast(permissions: string[]): boolean;
}

const IRBAC_SERVICE = new InjectionToken<IRbacService>('IRbacService');

export { IRbacService, IRBAC_SERVICE };
