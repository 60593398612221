import { Component, Inject } from '@angular/core';
import { interval } from 'rxjs';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';
import { IUnsecuredService, IUnsecuredStatus, IUNSECURED_SERVICE } from '../../services/unsecured/unsecured-service.interface';

@Component({
  selector: 'gtd-unsecured-bar',
  templateUrl: './unsecured-bar.component.html',
})
@DestroySubscribers()
export class UnsecuredBarComponent {
  private subscribers: any = {};
  public isLoading: boolean;
  public unsecuredStatus: IUnsecuredStatus;
  public timeLeft: number;

  constructor(@Inject(IUNSECURED_SERVICE) public unsecuredService: IUnsecuredService) {
    this.init();
  }

  init(): void {
    this.isLoading = true;
    this.subscribers.status = this.unsecuredService.getUnsecuredStatus().subscribe((status) => {
      this.unsecuredStatus = status;
      if (status.isUnsecured && status.unsecuredBefore) {
        this.isLoading = false;
        this.clearTimer();
        this.updateTimer();
        this.subscribers.timer = interval(1000).subscribe(() => this.updateTimer());
      }
    });
  }

  updateTimer(): void {
    this.timeLeft = this.unsecuredStatus.unsecuredBefore - Date.now();
    if (this.timeLeft < 0 && this.unsecuredStatus.isUnsecured) {
      this.leaveMode();
    }
  }

  clearTimer(): void {
    if (this.subscribers.timer) {
      this.subscribers.timer.unsubscribe();
    }
    this.timeLeft = null;
  }

  leaveMode(): void {
    this.isLoading = true;
    this.subscribers.leaveMode = this.unsecuredService.leave().subscribe((response) => {
      if (response.data && response.data.user) {
        this.clearTimer();
        this.unsecuredService.setUnsecuredStatus(false);
      }
    });
  }
}
