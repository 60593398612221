import { UntypedFormControl } from '@angular/forms';
import { Type } from '@angular/core';

export class FieldModel<T = any> {
  public label: string;
  public key: string;
  public type: string;
  public formControl: UntypedFormControl;
  public settings: Partial<T>;
  public component: Type<T>;
  private initialValue: any;

  constructor(label: string, key: string, component: Type<T>, formControl?: UntypedFormControl, settings?: Partial<T>, type?: string) {
    this.label = label;
    this.key = key;
    this.type = type;
    this.component = component;
    this.formControl = formControl ?? new UntypedFormControl();
    this.settings = settings ?? {};
    this.initialValue = this.formControl.value;
  }

  getComponent(): ComponentModel<T> {
    return {
      settings: {
        ...this.settings,
        control: this.formControl,
        label: this.label,
        key: this.key,
      },
      component: this.component,
    };
  }

  getInitialValue() {
    return this.initialValue;
  }
}

export interface ComponentModel<T = any> {
  settings: Partial<T> & { key: string; control: UntypedFormControl; label: string };
  component: Type<T>;
}
