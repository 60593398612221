import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { P500UIButtonsModule, P500UIIconsModule } from '@platform500services/p500-ui-kit';
import { Auth2FAModalModule } from '../../modals/2fa/2fa-modal.module';
import { UnsecuredBarComponent } from './unsecured-bar.component';

@NgModule({
  imports: [CommonModule, P500UIButtonsModule, P500UIIconsModule, Auth2FAModalModule],
  declarations: [UnsecuredBarComponent],
  exports: [UnsecuredBarComponent],
})
export class UnsecuredBarModule {}
