import { Component, Input, Inject, NgModuleRef } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { LocalStorage } from '@ngx-pwa/local-storage';

import { IAUTH_SERVICE, IAuthService } from '../../../core/services/auth';
import { IEVENT_SERVICE, IEventsService } from '../../../core/services/events/events.interface';
import { IConfigurationService, ICONFIGURATION_SERVICE } from '../../../core/services/configuration/configuration-service.interface';
import { IUnsecuredService, IUNSECURED_SERVICE } from '../../services/unsecured/unsecured-service.interface';
import { Auth2FAModal } from '../../modals/2fa/2fa.modal';
import { IMODALS_SERVICE, IModalsService } from '@platform500services/p500-ui-kit';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';
import { IEnvironmentService, IENVIRONMENT_SERVICE } from '../../services/environment/environment.interface';

@Component({
  selector: 'gtd-profile-menu',
  templateUrl: './profile-menu.component.html',
})
@DestroySubscribers()
export class ProfileMenuComponent {
  @Input() cssClass = '';

  public tableModeControl = new UntypedFormControl(false);
  public unsecuredModeControl = new UntypedFormControl(false);
  public isDemo: boolean;
  public controlUrl = '';

  private subscribers: any = {};

  constructor(
    @Inject(IAUTH_SERVICE) public authService: IAuthService,
    private router: Router,
    @Inject(LocalStorage) private localStorage: LocalStorage,
    @Inject(ICONFIGURATION_SERVICE) public configurationService: IConfigurationService,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    @Inject(IUNSECURED_SERVICE) public unsecuredService: IUnsecuredService,
    @Inject(IMODALS_SERVICE) private modalsService: IModalsService,
    @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService,
    private moduleRef: NgModuleRef<any>
  ) {
    const host = this.envService.getHost();
    this.controlUrl = `https://control.${host}.com`;
    this.isDemo = this.configurationService.data().isDemo;

    this.subscribers.accountMode = this.localStorage.getItem('account-mode').subscribe((v) => this.tableModeControl.setValue(!!v));

    this.subscribers.unsecuredStatus = this.unsecuredService.getUnsecuredStatus().subscribe((status) => {
      this.unsecuredModeControl.setValue(status.isUnsecured, { emitEvent: false });
    });

    this.subscribers.tableMode = this.tableModeControl.valueChanges.subscribe((value) => {
      this.eventsService.broadcast('account-mode', value);
      this.localStorage.setItem('account-mode', value).subscribe(() => {});
    });

    this.subscribers.unsecuredMode = this.unsecuredModeControl.valueChanges.subscribe((value) => {
      if (value) {
        this.modalsService.open(Auth2FAModal, { source: 'Profile Panel' }, this.moduleRef);
        this.unsecuredModeControl.setValue(false);
      } else {
        this.unsecuredService.leave().subscribe((response) => {
          if (response.data && response.data.user) {
            this.unsecuredService.setUnsecuredStatus(false);
          }
        });
      }
    });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/auth/login'], { queryParams: { logout: true } });
  }
}
