import { Optional, Inject, Injectable } from '@angular/core';
import { IEnvironmentService, Value } from './environment.interface';
import { BrowserWindowRef } from '../window/window.service';
import { IENVIRONMENT, IEnvironment } from '../../../../environments/environment.interface';

@Injectable()
export class EnvironmentService implements IEnvironmentService {
  private readonly environment: any;

  constructor(@Optional() @Inject(IENVIRONMENT) environment: IEnvironment, @Inject(BrowserWindowRef) private window: BrowserWindowRef) {
    this.environment = environment !== null ? environment : {};
  }

  getVars() {
    return this.environment;
  }

  getVar(key: string): Value {
    const keys = key.split('.');

    return this.getValueByPath(keys);
  }

  getHost(): string {
    const isProduction = this.getVar('production') as boolean;
    const host = isProduction ? this.window.nativeWindow.location.host : (this.getVar('services.api') as string);
    const hostParsed = host.split('.');
    return hostParsed.length > 1 ? hostParsed[1] : hostParsed[0];
  }

  private getValueByPath(path: string[], env: object = this.environment): Value {
    const value = env[path[0]];

    if (value) {
      return path.length === 1 ? value : this.getValueByPath(path.slice(1), value);
    }

    return '';
  }
}
