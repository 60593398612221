import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nonNullableArray',
  pure: true,
})
export class NonNullableArrayPipe implements PipeTransform {
  transform<T>(value: Array<T>): Array<T> {
    return value?.filter((element) => element !== undefined && element !== null);
  }
}
