import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

interface IncidentsDataInterface {
  unresolved_count: number;
  incident: IIncident;
  event: string;
}

interface IIncident {
  id: number;
  employee: null[];
  related_id: number;
  related_type: string;
  resolution_message: string;
  resolved_at: null;
  status: string;
  severity: string;
  message: string;
  created_at_timestamp: number;
}

interface IDataIncidentsResponseInterface {
  incidents: IIncident[];
}

interface IIncidentsDataService {
  getIncidents(): Observable<IIncident[]>;
}

const IINCIDENTS_SERVICE = new InjectionToken<IIncidentsDataService>('IIncidentsDataService');

export { IIncident, IncidentsDataInterface, IIncidentsDataService, IINCIDENTS_SERVICE, IDataIncidentsResponseInterface };
