<div class="c-tour-board">
  <div class="c-tour-board__learning-center" *ngIf="!isTourGoing">
    <div class="c-tour-board__title">
      <p500-ui-icon name="arrows-big-left c-tour-board__back-btn" (click)="closeTourBoard()"></p500-ui-icon>
      Learning Center
    </div>
    <div class="c-tour-board__subtitle">{{ progressService.getFinishedToursNumber() }} / {{ toursData.length }} is done</div>
    <div class="c-tour-board__tours-list">
      <ng-container *ngFor="let tour of toursData">
        <div class="c-tour-board__tour-link" (click)="openTour(tour.tourKey)">
          <img [src]="tour.tourIcon" class="c-tour-board__tour-link-icon" *ngIf="tour.tourIcon" />
          <div class="c-tour-board__tour-link-title">{{ tour.tourName }}</div>
        </div>
        <div class="c-tour-board__tour-info">
          <div class="c-tour-board__tour-progress">
            <p500-ui-progress-ring [radius]="19" [strokeWidth]="2" [percent]="progressService.getProgressPercent(tour.tourKey)">
              {{ progressService.getFinishedSectionsNumber(tour.tourKey) }}/{{ progressService.getTotalSectionsNumber(tour.tourKey) }}
            </p500-ui-progress-ring>
          </div>
          {{ tour.tourDescription }}
        </div>
      </ng-container>
    </div>
  </div>

  <div class="c-tour-board__tour" *ngIf="isTourGoing">
    <div class="c-tour-board__head">
      <div class="c-tour-board__section-number">
        <span>{{ sectionData?.currentSectionNumber }}</span>
      </div>
      <div class="c-tour-board__section-title">{{ sectionData?.commonTopic }}</div>
    </div>
    <div class="c-tour-board__body">
      <div class="c-tour-board__tour-icon" *ngIf="sectionData?.image">
        <img [src]="sectionData?.image" />
      </div>
      <div class="c-tour-board__tour-title">{{ sectionData?.name }}</div>
      <div class="c-tour-board__tour-description" *ngIf="sectionData?.description">
        {{ sectionData?.description }}
      </div>
      <div class="c-tour-board__steps">
        <ng-container *ngFor="let tour of sectionData?.tourChains">
          <ng-container *ngFor="let step of tour.steps">
            <div class="c-tour-board__step" [class.-passed]="stepsDone.includes(step.selector)" [innerHTML]="step.stepText"></div>
          </ng-container>
        </ng-container>
      </div>
      <button
        p500-ui-button
        color="white"
        class="c-tour-board__button-next"
        [class.is-disabled]="!isStepShown"
        (click)="next()"
        *ngIf="!isLastStep"
      >
        Next Sub-Step
      </button>
    </div>
    <div class="c-tour-board__footer">
      <button p500-ui-button color="white" [stroked]="true" class="c-tour-board__button-complete" (click)="closeTour()">
        Complete Tour
      </button>
      <div class="c-tour-board__part">{{ sectionData?.currentSectionNumber }} &nbsp;/&nbsp; {{ sectionData?.totalSectionsNumber }}</div>
    </div>
  </div>
</div>
