import { IAlert, IAlertService } from './alert.interfaces';
import { Inject, Injectable } from '@angular/core';
import {
  INotification,
  INotificationsApplicationService,
  INOTIFICATIONS_APPLICATION_SERVICE,
} from '../notifications/notifications-service.interface';
import { IIncidentsDataService, IINCIDENTS_SERVICE } from '../incidents/incidents-data.interface';
import { combineLatest, Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { getLatestDate } from './alert.helpers';

const LAST_DATE_ALERT_KEY = 'lastDateAlerts';

@Injectable()
export class AlertService implements IAlertService {
  private alerts$: Observable<IAlert[]>;
  private isReadStatus$: Observable<boolean>;

  constructor(
    @Inject(INOTIFICATIONS_APPLICATION_SERVICE) private notificationsApplicationService: INotificationsApplicationService,
    @Inject(IINCIDENTS_SERVICE) private incidentsService: IIncidentsDataService,
    private storageMap: StorageMap
  ) {
    this.setAlerts();
    this.setIsRead();
  }

  private setAlerts() {
    const notificationAlerts$: Observable<IAlert[]> = this.notificationsApplicationService.getNotifications().pipe(
      map((notifications) =>
        notifications.map(
          (notification: INotification): IAlert => ({
            ...notification,
            isIncident: false,
          })
        )
      )
    );

    const incidentAlerts$: Observable<IAlert[]> = this.incidentsService
      .getIncidents()
      .pipe(map((data) => data.map((incident) => ({ ...incident, type: incident.severity, isIncident: true }))));

    this.alerts$ = combineLatest([notificationAlerts$, incidentAlerts$]).pipe(
      map(([notification, incidents]) =>
        incidents
          .concat(notification)
          .sort((alertA, alertB) =>
            alertA.created_at_timestamp === alertB.created_at_timestamp
              ? 0
              : alertA.created_at_timestamp > alertB.created_at_timestamp
              ? -1
              : 1
          )
      ),
      publishReplay(1),
      refCount()
    );
  }

  private setIsRead() {
    const lastAlertDate$ = this.alerts$.pipe(map(getLatestDate));
    const lastAlertDateLC$ = this.storageMap.watch(LAST_DATE_ALERT_KEY);

    this.isReadStatus$ = combineLatest([lastAlertDate$, lastAlertDateLC$]).pipe(
      map(([lastAlertsDate, lastAlertsDateLC]) => lastAlertsDate === lastAlertsDateLC)
    );
  }

  getAlerts(): Observable<IAlert[]> {
    return this.alerts$;
  }

  getReadStatus(): Observable<boolean> {
    return this.isReadStatus$;
  }

  updateReadStatus(date: number): void {
    this.storageMap.set(LAST_DATE_ALERT_KEY, date).subscribe();
  }
}
