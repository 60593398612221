import { ITour } from './tours.constants';
import { Orientation } from 'ngx-guided-tour';

export const guideTour: ITour = {
  tourKey: 'guideTour',
  tourName: 'Guide Tour',
  tourIcon: '/assets/new-img/tours/guide-tour/icon.png',
  tourDescription: 'First tour of navigation in your CRM and proper generation reports',
  tourSections: [
    {
      name: 'Work with Tables',
      commonTopic: 'Table Section',
      description: `This CRM system has the following types of pages: table, element page, dashboard, report, tools.
                    To get acquainted with the tables, please go to the menu Traffic Distribution on the page Advertisers.`,
      image: '/assets/new-img/tours/guide-tour/tour1.png',
      tourChains: [
        {
          tourId: 'tables_menu-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section--menu-item .p500-ui-nav-section__title[data-title="Traffic Distribution"]',
              stepText: '1.1. Click on Traffic Distribution menu section',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'tables_item-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section__child[data-title="Advertisers"]',
              stepText: '1.2. Go to Advertisers page',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Filters & Navigation',
      commonTopic: 'Table Section',
      description: `Each table has a pagination at the top, filter buttons, settings, and a search box.
                    Click on the Filter Button, select the Name Field and type any name, then click on the Apply Filter Button.`,
      image: '/assets/new-img/tours/guide-tour/tour2.png',
      tourChains: [
        {
          tourId: 'tables_open-filters',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '.p500-ui-table[data-tag="table_BrandsTable"] .c-table-header__filters',
              stepText: '2.1. Click Filter button',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'tables_filters-name-field',
          viewport: '.c-filters',
          steps: [
            {
              selector: 'gtd-datatable-filters-modal .c-filters__item:nth-child(5) .c-filters__toggle-button',
              stepText: '2.2. Select Name field',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'tables_set-up-filters',
          viewport: '.c-filters',
          steps: [
            {
              selector: '.c-filters__item:nth-child(5) ',
              stepText: '2.3. Enter any value',
              orientation: Orientation.Bottom,
            },
            {
              selector: '.c-filters__apply',
              stepText: '2.4. Click Apply Filter button',
              orientation: Orientation.Top,
            },
          ],
        },
      ],
    },
    {
      name: 'Reset View',
      commonTopic: 'Table Section',
      description: `After the filter is applied, the contents of the table are updated. You can view the applied filters using the filters button.
                    To reset the table view, click the Reset Filter button.`,
      image: '/assets/new-img/tours/guide-tour/tour3.png',
      tourChains: [
        {
          tourId: 'tables_reset-filters',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '.p500-ui-table[data-tag="table_BrandsTable"] .c-table-header__reset-filters',
              stepText: '3.1. Click Reset Filter button',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Work with Reports',
      commonTopic: 'Report Section',
      description: `The system allows you to generate and download multi-level reports on the data, for this go to the menu section Reporting.
                    Let's look at the work with this section on the example of Data Exporter.`,
      image: '/assets/new-img/tours/guide-tour/tour4.png',
      tourChains: [
        {
          tourId: 'reports_menu-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section--menu-item .p500-ui-nav-section__title[data-title="Reporting"]',
              stepText: '4.1. Click on Reporting menu section',
              orientation: Orientation.Bottom,
            },
          ],
        },
        {
          tourId: 'reports_item-section',
          viewport: '.c-sidebar__navigation',
          steps: [
            {
              selector: '.p500-ui-nav-section__child[data-title="Data Exporter"]',
              stepText: '4.2. Go to Data Exporter page',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Generate Report',
      commonTopic: 'Report Section',
      description: `In reports, you can configure the time period, create conditions, select data, and set your columns using simple formulas.
                    For quick configuration of settings, you can use Presets that you can create yourself.`,
      image: '/assets/new-img/tours/guide-tour/tour5.png',
      tourChains: [
        {
          tourId: 'reports_set-up-builder',
          viewport: '.l-base__body',
          steps: [
            {
              selector: 'gtd-reports-builder-filters .c-widget__item:nth-child(2)',
              stepText: '5.1. Click Advertisers button',
              orientation: Orientation.Bottom,
            },
            {
              selector: 'gtd-reports-builder-filters gtd-date-interval button:nth-child(3)',
              stepText: '5.2. Set This Month date range',
              orientation: Orientation.Bottom,
            },
            {
              selector: '*[data-tag="reports-builder_generate-preview"]',
              stepText: '5.3. Click Generate Preview button',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
    {
      name: 'Download Report',
      commonTopic: 'Report Section',
      description: `You can view and download reports. Also you can share report via a link with the same configuration settings.
                    To download the report, click the Download CSV button.`,
      image: '/assets/new-img/tours/guide-tour/tour6.png',
      tourChains: [
        {
          tourId: 'reports_download',
          viewport: '.l-base__body',
          steps: [
            {
              selector: '*[data-tag="reports-builder_generate-csv"]',
              stepText: '6.1. Click Generate CSV button',
              orientation: Orientation.Bottom,
            },
          ],
        },
      ],
    },
  ],
};
