import { IMODALS_SERVICE, IModalsService, P500UIBaseModal } from '@platform500services/p500-ui-kit';
import { Component, Inject } from '@angular/core';
import { IEVENT_SERVICE, IEventsService } from '../../../core/services/events/events.interface';

@Component({
  selector: 'gtd-new-design-new-year-modal',
  templateUrl: './new-design-new-year.modal.html',
})
export class NewDesignNewYearModal extends P500UIBaseModal {
  constructor(
    @Inject(IMODALS_SERVICE) public modalsService: IModalsService,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService
  ) {
    super(modalsService);
  }

  public openTourBoard(): void {
    this.eventsService.broadcast('tourBoard:open');
    this.close();
  }
}
