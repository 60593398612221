import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filter, map, share } from 'rxjs/operators';
import { IENVIRONMENT_SERVICE, Value, IEnvironmentService } from '../environment/environment.interface';
import { ITableDataResponse, ITableResponse } from '../../components/facade/service-facade/data.response';
import { IFilterValues, IOrderValues, ITableService, TableBody } from './interfaces/table.service.interface';

@Injectable()
export class TableService implements ITableService {
  public data: ITableDataResponse;
  public endpointApi: Value;

  constructor(private http: HttpClient, @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService) {
    this.endpointApi = this.envService.getVar('services.api');
  }

  public getData(tableKey: string, page: number, limit: number, filters: IFilterValues, orderValues: IOrderValues, scopeValues) {
    const body: TableBody = {
      table: tableKey,
      page,
      limit,
      filters,
      order_values: orderValues,
    };

    if (scopeValues) {
      body.scope_values = scopeValues;
    }

    return this.http.post(this.endpointApi + '/table/data', body).pipe(
      share(),
      filter((response: ITableResponse) => !!response.data),
      map((response) => ({ ...response.data, columns: response.data.columns || [] }))
    );
  }
}
