<gtd-table-header
  [limit]="limit"
  [page]="page"
  [layoutType]="currentSettings?.type"
  [headerActions]="currentSettings?.headerActions"
  [multiActions]="currentSettings?.checkboxesConfig?.multiActions"
  [columns]="columns"
  [originSortColumns]="originSortColumns"
  [totalItems]="totalItems"
  [visibilityValues]="visibilityValues"
  [titleTemplate]="titleTemplate"
  [fieldModels]="filtersModel"
  (reload)="update()"
  (onChangeFilters)="onFilterChange($event)"
  (onChangeVisibility)="onVisibilityChange($event)"
  (onChangeLimit)="onLimitChange($event)"
  (onChangePage)="onPageChange($event)"
></gtd-table-header>

<ng-container [ngSwitch]="currentSettings?.type">
  <gtd-table
    *ngSwitchCase="'table'"
    [checkboxesGroup]="checkboxesGroup"
    [key]="currentSettings?.key"
    [rows]="data?.rows"
    [rowActions]="currentSettings?.rowActions"
    [columns]="visibleColumns"
    [orderValues]="orderValues"
    [statusColorScheme]="currentSettings?.statusColorScheme"
    [statusHint]="currentSettings?.statusHint"
    (sort)="handleSort($event)"
    [emptyTableAction]="emptyTableAction"
  ></gtd-table>

  <ng-container *ngSwitchCase="'card'">
    <div class="c-card__layout">
      <ng-container *ngFor="let row of data?.rows">
        <ng-container
          p500UIComponentOutlet
          [component]="currentSettings?.cardComponent"
          [settings]="{ row: row, actions: currentSettings?.rowActions }"
        ></ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="!data">
      <p500-ui-cube-preloader></p500-ui-cube-preloader>
    </ng-container>

    <ng-container *ngIf="data && !data?.rows.length">
      <ng-container *ngTemplateOutlet="emptyTableAction"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #titleTemplate>
  <ng-container *ngIf="!tables">
    <span class="c-table-header__title">{{ title || currentSettings?.title }}</span>
  </ng-container>
  <ng-container *ngIf="tables">
    <span class="c-table-header__title -gray mr-10">{{ title }}:</span>
    <p500-ui-dropdown class="c-dropdown c-dropdown--tables" position="left">
      <ng-template p500UIDropdownHeader let-isOpen="isOpen" let-toggle="toggle">
        <div class="c-table-header__subtitle" (click)="toggle()">
          {{ currentSettings?.title }}
          <button p500-ui-button-icon class="ml-10" size="xsmall" color="primary">
            <p500-ui-icon [name]="isOpen ? 'arrows-bold-up' : 'arrows-bold-down'"></p500-ui-icon>
          </button>
        </div>
      </ng-template>
      <ng-template p500UIDropdownBody>
        <ng-container *ngFor="let table of tables">
          <div p500UIDropdownItem class="c-dropdown__item" (click)="onTableChange(table)">
            <span class="c-dropdown__item-text">{{ table.title }}</span>
          </div>
        </ng-container>
      </ng-template>
    </p500-ui-dropdown>
    <gtd-dot-separator></gtd-dot-separator>
  </ng-container>
</ng-template>

<ng-template #emptyTableAction>
  <gtd-empty
    class="c-table__empty-block"
    [desc]="
      currentSettings?.emptyTemplateAction
        ? 'Data wasn\'t found for the current filters, but you can add a new element.'
        : 'Data wasn\'t found for the current filters.'
    "
    icon="box"
    [actions]="[createActionButton]"
  ></gtd-empty>
</ng-template>

<ng-template #createActionButton>
  <ng-container *ngIf="currentSettings?.emptyTemplateAction">
    <ng-container
      p500UIComponentOutlet
      [component]="currentSettings?.emptyTemplateAction.component"
      [settings]="currentSettings?.emptyTemplateAction.props"
    ></ng-container>
  </ng-container>
</ng-template>
