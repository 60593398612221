import { AfterViewInit, Component, Inject } from '@angular/core';
import { IConfigurationService, ICONFIGURATION_SERVICE } from '../../../core/services/configuration/configuration-service.interface';
import { Router, NavigationEnd } from '@angular/router';
import { BrowserWindowRef } from '../../../core/services/window/window.service';
import { DOCUMENT } from '@angular/common';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';

@Component({
  selector: 'gtd-metrika-script',
  template: '',
})
@DestroySubscribers()
export class MetrikaScriptComponent implements AfterViewInit {
  private metrikaFuncName = 'ym';
  public subscribers: any = {};

  constructor(
    @Inject(ICONFIGURATION_SERVICE) private configurationService: IConfigurationService,
    @Inject(BrowserWindowRef) private window: BrowserWindowRef,
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    const configurationData = this.configurationService.data();
    if (configurationData.brand.yandexMetrikaId) {
      this.initScript(configurationData.brand.yandexMetrikaId);
    }
  }

  initScript(metrikaId: string): void {
    const script = this.document.createElement('script');
    script.type = 'application/javascript';
    script.src = './assets/yandex-metrika/ym.js';
    script.onload = () => {
      if (!this.window.nativeWindow[this.metrikaFuncName]) {
        return;
      }
      this.window.nativeWindow[this.metrikaFuncName](+metrikaId, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });
      this.subscribers.event = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const url = event.url;
          this.window.nativeWindow[this.metrikaFuncName](+metrikaId, 'hit', url);
        }
      });
    };
    this.document.body.appendChild(script);
  }
}
