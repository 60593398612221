import { CommonModule } from '@angular/common';
import { NavPanelModule } from '../nav-panel/nav-panel.module';
import { NgModule } from '@angular/core';
import { P500UIButtonsModule } from '@platform500services/p500-ui-kit';
import { RouterModule } from '@angular/router';
import { TopBarComponent } from './top-bar.component';

@NgModule({
  imports: [CommonModule, RouterModule, P500UIButtonsModule, NavPanelModule],
  declarations: [TopBarComponent],
  exports: [TopBarComponent],
})
export class TopBarModule {}
