import { P500UIButtonsModule, P500UIIconsModule, P500UITooltipModule } from '@platform500services/p500-ui-kit';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfoModal } from './info.modal';

@NgModule({
  imports: [CommonModule, P500UIIconsModule, P500UIButtonsModule, P500UITooltipModule],
  declarations: [InfoModal],
  exports: [InfoModal],
})
export class InfoModalModule {}
