<p500-ui-new-table [key]="key" [rows]="rows">
  <ng-container *ngFor="let column of columns; first as isFirst" p500UIColumnDef [name]="column.id">
    <th
      *p500UIHeaderCellDef
      (click)="handleSort(column.id)"
      [class.order]="orderValues[column.id]"
      [attr.data-tag]="'table_column_' + column.id"
    >
      <div class="d-flex align-items-center" [class.justify-content-end]="column.type === 'money'">
        <p500-ui-checkbox-field
          (click)="$event.stopPropagation()"
          *ngIf="isFirst && checkboxesGroup && rows.length"
          class="mr-8"
          [indeterminate]="isIndeterminate$ | async"
          [ngModel]="isAllChecked$ | async"
          (ngModelChange)="changeValueGroup($event)"
        ></p500-ui-checkbox-field>

        <p500-ui-header-cell
          [id]="column.id"
          [label]="column.label"
          [statusHint]="isFirst ? statusHint : null"
          [orderValues]="orderValues"
          [isProtected]="column.type === 'type_protected'"
          [isUnsecured]="column.isUnsecured"
        ></p500-ui-header-cell>
      </div>
    </th>

    <td *p500UICell="let row">
      <div class="d-flex align-items-center" [class.justify-content-end]="column.type === 'money'">
        <ng-container *ngIf="isFirst">
          <div *ngIf="isFirst" class="status" [style.backgroundColor]="getCellStatusHexColor(row, statusColorScheme)"></div>
          <p500-ui-checkbox-field
            class="mr-8"
            *ngIf="checkboxesGroup"
            [formControl]="checkboxesGroup.get([row.id])"
          ></p500-ui-checkbox-field>
        </ng-container>

        <gtd-ui-field-dynamic
          [type]="column.type"
          [data]="row[column.id]"
          [row]="row"
          [source]="'Table: ' + key + ', Row ID: ' + row.id + ', Column: ' + column.id"
        ></gtd-ui-field-dynamic>
      </div>
    </td>
  </ng-container>

  <ng-container *ngFor="let action of rowActions" p500UIColumnDef>
    <th *p500UIHeaderCellDef></th>
    <ng-container *p500UICell="let row">
      <ng-container
        p500UIComponentOutlet
        *ngIf="action"
        [component]="action.component"
        [settings]="action.props | objectAssign: { row: row, rowId: row.id }"
      ></ng-container>
    </ng-container>
  </ng-container>

  <ng-template p500UITableEmptyAction>
    <ng-container *ngTemplateOutlet="emptyTableAction"></ng-container>
  </ng-template>
</p500-ui-new-table>
