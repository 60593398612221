<!-- <div class="c-sidebar__overlay" *ngIf="sidebarOverlay || isOpenSelector" (click)="toggleSidebar()"></div> -->
<div class="c-sidebar__inner">
  <!-- Navigation -->
  <div class="c-sidebar__navigation d-lg-flex flex-column">
    <div [class.d-lg-none]="isCollapsedNavigation">
      <div class="d-none d-sm-block">
        <gtd-selector-dropdown-header></gtd-selector-dropdown-header>
      </div>
      <ng-container *ngTemplateOutlet="search"></ng-container>
      <div class="d-none d-sm-block">
        <ng-container *ngTemplateOutlet="navigation"></ng-container>
      </div>
    </div>

    <ng-container [ngTemplateOutlet]="collapsedNavigationTpl"></ng-container>

    <div class="c-sidebar__navigation-footer mb-18 mt-auto d-none" [class.d-lg-flex]="!isCollapsedNavigation">
      <button class="c-sidebar__navigation-button" (click)="toggleNavigation()">
        <p500-ui-icon name="arrows-bold-left"></p500-ui-icon>
        <span>Hide</span>
      </button>

      <div class="d-flex flex-column">
        <span class="c-field-value"> Version {{ changelogVersion }} </span>
        <a routerLink="/app/changelog" class="c-link c-link--primary -blue">Go to Changelog</a>
      </div>
    </div>

    <div class="d-sm-none">
      <ng-container [ngSwitch]="sidebarService.getMenuSection()">
        <ng-container *ngSwitchCase="'menu'">
          <gtd-selector-dropdown-header></gtd-selector-dropdown-header>
          <ng-container *ngTemplateOutlet="navigation"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'notifications'">
          <gtd-notifications-menu></gtd-notifications-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'help'">
          <gtd-help-menu></gtd-help-menu>
        </ng-container>
        <ng-container *ngSwitchCase="'settings'">
          <gtd-settings-menu></gtd-settings-menu>
        </ng-container>

        <ng-container *ngSwitchCase="'profile'">
          <gtd-profile-menu cssClass="-profile-menu"></gtd-profile-menu>
          <gtd-ui-today-data cssClass="-box -tickers"></gtd-ui-today-data>
        </ng-container>

        <ng-container *ngSwitchCase="'tickers'">
          <gtd-profile-menu></gtd-profile-menu>
          <gtd-ui-today-data cssClass="-box -tickers"></gtd-ui-today-data>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <!-- End of Navigation -->

  <div class="c-sidebar__footer">
    <gtd-nav-panel></gtd-nav-panel>
  </div>
</div>

<ng-template #navigation>
  <div class="mb-14">
    <p500-ui-sidenav [menu]="sidebarMenu" (selectedItem)="toggleSidebar()"></p500-ui-sidenav>
  </div>
</ng-template>

<ng-template #search>
  <p500-ui-search-field (selectItem)="selectSearchItem($event)" [data]="searchData"></p500-ui-search-field>
</ng-template>

<ng-template #collapsedNavigationTpl>
  <div class="c-collapsed-navigation d-none" [class.d-lg-flex]="isCollapsedNavigation">
    <div
      class="c-collapsed-navigation__section"
      *ngFor="let section of sidebarMenu"
      [class.is-active]="isActiveSection(section)"
      [ngClass]="section.sectionButtonClass"
      (click)="openSection(section)"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
    >
      <p500-ui-icon [name]="'h-icon ' + section.icon"></p500-ui-icon>

      <!-- This template displays the overlay content and is connected to the button -->
      <ng-container *ngIf="section.children?.length">
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="section === currentSection"
          [cdkConnectedOverlayOffsetX]="60"
          [cdkConnectedOverlayOffsetY]="-40"
          (overlayOutsideClick)="closeSection()"
        >
          <div class="c-dropdown c-dropdown--menu">
            <div class="c-dropdown__title">{{ currentSection.title }}</div>

            <div class="c-dropdown__items">
              <ng-container *ngFor="let children of currentSection.children">
                <div
                  *ngFor="let item of children"
                  class="c-dropdown__item"
                  routerLinkActive="-active"
                  [routerLink]="item.link"
                  (click)="closeSection()"
                >
                  {{ item.title }}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>

    <div class="c-collapsed-navigation__footer">
      <button class="c-sidebar__navigation-button" (click)="toggleNavigation()">
        <p500-ui-icon name="arrows-bold-right"></p500-ui-icon>
      </button>
    </div>
  </div>
</ng-template>
