<div class="p500-ui-modal__inner">
  <div class="p500-ui-modal__head">
    <div class="p500-ui-modal__close" (click)="closeModal()">
      <p500-ui-icon name="controls-close"></p500-ui-icon>
    </div>
    <div class="p500-ui-modal__title c-modal__title" [ngClass]="{'-danger': type === types.DANGER}">
      <p500-ui-icon *ngIf="type === types.DANGER" class="mr-8" [name]="'other-danger'"></p500-ui-icon>
      {{ title }}
    </div>
    <div class="p500-ui-modal__subtitle">{{ subTitle }}</div>
  </div>
  <div class="p500-ui-modal__body">
    <div *ngIf="bodyText === prettyBodyText; else elseTmpl" class="c-modal__message" [ngClass]="{'-danger': type === types.DANGER}">
      {{ prettyBodyText }}
    </div>
    <ng-template #elseTmpl>
      <pre class="c-modal__message" [ngClass]="{'-danger': type === types.DANGER}">
        {{ prettyBodyText }}
      </pre>
    </ng-template>
    <span class="c-link c-link--primary -blue" (click)="copy($event)">
      <p500-ui-icon name="buttons-copy-big" p500UITooltip tooltipData="Copy to clipboard"></p500-ui-icon>
    </span>
  </div>
  <div *ngIf="showControlLink" class="p500-ui-modal__footer">
    <a [href]="'https://control.' + envService.getHost() + '.com/app/home'" class="c-link" target="_blank">
      <button type="button" [stroked]="true" p500-ui-button>Go to Profile</button>
    </a>
  </div>
</div>
