<ng-container [formGroup]="formGroup">
  <div class="p500-ui-modal__group" *ngFor="let config of groups">
    <p500-ui-accordion [initState]="config.initialCollapsedState !== false">
      <ng-template p500UIAccordionHeader let-isOpen="isOpen" let-toggle="toggle">
        <div *ngIf="config.name" class="head">
          <div class="p500-ui-modal__body-title" [class.-accordion]="config.isCollapsed" (click)="config.isCollapsed && toggle()">
            <span class="title">{{ config.name }}</span>
            <p500-ui-icon *ngIf="config.isCollapsed" class="icon" [name]="isOpen ? 'arrows-bold-up' : 'arrows-bold-down'"> </p500-ui-icon>
          </div>
        </div>
      </ng-template>
      <ng-template p500UIAccordionBody>
        <div class="row">
          <div
            *ngFor="let field of config.fields; index as idx"
            class="c-form__section col-12"
            gtdFieldWidth
            [grid]="config.col"
            [fields]="config.fields.length"
            [fieldIDx]="idx"
          >
            <p500-ui-control-dynamic-component
              [component]="components.get(field)"
              [settings]="componentsSettings.get(field)"
            ></p500-ui-control-dynamic-component>
          </div>
        </div>
      </ng-template>
    </p500-ui-accordion>
  </div>
</ng-container>
