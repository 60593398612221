import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CanActionRbacPipe } from './can-action-rbac.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CanActionRbacPipe],
  exports: [CanActionRbacPipe],
})
export class CanActionRbacModule {}
