import { Inject, Injectable } from '@angular/core';

import { IAUTH_SERVICE, IAuthService } from '../auth/auth-service.interface';
import { IRbacService } from './rbac-service.interface';
import { RbacPermissionsConstant } from './rbac-permissions.constant';

@Injectable()
export class RbacService implements IRbacService {
  constructor(@Inject(IAUTH_SERVICE) private authService: IAuthService) {}

  can(permission: string | string[]): boolean {
    if (!permission) {
      return true;
    }

    const user = this.authService.getUserData();
    if (!user) {
      return true;
    }

    const permissions = Array.isArray(permission) ? [...permission] : [permission];

    let permissionCounter = 0;

    for (const perm of permissions) {
      if (user.permissions[perm]) {
        permissionCounter++;
      }
    }

    return permissions.length === permissionCounter;
  }

  canAction(permission: string | string[]): boolean {
    if (!permission) {
      return true;
    }

    const user = this.authService.getUserData();
    if (!user) {
      return true;
    }

    const permissions = Array.isArray(permission) ? [...permission] : [permission];

    let permissionCounter = 0;

    for (const perm of permissions) {
      if (user.permissions[perm]) {
        permissionCounter++;
      }
    }

    // This is not the result of the check if user has this checkbox enabled in Master or Control,
    // but result of the check by RBAC module if action is allowed.
    //
    // So in other words:
    // Edit Anything is false - permission denied
    // Edit Anything is true - allowed
    if (!user.permissions[RbacPermissionsConstant.SCOPES_VIEW_ONLY]) {
      permissionCounter = 0;
    }

    return permissions.length === permissionCounter;
  }

  canAtLeast(permissions: string[]): boolean {
    if (!permissions) {
      return true;
    }

    const user = this.authService.getUserData();
    if (!user) {
      return true;
    }

    if (user.permissions) {
      let counter = 0;
      for (const i in permissions) {
        if (user.permissions[permissions[i]]) {
          counter++;
          break;
        }
      }
      return !!counter;
    }

    return false;
  }
}
