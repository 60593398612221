import { CommonModule } from '@angular/common';
import { HelpMenulModule } from '../help-menu/help-menu.module';
import { SettingsMenuModule } from './../settings-menu/settings-menu.module';
import { NavPanelModule } from '../nav-panel/nav-panel.module';
import { NgModule } from '@angular/core';
import {
  P500ApplicationsSelectorModule,
  P500UIButtonsModule,
  P500UIIconsModule,
  P500UISidenavModule,
} from '@platform500services/p500-ui-kit';
import { ProfileMenuModule } from '../profile-menu/profile-menu.module';
import { SidebarComponent } from './sidebar.component';
import { TodayDataModule } from '../today-data/today-data.module';
import { AppSelectorHeaderModule } from '../app-selector-header/app-selector-header.module';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { NotificationsMenuModule } from '../notifications-menu/notifications-menu.module';

@NgModule({
  imports: [
    CommonModule,
    NavPanelModule,
    TodayDataModule,
    HelpMenulModule,
    SettingsMenuModule,
    ProfileMenuModule,
    P500UISidenavModule,
    P500ApplicationsSelectorModule,
    P500UIButtonsModule,
    AppSelectorHeaderModule,
    P500UIIconsModule,
    RouterModule,
    OverlayModule,
    NotificationsMenuModule,
  ],
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
})
export class SidebarModule {}
