import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

interface IEventsService {
  /**
   * Subscribe on event bus by key
   */
  on<T>(key: string): Observable<T>;

  /**
   * Send event data to chanel by key
   */
  broadcast(key: string, data?: any): void;
}

const IEVENT_SERVICE = new InjectionToken<IEventsService>('IEventsService');

export { IEVENT_SERVICE, IEventsService };
