import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'gtd-ui-field-special-notification-type',
  templateUrl: './notification-type.component.html',
})
export class NotificationTypeComponent {
  @Input() data: string;
}
