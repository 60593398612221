import { Inject, Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IAUTH_SERVICE, IAuthService } from './auth-service.interface';
import { UserResponse } from './responses/user.response';

@Injectable()
export class UserResolverService implements Resolve<UserResponse> {
  constructor(@Inject(IAUTH_SERVICE) private authService: IAuthService, private router: Router) {}

  resolve(): Observable<UserResponse> {
    const userData = this.authService.getUserData();

    if (userData) {
      return of(userData);
    } else {
      return this.authService.getUser().pipe(
        tap((user) => {
          if (!user) {
            this.authService.logout();
            this.router.navigate(['/']);
          }
          if (user.logout) {
            this.authService.logout();
            this.router.navigate(['/auth/login'], { queryParams: { logout: true } });
          }
        }),
        catchError(() => {
          this.authService.logout();
          this.router.navigate(['/']);
          return of(null);
        })
      );
    }
  }
}
