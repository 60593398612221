import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

interface IUnsecuredService {
  isUnsecuredValue: boolean;
  getUnsecuredStatus(): Observable<IUnsecuredStatus>;
  setUnsecuredStatus(isUnsecured: boolean, unsecuredBefore?: number): void;
  enable(token_2fa: string, section: string): Observable<any>;
  leave(): Observable<any>;
}

interface IUnsecuredStatus {
  isUnsecured: boolean;
  unsecuredBefore: number | null;
}

const IUNSECURED_SERVICE = new InjectionToken<IUnsecuredService>('IUnsecuredService');

export { IUnsecuredService, IUNSECURED_SERVICE, IUnsecuredStatus };
