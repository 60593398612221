import { InjectionToken } from '@angular/core';
import { RefreshDirective } from '../../directives/refresh/refresh.directive';

interface IPageService {
  refresh(): void;
  refreshOneByOne(): void;
  push(key: string, data: RefreshDirective): void;
  remove(key: string): void;
}

const IPAGE_SERVICE = new InjectionToken<IPageService>('IPageService');

export { IPageService, IPAGE_SERVICE };
