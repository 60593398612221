import { P500UIButtonsModule, P500UIIconsModule, P500UIProgressModule } from '@platform500services/p500-ui-kit';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TourBoardComponent } from './tour-board.component';

@NgModule({
  imports: [CommonModule, P500UIButtonsModule, P500UIIconsModule, P500UIProgressModule],
  declarations: [TourBoardComponent],
  exports: [TourBoardComponent],
})
export class TourBoardModule {}
