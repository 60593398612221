<div class="c-menu-list" [ngClass]="cssClass">
  <div class="c-menu-list__title">How to use it</div>
  <ul class="c-menu-list__content">
    <li class="c-menu-list__item" (click)="openDocsBoard()"><p500-ui-icon name="other-wiki h-icon"></p500-ui-icon>Docs Section</li>
    <!-- TODO: dont remove, temporary changes -->
    <!-- <li class="c-menu-list__item d-none d-xxl-block" (click)="openTourBoard()">
      <p500-ui-icon name="other-learning h-icon"></p500-ui-icon>Learning Center
    </li> -->
    <li class="c-menu-list__item">
      <a href="https://platform500.atlassian.net/servicedesk/customer/portals" target="_blank">
        <p500-ui-icon name="other-tickets h-icon"></p500-ui-icon>Helpdesk Ticket
      </a>
    </li>
    <li class="c-menu-list__item">
      <a href="https://irev.com" target="_blank"> <p500-ui-icon name="other-platform500 h-icon"></p500-ui-icon>IREV </a>
    </li>
  </ul>
</div>
