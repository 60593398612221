import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IENVIRONMENT_SERVICE, Value, IEnvironmentService } from '../../services/environment/environment.interface';
import { IWebPushService } from './web-push-service.interface';
import { SwPush } from '@angular/service-worker';
import { IConfigurationService, ICONFIGURATION_SERVICE } from '../../services/configuration/configuration-service.interface';
import { BrowserWindowRef } from '../../services/window/window.service';

@Injectable()
export class WebPushService implements IWebPushService {
  private url: string | Value;

  constructor(
    @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService,
    @Inject(ICONFIGURATION_SERVICE) public configurationService: IConfigurationService,
    @Inject(BrowserWindowRef) protected window: BrowserWindowRef,
    private http: HttpClient,
    private swPush: SwPush
  ) {
    this.url = this.envService.getVar('services.api');
  }

  public wepPush(): void {
    const serverPublicKey = this.configurationService.data().brand?.vapid_public;
    if (serverPublicKey) {
      this.swPush
        .requestSubscription({ serverPublicKey })
        .then((sub) => {
          this.http.post(`${this.url}/web-push`, sub).subscribe(
            () => {},
            () => {}
          );
        })
        .catch(() => {});

      this.swPush.notificationClicks.subscribe(({ action, notification }) => {
        console.log('Action: ', action);
        console.log('Notification: ', notification);
        if (action === 'click') {
          console.log('Click Action');
          this.window.nativeWindow.open(notification.data.url);
        } else {
          console.log('Else');
          this.window.nativeWindow.open(notification.data.url);
        }
        console.log('Finish');
      });
    }
  }
}
