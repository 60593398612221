import { Inject, OnInit } from '@angular/core';
import {
  IApplication,
  ISelectorHeader,
} from '@platform500services/p500-ui-kit/widgets/applications-selector/applications-selector.interfaces';
import { DestroySubscribers } from '../../../decorators/destroy-subscribers.decorator';
import { BrowserWindowRef } from '../../../services/window/window.service';
import { IENVIRONMENT_SERVICE, IEnvironmentService } from '../../../services/environment/environment.interface';
import { IApplicationsService, IAPPLICATIONS_SERVICE } from '../../../services/applications/applications.interfaces';
import { Directive } from '@angular/core';

@Directive({})
@DestroySubscribers()
// tslint:disable-next-line: directive-class-suffix
export abstract class AppSelectorBaseComponent implements OnInit {
  public header: ISelectorHeader = {
    title: 'Loading...',
    subtitle: 'Leads Distribution',
  };
  public applications: IApplication[];
  private subscribers: any = {};

  constructor(
    @Inject(BrowserWindowRef) protected window: BrowserWindowRef,
    @Inject(IENVIRONMENT_SERVICE) protected envService: IEnvironmentService,
    @Inject(IAPPLICATIONS_SERVICE) protected applicationsService: IApplicationsService
  ) {}

  ngOnInit(): void {
    this.subscribers.availableApplications = this.applicationsService.getAvailableApplications().subscribe((applications) => {
      this.applications = applications.filter((application) => !application.isActive);
    });
    this.subscribers.availableApplications = this.applicationsService.getCurrentApplication().subscribe((application) => {
      if (application) {
        this.header = {
          title: application.name,
          subtitle: application.type,
        };
      }
    });
  }
}
