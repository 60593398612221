import { Component, Inject, OnInit, ElementRef, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CheckboxFieldComponent, IModalsService, IMODALS_SERVICE, P500UIBaseModal } from '@platform500services/p500-ui-kit';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';
import { FieldModel } from '../../services/actions/models/field.model';
import { IColumn } from '../../components/facade/service-facade/data.response';
import { IEventsService, IEVENT_SERVICE } from '../../services/events/events.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  templateUrl: './datatable-settings.modal.html',
})
@DestroySubscribers()
export class DatatableSettingsModal extends P500UIBaseModal implements OnInit {
  public form: UntypedFormGroup;
  public fields: FieldModel[] = [];
  public limitControl: UntypedFormControl;
  public buttonSettings: ElementRef;
  public columns: IColumn[];
  public originSortColumns: IColumn[];
  public limit: number;
  public availableLimits: number[];
  public visibilityValues: any;
  public showColumnsSettings: boolean;
  public changeLimit: (limit: number) => void;
  public changeVisibility: (filters: any) => void;

  private defaultLimit = 25;

  constructor(
    @Inject(IMODALS_SERVICE) public modalsService: IModalsService,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    private eRef: ElementRef,
    public breakpointObserver: BreakpointObserver
  ) {
    super(modalsService);

    this.subscribers.isShownIncidents = this.eventsService.on('closeSettings').subscribe(() => {
      this.close();
    });
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touch', ['$event'])
  handleOutsideClick(event) {
    if (
      !event.target.classList.contains('ng-option') &&
      !event.target.classList.contains('ng-option-label') &&
      !this.buttonSettings.nativeElement.contains(event.target) &&
      !this.eRef.nativeElement.contains(event.target)
    ) {
      this.closeModal();
    }
  }

  ngOnInit(): void {
    const formGroup = {};
    this.columns.forEach((column) => {
      const isVisible = this.visibilityValues[column.id] !== undefined ? this.visibilityValues[column.id] : column.visible_by_default;
      const fieldModel = new FieldModel(column.label, column.id, CheckboxFieldComponent, new UntypedFormControl(isVisible));
      this.fields.push(fieldModel);
      formGroup[fieldModel.key] = fieldModel.formControl;
    });

    this.form = new UntypedFormGroup(formGroup);
    this.limitControl = new UntypedFormControl(this.limit);
  }

  submit(): void {
    this.changeLimit(Number.parseInt(this.limitControl.value, 10));
    this.changeVisibility(this.form.value);

    this.closeModal();
  }

  resetDisplayOptions(): void {
    this.columns.forEach((column) => {
      this.form.controls[column.id].setValue(column.visible_by_default);
      this.limitControl.setValue(this.defaultLimit);
    });
    this.columns.sort(
      (a, b) =>
        this.originSortColumns.findIndex((col) => a.label === col.label) - this.originSortColumns.findIndex((col) => b.label === col.label)
    );

    this.submit();
  }

  closeModal(): void {
    this.eventsService.broadcast('closeSettings');
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.fields.sort(
      (a, b) => this.columns.findIndex((col) => a.label === col.label) - this.columns.findIndex((col) => b.label === col.label)
    );
  }

  get dragDisabled(): boolean {
    return this.breakpointObserver.isMatched(['(max-width: 600px)']);
  }
}
