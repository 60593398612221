<div *ngIf="isSettingLoaded" class="c-notifications-sidebar">
  <div class="c-notifications-sidebar__top-wrapper">
    <p500-ui-icon name="controls-close" (click)="close()" class="c-notifications-sidebar__close"></p500-ui-icon>

    <div class="c-notifications-sidebar__top">
      <h4 class="c-notifications-sidebar__title">Notifications Panel</h4>
      <a [routerLink]="'/app/settings/notification-settings'" class="c-notifications-sidebar__slack">Notification Settings</a>
    </div>

    <div class="c-notifications-sidebar__tabs">
      <ng-container *ngFor="let title of mainTabs">
        <button
          class="c-notifications-sidebar__button c-notifications-sidebar__tabs-button"
          [class.--active]="title === tabs.activeTabTitle"
          (click)="[(page = 1), (tabs.activeTabTitle = title), getNotificationsData()]"
        >
          <span class="c-notifications-sidebar__tabs-button__title">{{ title }}</span>
        </button>
      </ng-container>
    </div>

    <div class="c-notifications-sidebar__sub-tabs">
      <div class="c-notifications-sidebar__sub-tabs-wrapper">
        <ng-container *ngFor="let title of subTabs">
          <button
            class="c-notifications-sidebar__button c-notifications-sidebar__sub-tabs-button"
            [class.--active]="title === tabs.activeSubTabTitle[tabs.activeTabTitle]"
            (click)="[(page = 1), (tabs.activeSubTabTitle[tabs.activeTabTitle] = title), getNotificationsData()]"
          >
            <span class="c-notifications-sidebar__sub-tabs-button__title">{{ title }}</span>
          </button>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="notifications$ | async as notifications">
    <ul *ngIf="!isLoading && notifications?.length; else emptyTempl" class="c-notifications-sidebar__list">
      <ng-container *ngFor="let notification of notifications">
        <li
          class="c-notifications-sidebar__item"
          (click)="
            openNotificationsModal(
              tabs.activeTabTitle,
              notification.id,
              notification.created_at_timestamp,
              notification.message,
              getLinkMsg(notification.message) ? linkTempl : null,
              notification?.user_id,
              notification?.user_name
            )
          "
        >
          <div class="c-notifications-sidebar__header">
            <span class="c-notifications-sidebar__id">ID:{{ notification.id }}</span>
            <span class="c-notifications-sidebar__info">
              <p500-ui-icon size="12px" name="information"></p500-ui-icon>
              View Info
            </span>
            <div class="c-notifications-sidebar__new">
              <gtd-ui-field-special-notification-type [data]="notification.type"></gtd-ui-field-special-notification-type>
            </div>
          </div>
          <ng-container
            *ngTemplateOutlet="
              msgTempl;
              context: { message: notification.message, linkId: notification.user_id, userName: notification?.user_name }
            "
          >
          </ng-container>
          <div class="c-notifications-sidebar__date">{{ notification.created_at_timestamp | timeAgo }}</div>
        </li>
      </ng-container>
    </ul>
  </ng-container>

  <p500-ui-cube-preloader *ngIf="isLoading"></p500-ui-cube-preloader>

  <div class="c-notifications-sidebar__bottom">
    <div class="c-notifications-sidebar__search">
      <gtd-table-search placeholder="Search by ID" [ngModel]="null" (ngModelChange)="setFilterById($event)"></gtd-table-search>
    </div>
    <div class="c-notifications-sidebar__pagination">
      <p500-ui-pagination [totalItems]="totalItems" [itemsPerPage]="limit" [currentPage]="page" (currentPageChange)="onPageChange($event)">
      </p500-ui-pagination>
    </div>
  </div>
</div>

<ng-template let-message="message" #linkTempl>
  <span>{{ getLinkMsg(message)?.msg }}</span>
  <a [href]="getLinkMsg(message)?.link" target="_blank" class="c-link c-link--primary -blue" style="word-break: break-word">{{
    getLinkMsg(message)?.link
  }}</a>
</ng-template>

<ng-template let-message="message" #msgTempl>
  <gtd-ui-field-default-string-whitespace-or-empty [data]="message" [length]="50"> </gtd-ui-field-default-string-whitespace-or-empty>
</ng-template>

<ng-template #emptyTempl>
  <gtd-empty *ngIf="!isLoading" icon="box" [desc]="'Data wasn\'t found'"></gtd-empty>
</ng-template>
