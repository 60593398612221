import { TokenResponse } from './token.response';
import { UserResponse } from './user.response';

export class AuthResponse {
  constructor(public token: TokenResponse, public user: UserResponse) {}

  /**
   * Processing from json
   */
  public static fromJson(json: any) {
    return new AuthResponse(
      TokenResponse.fromJson({
        data: json.data.token,
      }),
      UserResponse.fromJson({
        data: json.data.user,
      })
    );
  }
}
