import { Component, Input } from '@angular/core';
import { ComponentModel } from '../../../services/actions/models/field.model';
import { UntypedFormGroup } from '@angular/forms';
import { Group } from '../../../services/actions/interfaces/action-config.interface';

@Component({
  selector: 'gtd-group-form',
  templateUrl: './group-form.component.html',
})
export class GroupFormComponent {
  @Input() formGroup: UntypedFormGroup;
  @Input() groups: Group[];
  @Input() components: Map<string, ComponentModel['component']>;
  @Input() componentsSettings: Map<string, ComponentModel['settings']>;
}
