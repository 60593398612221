import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { IDATA_SERVICE, IDataService } from '../../services/data/data-service.interface';
import { IRBAC_SERVICE, IRbacService } from '../../services/rbac/rbac-service.interface';
import { ResolveActionConfig } from '../../../core/action-configs/incidents/resolve.action-config';
import { RbacPermissionsConstant } from '../../services/rbac/rbac-permissions.constant';
import { IAlert, IAlertService, IALERT_SERVICE } from './services/alerts/alert.interfaces';
import { DestroySubscribers } from '../../decorators/destroy-subscribers.decorator';
import { IEventsService, IEVENT_SERVICE } from '../../services/events/events.interface';

@Component({
  selector: 'gtd-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroySubscribers()
export class NotificationsMenuComponent {
  @Input() cssClass = '';

  public alerts: IAlert[] = [];
  public resolveAction = new ResolveActionConfig(this.dataService, this.rbacService);
  public RbacPermissionsConstant = RbacPermissionsConstant;

  private subscribers: any = {};

  constructor(
    @Inject(IALERT_SERVICE) private alertService: IAlertService,
    @Inject(IDATA_SERVICE) private dataService: IDataService,
    @Inject(IRBAC_SERVICE) public rbacService: IRbacService,
    @Inject(IEVENT_SERVICE) private eventsService: IEventsService,
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef
  ) {
    this.subscribers.notifications = this.alertService.getAlerts().subscribe((alerts) => {
      this.alerts = alerts;
      this.cd.markForCheck();
    });
  }

  closeNotifications(): void {
    if (this.breakpointObserver.isMatched(['(max-width: 481px)'])) {
      this.eventsService.broadcast('sidebarComponent:toggle');
    }
  }
}
