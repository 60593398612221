import { IAlert } from './alert.interfaces';

export const getLatestDate = (alerts: IAlert[]): number => {
  let latestDate: number = null;
  for (const { created_at_timestamp } of alerts) {
    if (latestDate) {
      latestDate = latestDate > created_at_timestamp ? latestDate : created_at_timestamp;
    } else {
      latestDate = created_at_timestamp;
    }
  }

  return latestDate;
};
