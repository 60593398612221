import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChangelogBtnComponent } from './changelog-btn.component';

@NgModule({
  declarations: [ChangelogBtnComponent],
  exports: [ChangelogBtnComponent],
  imports: [CommonModule],
})
export class ChangelogBtnModule {}
