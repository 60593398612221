<div class="c-table-header">
  <div class="c-table-header__content-left">
    <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
    <ng-container *ngFor="let action of headerActions">
      <ng-container p500UIComponentOutlet *ngIf="action" [component]="action.component" [settings]="getActionProps(action)"></ng-container>
    </ng-container>
    <button
      p500-ui-button-icon
      size="large"
      color="primary"
      [attr.data-tag]="'table_button_refresh'"
      type="button"
      (click)="onClickUpdate()"
    >
      <p500-ui-icon name="controls-refresh"></p500-ui-icon>
    </button>
  </div>

  <div class="c-table-header__content-right">
    <p500-ui-dropdown position="bottom" class="c-dropdown c-dropdown--list c-dropdown--list-table" *ngIf="multiActions">
      <ng-template p500UIDropdownHeader let-isOpen="isOpen" let-toggle="toggle">
        <div class="c-table-header__multi-action">
          <button
            (click)="toggle()"
            p500-ui-button
            stroked="true"
            size="xsmall"
            [disabled]="(multiActions.count$ | async) === 0"
            [classIcon]="isOpen ? 'p500-ui-icon-arrows-select-up' : 'p500-ui-icon-arrows-select-down'"
            positionIcon="right"
            color="primary"
          >
            Actions ({{ multiActions.count$ | async }})
          </button>
        </div>
      </ng-template>
      <ng-template p500UIDropdownBody>
        <ng-container *ngIf="(multiActions.actions | nonNullableArray).length; else noActionsTmpl">
          <ng-container *ngFor="let action of multiActions.actions | nonNullableArray">
            <div p500UIDropdownItem class="c-dropdown__item">
              <ng-container p500UIComponentOutlet [component]="action.component" [settings]="action.props"></ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-template>
      <ng-template #noActionsTmpl>
        <div class="ml-10 mr-10">There are no actions for those elements</div>
      </ng-template>
    </p500-ui-dropdown>

    <div class="c-table-header__pagination">
      <p500-ui-pagination [totalItems]="totalItems" [itemsPerPage]="limit" [currentPage]="page" (currentPageChange)="onPageChange($event)">
      </p500-ui-pagination>
    </div>

    <div class="c-table-header__reset-filters d-none d-md-block" *ngIf="displayFilters?.length">
      <button p500-ui-confluent-button color="warn" (click)="removeAllFilters()">Reset Filters</button>
    </div>

    <div #buttonFilters class="c-table-header__filters" *ngIf="filtersModel?.length">
      <button
        p500-ui-button-icon
        size="medium"
        color="primary"
        [attr.data-tag]="'table_button_filters'"
        type="button"
        class="mr-6"
        (click)="onClickToggleFilters()"
        [class.is-active]="isFiltersOpen"
      >
        <p500-ui-icon name="controls-filters"></p500-ui-icon>
      </button>
    </div>
    <div class="c-table-header__search-mobile d-xl-none">
      <p500-ui-dropdown class="c-dropdown c-dropdown--list" position="right">
        <ng-template p500UIDropdownHeader let-isOpen="isOpen" let-toggle="toggle">
          <button p500-ui-button-icon class="mr-6" size="medium" color="primary" (click)="toggle()">
            <p500-ui-icon name="controls-search icon"></p500-ui-icon>
          </button>
        </ng-template>
        <ng-template p500UIDropdownBody>
          <div class="c-dropdown__item">
            <gtd-table-search
              [ngModel]="null"
              (ngModelChange)="setFilterById($event)"
              [placeholder]="'Search by ID...'"
              [normalizeFn]="normalizeId"
            ></gtd-table-search>
          </div>
        </ng-template>
      </p500-ui-dropdown>
    </div>
    <div class="c-table-header__search d-xl-block d-none">
      <gtd-table-search
        [ngModel]="null"
        (ngModelChange)="setFilterById($event)"
        [placeholder]="'Search by ID...'"
        [normalizeFn]="normalizeId"
      ></gtd-table-search>
    </div>
    <div class="c-table-header__settings" #buttonSettings *ngIf="columns">
      <button
        p500-ui-button-icon
        size="medium"
        color="primary"
        [attr.data-tag]="'table_button_settings'"
        type="button"
        class="mr-6"
        (click)="onClickToggleSettings()"
        [class.is-active]="isSettingsOpen"
      >
        <p500-ui-icon name="controls-settings"></p500-ui-icon>
      </button>
    </div>
  </div>
  <ng-container>
    <div *ngIf="displayFilters?.length" class="c-table-header__filter-values">
      <ng-container *ngFor="let filter of displayFilters">
        <button p500-ui-confluent-button color="secondary" class="mr-6">
          {{ filter.name }}: {{ filter.value }}
          <p500-ui-icon size="9px" name="controls-close" (click)="removeFilter(filter.key)"> </p500-ui-icon>
        </button>
      </ng-container>
    </div>
    <div class="c-table-header__search d-md-none">
      <gtd-table-search [ngModel]="null" (ngModelChange)="setFilterById($event)"></gtd-table-search>
    </div>
  </ng-container>
</div>
