import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { BaseLayoutComponent } from './core/layouts/base/base.layout';
import { ConfigurationResolverService } from './core/services/configuration/configuration.resolver.service';
import { DataResolverService } from './core/services/data/data.resolver.service';
import { NgModule } from '@angular/core';
import { UserResolverService } from './core/services/auth/user.resolver.service';

const routes: Routes = [
  {
    path: 'app',
    component: BaseLayoutComponent,
    resolve: {
      userData: UserResolverService,
      dataData: DataResolverService,
      configurationData: ConfigurationResolverService,
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'traffic-distribution',
        loadChildren: () => import('./modules/traffic-distribution/traffic-distribution.module').then((m) => m.TrafficDistributionModule),
      },
      {
        path: 'new-traffic-data',
        loadChildren: () => import('./modules/traffic-data/traffic-data.module').then((m) => m.TrafficDataModule),
      },
      {
        path: 'landings',
        loadChildren: () => import('./modules/landings/landings.module').then((m) => m.LandingsModule),
      },
      {
        path: 'reporting',
        loadChildren: () => import('./modules/reporting/reporting.module').then((m) => m.ReportingModule),
      },
      {
        path: 'pixels-postbacks',
        loadChildren: () => import('./modules/pixels-postbacks/pixels-postbacks.module').then((m) => m.PixelsPostbacksModule),
      },
      {
        path: 'finance-tools',
        loadChildren: () => import('./modules/finance-tools/finance-tools.module').then((m) => m.FinanceToolsModule),
      },
      {
        path: 'injections',
        loadChildren: () => import('./modules/injections/injection.module').then((m) => m.InjectionModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('./modules/notifications/notifications.module').then((m) => m.NotificationsModule),
      },
      {
        path: 'changelog',
        loadChildren: () => import('./modules/changelog/changelog.module').then((m) => m.ChangelogModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'leads-conversions',
        loadChildren: () => import('./modules/leads-conversions/leads-conversions.module').then((m) => m.LeadsConversionsModule),
      },
    ],
  },
  {
    path: 'auth',
    data: {
      public: true,
    },
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  { path: '', redirectTo: 'app/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'app/dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
