<div class="c-field-value" [ngSwitch]="data | lowercase">
  <ng-container *ngSwitchCase="'system'">
    <span class="c-field-value__text">System</span>
  </ng-container>
  <ng-container *ngSwitchCase="'error'">
    <span class="c-field-value__text text-color--yellow">Error</span>
  </ng-container>
  <ng-container *ngSwitchCase="'danger'">
    <span class="c-field-value__text text-color--orange">Danger</span>
  </ng-container>
  <ng-container *ngSwitchCase="'critical'">
    <span class="c-field-value__text text-color--red">Critical</span>
  </ng-container>
  <gtd-ui-field-default-string-or-empty *ngSwitchDefault [data]="data"></gtd-ui-field-default-string-or-empty>
</div>
