import { InjectionToken } from '@angular/core';

interface ISidebarService {
  getMenuSection(): string;

  setMenuSection(value: string);

  isCurrent(value: string);
}

const ISIDEBAR_SERVICE = new InjectionToken<ISidebarService>('ISidebarService');

export { ISidebarService, ISIDEBAR_SERVICE };
