import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';

import { IENVIRONMENT_SERVICE, Value, IEnvironmentService } from '../environment/environment.interface';
import { IActionsService } from './actions-service.interface';
import { ActionConfigInterface } from './interfaces/action-config.interface';
import { DataResponse } from './responses/data.response';
import { ValidationResponse } from './responses/validation.response';

@Injectable()
export class ActionsService implements IActionsService {
  private endpointApi: Value;

  constructor(private http: HttpClient, @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService) {
    this.endpointApi = this.envService.getVar('services.api');
  }

  public getData(actionConfig: ActionConfigInterface, id: number, actionArguments: object = {}): Observable<DataResponse> {
    const requestParams = {
      id: id ?? undefined,
      action: actionConfig.getKey(),
      arguments: actionArguments,
      repository: actionConfig.getData(),
    };

    return this.http.post(this.endpointApi + '/action/data', requestParams).pipe(
      map((response: any) => {
        return new DataResponse(response.data);
      }),
      share(),
      catchError(() => {
        return of(new DataResponse());
      })
    );
  }

  public process(
    actionConfig: ActionConfigInterface,
    data: object,
    id: number,
    additionalArguments: object = {}
  ): Observable<DataResponse> {
    const requestParams: any = {
      id: id ?? undefined,
      action: actionConfig.getKey(),
      repository: actionConfig.getData(),
      arguments: {
        ...additionalArguments,
        ...data,
      },
    };

    return this.http.post(`${this.endpointApi}/action/process`, requestParams).pipe(
      map((res: any) => {
        return new DataResponse(res.data);
      }),
      share(),
      catchError((err: any) => {
        if (err.error.validation_errors) {
          return throwError(new ValidationResponse(err.error.validation_errors));
        } else {
          // todo: log error
          return throwError({
            message: err.error_message,
          });
        }
      })
    );
  }
}
